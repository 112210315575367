import {
  DrawerFooter as ChakraDrawerFooter,
  ModalFooterProps,
  PositionProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type DrawerFooterProps = ModalFooterProps & {
  children: ReactNode;
  isSticky?: boolean;
};

export const DrawerFooter = ({
  children,
  isSticky = false,
  ...rest
}: DrawerFooterProps) => {
  const stickyStyle = isSticky
    ? {
        position: 'sticky' as PositionProps['position'],
        bottom: '0',
        backgroundColor: 'white',
        zIndex: 1200,
      }
    : {};

  return (
    <ChakraDrawerFooter {...stickyStyle} {...rest}>
      {children}
    </ChakraDrawerFooter>
  );
};
