import * as Sentry from '@sentry/browser';

export const initErrorTracking = (
  errorCrashTrackingId: string,
  environment: string,
) => {
  Sentry.init({
    dsn: errorCrashTrackingId,
    environment,
  });
};
