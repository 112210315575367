import { Box, BoxProps, Center, Flex, Image } from '@chakra-ui/react';
import { useImage } from '@libs/core/theme/utils';
import React, { ReactNode } from 'react';
import { HeaderStyled } from './header.style';
import { LogoutButton } from './logout-button';
import { UserProfile } from './user-profile';

type HeaderProps = BoxProps & {
  withoutProfile?: boolean;
  children?: ReactNode;
};

export const Header = ({ withoutProfile, children, ...rest }: HeaderProps) => {
  const logoImg = useImage('logo');

  return (
    <HeaderStyled>
      <Flex
        justify={{ base: 'flex-end', lg: 'space-between' }}
        width="100%"
        wrap="wrap"
      >
        <Box
          width="23rem"
          ml="0"
          display={{ base: 'none', lg: 'block' }}
          p="2rem 0"
        >
          <Image h="3rem" alt="logo" src={logoImg} />
        </Box>
        <Box width="calc(50% - 23rem)" {...rest}>
          {children}
        </Box>
        <Center justifyContent="flex-end" width="50%" px="2rem">
          {!withoutProfile ? <UserProfile /> : <LogoutButton />}
        </Center>
      </Flex>
    </HeaderStyled>
  );
};
