import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { ReleaseVersion } from '@libs/dashboard-core/components/version';
import { Logo } from '@libs/ui/components';
import { useImage } from '@libs/core/theme/utils';
import React, { ReactNode } from 'react';

type AuthLayoutProps = {
  children?: ReactNode;
  title: string;
  introduction?: string;
};

export const AuthLayout = ({
  children,
  title,
  introduction,
}: AuthLayoutProps) => {
  const logoImg = useImage('logoDark');
  const loginImg = useImage('login');

  return (
    <Flex align="center" width="100%" wrap="wrap">
      <Box
        position="fixed"
        top="6rem"
        left={{ base: '', md: '6rem' }}
        zIndex="4"
        pt={{ base: '2rem', md: '0' }}
        display={{ base: 'flex', md: '' }}
        width={{ base: '100vw', md: '' }}
        justifyContent={{ base: 'center', md: 'inherit' }}
      >
        <Logo src={logoImg} alt="logo" />
      </Box>
      <Box
        zIndex="500"
        position="absolute"
        w={{ base: '100%', md: '52rem' }}
        bottom="1rem"
      >
        <ReleaseVersion
          isOpen={true}
          color={{ base: 'white', md: 'ink.medium' }}
        />
      </Box>
      <Box
        p="relative"
        w={{ base: '100%', md: '52rem' }}
        padding={{ base: '2rem', lg: '0' }}
      >
        <Box
          maxWidth="42rem"
          position="relative"
          m="0 auto"
          zIndex="3"
          borderRadius=".8rem"
          px={{ base: '4rem', md: '2rem' }}
          py="4rem"
          bgColor={{ base: 'ink.white', md: 'transparent' }}
        >
          <Box mb="4rem">
            <Heading
              size="XLarge"
              as="h2"
              display={{ base: 'none', md: 'block' }}
            >
              {title}
            </Heading>
            <Heading
              textAlign="center"
              size="Title2"
              as="h2"
              display={{ base: 'block', md: 'none' }}
            >
              {title}
            </Heading>
            {introduction ? (
              <Text as="p" mt="1rem">
                {introduction}
              </Text>
            ) : null}
          </Box>
          {children}
        </Box>
      </Box>
      <Box minH="100vh">
        <Box
          position="fixed"
          zIndex="2"
          top="0"
          right="0"
          minH="100vh"
          width={{ base: '100%', md: 'calc(100% - 52rem)' }}
          backgroundImage={`url('${loginImg}')`}
          backgroundPosition="center bottom"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        ></Box>
      </Box>
    </Flex>
  );
};
