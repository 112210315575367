import {
  Box,
  BoxProps,
  Flex,
  useBreakpointValue,
  useTheme,
} from '@chakra-ui/react';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';

type OnboardingProgressBarItemWrapperProps = BoxProps & {
  checked?: boolean;
  vLine?: boolean;
  isOpen?: boolean;
  modActive?: boolean;
};

export const OnboardingProgressBarItemWrapper = ({
  children,
  checked,
  vLine = true,
  isOpen,
  modActive,
  ...rest
}: OnboardingProgressBarItemWrapperProps) => {
  const theme = useTheme();
  const Check = useIcon('Check');
  const breakpoint = useBreakpointValue({ base: 'base', lg: 'lg' });
  return (
    <Flex position="relative">
      <Box
        position="relative"
        w="2rem"
        h="2rem"
        borderRadius="full"
        borderColor="nav.type.normal"
        borderWidth=".3rem"
        display="inline-block"
        bgColor={modActive ? 'nav.type.normal' : 'nav.background.sidebar'}
        zIndex="6"
        {...rest}
      >
        {checked && <Check fill={theme.colors.status.positive.normal} />}
      </Box>
      {vLine && (
        <Box
          position="absolute"
          zIndex="5"
          w=".3rem"
          bgColor="nav.type.normal"
          height="calc(100% + 3rem)"
          left=".85rem"
          top="0"
          {...rest}
        />
      )}
      <Box
        as="span"
        width="calc(100% - 2rem)"
        pl=".6rem"
        display={isOpen || breakpoint === 'lg' ? 'block' : 'none'}
      >
        {children}
      </Box>
    </Flex>
  );
};
