import { Heading, Text, useDisclosure } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { CardInfoTile, Drawer } from '@libs/ui/components';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

type OnboardingSuccessWalletProps = {
  walletIban: string;
};

export const OnboardingSuccessWallet = ({
  walletIban,
}: OnboardingSuccessWalletProps) => {
  const CardWallet = useIcon('CardWallet');
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <CardInfoTile
        mt="2rem"
        onClick={onOpen}
        colorLight="main.secondary.lighter"
        colorDark="main.secondary.normal"
        icon={<CardWallet />}
        title={t(i18nKeys.common.topup_wallet)}
        introduction={t(i18nKeys.wallet_form.short_introduction)}
      />
      <Drawer
        title={t(i18nKeys.common.topup_wallet)}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <Drawer.Body>
          <Text size="Body1" color="ink.dark" mb="3rem">
            {t(i18nKeys.wallet_form.introduction)}
          </Text>

          <Heading size="Body1">{t(i18nKeys.common.iban)}</Heading>
          <Text>{walletIban}</Text>
        </Drawer.Body>
      </Drawer>
    </>
  );
};
