import * as React from 'react';

function SvgClock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.75C12.5523 5.75 13 6.19772 13 6.75V11H17.25C17.8023 11 18.25 11.4477 18.25 12C18.25 12.5523 17.8023 13 17.25 13H12C11.4477 13 11 12.5523 11 12V6.75C11 6.19772 11.4477 5.75 12 5.75Z"
      />
    </svg>
  );
}

export default SvgClock;
