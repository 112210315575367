/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type { ResetPasswordBody, ForgotPasswordBody } from '../../models';
import type { GetConfigurationResponse } from '../../models/ttps:--github.com-skiprco-booking-api-doc-blob-development-component-auth';
import type {
  Phone,
  Email,
} from '../../models/ttps:--github.com-skiprco-booking-api-doc-blob-development-component-common';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const resetPassword = <Data = unknown>(
  resetPasswordBody: ResetPasswordBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/auth/reset-password`, method: 'post', data: resetPasswordBody },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useResetPassword = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof resetPassword>,
    Error,
    { data: ResetPasswordBody },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof resetPassword>,
    Error,
    { data: ResetPasswordBody }
  >((props) => {
    const { data } = props || {};

    return resetPassword<Data>(data, requestOptions);
  }, mutationOptions);
};
export const forgotPassword = <Data = unknown>(
  forgotPasswordBody: ForgotPasswordBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    {
      url: `/v2/auth/forgot-password`,
      method: 'post',
      data: forgotPasswordBody,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useForgotPassword = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof forgotPassword>,
    Error,
    { data: ForgotPasswordBody },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof forgotPassword>,
    Error,
    { data: ForgotPasswordBody }
  >((props) => {
    const { data } = props || {};

    return forgotPassword<Data>(data, requestOptions);
  }, mutationOptions);
};
export const getConfigurationByPhoneNumber = <Data = unknown>(
  phonenumber: Phone,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? GetConfigurationResponse : Data>(
    {
      url: `/v2/auth/configuration/phone_number/${phonenumber}`,
      method: 'get',
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGetConfigurationByPhoneNumberQueryKey = (
  phonenumber: Phone,
) => [`/v2/auth/configuration/phone_number/${phonenumber}`];

export const useGetConfigurationByPhoneNumber = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  phonenumber: Phone,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getConfigurationByPhoneNumber>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGetConfigurationByPhoneNumberQueryKey(phonenumber);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof getConfigurationByPhoneNumber>,
    Error
  >(
    queryKey,
    () => getConfigurationByPhoneNumber<Data>(phonenumber, requestOptions),
    { enabled: !!phonenumber, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const getConfigurationByEmail = <Data = unknown>(
  email: Email,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? GetConfigurationResponse : Data>(
    { url: `/v2/auth/configuration/email/${email}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGetConfigurationByEmailQueryKey = (email: Email) => [
  `/v2/auth/configuration/email/${email}`,
];

export const useGetConfigurationByEmail = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  email: Email,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getConfigurationByEmail>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGetConfigurationByEmailQueryKey(email);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof getConfigurationByEmail>,
    Error
  >(queryKey, () => getConfigurationByEmail<Data>(email, requestOptions), {
    enabled: !!email,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
