import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import {
  expensesCreate,
  expensesGet,
  getExpensesGetQueryKey,
} from '@libs/api/endpoints';
import {
  Budget,
  ExpenseAllOfType,
  ExpenseCategoryCode,
  ExpensesGetParams,
  Registration,
  ValidationStatus,
} from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import {
  DateField,
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  InputField,
  SelectField,
  SwitchField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { EmployeeExpenseCreateFormData } from '../../employee.types';
import { validationEmployeeExpensesSchema } from '../../employee.validation';

type ExpenseCreateDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  employee: Registration;
  budget: Budget;
  expensesGetParams: ExpensesGetParams;
};

export const ExpenseCreateDrawer = ({
  employee,
  budget,
  expensesGetParams,
  isOpen,
  onClose,
}: ExpenseCreateDrawerProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createExpense, isLoading } = useMutation(
    ({ values }: FormSubmitData<EmployeeExpenseCreateFormData>) => {
      delete values.validation_status;
      return expensesCreate({
        ...values,
        amount: values.amount * 100,
        budget_id: budget.id,
        membership_id: employee.membership_id,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: t(
            i18nKeys.operator.employees.expenses.create.expense_success.title,
          ),
          content: t(
            i18nKeys.operator.employees.expenses.create.expense_success.content,
          ),
        });
        const queryKey = getExpensesGetQueryKey(expensesGetParams);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey, () => expensesGet(expensesGetParams));
        onClose();
      },
    },
  );

  const availableExpenseTypes = [
    ExpenseAllOfType.PRO,
    ExpenseAllOfType.PRIVATE,
  ];

  const initialValues = {
    title: 'Swapfiets:',
    category_code: ExpenseCategoryCode.bike,
    is_home_work: false,
    type: ExpenseAllOfType.PRIVATE,
    amount: 0,
    expense_occurred_at: '',
    justification: '',
    provider_name: '',
    validation_status: ValidationStatus.NO_APPROVAL_REQUIRED,
  };

  return (
    <Drawer
      title={t(i18nKeys.operator.employees.expenses.swapfiets.add_expense)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Formik<EmployeeExpenseCreateFormData>
        onSubmit={handleFormSubmit(createExpense)}
        initialValues={initialValues}
        validate={validateSchema(validationEmployeeExpensesSchema)}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Drawer.Body>
              <InputField
                name="title"
                label={t(i18nKeys.common.title)}
                isRequired
              />
              <InputField
                name="provider_name"
                label={t(i18nKeys.common.provider_name)}
              />
              <SelectField
                name="category_code"
                label={t(i18nKeys.common.expense_category_code)}
                isRequired
              >
                {Object.values(ExpenseCategoryCode).map((category) => (
                  <option key={category} value={category}>
                    {t(i18nKeys.common.ExpenseCategoryCode[category])}
                  </option>
                ))}
              </SelectField>
              <SwitchField
                name="is_home_work"
                label={t(i18nKeys.common.is_home_work)}
              />
              <SelectField
                name="type"
                label={t(i18nKeys.common.type)}
                isRequired
              >
                {availableExpenseTypes.map((value) => (
                  <option key={value} value={value}>
                    {t(i18nKeys.common.ExpenseType[value])}
                  </option>
                ))}
              </SelectField>
              <SelectField
                name="validation_status"
                label={t(i18nKeys.common.status)}
                isDisabled
                isRequired
              >
                {Object.values(ValidationStatus).map((status) => (
                  <option key={status} value={status}>
                    {t(i18nKeys.common.ValidationStatus[status])}
                  </option>
                ))}
              </SelectField>
              <InputField
                type="number"
                name="amount"
                label={t(i18nKeys.common.amount)}
                isRequired
              />
              <DateField
                name="expense_occurred_at"
                label={t(i18nKeys.common.date)}
                isRequired
              />
              <InputField
                name="justification"
                label={t(i18nKeys.common.justification)}
              />
            </Drawer.Body>
            <Drawer.Footer>
              <Flex w="100%" justify="space-between">
                <Button
                  size="body2"
                  w="auto"
                  variant="border"
                  onClick={onClose}
                >
                  {t(i18nKeys.common.cancel)}
                </Button>
                <Button
                  type="submit"
                  size="body2"
                  w="auto"
                  variant="primary"
                  disabled={!isValid || !dirty}
                  isLoading={isLoading}
                  loadingText={t(i18nKeys.common.submit)}
                >
                  {t(i18nKeys.common.submit)}
                </Button>
              </Flex>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
