import { List } from '@chakra-ui/react';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import React, { ReactNode } from 'react';
import { MenuItem } from './components/menu-item';

export type MenuItems = {
  route: Routes;
  exact?: boolean;
  icon: ReactNode;
  title: string;
  visible?: boolean;
  children?: MenuItems;
}[];

type MenuProps = {
  isOpen?: boolean;
  items: MenuItems;
  level?: number;
};

export const Menu = ({ isOpen, items, level }: MenuProps) => {
  if (items.length < 1) {
    return null;
  }

  return (
    <List>
      {items.map(
        ({ route, exact = false, icon, title, visible = true, children }) =>
          visible && (
            <MenuItem
              key={title}
              isOpen={isOpen}
              icon={icon}
              link={getPath(route)}
              exact={exact}
              items={children}
              level={level}
            >
              {title}
            </MenuItem>
          ),
      )}
    </List>
  );
};
