export type AuthErrorCode =
  | 'auth/user-not-found'
  | 'auth/wrong-password'
  | 'auth/too-many-requests'
  | 'auth/webview-unauthorized';

export type AuthError = {
  code: AuthErrorCode;
  message: string;
};

export const AuthErrorCode = {
  user_not_found: 'auth/user-not-found' as AuthErrorCode,
  wrong_password: 'auth/wrong-password' as AuthErrorCode,
  too_many_requests: 'auth/too-many-requests' as AuthErrorCode,
  webview_unauthorized: 'auth/webview-unauthorized' as AuthErrorCode,
};
