import { Box, Flex, Heading, List, ListProps } from '@chakra-ui/react';
import { useInfiniteScroll } from '@libs/core/utils';
import React, { ReactNode, useRef } from 'react';
import { Loader } from '../loader';

export type ScrollableListProps = ListProps & {
  children: ReactNode;
  title?: ReactNode;
  rightElement?: ReactNode;
  isLoading: boolean;
  callback: () => void;
  hasMore?: boolean;
};

export const ScrollableList = ({
  title,
  children,
  rightElement,
  isLoading,
  callback,
  hasMore,
  ...rest
}: ScrollableListProps) => {
  const loaderRef = useRef(null);
  useInfiniteScroll({ callback, loaderRef, hasMore });

  const heading = (
    <Heading
      mb={rightElement ? 0 : 5}
      verticalAlign="center"
      variant="content"
      as="h1"
      size="600"
    >
      {title}
    </Heading>
  );
  return (
    <>
      {rightElement ? (
        <Flex mb="8" align="center" justify="space-between">
          {heading}
          {rightElement}
        </Flex>
      ) : (
        heading
      )}

      <List {...rest}>{children}</List>
      {isLoading && (
        <Box position="relative" h="8rem">
          <Loader />
        </Box>
      )}
      <div id="infinite-scroll-loader" ref={loaderRef} />
    </>
  );
};
