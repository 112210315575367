import { FormikErrors, getIn, setIn } from 'formik';
import * as Yup from 'yup';

export function yupToFormErrors<Values>(
  yupError: Yup.ValidationError,
): FormikErrors<Values> {
  if (!yupError.inner) {
    return {};
  }
  if (yupError.inner.length === 0) {
    return setIn({}, yupError.path, [yupError.message]);
  }

  return yupError.inner.reduce((acc, err) => {
    if (!err.path) {
      return acc;
    }
    const currentErrors = getIn(acc, err.path, []);
    return setIn(acc, err.path, [...currentErrors, err.message]);
  }, {});
}

export const validateSchema = <T, C = Record<string, unknown>>(
  schema: Yup.BaseSchema<T, C>,
) => async <Values>(values: Values) => {
  try {
    await schema.validate(values, {
      abortEarly: false,
    });
    return {};
  } catch (e) {
    return yupToFormErrors(e);
  }
};
