/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type Resource = 'expenses' | 'km_allowances';

export const Resource = {
  expenses: 'expenses' as Resource,
  km_allowances: 'km_allowances' as Resource,
};
