export const Alert = {
  baseStyle: {
    padding: '1.5rem',
  },
  variants: {
    errorStyle: ({ theme }) => ({
      container: {
        backgroundColor: theme?.colors.status.negative.light,
        borderRadius: '.6rem',
        border: '.2rem solid',
        borderWidth: '.1rem',
        borderColor: theme?.colors.status.negative.type,
        padding: '1.5rem',
      },
    }),
  },
};
