/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseRefundStatus =
  | 'NONE'
  | 'REPORTED'
  | 'PENDING'
  | 'PAID'
  | 'CANCELED';

export const ExpenseRefundStatus = {
  NONE: 'NONE' as ExpenseRefundStatus,
  REPORTED: 'REPORTED' as ExpenseRefundStatus,
  PENDING: 'PENDING' as ExpenseRefundStatus,
  PAID: 'PAID' as ExpenseRefundStatus,
  CANCELED: 'CANCELED' as ExpenseRefundStatus,
};
