/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  SplittableTransactionsResponse,
  ListSplittableTransactionsParams,
  SplittableTransaction,
  UpdateSplittableTransactionRequest,
  SplitPayment,
  SplittableTransactionProducts,
  GetEligibleSplittableTransactionProductsParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const listSplittableTransactions = <Data = unknown>(
  params?: ListSplittableTransactionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? SplittableTransactionsResponse : Data
  >(
    { url: `/v2/splittable_transactions`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getListSplittableTransactionsQueryKey = (
  params?: ListSplittableTransactionsParams,
) => [`/v2/splittable_transactions`, ...(params ? [params] : [])];

export const useListSplittableTransactions = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ListSplittableTransactionsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof listSplittableTransactions>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getListSplittableTransactionsQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof listSplittableTransactions>,
    Error
  >(
    queryKey,
    () => listSplittableTransactions<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateSplittableTransaction = <Data = unknown>(
  splittabletransactionid: string,
  updateSplittableTransactionRequest: UpdateSplittableTransactionRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? SplittableTransaction : Data>(
    {
      url: `/v2/splittable_transactions/${splittabletransactionid}`,
      method: 'patch',
      data: updateSplittableTransactionRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useUpdateSplittableTransaction = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateSplittableTransaction>,
    Error,
    {
      splittabletransactionid: string;
      data: UpdateSplittableTransactionRequest;
    },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof updateSplittableTransaction>,
    Error,
    {
      splittabletransactionid: string;
      data: UpdateSplittableTransactionRequest;
    }
  >((props) => {
    const { splittabletransactionid, data } = props || {};

    return updateSplittableTransaction<Data>(
      splittabletransactionid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const splittableTransactionEmployeePartPayment = <Data = unknown>(
  splittabletransactionid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? SplitPayment : Data>(
    {
      url: `/v2/splittable_transactions/${splittabletransactionid}/employee_payment`,
      method: 'post',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useSplittableTransactionEmployeePartPayment = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof splittableTransactionEmployeePartPayment>,
    Error,
    { splittabletransactionid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof splittableTransactionEmployeePartPayment>,
    Error,
    { splittabletransactionid: string }
  >((props) => {
    const { splittabletransactionid } = props || {};

    return splittableTransactionEmployeePartPayment<Data>(
      splittabletransactionid,
      requestOptions,
    );
  }, mutationOptions);
};
export const getEligibleSplittableTransactionProducts = <Data = unknown>(
  params?: GetEligibleSplittableTransactionProductsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? SplittableTransactionProducts : Data
  >(
    {
      url: `/v2/splittable_transactions/eligible_products`,
      method: 'get',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGetEligibleSplittableTransactionProductsQueryKey = (
  params?: GetEligibleSplittableTransactionProductsParams,
) => [
  `/v2/splittable_transactions/eligible_products`,
  ...(params ? [params] : []),
];

export const useGetEligibleSplittableTransactionProducts = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: GetEligibleSplittableTransactionProductsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getEligibleSplittableTransactionProducts>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGetEligibleSplittableTransactionProductsQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof getEligibleSplittableTransactionProducts>,
    Error
  >(
    queryKey,
    () =>
      getEligibleSplittableTransactionProducts<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
