/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  MerchantRestrictionGroupsGetResponse,
  MerchantCategoryRestrictionGroupsGetParams,
  MerchantCategoryRestrictionGroupGet200,
  MerchantRestrictionGroup,
  MerchantRestrictionGroupsRequest,
  MerchantCategoryRestrictionGroupMerchantsListUpdateParams,
  MerchantCategoryRestrictionGroupMerchantsListDeleteParams,
  MerchantRestrictionGroupsGetPresenceResponse,
  MerchantCategoryRestrictionGroupsGetPresenceParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const merchantCategoryRestrictionGroupsGet = <Data = unknown>(
  params?: MerchantCategoryRestrictionGroupsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MerchantRestrictionGroupsGetResponse : Data
  >(
    { url: `/merchantCategoryRestrictionGroups`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMerchantCategoryRestrictionGroupsGetQueryKey = (
  params?: MerchantCategoryRestrictionGroupsGetParams,
) => [`/merchantCategoryRestrictionGroups`, ...(params ? [params] : [])];

export const useMerchantCategoryRestrictionGroupsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: MerchantCategoryRestrictionGroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof merchantCategoryRestrictionGroupsGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMerchantCategoryRestrictionGroupsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupsGet>,
    Error
  >(
    queryKey,
    () => merchantCategoryRestrictionGroupsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const merchantCategoryRestrictionGroupGet = <Data = unknown>(
  mccrestrictionid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MerchantCategoryRestrictionGroupGet200 : Data
  >(
    {
      url: `/merchantCategoryRestrictionGroups/${mccrestrictionid}`,
      method: 'get',
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMerchantCategoryRestrictionGroupGetQueryKey = (
  mccrestrictionid: string,
) => [`/merchantCategoryRestrictionGroups/${mccrestrictionid}`];

export const useMerchantCategoryRestrictionGroupGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  mccrestrictionid: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof merchantCategoryRestrictionGroupGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMerchantCategoryRestrictionGroupGetQueryKey(
    mccrestrictionid,
  );
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupGet>,
    Error
  >(
    queryKey,
    () =>
      merchantCategoryRestrictionGroupGet<Data>(
        mccrestrictionid,
        requestOptions,
      ),
    { enabled: !!mccrestrictionid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const merchantCategoryRestrictionGroupUpdate = <Data = unknown>(
  mccrestrictionid: string,
  merchantRestrictionGroupsRequest: MerchantRestrictionGroupsRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? MerchantRestrictionGroup : Data>(
    {
      url: `/merchantCategoryRestrictionGroups/${mccrestrictionid}`,
      method: 'put',
      data: merchantRestrictionGroupsRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMerchantCategoryRestrictionGroupUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupUpdate>,
    Error,
    { mccrestrictionid: string; data: MerchantRestrictionGroupsRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupUpdate>,
    Error,
    { mccrestrictionid: string; data: MerchantRestrictionGroupsRequest }
  >((props) => {
    const { mccrestrictionid, data } = props || {};

    return merchantCategoryRestrictionGroupUpdate<Data>(
      mccrestrictionid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const merchantCategoryRestrictionGroupMerchantsListUpdate = <
  Data = unknown
>(
  mccrestrictionid: string,
  params?: MerchantCategoryRestrictionGroupMerchantsListUpdateParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? MerchantRestrictionGroup : Data>(
    {
      url: `/merchantCategoryRestrictionGroups/${mccrestrictionid}/merchants`,
      method: 'put',
      data: undefined,
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMerchantCategoryRestrictionGroupMerchantsListUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupMerchantsListUpdate>,
    Error,
    {
      mccrestrictionid: string;
      params?: MerchantCategoryRestrictionGroupMerchantsListUpdateParams;
    },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupMerchantsListUpdate>,
    Error,
    {
      mccrestrictionid: string;
      params?: MerchantCategoryRestrictionGroupMerchantsListUpdateParams;
    }
  >((props) => {
    const { mccrestrictionid, params } = props || {};

    return merchantCategoryRestrictionGroupMerchantsListUpdate<Data>(
      mccrestrictionid,
      params,
      requestOptions,
    );
  }, mutationOptions);
};
export const merchantCategoryRestrictionGroupMerchantsListDelete = <
  Data = unknown
>(
  mccrestrictionid: string,
  params?: MerchantCategoryRestrictionGroupMerchantsListDeleteParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? MerchantRestrictionGroup : Data>(
    {
      url: `/merchantCategoryRestrictionGroups/${mccrestrictionid}/merchants`,
      method: 'delete',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMerchantCategoryRestrictionGroupMerchantsListDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupMerchantsListDelete>,
    Error,
    {
      mccrestrictionid: string;
      params?: MerchantCategoryRestrictionGroupMerchantsListDeleteParams;
    },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupMerchantsListDelete>,
    Error,
    {
      mccrestrictionid: string;
      params?: MerchantCategoryRestrictionGroupMerchantsListDeleteParams;
    }
  >((props) => {
    const { mccrestrictionid, params } = props || {};

    return merchantCategoryRestrictionGroupMerchantsListDelete<Data>(
      mccrestrictionid,
      params,
      requestOptions,
    );
  }, mutationOptions);
};
export const merchantCategoryRestrictionGroupsGetPresence = <Data = unknown>(
  mccrestrictionid: string,
  params?: MerchantCategoryRestrictionGroupsGetPresenceParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MerchantRestrictionGroupsGetPresenceResponse : Data
  >(
    {
      url: `/merchantCategoryRestrictionGroups/${mccrestrictionid}/presence`,
      method: 'get',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMerchantCategoryRestrictionGroupsGetPresenceQueryKey = (
  mccrestrictionid: string,
  params?: MerchantCategoryRestrictionGroupsGetPresenceParams,
) => [
  `/merchantCategoryRestrictionGroups/${mccrestrictionid}/presence`,
  ...(params ? [params] : []),
];

export const useMerchantCategoryRestrictionGroupsGetPresence = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  mccrestrictionid: string,
  params?: MerchantCategoryRestrictionGroupsGetPresenceParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof merchantCategoryRestrictionGroupsGetPresence>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMerchantCategoryRestrictionGroupsGetPresenceQueryKey(
    mccrestrictionid,
    params,
  );
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof merchantCategoryRestrictionGroupsGetPresence>,
    Error
  >(
    queryKey,
    () =>
      merchantCategoryRestrictionGroupsGetPresence<Data>(
        mccrestrictionid,
        params,
        requestOptions,
      ),
    { enabled: !!mccrestrictionid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
