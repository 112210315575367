import { Text } from '@chakra-ui/layout';
import { useServicesGet } from '@libs/api-v2/endpoints/services/services';
import { ProgramsGetParams, ProgramsGetResponse } from '@libs/api-v2/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { Table, Tag } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { ProgramStatusToTagStatus } from '../../employee.utils';
import { ProgramServicesDrawer } from './program-services-drawer';
import { ServiceTable } from './services-table';

// TODO: merge with ProgramsTable?

type ProgramTableProps = {
  programs: ProgramsGetResponse;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<ProgramsGetResponse, unknown>>;
  isLoading: boolean;
  isFetching: boolean;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  pageSize?: number;
  programsGetParams: ProgramsGetParams;
};

export const ProgramTable = ({
  programs,
  refetch,
  isLoading,
  isFetching,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
}: ProgramTableProps) => {
  const { t } = useTranslation();
  const EyeIcon = useIcon('Eye');
  const {
    drawerHandler,
    isOpen,
    onClose,
    activeDrawerIndex,
    selectedDrawerType,
  } = useDrawer();

  const { useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  useQueryParams({
    totalPages: programs?.meta.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  useEffect(() => {
    refetch();
  }, [refetch, pageSize]);

  const headers: TableHeaders = [
    {
      name: 'type',
      title: t(i18nKeys.common.program),
    },
    {
      name: 'start_at',
      title: t(i18nKeys.common.start_at),
    },
    { name: 'status', title: t(i18nKeys.common.status) },
    '',
  ];

  return (
    <>
      <Table headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !programs}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (programs?.data.length === 0 || !programs)
          }
        >
          {programs?.data.map(({ id, name, start_date, status }, index) => {
            return (
              <Fragment key={`${id}`}>
                <Table.TR
                  selectable
                  position="relative"
                  transform="scale(1,1)"
                  index={id}
                >
                  <Table.TD>
                    <Text>{name}</Text>
                  </Table.TD>
                  <Table.Date>{start_date}</Table.Date>
                  <Table.TD>
                    <Tag
                      hasIcon
                      tooltipLabel={
                        <Text size="Small">
                          {t(i18nKeys.common.activation_status[status])}
                        </Text>
                      }
                      status={ProgramStatusToTagStatus[status]}
                    />
                  </Table.TD>
                  <Table.Action
                    actions={[
                      {
                        onClick: () => drawerHandler(index),
                        icon: <EyeIcon width="2rem" height="2rem" />,
                      },
                    ]}
                  />
                </Table.TR>
              </Fragment>
            );
          })}
        </Table.TBody>
      </Table>
      {isOpen && (
        <ProgramServicesDrawer
          program={programs.data[activeDrawerIndex]}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
