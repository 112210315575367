import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import {
  expenseList,
  expensesValidationUpdate,
  getExpenseListQueryKey,
  useExpenseGet,
} from '@libs/api-v2/endpoints/expenses/expenses';
import { Expense, ExpenseStatus } from '@libs/api-v2/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import {
  DescriptionData,
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  InputField,
  SelectField,
  useToast,
} from '@libs/ui/components';
import { useTranslation } from 'react-i18next';
import { Direction } from '@libs/core/types/direction';
import { Form, Formik } from 'formik';
import { ExpenseUpdateReviewStatusFormData } from '../../employee/employee.types';
import { useMutation, useQueryClient } from 'react-query';
import { ExpensesGetParams } from '@libs/api/models';

type ExpenseUpdateDrawerProps = {
  expense: Expense;
  isOpen: boolean;
  onClose: () => void;
  expensesGetParams: ExpensesGetParams;
};

export const ExpenseUpdateDrawer = ({
  expense,
  isOpen,
  onClose,
  expensesGetParams,
}: ExpenseUpdateDrawerProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { data: expenseUpdate, isLoading } = useExpenseGet(expense.id);

  const {
    mutateAsync: updateExpense,
    isLoading: isLoadingMutation,
  } = useMutation(
    ({ values }: FormSubmitData<ExpenseUpdateReviewStatusFormData>) => {
      return expensesValidationUpdate(id, {
        review_comment: values.review_comment,
        review_status:
          values.review_status == ExpenseStatus.REFUSED
            ? ExpenseStatus.REFUSED
            : ExpenseStatus.NEED_MORE_INFO,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: t(
            i18nKeys.operator.employees.expenses.update.expense_success.title,
          ),
          content: t(
            i18nKeys.operator.employees.expenses.update.expense_success.content,
          ),
        });
        const queryKey = getExpenseListQueryKey(expensesGetParams);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey, () => expenseList(expensesGetParams));
        onClose();
      },
    },
  );

  if (isLoading || isLoadingMutation) {
    return null;
  }

  const initialValues = {
    review_status: ExpenseStatus.REFUSED,
    review_comment: '',
  };

  const { id } = expenseUpdate;

  return (
    <Drawer
      title={t(i18nKeys.common.expenses)}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik<ExpenseUpdateReviewStatusFormData>
        onSubmit={handleFormSubmit(updateExpense)}
        initialValues={initialValues}
        // validate={validateSchema(validationEmployeeExpensesSchema)}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Drawer.Body>
              <DescriptionData direction={Direction.horizontal} title={'id'}>
                {id}
              </DescriptionData>
              <SelectField
                name="review_status"
                label={t(i18nKeys.operator.expenses.review_status)}
                isRequired
              >
                {Object.values(ExpenseStatus).map(
                  (expenseStatus) =>
                    (expenseStatus == ExpenseStatus.REFUSED ||
                      expenseStatus == ExpenseStatus.NEED_MORE_INFO) && (
                      <option key={expenseStatus} value={expenseStatus}>
                        {t(i18nKeys.common.ValidationStatus[expenseStatus])}
                      </option>
                    ),
                )}
              </SelectField>
              <InputField
                name="review_comment"
                label={t(i18nKeys.operator.expenses.review_comment)}
                isRequired
              />
            </Drawer.Body>
            <Drawer.Footer>
              <Flex w="100%" justify="space-between">
                <Button
                  size="body2"
                  w="auto"
                  variant="border"
                  onClick={onClose}
                >
                  {t(i18nKeys.common.cancel)}
                </Button>
                <Button
                  type="submit"
                  size="body2"
                  w="auto"
                  variant="primary"
                  disabled={!isValid || !dirty}
                  isLoading={isLoading}
                  loadingText={t(i18nKeys.common.submit)}
                >
                  {t(i18nKeys.common.submit)}
                </Button>
              </Flex>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
