import { documentCreate } from '@libs/api/endpoints';
import { DocumentType, OnboardingStatus } from '@libs/api/models';
import { toBase64 } from '../../utils/converter';
import {
  EXAMPLE_BY_DOCUMENT_TYPE,
  ShareholderDocumentTypeForm,
} from './onboarding-wizard.constants';
import {
  AvailableSteps,
  OnboardingDocumentFormData,
} from './onboarding-wizard.types';

export const getShareholderDocumentsToUpload = (
  documentsInput: OnboardingDocumentFormData,
) => {
  return [
    ...(documentsInput.frontFile.length > 0 &&
    documentsInput.backFile.length > 0
      ? [
          {
            file: documentsInput.frontFile[0],
            document_type: DocumentType.ID_FRONT,
          },
        ]
      : []),
    ...(documentsInput.backFile.length
      ? [
          {
            file: documentsInput.backFile[0],
            document_type: DocumentType.ID_BACK,
          },
        ]
      : []),
    ...(documentsInput.passportFile.length > 0
      ? [
          {
            file: documentsInput.passportFile[0],
            document_type: DocumentType.PASSPORT,
          },
        ]
      : []),
  ];
};

export const getOrganisationDocumentsToUpload = ({
  bce,
  constitution,
}: {
  bce: File[];
  constitution: File[];
}) => [
  ...(bce.length > 0
    ? [{ file: bce[0], document_type: DocumentType.ORGANISATION_BCE }]
    : []),
  ...(constitution.length > 0
    ? [
        {
          file: constitution[0],
          document_type: DocumentType.ORGANISATION_CONSTITUTION,
        },
      ]
    : []),
];

export const useDocumentExample = (
  documentType: ShareholderDocumentTypeForm,
) => {
  const example = EXAMPLE_BY_DOCUMENT_TYPE[documentType];
  if (!example) {
    return [];
  }
  return [example.title, example.image];
};

export const uploadFile = async ({
  file,
  document_type,
}: {
  file: File;
  document_type: DocumentType;
}) => {
  const fileBase64 = await toBase64(file);
  const { url } = await documentCreate({
    file: fileBase64 as string,
    document_type,
  });
  return { url, document_type };
};

export const getWizardStep = (status: OnboardingStatus): AvailableSteps => {
  switch (status) {
    case OnboardingStatus.STARTED:
      return AvailableSteps.CONFIRM_ORGANISATION;
    case OnboardingStatus.ORG_DATA_SENT:
    case OnboardingStatus.UBO_SELECTED:
      return AvailableSteps.CONFIRM_SHAREHOLDER;
    case OnboardingStatus.UBO_DOC_UPLOADED:
      return AvailableSteps.REVIEW_SUBMIT;
    case OnboardingStatus.INTERNAL_REVIEW || OnboardingStatus.BANK_REVIEW:
      return AvailableSteps.UPLOAD_EMPLOYEES;
    default:
      return AvailableSteps.START_ONBOARDING;
  }
};
