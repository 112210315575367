import { Box, Text, useTheme } from '@chakra-ui/react';
import {
  getOrganisationGetQueryKey,
  useOrganisationsGet,
} from '@libs/api/endpoints';
import { Status } from '@libs/api/models';
import { FORMAT_DATE } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useCurrentBreakpointValue } from '@libs/core/theme';
import { capitalize } from '@libs/core/utils';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { selectCell } from '@libs/dashboard-core/utils/select-cell';
import { StatusToTagStatus } from '@libs/dashboard-core/utils/status';
import { Pager, Table, Tag, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useTable } from '@libs/ui/components/table/table.utils';
import { useIcon } from '@libs/core/theme/utils';
import dayjs from 'dayjs';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

export type OrganisationTableProps = {
  status?: Status;
  hasFilter?: boolean;
  hasSearch?: boolean;
  filters?: { name: string; values: string[] }[];
};

export const OrganisationTable = ({
  status,
  hasFilter = true,
  hasSearch = true,
  filters,
}: OrganisationTableProps) => {
  const propStatus =
    status ||
    (filters?.find(({ name }) => name === 'status').values[0] as Status);
  const queryClient = useQueryClient();
  const Edit = useIcon('Edit');
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const { isMobile, isTablet } = useCurrentBreakpointValue();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const {
    filterQuery,
    searchQuery,
    sortQuery,
    setFilterQuery,
    setSearchQuery,
    setSortQuery,
    useQueryParams,
    getFilterValue,
  } = useTable({
    filterQueryParams: [
      {
        name: 'status',
        value: propStatus,
      },
    ],
    setPageNumber,
    setPageSize,
  });

  const {
    // is false when fetching cached values
    isLoading,
    data: organisations,
    refetch,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useOrganisationsGet(
    {
      page_number: pageNumber,
      page_size: pageSize,
      q: searchQuery,
      status: status || (getFilterValue('status') as Status),
      sort: sortQuery?.length > 0 ? sortQuery.toString() : null,
    },
    { query: { keepPreviousData: true } },
  );

  useQueryParams({
    totalPages: organisations?.meta.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  const headers: TableHeaders = ((isMobile || isTablet) && [
    {
      name: 'legal_name',
      title: t(i18nKeys.common.full_name),
      sort: true,
      filter: true,
      filterName: 'status',
      // Status SUGGESTED is not needed for organisation status
      filterValues: Object.values(Status).filter(
        (item) => item !== Status.SUGGESTED,
      ),
    },
    t(i18nKeys.common.vat),
    {
      name: 'kyc_status',
      title: t(i18nKeys.common.kyc_status),
      sort: true,
    },
    '',
  ]) || [
    {
      name: 'legal_name',
      title: t(i18nKeys.common.full_name),
      sort: true,
      filter: true,
      filterName: 'status',
      // Status SUGGESTED is not needed for organisation status
      filterValues:
        filters?.find(({ name }) => name === 'status').values ||
        Object.values(Status).filter((item) => item !== Status.SUGGESTED),
      filterValuesI18nKey: i18nKeys.common.TagStatus,
    },
    t(i18nKeys.common.vat),
    t(i18nKeys.common.created_at),
    {
      name: 'kyc_status',
      title: t(i18nKeys.common.kyc_status),
      sort: true,
    },
    '',
  ];

  return (
    <>
      <Table
        hasFilter={hasFilter}
        hasSearch={hasSearch}
        onSubmit={submitHandler as any}
        initialValues={{ q: '' }}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sortQuery={sortQuery}
        setSortQuery={setSortQuery}
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
        headers={headers}
        searchPlaceholder={t(
          i18nKeys.common.table.organisation.search_placeholder,
        )}
        filterValuesI18nKey={i18nKeys.common.TagStatus}
      >
        <Table.TBody
          isLoading={(isLoading || isFetching) && !organisations}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (organisations?.data.length === 0 || !organisations)
          }
          colSpan={headers.length}
        >
          {organisations?.data.map((organisation) => {
            return (
              <Table.TR
                key={organisation.id}
                selectable
                onClick={() =>
                  selectCell(
                    getOrganisationGetQueryKey(organisation.id),
                    queryClient,
                    organisation,
                    history,
                    getPath(Routes.DASHBOARD_ORGANISATIONS_DETAILS, {
                      params: {
                        organisationId: organisation.id,
                      },
                    }),
                  )
                }
                index={organisation.id}
              >
                <Table.TD>
                  <Tag
                    w="2rem"
                    tooltipLabel={
                      <Text color={theme.colors.ink.dark} size="Small">
                        {t(i18nKeys.common.TagStatus[organisation.status])}
                      </Text>
                    }
                    status={StatusToTagStatus[organisation.status]}
                  />{' '}
                  {capitalize(organisation.legal_name)}
                </Table.TD>
                <Table.TD>{organisation.vat}</Table.TD>
                <Table.Date>{organisation.created_at}</Table.Date>
                <Table.TD>
                  <Box h="3rem">
                    <Tag
                      status={StatusToTagStatus[organisation.kyc_status]}
                      hasIcon
                      tooltipLabel={
                        <>
                          <Text color={theme.colors.ink.dark} size="SmallBold">
                            {t(
                              i18nKeys.common.TagStatus[
                                organisation.kyc_status
                              ],
                            )}
                          </Text>
                          {organisation.kyc_started_on && (
                            <Text
                              color={theme.colors.ink.dark}
                              size="Small"
                            >{`${t(i18nKeys.common.from)} ${dayjs(
                              organisation.kyc_started_on,
                            ).format(FORMAT_DATE)}`}</Text>
                          )}
                        </>
                      }
                    />
                  </Box>
                </Table.TD>
                <Table.Action
                  onClick={() =>
                    selectCell(
                      getOrganisationGetQueryKey(organisation.id),
                      queryClient,
                      organisation,
                      history,
                      getPath(Routes.DASHBOARD_ORGANISATIONS_DETAILS, {
                        params: {
                          organisationId: organisation.id,
                        },
                      }),
                    )
                  }
                  icon={<Edit width="2rem" height="2rem" />}
                />
              </Table.TR>
            );
          })}
        </Table.TBody>
      </Table>
      {organisations && organisations?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={organisations.meta.total_items}
          page_index={organisations.meta.page_index}
          total_pages={organisations.meta.total_pages}
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          mt="2rem"
          isFetching={isFetching}
        />
      )}
    </>
  );
};
