/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type { LoggedUser, Program, DeviceUpdateRequest } from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getCurrentUser = <Data = unknown>(
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? LoggedUser : Data>(
    { url: `/v2/me`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGetCurrentUserQueryKey = () => [`/v2/me`];

export const useGetCurrentUser = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getCurrentUser>, Error>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const queryKey = getGetCurrentUserQueryKey();
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof getCurrentUser>, Error>(
    queryKey,
    () => getCurrentUser<Data>(requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const usersUpdateDevices = <Data = unknown>(
  userid: string,
  deviceUpdateRequest: DeviceUpdateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Program : Data>(
    {
      url: `/v2/users/${userid}/device`,
      method: 'put',
      data: deviceUpdateRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useUsersUpdateDevices = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof usersUpdateDevices>,
    Error,
    { userid: string; data: DeviceUpdateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof usersUpdateDevices>,
    Error,
    { userid: string; data: DeviceUpdateRequest }
  >((props) => {
    const { userid, data } = props || {};

    return usersUpdateDevices<Data>(userid, data, requestOptions);
  }, mutationOptions);
};
