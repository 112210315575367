/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, UseQueryOptions } from 'react-query';
import type { LoggedUser } from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getCurrentUser = <Data = unknown>(
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? LoggedUser : Data>(
    { url: `/me`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGetCurrentUserQueryKey = () => [`/me`];

export const useGetCurrentUser = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getCurrentUser>, Error>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const queryKey = getGetCurrentUserQueryKey();
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof getCurrentUser>, Error>(
    queryKey,
    () => getCurrentUser<Data>(requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
