import * as React from 'react';

function SvgTalk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4956 4.02316C10.5513 3.90149 8.62953 4.49378 7.09104 5.6888C5.55256 6.88382 4.50324 8.59938 4.14013 10.5133C3.77795 12.4223 4.12328 14.3976 5.11125 16.0703C5.25432 16.3036 5.29946 16.5919 5.22051 16.8682L4.45601 19.544L7.13175 18.7795C7.40392 18.7017 7.69278 18.7436 7.92885 18.8883C9.60173 19.8766 11.5774 20.2221 13.4867 19.8599C15.4007 19.4967 17.1162 18.4474 18.3112 16.9089C19.5062 15.3704 20.0985 13.4486 19.9768 11.5043C19.8552 9.56006 19.0279 7.72707 17.6504 6.34958C16.2729 4.97208 14.4399 4.14483 12.4956 4.02316ZM7.27956 20.8173C9.28937 21.8929 11.6113 22.2514 13.8595 21.8248C16.2522 21.3709 18.3968 20.0591 19.8907 18.1358C21.3846 16.2125 22.125 13.81 21.9729 11.3794C21.8208 8.94884 20.7866 6.65739 19.0646 4.93536C17.3426 3.21333 15.0511 2.17916 12.6205 2.02706C10.19 1.87497 7.78746 2.61539 5.86417 4.10931C3.94088 5.60323 2.62911 7.74787 2.17518 10.1405C1.74864 12.3888 2.10708 14.7106 3.18271 16.7204L2.40934 19.4272C2.3236 19.7273 2.31967 20.0448 2.39795 20.347C2.47623 20.6491 2.63388 20.9247 2.85456 21.1454C3.07525 21.3661 3.35093 21.5238 3.65304 21.602C3.95514 21.6803 4.27269 21.6764 4.57277 21.5907L7.27956 20.8173Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10.5C8 9.94772 8.44772 9.5 9 9.5H15C15.5523 9.5 16 9.94772 16 10.5C16 11.0523 15.5523 11.5 15 11.5H9C8.44772 11.5 8 11.0523 8 10.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13.5C8 12.9477 8.44772 12.5 9 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H9C8.44772 14.5 8 14.0523 8 13.5Z"
      />
    </svg>
  );
}

export default SvgTalk;
