import { Heading } from '@chakra-ui/layout';
import { NotionTextArrayItem } from '@libs/api-v2/models/notionTextArrayItem';
import React from 'react';

type ServiceHeadingSpanProps = {
  text: NotionTextArrayItem;
  index: number;
};
export const ServiceHeadingSpan = ({
  text,
  index,
}: ServiceHeadingSpanProps) => {
  // Attributes
  const hasUrl = !!text?.text?.link?.url;

  if (hasUrl) {
    return (
      <Heading
        as={'a'}
        variant={text?.annotations?.bold ? 'bold' : 'normal'}
        target="_blank"
        size="600"
        ml={index !== 0 ? 1 : 0}
        style={{ cursor: 'pointer' }}
      >
        {text?.plain_text}
      </Heading>
    );
  }
  return (
    <Heading
      variant={text?.annotations?.bold ? 'bold' : 'normal'}
      as={'span'}
      size="600"
      ml={index !== 0 ? 1 : 0}
    >
      {text?.plain_text}
    </Heading>
  );
};
