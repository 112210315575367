import { Box } from '@chakra-ui/react';
import { Role } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Menu, MenuItems } from '@libs/dashboard-core/components/menu/menu';
import { useRoleState } from '@libs/dashboard-core/context';
import { Routes } from '@libs/dashboard-core/routes';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const SidebarMenu = ({ isOpen }: { isOpen?: boolean }) => {
  const { t } = useTranslation();
  const { role } = useRoleState();

  const HomeIcon = useIcon('Home');
  const OrganisationIcon = useIcon('Organisation');
  const EmployeeIcon = useIcon('EmptyUserAccount');
  const CreditCardIcon = useIcon('CreditCard');
  const RestrictionGroupIcon = useIcon('RestrictionGroup');
  const MerchantIcon = useIcon('StoreFront');
  const ServiceIcon = useIcon('StoreFront');

  const items: MenuItems = [
    {
      icon: <HomeIcon />,
      route: Routes.DASHBOARD,
      exact: true,
      title: t(i18nKeys.common.home),
      visible: true,
    },
    {
      icon: <OrganisationIcon />,
      route: Routes.DASHBOARD_ORGANISATIONS,
      title: t(i18nKeys.operator.organisations.title),
      visible: role === Role.OPERATOR_ADMIN,
    },
    {
      icon: <EmployeeIcon />,
      route: Routes.DASHBOARD_EMPLOYEES,
      title: t(i18nKeys.common.employees),
      visible: role === Role.OPERATOR_ADMIN,
    },
    {
      icon: <MerchantIcon />,
      route: Routes.DASHBOARD_MERCHANTS,
      title: t(i18nKeys.common.merchants),
      visible: role === Role.OPERATOR_ADMIN,
    },
    {
      icon: <RestrictionGroupIcon />,
      route: Routes.DASHBOARD_RESTRICTION_GROUPS,
      title: t(i18nKeys.common.restriction_groups),
      visible: role === Role.OPERATOR_ADMIN,
    },
    {
      icon: (
        <Box mt=".4rem">
          <CreditCardIcon />
        </Box>
      ),
      route: Routes.DASHBOARD_TRANSACTIONS,
      title: t(i18nKeys.common.transactions),
      visible: role === Role.OPERATOR_ADMIN,
    },
    {
      icon: <ServiceIcon />,
      route: Routes.DASHBOARD_SERVICES,
      title: t(i18nKeys.operator.services.title),
      visible: role === Role.OPERATOR_ADMIN,
    },
    {
      icon: <ServiceIcon />,
      route: Routes.DASHBOARD_EXPENSES,
      title: t(i18nKeys.operator.expenses.title),
      visible: role === Role.OPERATOR_ADMIN,
    },
  ];

  return <Menu isOpen={isOpen} items={items} />;
};
