import { Button, ButtonProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export type PagerButtonProps = ButtonProps & {
  children: ReactNode;
};

export const PagerButton = ({ children, ...rest }: PagerButtonProps) => {
  return (
    <Button mx=".5rem" variant="pagination" size="body1" {...rest}>
      {children}
    </Button>
  );
};
