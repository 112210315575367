import * as React from 'react';

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6464 4.54289C19.0206 4.93342 19.0206 5.56658 18.6464 5.95711L5.70889 19.4571C5.33464 19.8476 4.72786 19.8476 4.3536 19.4571C3.97935 19.0666 3.97935 18.4334 4.3536 18.0429L17.2911 4.54289C17.6654 4.15237 18.2721 4.15237 18.6464 4.54289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.3536 4.54289C4.72786 4.15237 5.33464 4.15237 5.70889 4.54289L18.6464 18.0429C19.0206 18.4334 19.0206 19.0666 18.6464 19.4571C18.2721 19.8476 17.6654 19.8476 17.2911 19.4571L4.3536 5.95711C3.97935 5.56658 3.97935 4.93342 4.3536 4.54289Z"
      />
    </svg>
  );
}

export default SvgClose;
