/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyType = 'EXPENSE' | 'REFUND';

export const PolicyType = {
  EXPENSE: 'EXPENSE' as PolicyType,
  REFUND: 'REFUND' as PolicyType,
};
