import * as React from 'react';

function SvgAlert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.5C12.5523 6.5 13 6.94772 13 7.5V12.75C13 13.3023 12.5523 13.75 12 13.75C11.4477 13.75 11 13.3023 11 12.75V7.5C11 6.94772 11.4477 6.5 12 6.5Z"
      />
      <path d="M12 17.25C12.6213 17.25 13.125 16.7463 13.125 16.125C13.125 15.5037 12.6213 15 12 15C11.3787 15 10.875 15.5037 10.875 16.125C10.875 16.7463 11.3787 17.25 12 17.25Z" />
    </svg>
  );
}

export default SvgAlert;
