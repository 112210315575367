import * as React from 'react';

function SvgAddress(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 21.75C4.25 21.1977 4.69772 20.75 5.25 20.75H18.75C19.3023 20.75 19.75 21.1977 19.75 21.75C19.75 22.3023 19.3023 22.75 18.75 22.75H5.25C4.69772 22.75 4.25 22.3023 4.25 21.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.75C10.8954 7.75 10 8.64543 10 9.75C10 10.8546 10.8954 11.75 12 11.75C13.1046 11.75 14 10.8546 14 9.75C14 8.64543 13.1046 7.75 12 7.75ZM8 9.75C8 7.54086 9.79086 5.75 12 5.75C14.2091 5.75 16 7.54086 16 9.75C16 11.9591 14.2091 13.75 12 13.75C9.79086 13.75 8 11.9591 8 9.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.25C10.2761 3.25 8.62279 3.93482 7.40381 5.15381C6.18482 6.37279 5.5 8.02609 5.5 9.75C5.5 12.7649 7.18685 15.5347 9.00461 17.6251C9.90127 18.6562 10.8005 19.4883 11.4758 20.0623C11.6719 20.229 11.8486 20.3734 12 20.4941C12.1514 20.3734 12.3281 20.229 12.5242 20.0623C13.1995 19.4883 14.0987 18.6562 14.9954 17.6251C16.8132 15.5347 18.5 12.7649 18.5 9.75C18.5 8.02609 17.8152 6.37279 16.5962 5.15381C15.3772 3.93482 13.7239 3.25 12 3.25ZM12 21.75C11.4265 22.5692 11.4263 22.569 11.426 22.5688L11.4232 22.5669L11.4172 22.5627L11.3971 22.5484C11.3802 22.5363 11.3564 22.5192 11.3262 22.4971C11.2656 22.4529 11.1792 22.3888 11.0705 22.3057C10.8533 22.1397 10.5467 21.8974 10.1805 21.5862C9.4495 20.9648 8.47373 20.0625 7.49539 18.9374C5.56315 16.7154 3.5 13.4851 3.5 9.75C3.5 7.49566 4.39553 5.33365 5.98959 3.73959C7.58365 2.14553 9.74566 1.25 12 1.25C14.2543 1.25 16.4163 2.14553 18.0104 3.73959C19.6045 5.33365 20.5 7.49566 20.5 9.75C20.5 13.4851 18.4368 16.7154 16.5046 18.9374C15.5263 20.0625 14.5505 20.9648 13.8195 21.5862C13.4533 21.8974 13.1467 22.1397 12.9295 22.3057C12.8208 22.3888 12.7344 22.4529 12.6738 22.4971C12.6436 22.5192 12.6198 22.5363 12.6029 22.5484L12.5828 22.5627L12.5768 22.5669L12.5748 22.5683C12.5745 22.5685 12.5735 22.5692 12 21.75ZM12 21.75L12.5735 22.5692C12.2291 22.8103 11.7703 22.8099 11.426 22.5688L12 21.75Z"
      />
    </svg>
  );
}

export default SvgAddress;
