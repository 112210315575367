import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Direction } from '@libs/core/types';
import React, { ReactNode } from 'react';

export type DescriptionDataProps = {
  title?: string;
  children: ReactNode;
  icon?: ReactNode;
  direction?: Direction;
  size?: string;
  formatChildren?: boolean;
};

export const DescriptionData = ({
  title,
  children,
  icon,
  direction = Direction.vertical,
  size = direction === Direction.horizontal ? 'Body1' : 'Body2',
  formatChildren = true,
}: DescriptionDataProps) => {
  if (direction === Direction.horizontal) {
    return (
      <Flex
        justify="space-between"
        borderBottom=".1rem solid"
        borderBottomColor="ink.medium"
      >
        <Text size={size} color="ink.dark" my="2rem">
          {title}
        </Text>
        {formatChildren ? (
          <Text size={size} color="ink.black" my="2rem">
            {children}
          </Text>
        ) : (
          children
        )}
      </Flex>
    );
  }

  return (
    <Flex mb="2rem">
      {icon && (
        <Box w="2.4rem" h="2.4rem" mr="1rem">
          {icon}
        </Box>
      )}
      <Box>
        {title && (
          <Heading size={size} data-cy="descriptiondata-heading">
            {title}
          </Heading>
        )}
        {formatChildren ? (
          <Text color="ink.dark" size={size}>
            {children}
          </Text>
        ) : (
          children
        )}
      </Box>
    </Flex>
  );
};
