import * as React from 'react';

function SvgPhone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.75C17 3.47386 16.7761 3.25 16.5 3.25L7.5 3.25C7.22386 3.25 7 3.47386 7 3.75L7 20.25C7 20.5261 7.22386 20.75 7.5 20.75H16.5C16.7761 20.75 17 20.5261 17 20.25V3.75ZM16.5 1.25C17.8807 1.25 19 2.36929 19 3.75V20.25C19 21.6307 17.8807 22.75 16.5 22.75H7.5C6.11929 22.75 5 21.6307 5 20.25L5 3.75C5 2.36929 6.11929 1.25 7.5 1.25L16.5 1.25Z"
      />
      <path d="M12 6.75C12.6213 6.75 13.125 6.24632 13.125 5.625C13.125 5.00368 12.6213 4.5 12 4.5C11.3787 4.5 10.875 5.00368 10.875 5.625C10.875 6.24632 11.3787 6.75 12 6.75Z" />
    </svg>
  );
}

export default SvgPhone;
