import { useTheme } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';

type NavPathProps = {
  variants: {
    closed: {
      d: string;
    };
    open: {
      d: string;
    };
  };
};

export const NavPath = ({ ...rest }) => {
  const theme = useTheme();
  return (
    <motion.path
      strokeWidth="3"
      stroke={theme.colors.nav.type.normal}
      strokeLinecap="round"
      {...rest}
    />
  );
};
