/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyConditionField = 'EXPENSE_AMOUNT';

export const PolicyConditionField = {
  EXPENSE_AMOUNT: 'EXPENSE_AMOUNT' as PolicyConditionField,
};
