import { Text, Image } from '@chakra-ui/react';
import { Organisation } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useCurrentBreakpointValue } from '@libs/core/theme';
import { capitalize } from '@libs/core/utils';
import { Pager, Table, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useServicesGet } from '@libs/api-v2/endpoints/services/services';
import { ServiceDetailsDrawer } from './service-details-drawer';

type ServicesTableProps = {
  organisation?: Organisation;
};

export const ServicesTable = ({ organisation }: ServicesTableProps) => {
  const Edit = useIcon('Edit');
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { isMobile, isTablet } = useCurrentBreakpointValue();
  const { searchQuery, setSearchQuery, useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  const {
    drawerHandler,
    isOpen,
    onClose,
    activeDrawerIndex,
    selectedDrawerType,
  } = useDrawer();

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  const {
    // is false when fetching cached values
    isLoading,
    refetch,
    data: services,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useServicesGet(
    {
      payment_method: null,
      program_id: null,
      page_number: pageNumber,
      page_size: pageSize,
    },
    { query: { keepPreviousData: true } },
  );

  useQueryParams({
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
    totalPages: services?.meta.total_pages,
  });

  const headers: TableHeaders = [
    '',
    t(i18nKeys.common.title),
    t(i18nKeys.operator.services.category_name_key),
    '',
  ];

  return (
    <>
      <Table
        onSubmit={submitHandler as any}
        initialValues={{ q: '' }}
        headers={headers}
        // Uncomment when available
        // hasSearch
        // searchPlaceholder={t(i18nKeys.common.table.employee.search_placeholder)}
        // searchQuery={searchQuery}
        // setSearchQuery={setSearchQuery}
      >
        <Table.TBody
          isLoading={(isLoading || isFetching) && !services}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (services?.data.length === 0 || !services)
          }
        >
          {services?.data.map(
            ({ id, name_key, category_name_key, icon_url }, index) => (
              <Table.TR
                key={id}
                selectable
                index={id}
                position="relative"
                transform="scale(1,1)"
                onClick={() => drawerHandler(index)}
              >
                <Table.TD>
                  <Image src={icon_url} alt="icon" htmlWidth={20.0} />
                </Table.TD>
                <Table.TD>
                  <Text size="Body2">{capitalize(name_key)} </Text>
                </Table.TD>
                <Table.TD>
                  <Text>{category_name_key} </Text>
                </Table.TD>
              </Table.TR>
            ),
          )}
        </Table.TBody>
      </Table>
      {isOpen && (
        <ServiceDetailsDrawer
          isOpen={isOpen}
          onClose={onClose}
          service={services.data[activeDrawerIndex]}
        />
      )}
      {services && services?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={services.meta.total_items}
          page_index={services.meta.page_index}
          total_pages={services.meta.total_pages}
          mt="2rem"
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          isFetching={isFetching}
        />
      )}
    </>
  );
};
