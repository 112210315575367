/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ExpenseCategoryCode =
  | 'car'
  | 'bike'
  | 'motor_scooter'
  | 'kick_scooter'
  | 'public_transport'
  | 'taxi'
  | 'parking'
  | 'car_rent_or_lease_long_term'
  | 'housing_interest'
  | 'fuel_charging_parking_toll'
  | 'company_lease_bike'
  | 'parking_at_station'
  | 'fuel_or_charging'
  | 'equipment_purchase'
  | 'e-vehicle_purchase'
  | 'e-vehicle_leasing'
  | 'housing_rent'
  | 'unknown';

export const ExpenseCategoryCode = {
  car: 'car' as ExpenseCategoryCode,
  bike: 'bike' as ExpenseCategoryCode,
  motor_scooter: 'motor_scooter' as ExpenseCategoryCode,
  kick_scooter: 'kick_scooter' as ExpenseCategoryCode,
  public_transport: 'public_transport' as ExpenseCategoryCode,
  taxi: 'taxi' as ExpenseCategoryCode,
  parking: 'parking' as ExpenseCategoryCode,
  car_rent_or_lease_long_term: 'car_rent_or_lease_long_term' as ExpenseCategoryCode,
  housing_interest: 'housing_interest' as ExpenseCategoryCode,
  fuel_charging_parking_toll: 'fuel_charging_parking_toll' as ExpenseCategoryCode,
  company_lease_bike: 'company_lease_bike' as ExpenseCategoryCode,
  parking_at_station: 'parking_at_station' as ExpenseCategoryCode,
  fuel_or_charging: 'fuel_or_charging' as ExpenseCategoryCode,
  equipment_purchase: 'equipment_purchase' as ExpenseCategoryCode,
  evehicle_purchase: 'e-vehicle_purchase' as ExpenseCategoryCode,
  evehicle_leasing: 'e-vehicle_leasing' as ExpenseCategoryCode,
  housing_rent: 'housing_rent' as ExpenseCategoryCode,
  unknown: 'unknown' as ExpenseCategoryCode,
};
