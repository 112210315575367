import { RegistrationGetParams } from '@libs/api/models';
import { History } from 'history';
import { QueryClient } from 'react-query';

export const selectCell = (
  queryKey: (string | RegistrationGetParams)[],
  queryClient: QueryClient,
  updater: any,
  history: History,
  path: string,
) => {
  // force cache refresh
  queryClient.setQueryData(queryKey, updater);
  queryClient.invalidateQueries(queryKey);
  history.push(path);
};
