/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type MembershipRole = 'EMPLOYEE' | 'FLEET_MGR';

export const MembershipRole = {
  EMPLOYEE: 'EMPLOYEE' as MembershipRole,
  FLEET_MGR: 'FLEET_MGR' as MembershipRole,
};
