/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyRequiredField =
  | 'EXPENSE_RECEIPT'
  | 'EXPENSE_MEMO'
  | 'EXPENSE_EXPENSE_CODE';

export const PolicyRequiredField = {
  EXPENSE_RECEIPT: 'EXPENSE_RECEIPT' as PolicyRequiredField,
  EXPENSE_MEMO: 'EXPENSE_MEMO' as PolicyRequiredField,
  EXPENSE_EXPENSE_CODE: 'EXPENSE_EXPENSE_CODE' as PolicyRequiredField,
};
