import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  useTheme,
} from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export type LinkProps = ChakraLinkProps & {
  to: string;
  isActive?: boolean;
  level?: number;
};

export const Link = ({
  isExternal,
  children,
  to,
  href,
  variant,
  isActive = false,
  level = 0,
  ...rest
}: LinkProps) => {
  const theme = useTheme();
  const { variants } = theme.components.Link;
  const variantOptions = {
    theme,
    isActive,
    level,
  };
  const linkStyles = variants?.[variant]?.(variantOptions);

  return (
    <ChakraLink
      as={RouterLink}
      to={to}
      href={href || to}
      variant={variant}
      isExternal={isExternal}
      style={{ margin: 'auto auto', ...rest?.style }}
      {...linkStyles}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
};
