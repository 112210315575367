/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type { Registration, RegistrationGetParams } from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const registrationGet = <Data = unknown>(
  membershipid: string,
  params?: RegistrationGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    { url: `/memberships/${membershipid}/registration`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getRegistrationGetQueryKey = (
  membershipid: string,
  params?: RegistrationGetParams,
) => [`/memberships/${membershipid}/registration`, ...(params ? [params] : [])];

export const useRegistrationGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  membershipid: string,
  params?: RegistrationGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof registrationGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getRegistrationGetQueryKey(membershipid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof registrationGet>, Error>(
    queryKey,
    () => registrationGet<Data>(membershipid, params, requestOptions),
    { enabled: !!membershipid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const registrationUpdate = <Data = unknown>(
  membershipid: string,
  registration: Registration,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    {
      url: `/memberships/${membershipid}/registration`,
      method: 'put',
      data: registration,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useRegistrationUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof registrationUpdate>,
    Error,
    { membershipid: string; data: Registration },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof registrationUpdate>,
    Error,
    { membershipid: string; data: Registration }
  >((props) => {
    const { membershipid, data } = props || {};

    return registrationUpdate<Data>(membershipid, data, requestOptions);
  }, mutationOptions);
};
