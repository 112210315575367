/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type RegistrationRequestCreateRole = 'EMPLOYEE' | 'FLEET_MGR';

export const RegistrationRequestCreateRole = {
  EMPLOYEE: 'EMPLOYEE' as RegistrationRequestCreateRole,
  FLEET_MGR: 'FLEET_MGR' as RegistrationRequestCreateRole,
};
