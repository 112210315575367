import * as React from 'react';

function SvgArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 3.79289C17.0976 4.18342 17.0976 4.81658 16.7071 5.20711L9.91421 12L16.7071 18.7929C17.0976 19.1834 17.0976 19.8166 16.7071 20.2071C16.3166 20.5976 15.6834 20.5976 15.2929 20.2071L7.79289 12.7071C7.40237 12.3166 7.40237 11.6834 7.79289 11.2929L15.2929 3.79289C15.6834 3.40237 16.3166 3.40237 16.7071 3.79289Z"
      />
    </svg>
  );
}

export default SvgArrowLeft;
