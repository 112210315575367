import * as React from 'react';

function SvgAdd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63542 12C2.63542 11.4477 3.06448 11 3.59376 11H19.4063C19.9355 11 20.3646 11.4477 20.3646 12C20.3646 12.5523 19.9355 13 19.4063 13H3.59376C3.06448 13 2.63542 12.5523 2.63542 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 2.75C12.0293 2.75 12.4583 3.19772 12.4583 3.75V20.25C12.4583 20.8023 12.0293 21.25 11.5 21.25C10.9707 21.25 10.5417 20.8023 10.5417 20.25V3.75C10.5417 3.19772 10.9707 2.75 11.5 2.75Z"
      />
    </svg>
  );
}

export default SvgAdd;
