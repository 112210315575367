/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type PayrollStatus = 'PAID' | 'ON_HOLD';

export const PayrollStatus = {
  PAID: 'PAID' as PayrollStatus,
  ON_HOLD: 'ON_HOLD' as PayrollStatus,
};
