/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  ProgramsGetResponse,
  ProgramsGetParams,
  Program,
  ProgramWithMeta,
  ProgramGroupGetResponse,
  ProgramGroupsGetParams,
  ProgramGroup,
  ProgramGroupsUpdateRequest,
  ProgramGroupWithMeta,
  ProgramGroupGetParams,
  ProgramGroupData,
  ProgramGroupLimit,
  ProgramGroupLimitGetParams,
  ProgramGroupLimitUpdateParams,
  ModifyDisplayBudgetLimitRequest,
  ProgramGroupLimitDisplayPutParams,
  ProductsGetResponse,
  ProgramProductsGetParams,
  ProgramProduct,
  ProgramProductConfiguration,
  ProgramServicesGetResponse,
  ProgramServicesGetParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const programsGet = <Data = unknown>(
  params?: ProgramsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramsGetResponse : Data>(
    { url: `/v2/programs`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getProgramsGetQueryKey = (params?: ProgramsGetParams) => [
  `/v2/programs`,
  ...(params ? [params] : []),
];

export const useProgramsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ProgramsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof programsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getProgramsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof programsGet>, Error>(
    queryKey,
    () => programsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const programUpdate = <Data = unknown>(
  programid: string,
  program: Program,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Program : Data>(
    { url: `/v2/programs/${programid}`, method: 'put', data: program },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useProgramUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof programUpdate>,
    Error,
    { programid: string; data: Program },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof programUpdate>,
    Error,
    { programid: string; data: Program }
  >((props) => {
    const { programid, data } = props || {};

    return programUpdate<Data>(programid, data, requestOptions);
  }, mutationOptions);
};
export const programGet = <Data = unknown>(
  programid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramWithMeta : Data>(
    { url: `/v2/programs/${programid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getProgramGetQueryKey = (programid: string) => [
  `/v2/programs/${programid}`,
];

export const useProgramGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  programid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof programGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getProgramGetQueryKey(programid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof programGet>, Error>(
    queryKey,
    () => programGet<Data>(programid, requestOptions),
    { enabled: !!programid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const programGroupsGet = <Data = unknown>(
  programid: string,
  params?: ProgramGroupsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramGroupGetResponse : Data>(
    { url: `/v2/programs/${programid}/groups`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getProgramGroupsGetQueryKey = (
  programid: string,
  params?: ProgramGroupsGetParams,
) => [`/v2/programs/${programid}/groups`, ...(params ? [params] : [])];

export const useProgramGroupsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  programid: string,
  params?: ProgramGroupsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof programGroupsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getProgramGroupsGetQueryKey(programid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof programGroupsGet>, Error>(
    queryKey,
    () => programGroupsGet<Data>(programid, params, requestOptions),
    { enabled: !!programid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const programGroupsUpdate = <Data = unknown>(
  programid: string,
  programGroupsUpdateRequest: ProgramGroupsUpdateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramGroup : Data>(
    {
      url: `/v2/programs/${programid}/groups`,
      method: 'put',
      data: programGroupsUpdateRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useProgramGroupsUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof programGroupsUpdate>,
    Error,
    { programid: string; data: ProgramGroupsUpdateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof programGroupsUpdate>,
    Error,
    { programid: string; data: ProgramGroupsUpdateRequest }
  >((props) => {
    const { programid, data } = props || {};

    return programGroupsUpdate<Data>(programid, data, requestOptions);
  }, mutationOptions);
};
export const programGroupGet = <Data = unknown>(
  programid: string,
  groupid: string,
  params?: ProgramGroupGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramGroupWithMeta : Data>(
    {
      url: `/v2/programs/${programid}/groups/${groupid}`,
      method: 'get',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getProgramGroupGetQueryKey = (
  programid: string,
  groupid: string,
  params?: ProgramGroupGetParams,
) => [
  `/v2/programs/${programid}/groups/${groupid}`,
  ...(params ? [params] : []),
];

export const useProgramGroupGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  programid: string,
  groupid: string,
  params?: ProgramGroupGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof programGroupGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getProgramGroupGetQueryKey(programid, groupid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof programGroupGet>, Error>(
    queryKey,
    () => programGroupGet<Data>(programid, groupid, params, requestOptions),
    { enabled: !!(programid && groupid), ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const programGroupUpdate = <Data = unknown>(
  programid: string,
  groupid: string,
  programGroupData: ProgramGroupData,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramGroup : Data>(
    {
      url: `/v2/programs/${programid}/groups/${groupid}`,
      method: 'put',
      data: programGroupData,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useProgramGroupUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof programGroupUpdate>,
    Error,
    { programid: string; groupid: string; data: ProgramGroupData },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof programGroupUpdate>,
    Error,
    { programid: string; groupid: string; data: ProgramGroupData }
  >((props) => {
    const { programid, groupid, data } = props || {};

    return programGroupUpdate<Data>(programid, groupid, data, requestOptions);
  }, mutationOptions);
};
export const programGroupLimitGet = <Data = unknown>(
  programid: string,
  groupid: string,
  params?: ProgramGroupLimitGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramGroupLimit : Data>(
    {
      url: `/v2/programs/${programid}/groups/${groupid}/limit`,
      method: 'get',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getProgramGroupLimitGetQueryKey = (
  programid: string,
  groupid: string,
  params?: ProgramGroupLimitGetParams,
) => [
  `/v2/programs/${programid}/groups/${groupid}/limit`,
  ...(params ? [params] : []),
];

export const useProgramGroupLimitGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  programid: string,
  groupid: string,
  params?: ProgramGroupLimitGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof programGroupLimitGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getProgramGroupLimitGetQueryKey(programid, groupid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof programGroupLimitGet>, Error>(
    queryKey,
    () =>
      programGroupLimitGet<Data>(programid, groupid, params, requestOptions),
    { enabled: !!(programid && groupid), ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const programGroupLimitUpdate = <Data = unknown>(
  programid: string,
  groupid: string,
  programGroupLimit: ProgramGroupLimit,
  params?: ProgramGroupLimitUpdateParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramGroupLimit : Data>(
    {
      url: `/v2/programs/${programid}/groups/${groupid}/limit`,
      method: 'put',
      data: programGroupLimit,
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useProgramGroupLimitUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof programGroupLimitUpdate>,
    Error,
    {
      programid: string;
      groupid: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitUpdateParams;
    },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof programGroupLimitUpdate>,
    Error,
    {
      programid: string;
      groupid: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitUpdateParams;
    }
  >((props) => {
    const { programid, groupid, data, params } = props || {};

    return programGroupLimitUpdate<Data>(
      programid,
      groupid,
      data,
      params,
      requestOptions,
    );
  }, mutationOptions);
};
export const programGroupLimitDisplayPut = <Data = unknown>(
  programgrouplimitid: string,
  modifyDisplayBudgetLimitRequest: ModifyDisplayBudgetLimitRequest,
  params?: ProgramGroupLimitDisplayPutParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramGroupLimit : Data>(
    {
      url: `/v2/program-group-limits/${programgrouplimitid}`,
      method: 'patch',
      data: modifyDisplayBudgetLimitRequest,
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useProgramGroupLimitDisplayPut = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof programGroupLimitDisplayPut>,
    Error,
    {
      programgrouplimitid: string;
      data: ModifyDisplayBudgetLimitRequest;
      params?: ProgramGroupLimitDisplayPutParams;
    },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof programGroupLimitDisplayPut>,
    Error,
    {
      programgrouplimitid: string;
      data: ModifyDisplayBudgetLimitRequest;
      params?: ProgramGroupLimitDisplayPutParams;
    }
  >((props) => {
    const { programgrouplimitid, data, params } = props || {};

    return programGroupLimitDisplayPut<Data>(
      programgrouplimitid,
      data,
      params,
      requestOptions,
    );
  }, mutationOptions);
};
export const programProductsGet = <Data = unknown>(
  programid: string,
  params?: ProgramProductsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProductsGetResponse : Data>(
    { url: `/v2/programs/${programid}/products`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getProgramProductsGetQueryKey = (
  programid: string,
  params?: ProgramProductsGetParams,
) => [`/v2/programs/${programid}/products`, ...(params ? [params] : [])];

export const useProgramProductsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  programid: string,
  params?: ProgramProductsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof programProductsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getProgramProductsGetQueryKey(programid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof programProductsGet>, Error>(
    queryKey,
    () => programProductsGet<Data>(programid, params, requestOptions),
    { enabled: !!programid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const programProductGet = <Data = unknown>(
  programid: string,
  productid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramProduct : Data>(
    { url: `/v2/programs/${programid}/products/${productid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getProgramProductGetQueryKey = (
  programid: string,
  productid: string,
) => [`/v2/programs/${programid}/products/${productid}`];

export const useProgramProductGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  programid: string,
  productid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof programProductGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getProgramProductGetQueryKey(programid, productid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof programProductGet>, Error>(
    queryKey,
    () => programProductGet<Data>(programid, productid, requestOptions),
    { enabled: !!(programid && productid), ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const programProductUpdate = <Data = unknown>(
  programid: string,
  productid: string,
  programProductConfiguration: ProgramProductConfiguration,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramProduct : Data>(
    {
      url: `/v2/programs/${programid}/products/${productid}`,
      method: 'put',
      data: programProductConfiguration,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useProgramProductUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof programProductUpdate>,
    Error,
    { programid: string; productid: string; data: ProgramProductConfiguration },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof programProductUpdate>,
    Error,
    { programid: string; productid: string; data: ProgramProductConfiguration }
  >((props) => {
    const { programid, productid, data } = props || {};

    return programProductUpdate<Data>(
      programid,
      productid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const programServicesGet = <Data = unknown>(
  programid: string,
  params?: ProgramServicesGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? ProgramServicesGetResponse : Data
  >(
    { url: `/v2/programs/${programid}/services`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getProgramServicesGetQueryKey = (
  programid: string,
  params?: ProgramServicesGetParams,
) => [`/v2/programs/${programid}/services`, ...(params ? [params] : [])];

export const useProgramServicesGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  programid: string,
  params?: ProgramServicesGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof programServicesGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getProgramServicesGetQueryKey(programid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof programServicesGet>, Error>(
    queryKey,
    () => programServicesGet<Data>(programid, params, requestOptions),
    { enabled: !!programid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
