import { AlertProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { AlertBox } from './alert.style';

export type AlertBoxProps = AlertProps & {
  children: ReactNode;
};

export const Alert = ({ children, status, variant }: AlertBoxProps) => {
  return (
    <AlertBox data-cy="alert" variant={variant} status={status}>
      {children}
    </AlertBox>
  );
};
