/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type MembershipStatus =
  | 'SUGGESTED'
  | 'CREATED'
  | 'STARTED'
  | 'CONFIRMED'
  | 'VALIDATED'
  | 'REFUSED'
  | 'CANCELED'
  | 'IN_REVIEW';

export const MembershipStatus = {
  SUGGESTED: 'SUGGESTED' as MembershipStatus,
  CREATED: 'CREATED' as MembershipStatus,
  STARTED: 'STARTED' as MembershipStatus,
  CONFIRMED: 'CONFIRMED' as MembershipStatus,
  VALIDATED: 'VALIDATED' as MembershipStatus,
  REFUSED: 'REFUSED' as MembershipStatus,
  CANCELED: 'CANCELED' as MembershipStatus,
  IN_REVIEW: 'IN_REVIEW' as MembershipStatus,
};
