import {
  Box,
  Button,
  Checkbox,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useCurrentBreakpointValue } from '@libs/core/theme';
import { Tag } from '@libs/ui/components/tag';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

type TableFilterProps = {
  columns: {
    name: string;
    values: string[];
    valuesI18nKey?: { [x: string]: string };
    title?: string;
  }[];
  filterQuery: string[];
  setFilterQuery: React.Dispatch<React.SetStateAction<string[]>>;
  valuesI18nKey?: { [x: string]: string };
};

export const TableFilter = ({
  columns,
  filterQuery,
  setFilterQuery,
  valuesI18nKey,
}: TableFilterProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const Filter = useIcon('Filter');
  const Close = useIcon('Close');
  const { isMobile } = useCurrentBreakpointValue();

  return (
    <Box>
      <Popover variant="responsive" placement="bottom">
        <PopoverTrigger>
          {isMobile ? (
            <Button
              variant="border"
              size="body2"
              fontFamily={theme.fonts.body1}
              borderWidth=".1rem"
              p="1.5rem"
            >
              <Box h="2.5rem" w="2.5rem">
                <Filter />
              </Box>
            </Button>
          ) : (
            <Button
              variant="border"
              size="body2"
              fontFamily={theme.fonts.body1}
              borderWidth=".1rem"
              rightIcon={
                <Box h="2.5rem" w="2.5rem">
                  <Filter />
                </Box>
              }
            >
              {t(i18nKeys.common.table.filter_button)}
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent>
          <PopoverCloseButton />
          <Flex>
            {columns.map((column) => (
              <Flex key={column.name} direction="column">
                <PopoverHeader p="1rem 2rem">
                  <Box
                    as="span"
                    verticalAlign="text-bottom"
                    display="inline-block"
                  >
                    <Text fontFamily={theme.fonts.body2} fontWeight="bold">
                      {column.title || t(i18nKeys.common[column.name])}
                    </Text>
                  </Box>
                </PopoverHeader>
                <PopoverBody p="1rem 2rem" role="group">
                  {column.values.map((value) => (
                    <Box key={value}>
                      <Checkbox
                        variant="sorting"
                        size="Body2"
                        name={`${column.name}-filter-checked`}
                        alignItems="flex-start"
                        my=".75rem"
                        value={value}
                        // for now only one box can be checked in a column
                        isDisabled={
                          filterQuery.find((query) =>
                            query.includes(column.name),
                          ) && !filterQuery.includes(`${column.name}:${value}`)
                        }
                        isChecked={
                          !!filterQuery.find((param) => param.endsWith(value))
                        }
                        onChange={({ target: { value } }) =>
                          setFilterQuery((query) =>
                            !query.find((param) => param.endsWith(value))
                              ? [...query, `${column.name}:${value}`]
                              : query.filter((param) => !param.endsWith(value)),
                          )
                        }
                      >
                        {(valuesI18nKey && t(valuesI18nKey[value])) ||
                          (column.valuesI18nKey &&
                            t(column.valuesI18nKey[value])) ||
                          value}
                      </Checkbox>
                    </Box>
                  ))}
                </PopoverBody>
              </Flex>
            ))}
          </Flex>
        </PopoverContent>
      </Popover>

      {filterQuery.length > 0 && (
        <Box mt="1rem" d="flex" alignItems="center">
          <Text
            verticalAlign="middle"
            d="inline-block"
            pr="1rem"
            variant="body2"
            fontWeight="bold"
          >
            {t(i18nKeys.common.table.active_filters)}:
          </Text>{' '}
          <Box d="inline-block">
            {filterQuery.map((query) => {
              return (
                <Tag
                  key={query}
                  h="3rem"
                  p=".5rem .5rem .5rem 1rem"
                  borderRadius="full"
                  mr=".5rem"
                  backgroundColor={theme.colors.ink.lighter}
                  _hover={{
                    bgColor: theme.colors.ink.light,
                  }}
                  rightIcon={
                    <Button
                      width="1.75rem"
                      height="1.75rem"
                      bgColor={theme.colors.ink.dark}
                      borderRadius="full"
                      ml=".75rem"
                      p="0"
                      onClick={() =>
                        setFilterQuery((q) =>
                          q.filter((param) => param !== query),
                        )
                      }
                      _hover={{ bgColor: theme.colors.ink.darker }}
                      _active={{ bgColor: theme.colors.ink.darker }}
                    >
                      <Close fill={theme.colors.ink.light} />
                    </Button>
                  }
                >
                  <Text key={query} size="Small" color={theme.colors.ink.dark}>
                    {t(
                      i18nKeys.common[
                        columns.find((col) => query.includes(col.name))?.name
                      ],
                    ) || columns.find((col) => query.includes(col.name))?.title}
                    :{' '}
                    {(valuesI18nKey && t(valuesI18nKey[query.split(':')[1]])) ||
                      t(
                        columns.find((col) => query.includes(col.name))
                          ?.valuesI18nKey[query.split(':')[1]],
                      ) ||
                      query.split(':')[1]}
                  </Text>
                </Tag>
              );
            })}
          </Box>
          <Text
            cursor="pointer"
            ml=".5rem"
            variant="body2"
            fontWeight={400}
            textColor={theme.colors.main.secondary.light}
            onClick={() => setFilterQuery([])}
          >
            {t(i18nKeys.common.table.clear_filters)}
          </Text>
        </Box>
      )}
    </Box>
  );
};
