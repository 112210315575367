import { Flex } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Thumbnail, UploadField } from '@libs/ui/components';
import React from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

type UploadPreviewProps = {
  uploadURL: string;
  previewURL: string;
  uploadName: string;
  previewTitle: string;
  options: DropzoneOptions;
};

export const UploadPreview = ({
  uploadURL,
  uploadName,
  previewURL,
  previewTitle,
  options,
}: UploadPreviewProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      direction={{
        base: 'column',
        md: 'column',
        lg: 'row',
      }}
    >
      <UploadField
        name={uploadName}
        options={options}
        isRequired
        fileURL={uploadURL}
      />
      <Thumbnail
        ml={{
          base: '0',
          md: '0',
          lg: '2rem',
        }}
        fileURL={previewURL}
        title={previewTitle}
      >
        {t(i18nKeys.common.example)}
      </Thumbnail>
    </Flex>
  );
};
