import { Text, TextProps } from '@chakra-ui/layout';
import React from 'react';
import { Table } from '../table';

type TableNumberProps = TextProps & {
  children: number;
};

export const TableNumber = ({ children, ...rest }: TableNumberProps) => {
  return (
    <Table.TD>
      <Text align="right" size="Body2" {...rest}>
        {children}
      </Text>
    </Table.TD>
  );
};
