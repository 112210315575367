/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useMutation, UseMutationOptions } from 'react-query';
import type { Document, DocumentCreateBody } from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const documentCreate = <Data = unknown>(
  documentCreateBody: DocumentCreateBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Document : Data>(
    { url: `/v2/documents`, method: 'post', data: documentCreateBody },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useDocumentCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof documentCreate>,
    Error,
    { data: DocumentCreateBody },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof documentCreate>,
    Error,
    { data: DocumentCreateBody }
  >((props) => {
    const { data } = props || {};

    return documentCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
