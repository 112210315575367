import { Box, Link, Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileIcon } from '../file';
import { Thumbnail } from '../thumbnail';
import { FileExtensionType } from '../thumbnail/thumbnail.constant';
import { isPdfFileFromURL } from '../thumbnail/thumbnail.utils';

export type PreviewAfterUploadProps = {
  name: string;
  fileURL: string;
  type?: string;
  onClose: () => void;
};

export const PreviewAfterUpload = ({
  onClose,
  fileURL,
  name,
  type,
}: PreviewAfterUploadProps) => {
  const { t } = useTranslation();
  return (
    <Box width="100%" textAlign="center">
      {type === FileExtensionType.PDF || isPdfFileFromURL(fileURL) ? (
        <Link href={fileURL} isExternal>
          <Box width="12rem" margin="0 auto">
            <FileIcon type={FileExtensionType.PDF.toString().substr(-3)} />
          </Box>
        </Link>
      ) : (
        <motion.div
          animate={{ scale: [0.5, 1] }}
          transition={{ duration: 0.4 }}
        >
          <Thumbnail
            width="10rem"
            height="8rem"
            title={name}
            margin="0 auto"
            fileURL={fileURL}
          ></Thumbnail>
        </motion.div>
      )}

      <Text mt=".5rem" wordBreak="break-all">
        {name}
      </Text>
      <Link textDecoration="underline" cursor="pointer" onClick={onClose}>
        {t(i18nKeys.common.remove)}
      </Link>
    </Box>
  );
};
