export const colors = {
  background: {
    page: '#F5F6F7',
    table: '#F7F7F7',
  },
  header: {
    background: '#FFFFFF',
  },
  status: {
    info: {
      light: '#EAF5FB',
      normal: '#96CFE9',
      type: '#63A2BE',
    },
    suggested: {
      light: '#F5F1FB',
      normal: '#927AD3',
      type: '#4918B6',
    },
    pending: {
      light: '#FFF5E0',
      normal: '#FFCD69',
      type: '#FF9D4C',
    },
    in_review: {
      light: '#FFF5E0',
      normal: '#FFCD69',
      type: '#FF9D4C',
    },
    positive: {
      light: '#E7FBF5',
      normal: '#94E4CD',
      type: '#60B39B',
      100: '#E7FBF5',
      200: '#CEECE3',
      300: '#94E4CD',
      400: '#B3DDD1',
      500: '#60B39B',
      600: '#4E9B85',
      700: '#3B8B74',
      800: '#2B7963',
      900: '#15654E',
    },
    started: {
      light: '#E7FBF5',
      normal: '#94E4CD',
      type: '#60B39B',
    },
    created: {
      light: '#E7FBF5',
      normal: '#94E4CD',
      type: '#60B39B',
    },
    negative: {
      light: '#FFE9E9',
      normal: '#FC9890',
      type: '#E95B4F',
      100: '#FFE9E9',
      200: '#F4ADA7',
      300: '#FC9890',
      400: '#EF796F',
      500: '#E95B4F',
      600: '#DE493C',
      700: '#D63A2C',
      800: '#CE2C1E',
      900: '#B11B0F',
    },
    none: {
      light: '#EAF5FB',
      normal: '#96CFE9',
      type: '#63A2BE',
    },
  },
  spinner: {
    main: '#FF4940',
    empty: '#EDEDED',
  },
  title: {
    color1: '#252321',
    color2: '#252321',
  },
  link: {
    normal: '#927AD3',
    hover: '#4918B6',
  },
  main: {
    primary: {
      lighter: '#FFF1EE',
      light: '#FF6D66',
      normal: '#FF4940',
      dark: '#E31A10',
      darker: '#B80900',
    },
    secondary: {
      lighter: '#F5F1FB',
      light: '#927AD3',
      normal: '#4918B6',
      dark: '#3E1599',
      darker: '#1F094F',
    },
  },
  ink: {
    white: '#FFFFFF',
    lighter: '#EDEDED',
    light: '#D5D6D7',
    medium: '#A5A6A7',
    dark: '#818283',
    darker: '#3A3939',
    black: '#252321',
  },
  nav: {
    background: { sidebar: '#3432ca', item: '#3E1599', leftbar: '#927AD3' },
    type: {
      active: '#fff',
      normal: '#F5F1FB',
      lighter: '#F5F1FB',
      light: '#927AD3',
    },
  },
  button: {
    background: { disabled: '#D5D6D7' },
    type: {
      normal: '#927AD3',
      lighter: '#3A3939',
      light: '#927AD3',
    },
    text: {
      normal: '#ffffff',
      disabled: '#FFFFFF',
    },
  },
  badge: {
    text: '#7D2829',
    primary: '#F9DADA',
    white: '#FFFF',
  },
};
