import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { SORT_VALUES } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/ui';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { getSortValue, sortColumn } from '../table.utils';
import { SortIcon } from './sort-icon';

export const TableColumnSort = ({
  query,
  setQuery,
  name,
  children,
}: {
  query: string[];
  setQuery: React.Dispatch<React.SetStateAction<string[]>>;
  name: string;
  children?: ReactNode;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const SORT_TEXT = {
    [SORT_VALUES.DEFAULT]: t(i18nKeys.common.table.sort.default),
    [SORT_VALUES.ASC]: t(i18nKeys.common.table.sort.asc),
    [SORT_VALUES.DESC]: t(i18nKeys.common.table.sort.desc),
  };

  return (
    <Popover variant="responsive" placement="bottom-start">
      <PopoverTrigger>
        <Button
          role="group"
          p="0"
          verticalAlign="middle"
          variant="transparant"
          rightIcon={
            <Box
              _groupHover={{ fill: theme.colors.main.secondary.light }}
              w="1.8rem"
              h="1.8rem"
            >
              <SortIcon
                value={getSortValue(query, name) || SORT_VALUES.DEFAULT}
              />
            </Box>
          }
        >
          <Text
            size="SmallBold"
            color={theme.colors.ink.dark}
            _groupHover={{ color: theme.colors.main.secondary.light }}
          >
            {children}
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody px="1.5rem">
          {Object.keys(SORT_TEXT).map((sortKey) => (
            <Box key={sortKey}>
              <Button
                variant="transparant"
                role="group"
                leftIcon={
                  <Box
                    fill={
                      getSortValue(query, name) === sortKey
                        ? theme.colors.main.secondary.light
                        : theme.colors.ink.darker
                    }
                    _groupHover={{ fill: theme.colors.main.secondary.light }}
                    w="1.8rem"
                    h="1.8rem"
                  >
                    <SortIcon value={sortKey} />
                  </Box>
                }
                p=".8rem .5rem .8rem 0"
                h="auto"
                size="body2"
                onClick={() =>
                  sortColumn({ sortName: name, value: sortKey, setQuery })
                }
              >
                <Text
                  textColor={
                    getSortValue(query, name) === sortKey
                      ? theme.colors.main.secondary.dark
                      : theme.colors.ink.darker
                  }
                  _groupHover={{ textColor: theme.colors.main.secondary.dark }}
                  size="Small"
                >
                  {SORT_TEXT[sortKey]}
                </Text>
              </Button>
            </Box>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
