import assistant from './assistant.svg';
import bgNav from './bg-nav.jpg';
import login from './login.jpg';
import logoDark from './logo-dark.svg';
import logo from './logo.svg';
import skiprPaymentCard from './skipr-payment-card.png';
import skiprPaymentCardBlocked from './skipr-payment-card-blocked.png';

export const images = {
  bgNav,
  login,
  logo,
  logoDark,
  assistant,
  skiprPaymentCard,
  skiprPaymentCardBlocked,
};
