/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  BudgetsGetResponse,
  BudgetsGetParams,
  Budget,
  BudgetsGetCurrentParams,
  BudgetsGetFutureParams,
  BudgetsGetPastParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const budgetsGet = <Data = unknown>(
  params?: BudgetsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? BudgetsGetResponse : Data>(
    { url: `/budgets`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getBudgetsGetQueryKey = (params?: BudgetsGetParams) => [
  `/budgets`,
  ...(params ? [params] : []),
];

export const useBudgetsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: BudgetsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof budgetsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getBudgetsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof budgetsGet>, Error>(
    queryKey,
    () => budgetsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const budgetsCreate = <Data = unknown>(
  budget: Budget,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Budget : Data>(
    { url: `/budgets`, method: 'post', data: budget },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useBudgetsCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof budgetsCreate>,
    Error,
    { data: Budget },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof budgetsCreate>,
    Error,
    { data: Budget }
  >((props) => {
    const { data } = props || {};

    return budgetsCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const budgetsUpdate = <Data = unknown>(
  budgetid: string,
  budget: Budget,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Budget : Data>(
    { url: `/budgets/${budgetid}`, method: 'put', data: budget },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useBudgetsUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof budgetsUpdate>,
    Error,
    { budgetid: string; data: Budget },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof budgetsUpdate>,
    Error,
    { budgetid: string; data: Budget }
  >((props) => {
    const { budgetid, data } = props || {};

    return budgetsUpdate<Data>(budgetid, data, requestOptions);
  }, mutationOptions);
};
export const budgetsGetCurrent = <Data = unknown>(
  params?: BudgetsGetCurrentParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? BudgetsGetResponse : Data>(
    { url: `/budgets/current`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getBudgetsGetCurrentQueryKey = (
  params?: BudgetsGetCurrentParams,
) => [`/budgets/current`, ...(params ? [params] : [])];

export const useBudgetsGetCurrent = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: BudgetsGetCurrentParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof budgetsGetCurrent>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getBudgetsGetCurrentQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof budgetsGetCurrent>, Error>(
    queryKey,
    () => budgetsGetCurrent<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const budgetsGetFuture = <Data = unknown>(
  params?: BudgetsGetFutureParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? BudgetsGetResponse : Data>(
    { url: `/budgets/future`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getBudgetsGetFutureQueryKey = (
  params?: BudgetsGetFutureParams,
) => [`/budgets/future`, ...(params ? [params] : [])];

export const useBudgetsGetFuture = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: BudgetsGetFutureParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof budgetsGetFuture>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getBudgetsGetFutureQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof budgetsGetFuture>, Error>(
    queryKey,
    () => budgetsGetFuture<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const budgetsGetPast = <Data = unknown>(
  params?: BudgetsGetPastParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? BudgetsGetResponse : Data>(
    { url: `/budgets/past`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getBudgetsGetPastQueryKey = (params?: BudgetsGetPastParams) => [
  `/budgets/past`,
  ...(params ? [params] : []),
];

export const useBudgetsGetPast = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: BudgetsGetPastParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof budgetsGetPast>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getBudgetsGetPastQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof budgetsGetPast>, Error>(
    queryKey,
    () => budgetsGetPast<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
