import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

type OnboardingProgressTimeProps = {
  timing: string;
  isOpen: boolean;
};

export const OnboardingProgressTime = ({
  timing,
  isOpen,
}: OnboardingProgressTimeProps) => {
  const theme = useTheme();
  const Clock = useIcon('Clock');
  const { t } = useTranslation();
  return timing ? (
    <Box
      display={{ base: isOpen ? 'block' : 'none', lg: 'block' }}
      position="absolute"
      bottom="2rem"
      text-align="center"
      bgColor={theme.colors.main.secondary.lighter}
      borderRadius={theme.borders.md}
      p="2rem 1rem 1rem"
      left="50%"
      transform="translateX(-50%)"
      width="80%"
    >
      <Flex
        pos="absolute"
        top="-1.3rem"
        margin="0 auto"
        borderWidth=".2rem"
        borderColor={theme.colors.main.secondary.lighter}
        borderRadius={theme.borders.lg}
        bgColor="ink.white"
        p=".2rem 1rem"
        left="50%"
        transform="translateX(-50%)"
      >
        <Box
          w="1.6rem"
          h="1.6rem"
          fill={theme.colors.main.secondary.dark}
          mr=".3rem"
          verticalAlign="baseline"
        >
          <Clock />
        </Box>
        <Text color={theme.colors.main.secondary.dark} size="Small">
          {timing}
        </Text>
      </Flex>
      <Text
        color={theme.colors.main.secondary.dark}
        size="Small"
        textAlign="center"
        fontWeight="bold"
      >
        {t(i18nKeys.common.estimated_remaining_time)}
      </Text>
    </Box>
  ) : null;
};
