import dayjs from 'dayjs';

const today = dayjs();

export const initYearMaxDate = (years?: number) => {
  return years ? today.subtract(years, 'year').toDate() : new Date();
};

export const diffOfDaysFromToday = (date?: string) => {
  return date ? today.diff(dayjs(date), 'day') : 0;
};
