/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type Gender = 'Male' | 'Female';

export const Gender = {
  Male: 'Male' as Gender,
  Female: 'Female' as Gender,
};
