import { Box, List } from '@chakra-ui/react';
import { ReleaseVersion } from '@libs/dashboard-core/components/version';
import { UseWizardStep } from '@libs/ui/components/wizard/hooks';
import React from 'react';
import { OnboardingProgressBarItem } from './onboarding-progress-bar-item';
import { OnboardingProgressTime } from './onboarding-progress-time';

type OnboardingProgressBarProps = {
  isOpen?: boolean;
  steps: UseWizardStep[];
  activeStepIndex: number;
};

export const OnboardingProgressBar = ({
  isOpen,
  steps,
  activeStepIndex,
}: OnboardingProgressBarProps) => {
  return (
    <>
      <Box
        position="relative"
        padding={{ base: isOpen ? '3rem' : '0', lg: '3rem' }}
      >
        <List
          spacing="2rem"
          w={{ base: isOpen ? 'auto' : '2rem', lg: 'auto' }}
          p={{ base: isOpen ? '0' : '2rem 0', lg: '0' }}
          m="2rem auto"
        >
          {steps.map(({ id, isHidden, label }, index) => {
            if (isHidden) {
              return null;
            }
            return (
              <OnboardingProgressBarItem
                key={id}
                modLast={index === steps.length - 2}
                isOpen={isOpen}
                modActive={activeStepIndex === index}
                modComplete={activeStepIndex > index}
              >
                {label}
              </OnboardingProgressBarItem>
            );
          })}
        </List>
      </Box>
      <OnboardingProgressTime
        isOpen={isOpen}
        timing={steps[activeStepIndex]?.time}
      />
      <ReleaseVersion />
    </>
  );
};
