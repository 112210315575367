const ONBOARDING_STARTED = 'Onboarding Started';
const ORG_DETAILS_COMPLETED = 'Org Details Completed';
const ORG_DOCUMENT_COMPLETED = 'Org Document Completed';
const UBO_DETAILS_COMPLETED = 'UBO Details Completed';
const UBO_ID_COMPLETED = 'UBO ID Completed';
const UBO_POA_COMPLETED = 'UBO POA Completed';
const ONBOARDING_COMPLETE = 'Onboarding Completed';

export const ONBOARDING_EVENTS = {
  ONBOARDING_STARTED,
  ORG_DETAILS_COMPLETED,
  ORG_DOCUMENT_COMPLETED,
  UBO_DETAILS_COMPLETED,
  UBO_ID_COMPLETED,
  UBO_POA_COMPLETED,
  ONBOARDING_COMPLETE,
};
