/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type SocialSecretarySocialSecretaryName = 'PARTENA' | 'ATTENTIA';

export const SocialSecretarySocialSecretaryName = {
  PARTENA: 'PARTENA' as SocialSecretarySocialSecretaryName,
  ATTENTIA: 'ATTENTIA' as SocialSecretarySocialSecretaryName,
};
