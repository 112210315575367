/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type Role = 'USER' | 'OPERATOR_ADMIN';

export const Role = {
  USER: 'USER' as Role,
  OPERATOR_ADMIN: 'OPERATOR_ADMIN' as Role,
};
