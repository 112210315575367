import { Box, Image } from '@chakra-ui/react';
import { useImage } from '@libs/core/theme/utils';
import { motion, useCycle } from 'framer-motion';
import React, { ReactNode } from 'react';
import { ReleaseVersion } from '../version/release-version';
import { NavHeader } from './nav-header';
import { NavToggle } from './nav-toggle';
import { navType } from './nav.type';

type NavProps = {
  title?: ReactNode;
  introduction?: ReactNode;
  children?: ReactNode;
  type?: keyof navType;
  activeStep?: number;
};

export const Nav = ({ title, introduction, children }: NavProps) => {
  const logoImg = useImage('logo');
  const bgNav = useImage('bgNav');
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <Box width={{ base: '6rem', lg: '23rem' }}>
      <Box
        as="aside"
        w={{ base: isOpen ? '23rem' : '6rem', lg: '23rem' }}
        position="fixed"
        transition="cubic-bezier(.72,.13,0,.94) width .4s"
        left="0"
        bottom="0"
        top="0"
        backgroundImage={`url(${bgNav})`}
        backgroundSize="cover"
        backgroundPosition="bottom left"
        backgroundColor="nav.background.sidebar"
        zIndex="1000"
        boxShadow="0 .1rem .3rem 0 rgba(0,0,0,0.2)"
      >
        <motion.nav
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          role="navigation"
        >
          <Image
            transition="cubic-bezier(.72,.13,0,.94) all .4s"
            width={{ base: isOpen ? 'auto' : '4rem', lg: 'auto' }}
            h="3rem"
            src={logoImg}
            m={{
              base: isOpen ? '3rem auto' : '2rem auto 3rem',
              lg: '3rem auto',
            }}
          />
          <Box
            position="absolute"
            top="4.5rem"
            display={{ base: 'block', lg: 'none' }}
            w="100%"
            maxW="6rem"
          >
            <NavToggle toggle={() => toggleOpen()} />
          </Box>
          <Box>
            {title && (
              <NavHeader
                isOpen={isOpen}
                title={title}
                introduction={introduction}
              />
            )}
            {children &&
              React.cloneElement(children as React.ReactElement, { isOpen })}
          </Box>

          <ReleaseVersion isOpen={isOpen} />
        </motion.nav>
      </Box>
    </Box>
  );
};
