/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  Card,
  ConvertCardToPhysicalRequest,
  CardImage,
  CardsGetResponse,
  CardsGetParams,
} from '../../models';
import type {
  RetrievePinResponse,
  RetrievePinRequest,
} from '../../models/ttps:--github.com-skiprco-booking-api-doc-blob-development-component-auth';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const cardGet = <Data = unknown>(
  cardid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Card : Data>(
    { url: `/v2/cards/${cardid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getCardGetQueryKey = (cardid: string) => [`/v2/cards/${cardid}`];

export const useCardGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  cardid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof cardGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getCardGetQueryKey(cardid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof cardGet>, Error>(
    queryKey,
    () => cardGet<Data>(cardid, requestOptions),
    { enabled: !!cardid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const cardConvertToPhysical = <Data = unknown>(
  cardid: string,
  convertCardToPhysicalRequest: ConvertCardToPhysicalRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    {
      url: `/v2/cards/${cardid}/convert_to_physical`,
      method: 'post',
      data: convertCardToPhysicalRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useCardConvertToPhysical = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof cardConvertToPhysical>,
    Error,
    { cardid: string; data: ConvertCardToPhysicalRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof cardConvertToPhysical>,
    Error,
    { cardid: string; data: ConvertCardToPhysicalRequest }
  >((props) => {
    const { cardid, data } = props || {};

    return cardConvertToPhysical<Data>(cardid, data, requestOptions);
  }, mutationOptions);
};
export const cardImageGet = <Data = unknown>(
  cardid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? CardImage : Data>(
    { url: `/v2/cards/${cardid}/image`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getCardImageGetQueryKey = (cardid: string) => [
  `/v2/cards/${cardid}/image`,
];

export const useCardImageGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  cardid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof cardImageGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getCardImageGetQueryKey(cardid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof cardImageGet>, Error>(
    queryKey,
    () => cardImageGet<Data>(cardid, requestOptions),
    { enabled: !!cardid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const cardPinRetrieval = <Data = unknown>(
  cardid: string,
  retrievePinRequest: RetrievePinRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? RetrievePinResponse : Data>(
    {
      url: `/v2/cards/${cardid}/pin_retrieval`,
      method: 'post',
      data: retrievePinRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useCardPinRetrieval = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof cardPinRetrieval>,
    Error,
    { cardid: string; data: RetrievePinRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof cardPinRetrieval>,
    Error,
    { cardid: string; data: RetrievePinRequest }
  >((props) => {
    const { cardid, data } = props || {};

    return cardPinRetrieval<Data>(cardid, data, requestOptions);
  }, mutationOptions);
};
export const cardsGet = <Data = unknown>(
  params?: CardsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? CardsGetResponse : Data>(
    { url: `/v2/cards`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getCardsGetQueryKey = (params?: CardsGetParams) => [
  `/v2/cards`,
  ...(params ? [params] : []),
];

export const useCardsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: CardsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof cardsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getCardsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof cardsGet>, Error>(
    queryKey,
    () => cardsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
