import { Box, BoxProps, Heading, Text } from '@chakra-ui/react';
import { CommonHeadings } from '@libs/ui/theme';
import React, { ReactNode } from 'react';

export type PageHeaderProps = Omit<BoxProps, 'title'> & {
  children?: ReactNode;
  title: ReactNode;
  introduction?: string;
  size?: keyof CommonHeadings;
};

export const PageHeader = ({
  title,
  introduction,
  children,
  size,
  ...rest
}: PageHeaderProps) => {
  return (
    <Box {...rest} data-cy="page-header">
      <Heading size={size} data-cy="page-header-heading">
        {title}
      </Heading>
      {introduction && <Text mt="1rem">{introduction}</Text>}
      {children}
    </Box>
  );
};
