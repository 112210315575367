/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  Membership,
  AddMembershipRole,
  MembershipProductsGetResponse,
  MembershipProductsGetParams,
  ProgramsGetResponse,
  MembershipProgramsGetParams,
  MembershipKmAllowanceProductCreateResponse,
  MembershipKmAllowanceProductCreateRequest,
  ProgramGroup,
  Registration,
  MembershipRegistrationGetParams,
  RegistrationPatch,
  ServicesGetResponse,
  MembershipServicesGetParams,
  MembershipSummariesGetResponse,
  MembershipSummariesGetParams,
  Invitation,
  CreateInvitationRequest,
  UpdateInvitationRequest,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const membershipCreate = <Data = unknown>(
  addMembershipRole: AddMembershipRole,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Membership : Data>(
    { url: `/v2/memberships`, method: 'post', data: addMembershipRole },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMembershipCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof membershipCreate>,
    Error,
    { data: AddMembershipRole },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof membershipCreate>,
    Error,
    { data: AddMembershipRole }
  >((props) => {
    const { data } = props || {};

    return membershipCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const membershipDelete = <Data = unknown>(
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/memberships/${membershipid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMembershipDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof membershipDelete>,
    Error,
    { membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof membershipDelete>,
    Error,
    { membershipid: string }
  >((props) => {
    const { membershipid } = props || {};

    return membershipDelete<Data>(membershipid, requestOptions);
  }, mutationOptions);
};
export const membershipProductsGet = <Data = unknown>(
  membershipid: string,
  params?: MembershipProductsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MembershipProductsGetResponse : Data
  >(
    { url: `/v2/memberships/${membershipid}/products`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMembershipProductsGetQueryKey = (
  membershipid: string,
  params?: MembershipProductsGetParams,
) => [`/v2/memberships/${membershipid}/products`, ...(params ? [params] : [])];

export const useMembershipProductsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  membershipid: string,
  params?: MembershipProductsGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof membershipProductsGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMembershipProductsGetQueryKey(membershipid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof membershipProductsGet>, Error>(
    queryKey,
    () => membershipProductsGet<Data>(membershipid, params, requestOptions),
    { enabled: !!membershipid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const membershipProgramsGet = <Data = unknown>(
  membershipid: string,
  params?: MembershipProgramsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramsGetResponse : Data>(
    { url: `/v2/memberships/${membershipid}/programs`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMembershipProgramsGetQueryKey = (
  membershipid: string,
  params?: MembershipProgramsGetParams,
) => [`/v2/memberships/${membershipid}/programs`, ...(params ? [params] : [])];

export const useMembershipProgramsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  membershipid: string,
  params?: MembershipProgramsGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof membershipProgramsGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMembershipProgramsGetQueryKey(membershipid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof membershipProgramsGet>, Error>(
    queryKey,
    () => membershipProgramsGet<Data>(membershipid, params, requestOptions),
    { enabled: !!membershipid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const membershipKmAllowanceProductCreate = <Data = unknown>(
  membershipid: string,
  membershipKmAllowanceProductCreateRequest: MembershipKmAllowanceProductCreateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MembershipKmAllowanceProductCreateResponse : Data
  >(
    {
      url: `/v2/memberships/${membershipid}/products/km_allowance`,
      method: 'post',
      data: membershipKmAllowanceProductCreateRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMembershipKmAllowanceProductCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof membershipKmAllowanceProductCreate>,
    Error,
    { membershipid: string; data: MembershipKmAllowanceProductCreateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof membershipKmAllowanceProductCreate>,
    Error,
    { membershipid: string; data: MembershipKmAllowanceProductCreateRequest }
  >((props) => {
    const { membershipid, data } = props || {};

    return membershipKmAllowanceProductCreate<Data>(
      membershipid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const membershipProgramGroupGet = <Data = unknown>(
  membershipid: string,
  programid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramGroup : Data>(
    {
      url: `/v2/memberships/${membershipid}/programs/${programid}/group`,
      method: 'get',
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMembershipProgramGroupGetQueryKey = (
  membershipid: string,
  programid: string,
) => [`/v2/memberships/${membershipid}/programs/${programid}/group`];

export const useMembershipProgramGroupGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  membershipid: string,
  programid: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof membershipProgramGroupGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMembershipProgramGroupGetQueryKey(
    membershipid,
    programid,
  );
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof membershipProgramGroupGet>,
    Error
  >(
    queryKey,
    () =>
      membershipProgramGroupGet<Data>(membershipid, programid, requestOptions),
    { enabled: !!(membershipid && programid), ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const membershipRegistrationGet = <Data = unknown>(
  membershipid: string,
  params?: MembershipRegistrationGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    {
      url: `/v2/memberships/${membershipid}/registration`,
      method: 'get',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMembershipRegistrationGetQueryKey = (
  membershipid: string,
  params?: MembershipRegistrationGetParams,
) => [
  `/v2/memberships/${membershipid}/registration`,
  ...(params ? [params] : []),
];

export const useMembershipRegistrationGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  membershipid: string,
  params?: MembershipRegistrationGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof membershipRegistrationGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMembershipRegistrationGetQueryKey(membershipid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof membershipRegistrationGet>,
    Error
  >(
    queryKey,
    () => membershipRegistrationGet<Data>(membershipid, params, requestOptions),
    { enabled: !!membershipid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const membershipRegistrationPut = <Data = unknown>(
  membershipid: string,
  registration: Registration,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    {
      url: `/v2/memberships/${membershipid}/registration`,
      method: 'put',
      data: registration,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMembershipRegistrationPut = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof membershipRegistrationPut>,
    Error,
    { membershipid: string; data: Registration },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof membershipRegistrationPut>,
    Error,
    { membershipid: string; data: Registration }
  >((props) => {
    const { membershipid, data } = props || {};

    return membershipRegistrationPut<Data>(membershipid, data, requestOptions);
  }, mutationOptions);
};
export const membershipRegistrationPatch = <Data = unknown>(
  membershipid: string,
  registrationPatch: RegistrationPatch,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    {
      url: `/v2/memberships/${membershipid}/registration`,
      method: 'patch',
      data: registrationPatch,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMembershipRegistrationPatch = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof membershipRegistrationPatch>,
    Error,
    { membershipid: string; data: RegistrationPatch },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof membershipRegistrationPatch>,
    Error,
    { membershipid: string; data: RegistrationPatch }
  >((props) => {
    const { membershipid, data } = props || {};

    return membershipRegistrationPatch<Data>(
      membershipid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const membershipServicesGet = <Data = unknown>(
  membershipid: string,
  params?: MembershipServicesGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ServicesGetResponse : Data>(
    { url: `/v2/memberships/${membershipid}/services`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMembershipServicesGetQueryKey = (
  membershipid: string,
  params?: MembershipServicesGetParams,
) => [`/v2/memberships/${membershipid}/services`, ...(params ? [params] : [])];

export const useMembershipServicesGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  membershipid: string,
  params?: MembershipServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof membershipServicesGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMembershipServicesGetQueryKey(membershipid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof membershipServicesGet>, Error>(
    queryKey,
    () => membershipServicesGet<Data>(membershipid, params, requestOptions),
    { enabled: !!membershipid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const membershipSummariesGet = <Data = unknown>(
  membershipid: string,
  params?: MembershipSummariesGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MembershipSummariesGetResponse : Data
  >(
    { url: `/v2/memberships/${membershipid}/summaries`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMembershipSummariesGetQueryKey = (
  membershipid: string,
  params?: MembershipSummariesGetParams,
) => [`/v2/memberships/${membershipid}/summaries`, ...(params ? [params] : [])];

export const useMembershipSummariesGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  membershipid: string,
  params?: MembershipSummariesGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof membershipSummariesGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMembershipSummariesGetQueryKey(membershipid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof membershipSummariesGet>, Error>(
    queryKey,
    () => membershipSummariesGet<Data>(membershipid, params, requestOptions),
    { enabled: !!membershipid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const membershipInvitationPost = <Data = unknown>(
  membershipid: string,
  createInvitationRequest: CreateInvitationRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Invitation : Data>(
    {
      url: `/v2/memberships/${membershipid}/invitation`,
      method: 'post',
      data: createInvitationRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMembershipInvitationPost = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof membershipInvitationPost>,
    Error,
    { membershipid: string; data: CreateInvitationRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof membershipInvitationPost>,
    Error,
    { membershipid: string; data: CreateInvitationRequest }
  >((props) => {
    const { membershipid, data } = props || {};

    return membershipInvitationPost<Data>(membershipid, data, requestOptions);
  }, mutationOptions);
};
export const membershipInvitationPut = <Data = unknown>(
  membershipid: string,
  invitationid: string,
  updateInvitationRequest: UpdateInvitationRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Invitation : Data>(
    {
      url: `/v2/memberships/${membershipid}/invitation/${invitationid}`,
      method: 'put',
      data: updateInvitationRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMembershipInvitationPut = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof membershipInvitationPut>,
    Error,
    {
      membershipid: string;
      invitationid: string;
      data: UpdateInvitationRequest;
    },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof membershipInvitationPut>,
    Error,
    {
      membershipid: string;
      invitationid: string;
      data: UpdateInvitationRequest;
    }
  >((props) => {
    const { membershipid, invitationid, data } = props || {};

    return membershipInvitationPut<Data>(
      membershipid,
      invitationid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const membershipValidateRegistration = <Data = unknown>(
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    {
      url: `/v2/memberships/${membershipid}/validate_registration`,
      method: 'post',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMembershipValidateRegistration = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof membershipValidateRegistration>,
    Error,
    { membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof membershipValidateRegistration>,
    Error,
    { membershipid: string }
  >((props) => {
    const { membershipid } = props || {};

    return membershipValidateRegistration<Data>(membershipid, requestOptions);
  }, mutationOptions);
};
