/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  ExpensesGetResponse,
  ExpensesGetParams,
  Expense,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const expensesGet = <Data = unknown>(
  params?: ExpensesGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpensesGetResponse : Data>(
    { url: `/expenses`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getExpensesGetQueryKey = (params?: ExpensesGetParams) => [
  `/expenses`,
  ...(params ? [params] : []),
];

export const useExpensesGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ExpensesGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof expensesGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getExpensesGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof expensesGet>, Error>(
    queryKey,
    () => expensesGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const expensesCreate = <Data = unknown>(
  expense: Expense,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/expenses`, method: 'post', data: expense },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useExpensesCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof expensesCreate>,
    Error,
    { data: Expense },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof expensesCreate>,
    Error,
    { data: Expense }
  >((props) => {
    const { data } = props || {};

    return expensesCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
