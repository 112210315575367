import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Role } from '@libs/api/models';
import { getI18nInstance } from '@libs/core/i18n';
import { ErrorCrashTrackingBoundary } from '@libs/dashboard-core/components/error-crash-tracking';
import { QUERY_CLIENT } from '@libs/dashboard-core/constants';
import { ProvideAuth, ProvideRole } from '@libs/dashboard-core/context';
import {
  OnboardedRoute,
  PrivateRoute,
  RoleRoute,
} from '@libs/dashboard-core/guards';
import {
  ConfirmResetPasswordPage,
  DashboardFleetManagerPage,
  EmployeeDetailsPage,
  DashboardOperatorPage,
  EmployeePage,
  LoginPage,
  MerchantPage,
  OnboardingPage,
  OrganisationDetailsPage,
  OrganisationOnboardingPage,
  OrganisationPage,
  ResetPasswordPage,
  RestrictionGroupPage,
  ServiceUnavailablePage,
  SetPasswordPage,
  TransactionPage,
  ServicePage,
  ExpensePage,
} from '@libs/dashboard-core/pages';
import { getRouterPath, Routes } from '@libs/dashboard-core/routes';
import {
  DefaultTheme,
  GlobalFonts,
  GlobalStyle,
} from '@libs/dashboard-core/theme';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { BUILD_CONFIG } from '../build-config';

export const App = () => {
  const Theme = extendTheme(DefaultTheme);

  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      <ChakraProvider resetCSS theme={Theme}>
        <GlobalStyle />
        <GlobalFonts />
        <I18nextProvider i18n={getI18nInstance(BUILD_CONFIG.i18n)}>
          <ErrorCrashTrackingBoundary config={BUILD_CONFIG}>
            <ProvideAuth>
              <Router>
                <Switch>
                  <Route path={getRouterPath(Routes.SERVICE_UNAVAILABLE)}>
                    <ServiceUnavailablePage />
                  </Route>
                  <Route path={getRouterPath(Routes.AUTH)}>
                    <Switch>
                      <Route path={getRouterPath(Routes.AUTH_LOGIN)}>
                        <LoginPage />
                      </Route>
                      <Route path={getRouterPath(Routes.AUTH_SET_PASSWORD)}>
                        <SetPasswordPage />
                      </Route>
                      <Route path={getRouterPath(Routes.AUTH_RESET_PASSWORD)}>
                        <ResetPasswordPage />
                      </Route>
                      <Route
                        path={getRouterPath(Routes.AUTH_CONFIRM_RESET_PASSWORD)}
                      >
                        <ConfirmResetPasswordPage />
                      </Route>
                      <Redirect to={getRouterPath(Routes.AUTH_LOGIN)} />
                    </Switch>
                  </Route>
                  <PrivateRoute>
                    <ProvideRole>
                      <RoleRoute role={Role.USER}>
                        <Switch>
                          <Route path={getRouterPath(Routes.DASHBOARD)} exact>
                            <OnboardedRoute>
                              <DashboardFleetManagerPage />
                            </OnboardedRoute>
                          </Route>
                          <Route path={getRouterPath(Routes.ONBOARDING)} exact>
                            <OnboardingPage />
                          </Route>
                          <Redirect to={getRouterPath(Routes.DASHBOARD)} />
                        </Switch>
                      </RoleRoute>
                      <RoleRoute role={Role.OPERATOR_ADMIN}>
                        <Switch>
                          <Route path={getRouterPath(Routes.DASHBOARD)} exact>
                            <DashboardOperatorPage />
                          </Route>
                          <Route
                            path={getRouterPath(Routes.DASHBOARD_TRANSACTIONS)}
                            exact
                          >
                            <TransactionPage />
                          </Route>
                          <Route
                            path={getRouterPath(Routes.DASHBOARD_MERCHANTS)}
                            exact
                          >
                            <MerchantPage />
                          </Route>
                          <Route
                            path={getRouterPath(
                              Routes.DASHBOARD_RESTRICTION_GROUPS,
                            )}
                            exact
                          >
                            <RestrictionGroupPage />
                          </Route>
                          <Route
                            path={getRouterPath(Routes.DASHBOARD_ORGANISATIONS)}
                            exact
                          >
                            <OrganisationPage />
                          </Route>
                          <Route
                            exact
                            path={getRouterPath(
                              Routes.DASHBOARD_ORGANISATIONS_DETAILS,
                            )}
                          >
                            <OrganisationDetailsPage />
                          </Route>
                          <Route
                            path={getRouterPath(Routes.DASHBOARD_SERVICES)}
                            exact
                          >
                            <ServicePage />
                          </Route>
                          <Route
                            path={getRouterPath(Routes.DASHBOARD_EXPENSES)}
                            exact
                          >
                            <ExpensePage />
                          </Route>
                          <Route
                            path={getRouterPath(
                              Routes.DASHBOARD_EMPLOYEES_DETAILS,
                            )}
                          >
                            <EmployeeDetailsPage />
                          </Route>
                          <Route
                            path={getRouterPath(Routes.DASHBOARD_EMPLOYEES)}
                            exact
                          >
                            <EmployeePage />
                          </Route>
                          <Route
                            path={getRouterPath(
                              Routes.DASHBOARD_ORGANISATIONS_DETAILS_ONBOARDING,
                            )}
                            exact
                          >
                            <OrganisationOnboardingPage />
                          </Route>
                          <Redirect to={getRouterPath(Routes.DASHBOARD)} />
                        </Switch>
                      </RoleRoute>
                    </ProvideRole>
                  </PrivateRoute>
                </Switch>
              </Router>
            </ProvideAuth>
          </ErrorCrashTrackingBoundary>
        </I18nextProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
