export const Spinner = {
  baseStyle: ({
    theme,
    size,
    color = theme.colors.spinner.main,
    emptyColor = theme.colors.spinner.empty,
  }) => {
    return {
      borderWidth: size !== 'xl' ? '.2rem' : '.4rem',
      borderBottomColor: emptyColor,
      borderLeftColor: emptyColor,
      color,
    };
  },
  defaultProps: {
    size: 'xl',
    speed: '.65s',
    thickness: '.4rem',
  },
};
