import * as React from 'react';

function SvgCardWallet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 6.25V17.75H20.75V6.25H3.25ZM1.25 6C1.25 5.0335 2.0335 4.25 3 4.25H21C21.9665 4.25 22.75 5.0335 22.75 6V18C22.75 18.9665 21.9665 19.75 21 19.75H3C2.0335 19.75 1.25 18.9665 1.25 18V6Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7495 15.75C14.7495 15.1977 15.1972 14.75 15.7495 14.75H18.7495C19.3017 14.75 19.7495 15.1977 19.7495 15.75C19.7495 16.3023 19.3017 16.75 18.7495 16.75H15.7495C15.1972 16.75 14.7495 16.3023 14.7495 15.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2495 15.75C10.2495 15.1977 10.6972 14.75 11.2495 14.75H12.7495C13.3017 14.75 13.7495 15.1977 13.7495 15.75C13.7495 16.3023 13.3017 16.75 12.7495 16.75H11.2495C10.6972 16.75 10.2495 16.3023 10.2495 15.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.24945 9.0799C1.24945 8.52762 1.69717 8.0799 2.24945 8.0799H21.7494C22.3017 8.0799 22.7494 8.52762 22.7494 9.0799C22.7494 9.63219 22.3017 10.0799 21.7494 10.0799H2.24945C1.69717 10.0799 1.24945 9.63219 1.24945 9.0799Z"
      />
    </svg>
  );
}

export default SvgCardWallet;
