/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type EntityType =
  | 'REPORTING_FINANCIAL_INSTITUTION'
  | 'NON_REPORTING_FINANCIAL_INSTITUTION'
  | 'ACTIVE_NON_FINANCIAL_ENTITY_GOVERNEMENT'
  | 'ACTIVE_NON_FINANCIAL_ENTITY_OTHER'
  | 'PASSIVE_NON_FINANCIAL_ENTITY';

export const EntityType = {
  REPORTING_FINANCIAL_INSTITUTION: 'REPORTING_FINANCIAL_INSTITUTION' as EntityType,
  NON_REPORTING_FINANCIAL_INSTITUTION: 'NON_REPORTING_FINANCIAL_INSTITUTION' as EntityType,
  ACTIVE_NON_FINANCIAL_ENTITY_GOVERNEMENT: 'ACTIVE_NON_FINANCIAL_ENTITY_GOVERNEMENT' as EntityType,
  ACTIVE_NON_FINANCIAL_ENTITY_OTHER: 'ACTIVE_NON_FINANCIAL_ENTITY_OTHER' as EntityType,
  PASSIVE_NON_FINANCIAL_ENTITY: 'PASSIVE_NON_FINANCIAL_ENTITY' as EntityType,
};
