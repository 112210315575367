/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type DocumentType =
  | 'ORGANISATION_CONSTITUTION'
  | 'ORGANISATION_BCE'
  | 'ID_FRONT'
  | 'ID_BACK'
  | 'PASSPORT'
  | 'PROOF_OF_ADDRESS'
  | 'US_PERSON_ADDITIONAL'
  | 'PROXY_GRANTING_EMPLOYEE'
  | 'RESIDENCE_PERMIT';

export const DocumentType = {
  ORGANISATION_CONSTITUTION: 'ORGANISATION_CONSTITUTION' as DocumentType,
  ORGANISATION_BCE: 'ORGANISATION_BCE' as DocumentType,
  ID_FRONT: 'ID_FRONT' as DocumentType,
  ID_BACK: 'ID_BACK' as DocumentType,
  PASSPORT: 'PASSPORT' as DocumentType,
  PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS' as DocumentType,
  US_PERSON_ADDITIONAL: 'US_PERSON_ADDITIONAL' as DocumentType,
  PROXY_GRANTING_EMPLOYEE: 'PROXY_GRANTING_EMPLOYEE' as DocumentType,
  RESIDENCE_PERMIT: 'RESIDENCE_PERMIT' as DocumentType,
};
