import {
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  SimpleGrid,
  useTheme,
} from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Card, CheckboxField } from '@libs/ui/components';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderDocumentTypeForm } from '../../onboarding-wizard.constants';

type ProofQualityImageProps = {
  documentType: ShareholderDocumentTypeForm;
};
export const ProofQualityImage = ({ documentType }: ProofQualityImageProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const Alert = useIcon('Alert');
  return (
    <Card bgColor="background.page">
      <Flex>
        <Box
          mr="1rem"
          mt="-.5rem"
          display="inline-block"
          verticalAlign="middle"
        >
          <Alert width="3rem" height="3rem" fill={theme.colors.ink.dark} />
        </Box>
        <Heading size="Subhead">
          {t(i18nKeys.onboarding.documents.quality.title)}
        </Heading>
      </Flex>

      <List align="flex-start">
        <SimpleGrid columns={2} spacing="1rem" ml="4rem">
          <ListItem>
            <CheckboxField name="imageClear" checkboxProps={{ size: 'sm' }}>
              {t(i18nKeys.onboarding.documents.quality.spec1)}
            </CheckboxField>
          </ListItem>
          <ListItem>
            <CheckboxField name="imageColor" checkboxProps={{ size: 'sm' }}>
              {t(i18nKeys.onboarding.documents.quality.spec2)}
            </CheckboxField>
          </ListItem>
          <ListItem>
            <CheckboxField name="imageCopy" checkboxProps={{ size: 'sm' }}>
              {t(i18nKeys.onboarding.documents.quality.spec3)}
            </CheckboxField>
          </ListItem>
          <ListItem>
            <CheckboxField name="imagePdf" checkboxProps={{ size: 'sm' }}>
              {t(i18nKeys.onboarding.documents.quality.spec4)}
            </CheckboxField>
          </ListItem>
        </SimpleGrid>
      </List>
    </Card>
  );
};
