/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type RegistrationStateStatus =
  | 'SUGGESTED'
  | 'CREATED'
  | 'CONFIRMED'
  | 'VALIDATED'
  | 'REFUSED';

export const RegistrationStateStatus = {
  SUGGESTED: 'SUGGESTED' as RegistrationStateStatus,
  CREATED: 'CREATED' as RegistrationStateStatus,
  CONFIRMED: 'CONFIRMED' as RegistrationStateStatus,
  VALIDATED: 'VALIDATED' as RegistrationStateStatus,
  REFUSED: 'REFUSED' as RegistrationStateStatus,
};
