import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import * as Yup from 'yup';

export const validationLoginSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export const validationResetPasswordSchema = Yup.object({
  email: Yup.string().email().required(),
});

export const validationConfirmResetPasswordSchema = Yup.object({
  password: Yup.string().required().password(),
  passwordConfirmed: Yup.string()
    .required()
    .equalToField(
      'password',
      i18nKeys.common.errors.password.passwords_mismatch,
    ),
});

export const validationCreateUserSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required().password(),
  passwordConfirmed: Yup.string()
    .required()
    .equalToField(
      'password',
      i18nKeys.common.errors.password.passwords_mismatch,
    ),
});

export const validationSetPasswordSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required().password(),
  passwordConfirmed: Yup.string()
    .required()
    .equalToField(
      'password',
      i18nKeys.common.errors.password.passwords_mismatch,
    ),
});
