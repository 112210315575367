/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type RestrictionGroupStatus = 'VALIDATED' | 'PENDING' | 'CANCELED';

export const RestrictionGroupStatus = {
  VALIDATED: 'VALIDATED' as RestrictionGroupStatus,
  PENDING: 'PENDING' as RestrictionGroupStatus,
  CANCELED: 'CANCELED' as RestrictionGroupStatus,
};
