/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  RegistrationsGetResponse,
  RegistrationsGetParams,
  Registration,
  RegistrationCreateRequest,
  Address,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const registrationsGet = <Data = unknown>(
  params?: RegistrationsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? RegistrationsGetResponse : Data>(
    { url: `/v2/registrations`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getRegistrationsGetQueryKey = (
  params?: RegistrationsGetParams,
) => [`/v2/registrations`, ...(params ? [params] : [])];

export const useRegistrationsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: RegistrationsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof registrationsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getRegistrationsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof registrationsGet>, Error>(
    queryKey,
    () => registrationsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const registrationsCreate = <Data = unknown>(
  registrationCreateRequest: RegistrationCreateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    {
      url: `/v2/registrations`,
      method: 'post',
      data: registrationCreateRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useRegistrationsCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof registrationsCreate>,
    Error,
    { data: RegistrationCreateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof registrationsCreate>,
    Error,
    { data: RegistrationCreateRequest }
  >((props) => {
    const { data } = props || {};

    return registrationsCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const registrationUpdate = <Data = unknown>(
  membershipid: string,
  registration: Registration,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    {
      url: `/v2/registrations/${membershipid}`,
      method: 'put',
      data: registration,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useRegistrationUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof registrationUpdate>,
    Error,
    { membershipid: string; data: Registration },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof registrationUpdate>,
    Error,
    { membershipid: string; data: Registration }
  >((props) => {
    const { membershipid, data } = props || {};

    return registrationUpdate<Data>(membershipid, data, requestOptions);
  }, mutationOptions);
};
export const registrationDelete = <Data = unknown>(
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/registrations/${membershipid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useRegistrationDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof registrationDelete>,
    Error,
    { membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof registrationDelete>,
    Error,
    { membershipid: string }
  >((props) => {
    const { membershipid } = props || {};

    return registrationDelete<Data>(membershipid, requestOptions);
  }, mutationOptions);
};
export const registrationAddressUpdate = <Data = unknown>(
  membershipid: string,
  address: Address,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    {
      url: `/v2/registrations/${membershipid}/address`,
      method: 'put',
      data: address,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useRegistrationAddressUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof registrationAddressUpdate>,
    Error,
    { membershipid: string; data: Address },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof registrationAddressUpdate>,
    Error,
    { membershipid: string; data: Address }
  >((props) => {
    const { membershipid, data } = props || {};

    return registrationAddressUpdate<Data>(membershipid, data, requestOptions);
  }, mutationOptions);
};
