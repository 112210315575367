import * as React from 'react';

function SvgArrowNoSort(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.7,11.6c-0.4-0.2-0.9-0.2-1.3,0.1l-2,1.6V4.5c0-0.1,0-0.1,0-0.2v0c-0.1-0.5-0.6-0.8-1.1-0.7c-0.5,0.1-0.9,0.5-0.8,0.9
		v8.8l-2-1.6c-0.3-0.3-0.9-0.3-1.3-0.1c0,0-0.1,0-0.1,0.1c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6l3.8,3c0.2,0.2,0.4,0.2,0.7,0.2
		s0.5-0.1,0.7-0.2l3.8-3c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.2-0.4,0.2-0.6C19.1,12,19,11.8,18.7,11.6z"
      />
      <path
        d="M9.9,8.1c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6l-3.8-3c-0.4-0.3-1-0.3-1.4,0L1,6.9c0,0-0.1,0.1-0.1,0.1
		C0.7,7.2,0.6,7.4,0.7,7.6c0,0.2,0.2,0.5,0.4,0.6C1.5,8.4,2,8.4,2.4,8.1l2-1.6v8.8c0,0.1,0,0.1,0,0.2v0c0.1,0.4,0.5,0.7,1,0.7
		c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.5,0.8-0.9V6.5l2,1.6c0.3,0.3,0.9,0.3,1.3,0.1C9.8,8.2,9.8,8.2,9.9,8.1z M4.7,15.4L4.7,15.4
		L4.7,15.4L4.7,15.4z"
      />
    </svg>
  );
}

export default SvgArrowNoSort;
