export const Radio = {
  baseStyle: ({ theme }) => ({
    label: { fontFamily: theme.fonts.body1 },
    control: {
      borderColor: theme.colors.ink.black,
      marginRight: '.5rem',
      _checked: {
        borderColor: theme.colors.ink.black,
        bg: theme.colors.ink.white,
        _before: { bg: theme.colors.ink.black, width: '70%', height: '70%' },
        _hover: {
          bg: theme.colors.ink.white,
          borderColor: theme.colors.ink.light,
        },
      },
      _hover: {
        bg: theme.colors.ink.white,
        borderColor: theme.colors.ink.light,
      },
    },
  }),
  sizes: {
    md: {
      control: { w: '2.4rem', h: '2.4rem' },
      label: ({ theme }) => ({
        fontSize: theme.fontSizes.body2,
        lineHeight: theme.lineHeights.body2,
      }),
    },
  },
};
