import {
  Badge,
  Box,
  CloseButton,
  Image,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useImage } from '@libs/core/theme/utils';
import React, { Children, useEffect } from 'react';
import { AssistantCard } from './assistant-card';
import { Arrow, AssistantCollapse } from './assistant.style';

export const Assistant = ({ children }) => {
  const assistantImg = useImage('assistant');
  const count = Children.count(children);
  const { isOpen, onOpen, onToggle } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, []);

  return (
    <Stack
      direction={{ base: 'row-reverse', md: 'column' }}
      align="center"
      zIndex={{ base: '1000', md: '300' }}
      pos={{ base: 'fixed', md: 'relative' }}
      bottom={{ base: '0' }}
      top={{ base: 'auto', md: '-10rem' }}
      right={{ base: '0' }}
      left={{ base: '0' }}
      width={{ base: '100%' }}
      p="2rem 0"
    >
      <Box
        width={{ base: '6rem', md: '100%' }}
        maxWidth="35rem"
        pos={{ base: 'absolute', md: 'relative' }}
        bottom={{ base: '2rem', md: '0' }}
      >
        <Box
          boxSize={{ base: '4rem', md: '5rem' }}
          m="0 auto"
          onClick={onToggle}
          pos="relative"
        >
          <Badge
            variant="secondary"
            pos="absolute"
            zIndex="56"
            right=".1rem"
            top="-.5rem"
            borderRadius="full"
            fontSize="0.8em"
            boxSize="2.2rem"
            textAlign="center"
          >
            {count}
          </Badge>
          <Image
            border=".4rem solid white"
            backgroundColor="white"
            boxSize="100%"
            alt="Your onboarding Assistant"
            borderRadius="full"
            src={assistantImg}
            position="relative"
            zIndex="55"
          />
        </Box>
      </Box>
      <Box
        maxWidth="35rem"
        width={{ base: '100%' }}
        mb={{ base: '8rem', md: '0' }}
        position="relative"
        top=".3rem"
      >
        <AssistantCollapse in={isOpen} animateOpacity>
          <Arrow />
          <CloseButton
            data-cy="assistant-close-button"
            position="absolute"
            right="0"
            onClick={onToggle}
          />
          {children}
        </AssistantCollapse>
      </Box>
    </Stack>
  );
};

Assistant.Card = AssistantCard;
