/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type TripType = 'COMPLETE' | 'PARTIAL' | 'COMPLETE_AND_PARTIAL' | 'NONE';

export const TripType = {
  COMPLETE: 'COMPLETE' as TripType,
  PARTIAL: 'PARTIAL' as TripType,
  COMPLETE_AND_PARTIAL: 'COMPLETE_AND_PARTIAL' as TripType,
  NONE: 'NONE' as TripType,
};
