import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
} from '@chakra-ui/react';
import { useRegistrationGet } from '@libs/api/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import {
  getPath,
  Routes,
  ROUTES,
  addQueryParams,
} from '@libs/dashboard-core/routes';
import { Loader } from '@libs/ui/components';
import { Link } from '@libs/ui/components/link';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { DashboardLayout } from '../../../components/dashboard-layout';
import { EmployeeDetailsBudgets } from './components/employee-details-budgets';
import { EmployeeDetailsCards } from './components/employee-details-cards';
import { EmployeeDetailsExpenses } from './components/employee-details-expenses';
import { EmployeeDetailsMembershipSummaries } from './components/employee-details-membership-summaries';
import { EmployeeDetailsOverview } from './components/employee-details-overview';
import { EmployeeDetailsPrograms } from './components/employee-details-programs';

export const useEmployeeDetailsPageRoutes = () => {
  return [
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_BUDGETS],
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_PROGRAMS],
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_MEMBERSHIP_SUMMARIES],
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_EXPENSES],
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_CARD],
  ];
};

export const EmployeeDetailsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { membershipId } = useParams<{ membershipId: string }>();
  const routes = useEmployeeDetailsPageRoutes();
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    routes
      ?.map((elem, index) => {
        return location.pathname.includes(elem.path) && index;
      })
      .filter((e) => e)[0] || 0,
  );

  const { data: employee, isLoading } = useRegistrationGet(membershipId);

  if (!employee || isLoading) {
    return <Loader />;
  }

  const tabList = [
    {
      title: t(i18nKeys.common.budgets) + '(old-gen)',
      component: <EmployeeDetailsBudgets employee={employee} />,
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_BUDGETS,
    },
    {
      title: t(i18nKeys.common.expenses) + '(old-gen)',
      component: (
        <EmployeeDetailsExpenses employee={employee} isOldGen={true} />
      ),
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_EXPENSES_OLDGEN,
    },
    {
      title: t(i18nKeys.common.programs),
      component: <EmployeeDetailsPrograms employee={employee} />,
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_PROGRAMS,
    }, //PROGRAMS
    {
      title: t(i18nKeys.common.budgets),
      component: <EmployeeDetailsMembershipSummaries employee={employee} />,
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_MEMBERSHIP_SUMMARIES,
    }, //MEMBERSHIPSUMMARIES
    {
      title: t(i18nKeys.common.expenses),
      component: <EmployeeDetailsExpenses employee={employee} />,
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_EXPENSES,
    },
    {
      title: t(i18nKeys.common.card),
      component: <EmployeeDetailsCards employee={employee} />,
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_CARD,
    },
  ];

  const tabChangeHandler = (index: number) => {
    setActiveTabIndex(index);
  };

  const getUrl = (index: number) =>
    addQueryParams(
      getPath(tabList[index].route, {
        params: { membershipId: employee.membership_id },
      }),
      { page: 1, pageSize: 10 },
    );

  return (
    <DashboardLayout>
      <Breadcrumb mb="2rem">
        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD)}
          >
            {t(i18nKeys.common.home)}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD_EMPLOYEES)}
          >
            {t(i18nKeys.common.employees)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink variant="breadcrumb" as={Link} to="#">
            {capitalize(employee.first_name)} {capitalize(employee.last_name)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <EmployeeDetailsOverview employee={employee} />
      <Tabs mt="2rem" onChange={tabChangeHandler} index={activeTabIndex}>
        <TabList>
          {tabList.map(({ title }, index) => (
            <Tab
              textColor="ink.medium"
              fontSize="body2"
              fontFamily={theme.fonts.large}
              _hover={{
                textColor:
                  activeTabIndex === index
                    ? 'ink.black'
                    : 'main.secondary.normal',
              }}
              _selected={{
                textColor: 'ink.black',
                borderBottomColor: 'main.primary.normal',
              }}
              key={title}
              as={NavLink}
              to={getUrl(index)}
            >
              {title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabList.map(({ title, component }) => (
            <TabPanel key={title}>{component}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </DashboardLayout>
  );
};
