import { Box, Text, useTheme } from '@chakra-ui/react';
import { Status } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { StatusToTagStatus } from '@libs/dashboard-core/utils/status';
import { Table, Tag } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type KycRowCommentProps = {
  status?: Status;
  comment?: string;
};

export const KycRowComment = ({ status, comment }: KycRowCommentProps) => {
  const comments = comment ? comment.split('----') : null;
  const { t } = useTranslation();
  const theme = useTheme();
  if (comment) {
    return (
      <>
        <Table.TD>
          <Tag
            status={StatusToTagStatus[status]}
            hasIcon
            tooltipLabel={
              <Text color={theme.colors.ink.dark} size="Small">
                {t(i18nKeys.common.TagStatus[status])}
              </Text>
            }
          />
        </Table.TD>
        <Table.TD>
          <Box maxWidth="56rem">
            {comments && comments.length > 1
              ? comments.map((comment, index) => (
                  <Text key={index}> {comment} </Text>
                ))
              : comment}
          </Box>
        </Table.TD>
      </>
    );
  }

  if (status?.toString() !== Status.VALIDATED) {
    return (
      <>
        <Table.TD>
          <Tag
            status={StatusToTagStatus[status]}
            hasIcon
            tooltipLabel={
              <Text color={theme.colors.ink.dark} size="Small">
                {t(i18nKeys.common.TagStatus[status])}
              </Text>
            }
          />
        </Table.TD>
        <Table.TD>
          <Box maxWidth="56rem">
            {t(i18nKeys.operator.organisations.details.kyc.not_data)}
          </Box>
        </Table.TD>
      </>
    );
  }
  return (
    <Table.TD colSpan={2}>
      <Box maxWidth="56rem">
        {t(i18nKeys.operator.organisations.details.kyc.data_ok)}
      </Box>
    </Table.TD>
  );
};
