export const Modal = {
  baseStyle: {
    overlay: {
      zIndex: 2147483008,
    },
    dialogContainer: {
      zIndex: 2147483009,
    },
    dialog: {
      zIndex: 2147483007,
    },
  },
  sizes: {
    xl: {
      dialog: { maxW: '90rem' },
    },
    lg: { dialog: { maxW: '80rem' } },
    md: { dialog: { maxW: '70rem' } },
    sm: { dialog: { maxW: '50rem' } },
    xs: { dialog: { maxW: '32rem' } },
  },
};
