import { Box, BoxProps, Divider, Heading, useTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export type CardProps = Omit<BoxProps, 'title'> & {
  children: ReactNode;
  title?: ReactNode;
};

export const Card = ({ children, title, ...rest }: CardProps) => {
  const theme = useTheme();
  return (
    <Box
      p="2.5rem"
      bgColor={theme.colors.ink.white}
      borderRadius={theme.borders.lg}
      {...rest}
    >
      {title && (
        <>
          <Box p="2.5rem">
            <Heading size="Title3" data-cy="card-heading">
              {title}
            </Heading>
          </Box>
          <Divider></Divider>
        </>
      )}
      {children}
    </Box>
  );
};
