import { css, Global } from '@emotion/react';
import React from 'react';

import TitleBoldWoff from './National2-Bold.woff';
import TitleBoldWoff2 from './National2-Bold.woff2';
import TitleBoldItWoff from './National2-BoldIt.woff';
import TitleBoldItWoff2 from './National2-BoldIt.woff2';
import ContentRegularWoff from './National2-Regular.woff';
import ContentRegularWoff2 from './National2-Regular.woff2';

export const GlobalFonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'TitleBold';
        src: local('TitleBold'), local('TitleBold'),
          url(${TitleBoldWoff}) format('woff2'),
          url(${TitleBoldWoff2}) format('woff');
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: 'TitleBoldIt';
        src: local('TitleBoldIt'), local('TitleBoldIt'),
          url(${TitleBoldItWoff}) format('woff2'),
          url(${TitleBoldItWoff2}) format('woff');
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: 'ContentRegular';
        src: local('ContentRegular'), local('ContentRegular'),
          url(${ContentRegularWoff}) format('woff2'),
          url(${ContentRegularWoff2}) format('woff');
        font-weight: 300;
        font-style: normal;
      }
    `}
  />
);

export const fonts = {
  Xlarge: 'TitleBoldIt',
  large: 'TitleBoldIt',
  title1: 'TitleBoldIt',
  title2: 'TitleBold',
  title3: 'TitleBoldIt',
  headline: 'TitleBoldIt',
  subhead: 'TitleBoldIt',
  body1: 'ContentRegular',
  body1Bold: 'TitleBold',
  body2: 'ContentRegular',
  body2Bold: 'TitleBold',
  small: 'ContentRegular',
  smallBold: 'TitleBold',
  xsmall: 'ContentRegular',
  xsmallBold: 'TitleBold',
  body: 'ContentRegular',
  heading: 'TitleBoldIt',
};
