import * as React from 'react';

function SvgAttachment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M7.31256 15.5H17.8126C18.2104 15.5 18.5919 15.3419 18.8732 15.0606C19.1545 14.7793 19.3125 14.3978 19.3125 14C19.3125 13.6022 19.1545 13.2206 18.8732 12.9393C18.5919 12.658 18.2104 12.5 17.8126 12.5H4.31256C2.69645 12.5 1.38633 13.8431 1.38633 15.5C1.38633 17.1569 2.69644 18.5 4.31256 18.5H17.9995C19.193 18.5 20.3376 18.0259 21.1815 17.182C22.0254 16.3381 22.4995 15.1935 22.4995 14C22.4995 12.8065 22.0254 11.6619 21.1815 10.818C20.3376 9.97411 19.193 9.5 17.9995 9.5H7.49951"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAttachment;
