/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type PersonalAssets =
  | 'less_three_hundred_twenty'
  | 'less_hundred_thirthy'
  | 'less_two'
  | 'less_four_hundred_sixty';

export const PersonalAssets = {
  less_three_hundred_twenty: 'less_three_hundred_twenty' as PersonalAssets,
  less_hundred_thirthy: 'less_hundred_thirthy' as PersonalAssets,
  less_two: 'less_two' as PersonalAssets,
  less_four_hundred_sixty: 'less_four_hundred_sixty' as PersonalAssets,
};
