import { AnnualTurnOver, Title } from '@libs/api/models';
import { FIREBASE_STORAGE_FILES } from '@libs/dashboard-core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { TEN_MB } from '@libs/core/constants';

export const UPLOAD_FILE_OPTIONS = {
  multiple: false,
  maxFiles: 1,
  minSize: 1,
  maxSize: TEN_MB,
  accept: 'image/jpeg, image/png, application/pdf',
};

export const UPLOAD_FILE_OPTIONS_ID = {
  multiple: false,
  maxFiles: 1,
  minSize: 1,
  maxSize: TEN_MB,
  accept: 'image/jpeg, image/png',
};

export type ShareholderTypeForm = 'Known' | 'Unknown' | 'Undefined';

export const ShareholderTypeForm = {
  KNOWN: 'Known' as ShareholderTypeForm,
  UNKNOWN: 'Unknown' as ShareholderTypeForm,
  UNDEFINED: 'Undefined' as ShareholderTypeForm,
};

export type ShareholderDocumentTypeForm = 'identity' | 'passport';

export type CompanyDetailsSteps = 'confirm' | 'review';
export const CompanyDetailsSteps = {
  CONFIRM: 'confirm' as CompanyDetailsSteps,
  REVIEW: 'review' as CompanyDetailsSteps,
};

export const ShareholderDocumentTypeForm = {
  IDENTITY: 'identity' as ShareholderDocumentTypeForm,
  PASSPORT: 'passport' as ShareholderDocumentTypeForm,
};

export const SELECTABLE_DOCUMENTS = [
  ShareholderDocumentTypeForm.IDENTITY,
  ShareholderDocumentTypeForm.PASSPORT,
];

export const EXAMPLE_BY_DOCUMENT_TYPE = {
  [ShareholderDocumentTypeForm.IDENTITY]: {
    image: FIREBASE_STORAGE_FILES.images.ID_EXAMPLE,
    title: i18nKeys.onboarding.documents.identity,
  },
  [ShareholderDocumentTypeForm.PASSPORT]: {
    image: FIREBASE_STORAGE_FILES.images.PASSPORT_EXAMPLE,
    title: i18nKeys.onboarding.documents.passport,
  },
};

export const CONSTITUTION_EXAMPLE =
  'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/templates%2Fskipr_constitution.pdf?alt=media&token=a3432edb-9450-4f1b-a0f4-532efea8b653';

export const DAO_EXAMPLE =
  'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/templates%2FDOA.pdf?alt=media&token=aafbbdea-8c13-4e64-b183-e750d4c17a7d';

export const SHAREHOLDER_ID_NEW = 'new';

export const SHAREHOLDER_DEFAULT = {
  title: Title.M as Title,
  email: '',
  phone_number: '',
  first_name: '',
  last_name: '',
  nationality: '',
  taxes: {
    tax_residence_id: '',
  },
  address: {
    street: '',
    city: '',
    postal_code: '',
    country_code: '',
  },
  date_of_birth: '',
  place_of_birth: '',
  occupation: '',
};

export const ANNUAL_TURN_OVER_ACCEPTED = [
  AnnualTurnOver.less_three_k,
  AnnualTurnOver.less_ten_k,
  AnnualTurnOver.less_hundred_k,
  AnnualTurnOver.over_hundred_k,
];
