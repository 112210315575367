import { RadioGroup, RadioGroupProps, Stack, Text } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { FC, ReactNode } from 'react';
import { FormControl } from '../components/form-control';
import { BaseProps } from '../form.types';

export type RadioFieldProps = BaseProps & {
  radioProps?: RadioGroupProps;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  children: ReactNode;
  isBoolean?: boolean;
  stackWidth?: string;
};

export const RadioGroupField: FC<RadioFieldProps> = (
  props: RadioFieldProps,
) => {
  const {
    name,
    label,
    radioProps,
    children,
    direction = 'row',
    isBoolean,
    stackWidth,
    ...formControlProps
  } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl
      id={name}
      meta={meta}
      isEmpty={!field.value}
      {...formControlProps}
    >
      <Stack
        width={stackWidth}
        direction={direction}
        align="flex-start"
        spacing={10}
      >
        {label && <Text>{label}</Text>}
        <RadioGroup
          width={stackWidth}
          h="auto"
          value={isBoolean ? field.value.toString() : field.value}
          onChange={(value) => {
            helpers.setValue(isBoolean ? value === 'true' : value);
          }}
          {...radioProps}
        >
          {children}
        </RadioGroup>
      </Stack>
    </FormControl>
  );
};
