import { ButtonProps, useTheme } from '@chakra-ui/react';
import { useIcon } from '@libs/core/theme/utils';
import React, { AllHTMLAttributes, ReactNode } from 'react';
import {
  WizardBox,
  WizardBullet,
  WizardButton,
  WizardConnector,
  WizardLabel,
} from '../wizard.style';

type WizardStepProps = ButtonProps &
  AllHTMLAttributes<HTMLButtonElement> & {
    children?: ReactNode;
    step?: string;
    time?: string;
    modActive?: boolean;
    modSuccess?: boolean;
    modCompleted?: boolean;
  };

export const WizardProgressbarStep = ({
  modActive,
  modSuccess,
  modCompleted,
  step,
  children,
  ...rest
}: WizardStepProps) => {
  const Check = useIcon('Check');
  const Theme = useTheme();

  return (
    <WizardBox>
      <WizardConnector
        modActive={modActive}
        modSuccess={modSuccess}
        modCompleted={modCompleted}
      ></WizardConnector>
      <WizardButton
        {...rest}
        size="Body2"
        variant="wizard"
        aria-current={modActive ? 'step' : undefined}
      >
        <WizardBullet
          modActive={modActive}
          modSuccess={modSuccess}
          modCompleted={modCompleted}
        >
          {(modCompleted || modSuccess) && (
            <Check fill={Theme.colors.ink.white} height="2rem" width="2rem" />
          )}
        </WizardBullet>
        <WizardLabel
          modActive={modActive}
          modSuccess={modSuccess}
          modCompleted={modCompleted}
          as="span"
          w="100%"
        >
          {children}
        </WizardLabel>
      </WizardButton>
    </WizardBox>
  );
};
