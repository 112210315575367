import { Box, BoxProps, Text } from '@chakra-ui/react';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';

type FileIconProps = BoxProps & {
  type: string;
};

export const FileIcon = ({ type, ...rest }: FileIconProps) => {
  const File = useIcon('File');
  return (
    <Box pos="relative" w="3.6rem" h="3.6rem" m="0 auto" {...rest}>
      <Text
        pos="absolute"
        top=".8rem"
        right="1rem"
        fontSize="1rem"
        textTransform="uppercase"
      >
        {type}
      </Text>
      <File />
    </Box>
  );
};
