import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import {
  cardsGet,
  getCardsGetQueryKey,
  useCardsUpdate,
  useMerchantCategoryRestrictionGroupsGet,
  useMerchantIdRestrictionGroupsGet,
} from '@libs/api/endpoints';
import { Card, CardsGetParams } from '@libs/api/models';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Drawer, SelectField } from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { QueryStatusText } from '../../../components/query-status-text';
import { CardDrawerProps } from '../../employee.types';

type CardEditDrawerProps = CardDrawerProps & {
  cardsGetQueryParams: CardsGetParams;
};

export const CardEditDrawer = ({
  card,
  isOpen,
  onClose,
  cardsGetQueryParams,
}: CardEditDrawerProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const params = {
    page_size: 100,
  };
  const { data: midGroups } = useMerchantIdRestrictionGroupsGet(params);
  const { data: mccGroups } = useMerchantCategoryRestrictionGroupsGet(params);

  const successHandler = () => {
    const queryKey = getCardsGetQueryKey(cardsGetQueryParams);
    queryClient.invalidateQueries(queryKey);
    queryClient.fetchQuery(queryKey, () => cardsGet(cardsGetQueryParams));
    setError(null);
  };
  const { mutateAsync: cardUpdate, status } = useCardsUpdate({
    mutation: {
      onError: (error: any) => {
        setError(error);
      },
      onSuccess: successHandler,
    },
  });

  const submitHandler = (values: Card) => {
    cardUpdate({ cardid: card.id, data: { ...card, ...values } });
  };

  const statusText = {
    [QueryStatus.loading]: t(i18nKeys.operator.cards.edit.status.loading),
    [QueryStatus.error]: t(i18nKeys.operator.cards.edit.status.error),
    [QueryStatus.success]: t(i18nKeys.operator.cards.edit.status.success),
  };

  return (
    <Drawer
      title={t(i18nKeys.operator.cards.edit.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <Box>
          <Formik<Card>
            onSubmit={submitHandler}
            initialValues={{
              mcc_restriction_group_id: card.mcc_restriction_group_id,
              mid_restriction_group_id: card.mid_restriction_group_id,
            }}
          >
            {({ isValid, dirty }) => (
              <Form>
                <SelectField
                  name="mcc_restriction_group_id"
                  label={t(i18nKeys.operator.cards.mcc_restriction_group)}
                >
                  {/* empty? */}
                  <option key={0}>{}</option>
                  {mccGroups?.data.map((mccGroup) => (
                    <option key={mccGroup.id} value={mccGroup.id}>
                      {capitalize(mccGroup.name)}
                    </option>
                  ))}
                </SelectField>
                <SelectField
                  name="mid_restriction_group_id"
                  label={t(i18nKeys.operator.cards.mid_restriction_group)}
                >
                  {/* empty? */}
                  <option key={0}>{}</option>
                  {midGroups?.data.map((midGroup) => (
                    <option key={midGroup.id} value={midGroup.id}>
                      {capitalize(midGroup.name)}
                    </option>
                  ))}
                </SelectField>
                <Button
                  type="submit"
                  variant="primary"
                  size="body2"
                  mt="2rem"
                  mr="2rem"
                  disabled={!isValid || !dirty}
                  isLoading={status === QueryStatus.loading}
                  loadingText={t(i18nKeys.common.submit)}
                >
                  {t(i18nKeys.common.submit)}
                </Button>
                <QueryStatusText
                  error={error}
                  status={status}
                  statusText={statusText}
                />
              </Form>
            )}
          </Formik>
        </Box>
      </Drawer.Body>
    </Drawer>
  );
};
