import { RestrictionGroupStatus } from '@libs/api/models';

export type RestrictionGroupUpdateFormData = {
  status: RestrictionGroupStatus;
  name: string;
};

export type RestrictionGroupType = 'MCC' | 'MID';

export const RestrictionGroupType = {
  MCC: 'MCC' as RestrictionGroupType,
  MID: 'MID' as RestrictionGroupType,
};
