import * as React from 'react';

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9571 6.0434C21.3476 6.43393 21.3476 7.0671 20.9571 7.45761L10.4571 17.9571C10.0666 18.3476 9.43345 18.3476 9.04292 17.9572L3.79292 12.7076C3.40238 12.3171 3.40235 11.684 3.79286 11.2934C4.18337 10.9029 4.81653 10.9028 5.20708 11.2934L9.74998 15.8359L19.5429 6.04337C19.9334 5.65285 20.5666 5.65286 20.9571 6.0434Z"
      />
    </svg>
  );
}

export default SvgCheck;
