import { Box, Flex, Text } from '@chakra-ui/react';
import { PageHeader } from '@libs/ui/components';
import React, { ReactNode } from 'react';
type OnboardingLayoutProps = {
  children: ReactNode;
  title: ReactNode;
  introduction?: string | ReactNode;
};

export const OnboardingLayout = ({
  children,
  title,
  introduction,
}: OnboardingLayoutProps) => {
  const [right, left] = React.Children.toArray(children);
  return (
    <>
      <PageHeader
        size="Title1"
        title={title}
        width={{ base: '100%', lg: 'calc(100% - 27rem)' }}
        maxW="69rem"
      >
        <Text maxW="69rem" w="100%" mt="1rem">
          {introduction}
        </Text>
      </PageHeader>
      <Flex justify="flex-start" minH="60vh" mt="3rem">
        <Box w="100%" mr={{ base: 0, lg: '2rem' }} pb="4rem">
          {right}
        </Box>
        <Box>{left}</Box>
      </Flex>
    </>
  );
};
