/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ServiceCategoryNameKey =
  | 'bike'
  | 'car'
  | 'equipment'
  | 'infrastructure'
  | 'leasing'
  | 'long_distance'
  | 'micro_mobility'
  | 'private_chauffeur_service'
  | 'public_transport'
  | 'renting'
  | 'shared_mobility'
  | 'tax_free_allowance';

export const ServiceCategoryNameKey = {
  bike: 'bike' as ServiceCategoryNameKey,
  car: 'car' as ServiceCategoryNameKey,
  equipment: 'equipment' as ServiceCategoryNameKey,
  infrastructure: 'infrastructure' as ServiceCategoryNameKey,
  leasing: 'leasing' as ServiceCategoryNameKey,
  long_distance: 'long_distance' as ServiceCategoryNameKey,
  micro_mobility: 'micro_mobility' as ServiceCategoryNameKey,
  private_chauffeur_service: 'private_chauffeur_service' as ServiceCategoryNameKey,
  public_transport: 'public_transport' as ServiceCategoryNameKey,
  renting: 'renting' as ServiceCategoryNameKey,
  shared_mobility: 'shared_mobility' as ServiceCategoryNameKey,
  tax_free_allowance: 'tax_free_allowance' as ServiceCategoryNameKey,
};
