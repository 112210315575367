import { BoxProps, Flex, Heading, Text } from '@chakra-ui/layout';
import { ThemingProps } from '@chakra-ui/system';
import {
  CurrencyFormat,
  CurrencySymbol,
  formatCurrency,
} from '@libs/core/constants';
import React from 'react';

export type CurrencyProps = ThemingProps &
  BoxProps & {
    children: number;
    symbol?: CurrencySymbol;
    format?: CurrencyFormat;
    textOptions?: ThemingProps & BoxProps;
    isHeading?: boolean;
  };

export const Currency = ({
  children = 0,
  size,
  format = CurrencyFormat.euro,
  symbol = CurrencySymbol.euro,
  textOptions,
  isHeading = false,
  ...rest
}: CurrencyProps) => {
  const formattedValue = formatCurrency({ format, number: children });
  const props = { ...textOptions, size: size, mr: '.2rem' };

  return (
    <Flex {...rest}>
      {isHeading ? (
        <>
          <Heading {...props}>{formattedValue}</Heading>
          <Heading {...props}>{symbol}</Heading>
        </>
      ) : (
        <>
          <Text {...props}>{formattedValue}</Text>
          <Text {...props}>{symbol}</Text>
        </>
      )}
    </Flex>
  );
};
