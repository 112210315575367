import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { Registration } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CardCreateDrawer } from './card-create-drawer';
import { CardTable } from './card-table';

type EmployeeDetailsCardsProps = {
  employee: Registration;
};

export const EmployeeDetailsCards = ({
  employee,
}: EmployeeDetailsCardsProps) => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const history = useHistory();

  return (
    <Flex direction="column">
      <Flex mt="1rem" mb="0" alignSelf="flex-end">
        <Button
          variant="border"
          size="body1"
          mr="1rem"
          onClick={() =>
            history.push(
              getPath(Routes.DASHBOARD_TRANSACTIONS) +
                `?membership_id=${employee.membership_id}`,
            )
          }
        >
          {t(i18nKeys.common.transactions)}
        </Button>
        <Button variant="primary" size="body1" onClick={onOpen}>
          {t(i18nKeys.operator.cards.add)}
        </Button>
      </Flex>
      <CardTable employee={employee} />
      {isOpen && (
        <CardCreateDrawer
          employee={employee}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Flex>
  );
};
