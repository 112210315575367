import {
  Box,
  BoxProps,
  Drawer as ChakraDrawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps as DrawerBaseProps,
  ModalHeaderProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { DrawerBody } from './components/drawer-body';
import { DrawerFooter } from './components/drawer-footer';

export type DrawerProps = DrawerBaseProps & {
  title: string | ReactNode;
  children: ReactNode;
  showCloseButton?: boolean;
  drawerHeaderProps?: ModalHeaderProps;
  contentProps?: BoxProps;
};

export const Drawer = ({
  children,
  title,
  showCloseButton = true,
  drawerHeaderProps,
  contentProps,
  ...rest
}: DrawerProps) => {
  return (
    <ChakraDrawer {...rest}>
      {/* This will insure that other portal nodes like toasts will be above the overlay */}
      <DrawerOverlay zIndex="1000">
        <DrawerContent overflowX="auto">
          {showCloseButton && (
            <DrawerCloseButton data-cy="drawer-close-button" />
          )}
          <DrawerHeader
            px="2rem"
            borderBottomWidth=".1rem"
            {...drawerHeaderProps}
          >
            {title}
          </DrawerHeader>
          <Box px="2rem" {...contentProps}>
            {children}
          </Box>
        </DrawerContent>
      </DrawerOverlay>
    </ChakraDrawer>
  );
};

Drawer.Body = DrawerBody;
Drawer.Footer = DrawerFooter;
