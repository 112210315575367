/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type AnnualTurnOver =
  | 'less_forty'
  | 'less_hundred'
  | 'less_two_hundred_fifty'
  | 'less_one_k'
  | 'less_three_k'
  | 'less_ten_k'
  | 'less_hundred_k'
  | 'over_hundred_k';

export const AnnualTurnOver = {
  less_forty: 'less_forty' as AnnualTurnOver,
  less_hundred: 'less_hundred' as AnnualTurnOver,
  less_two_hundred_fifty: 'less_two_hundred_fifty' as AnnualTurnOver,
  less_one_k: 'less_one_k' as AnnualTurnOver,
  less_three_k: 'less_three_k' as AnnualTurnOver,
  less_ten_k: 'less_ten_k' as AnnualTurnOver,
  less_hundred_k: 'less_hundred_k' as AnnualTurnOver,
  over_hundred_k: 'over_hundred_k' as AnnualTurnOver,
};
