import {
  Box,
  BoxProps,
  Center,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { useIcon } from '@libs/core/theme/utils';
import React, { ReactNode } from 'react';
import { Loader } from '../../loader';
import { Modal } from '../../modal';

type ThumbnailPreviewProps = Omit<BoxProps, 'title'> & {
  children?: ReactNode;
  title: string;
  fileURL: string;
  disabled?: boolean;
};

export const ThumbnailPreview = ({
  children,
  title,
  fileURL,
  disabled = false,
  ...rest
}: ThumbnailPreviewProps) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const PreviewIcon = useIcon('Eye');
  const NoPreviewIcon = useIcon('EyeOff');

  return (
    <>
      <Center
        onClick={() => !disabled && onOpen()}
        overflow="hidden"
        position="relative"
        my="1rem"
        w="100%"
        h="12rem"
        borderRadius="1.2rem"
        bgImage={`url(${fileURL})`}
        bgSize="cover"
        bgPosition="center"
        maxWidth={{
          base: '100%',
          md: '100%',
          lg: '18rem',
        }}
        {...rest}
      >
        <Box
          zIndex="6"
          position="relative"
          color="ink.white"
          textAlign="center"
        >
          <Box margin="0 auto" width="1.8rem" height="1.8rem">
            {disabled ? (
              <NoPreviewIcon fill={theme.colors.ink.white} />
            ) : (
              <PreviewIcon fill={theme.colors.ink.white} />
            )}
          </Box>
          <Text size="Small" color="ink.white">
            {children}
          </Text>
        </Box>
        <Box
          zIndex="5"
          bgColor="ink.dark"
          w="100%"
          h="100%"
          position="absolute"
          opacity=".6"
        ></Box>
      </Center>
      <Modal
        title={title}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior="inside"
      >
        <Modal.Body>
          <Center>
            <Image
              alt={title}
              src={fileURL}
              w="100%"
              h="auto"
              fallback={
                <Box my="2rem" position="relative" h="5rem">
                  <Loader />
                </Box>
              }
            />
          </Center>
        </Modal.Body>
      </Modal>
    </>
  );
};
