export const Breadcrumb = {
  baseStyle: {
    link: (theme) => ({
      color: theme.colors.ink.medium,
      fontSize: '1.2rem',
      textDecoration: 'none',

      _hover: {
        textDecoration: 'underline',
      },
    }),
  },
};
