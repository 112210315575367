/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, UseQueryOptions } from 'react-query';
import type { WalletGetResponse, GetWalletParams } from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getWallet = <Data = unknown>(
  params?: GetWalletParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? WalletGetResponse : Data>(
    { url: `/v2/wallets`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGetWalletQueryKey = (params?: GetWalletParams) => [
  `/v2/wallets`,
  ...(params ? [params] : []),
];

export const useGetWallet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: GetWalletParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getWallet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGetWalletQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof getWallet>, Error>(
    queryKey,
    () => getWallet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
