/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  RegistrationsGetResponse,
  RegistrationsGetParams,
  Registration,
  RegistrationRequestCreate,
  RegistrationImportCsv,
  RegistrationsStatisticsGetResponse,
  RegistrationsStatisticsGetParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const registrationsGet = <Data = unknown>(
  params?: RegistrationsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? RegistrationsGetResponse : Data>(
    { url: `/registrations`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getRegistrationsGetQueryKey = (
  params?: RegistrationsGetParams,
) => [`/registrations`, ...(params ? [params] : [])];

export const useRegistrationsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: RegistrationsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof registrationsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getRegistrationsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof registrationsGet>, Error>(
    queryKey,
    () => registrationsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const registrationCreate = <Data = unknown>(
  registrationRequestCreate: RegistrationRequestCreate,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Registration : Data>(
    { url: `/registrations`, method: 'post', data: registrationRequestCreate },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useRegistrationCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof registrationCreate>,
    Error,
    { data: RegistrationRequestCreate },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof registrationCreate>,
    Error,
    { data: RegistrationRequestCreate }
  >((props) => {
    const { data } = props || {};

    return registrationCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const registrationImportCsv = <Data = unknown>(
  registrationImportCsv: RegistrationImportCsv,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    {
      url: `/registrations/import/csv`,
      method: 'post',
      data: registrationImportCsv,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useRegistrationImportCsv = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof registrationImportCsv>,
    Error,
    { data: RegistrationImportCsv },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof registrationImportCsv>,
    Error,
    { data: RegistrationImportCsv }
  >((props) => {
    const { data } = props || {};

    return registrationImportCsv<Data>(data, requestOptions);
  }, mutationOptions);
};
export const registrationsStatisticsGet = <Data = unknown>(
  params?: RegistrationsStatisticsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? RegistrationsStatisticsGetResponse : Data
  >(
    { url: `/registrations/statistics`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getRegistrationsStatisticsGetQueryKey = (
  params?: RegistrationsStatisticsGetParams,
) => [`/registrations/statistics`, ...(params ? [params] : [])];

export const useRegistrationsStatisticsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: RegistrationsStatisticsGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof registrationsStatisticsGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getRegistrationsStatisticsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof registrationsStatisticsGet>,
    Error
  >(
    queryKey,
    () => registrationsStatisticsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
