import * as React from 'react';

function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 7.79289C11.6834 7.40237 12.3166 7.40237 12.7071 7.79289L20.2071 15.2929C20.5976 15.6834 20.5976 16.3166 20.2071 16.7071C19.8166 17.0976 19.1834 17.0976 18.7929 16.7071L12 9.91421L5.20711 16.7071C4.81658 17.0976 4.18342 17.0976 3.79289 16.7071C3.40237 16.3166 3.40237 15.6834 3.79289 15.2929L11.2929 7.79289Z"
      />
    </svg>
  );
}

export default SvgArrowRight;
