import { Flex, Switch } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ReactNode } from 'react';
import { FormControl } from '../components/form-control';
import { InputSwitchLabel, SwitchFlex } from '../form.style';
import { BaseProps } from '../form.types';

type SwitchFieldProps = Omit<BaseProps, 'label'> & {
  label?: string | ReactNode;
};

export const SwitchField = (props: SwitchFieldProps) => {
  const { name, label, isDisabled, ...formControlProps } = props;
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  return (
    <FormControl
      id={name}
      meta={meta}
      isEmpty={!field.value}
      as={Flex}
      alignItems="center"
      {...formControlProps}
    >
      <SwitchFlex align="center" justify="space-between">
        {label && <InputSwitchLabel htmlFor={name}>{label}</InputSwitchLabel>}
        <Switch
          id={name}
          {...field}
          isInvalid={!!error && touched}
          isChecked={field.value || false}
          isDisabled={isDisabled}
        />
      </SwitchFlex>
    </FormControl>
  );
};
