/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type NotionBlockType =
  | 'paragraph'
  | 'heading_1'
  | 'heading_2'
  | 'heading_3'
  | 'bulleted_list_item'
  | 'video'
  | 'image';

export const NotionBlockType = {
  paragraph: 'paragraph' as NotionBlockType,
  heading_1: 'heading_1' as NotionBlockType,
  heading_2: 'heading_2' as NotionBlockType,
  heading_3: 'heading_3' as NotionBlockType,
  bulleted_list_item: 'bulleted_list_item' as NotionBlockType,
  video: 'video' as NotionBlockType,
  image: 'image' as NotionBlockType,
};
