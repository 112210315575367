import * as Yup from 'yup';

export const validationEmployeeBudgetsSchema = Yup.object({
  type: Yup.string().required(),
  status: Yup.string().required(),
  start_at: Yup.string().required(),
  end_at: Yup.string().required(),
  initial_total: Yup.number().required(),
  is_carry_over_enabled: Yup.boolean(),
});

export const validationEmployeeExpensesSchema = Yup.object({
  title: Yup.string().required(),
  category_code: Yup.string().required(),
  is_home_work: Yup.boolean(),
  type: Yup.string().required(),
  amount: Yup.number().required(),
  expense_occurred_at: Yup.string().required(),
  justification: Yup.string(),
  provider_name: Yup.string(),
  validation_status: Yup.string().required(),
});
