import React, { ReactNode } from 'react';
import {
  ModalBodyProps as ModalBodyBaseProps,
  ModalBody as ChackraModalBody,
} from '@chakra-ui/react';

type ModalBodyProps = ModalBodyBaseProps & {
  children: ReactNode;
};

export const ModalBody = ({ children, ...rest }: ModalBodyProps) => (
  <ChackraModalBody {...rest}>{children}</ChackraModalBody>
);
