import { Box } from '@chakra-ui/layout';
import { useIcon } from '../utils';
import React from 'react';

export const RestrictionGroup = (props) => {
  const MerchantIcon = useIcon('StoreFront');
  const BlockedIcon = useIcon('Blocked');

  return (
    <Box position="relative" {...props}>
      <Box ml=".6rem" pt=".6rem">
        <MerchantIcon width="2rem" height="2rem" />
      </Box>
      <Box position="absolute" top="-.45rem" left="-.45rem">
        <BlockedIcon width="4rem" height="4rem" />
      </Box>
    </Box>
  );
};
