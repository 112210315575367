import * as React from 'react';

function SvgProofAuthority(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0343 16.5459C9.37659 16.5458 7.74804 17.0018 6.31228 17.868C4.87652 18.7342 3.6841 19.98 2.85482 21.4804C2.58021 21.9773 1.97225 22.1473 1.49691 21.8603C1.02157 21.5732 0.858859 20.9378 1.13348 20.4409C2.13728 18.6248 3.58064 17.1167 5.31855 16.0683C7.05647 15.0198 9.02774 14.4679 11.0343 14.4679C13.0409 14.4679 15.0122 15.0199 16.7501 16.0684C18.488 17.1169 19.9313 18.6249 20.9351 20.4411C21.2097 20.938 21.047 21.5735 20.5716 21.8605C20.0963 22.1475 19.4883 21.9774 19.2137 21.4806C18.3845 19.9802 17.1921 18.7343 15.7563 17.8681C14.3206 17.0019 12.6921 16.5459 11.0343 16.5459Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1828 4.21716C11.252 3.98723 10.2775 4.04309 9.37638 4.37804C8.47523 4.71298 7.68577 5.31277 7.10284 6.10533C6.5199 6.8979 6.16828 7.84954 6.09022 8.84592C6.01215 9.84231 6.21097 10.8411 6.66278 11.7222C7.11459 12.6033 7.80018 13.3294 8.63718 13.8131C9.47418 14.2969 10.427 14.5177 11.3812 14.4491C12.3353 14.3805 13.2503 14.0255 14.016 13.4265C14.7818 12.8276 15.3659 12.0103 15.6981 11.0728C15.8887 10.5347 16.4606 10.26 16.9754 10.4593C17.4902 10.6586 17.7529 11.2564 17.5623 11.7945C17.0972 13.1072 16.2793 14.2517 15.207 15.0903C14.1347 15.929 12.8536 16.4262 11.5175 16.5222C10.1815 16.6182 8.84728 16.309 7.67527 15.6317C6.50326 14.9543 5.54326 13.9377 4.91061 12.7038C4.27796 11.47 3.99957 10.0715 4.10888 8.67632C4.21818 7.28113 4.71054 5.94859 5.5268 4.8388C6.34306 3.72901 7.4485 2.88916 8.71033 2.42016C9.97215 1.95115 11.3367 1.87293 12.64 2.19489C13.1743 2.32687 13.505 2.88655 13.3787 3.44499C13.2525 4.00342 12.717 4.34914 12.1828 4.21716Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9355 3.3606C23.3146 3.77567 23.3 4.43335 22.9029 4.82955L18.4299 9.24526C18.0458 9.6285 17.4413 9.6285 17.0572 9.24525L14.3238 6.5179C13.9267 6.12169 13.9121 5.46402 14.2911 5.04895C14.6702 4.63388 15.2994 4.61859 15.6965 5.0148L17.7436 7.05735L21.5302 3.32643C21.9273 2.93023 22.5565 2.94552 22.9355 3.3606Z"
      />
    </svg>
  );
}

export default SvgProofAuthority;
