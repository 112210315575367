import { Box, Divider, Heading } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export type AssistantCardProps = {
  children: ReactNode;
  title: string;
};

export const AssistantCard = ({ title, children }: AssistantCardProps) => {
  return (
    <Box
      bg="white"
      boxShadow="sm"
      borderRadius="lg"
      width="100%"
      p={5}
      mb="2rem"
    >
      <Heading as="h4" mt="0" fontSize="1.7rem">
        {title}
      </Heading>
      <Divider mx="auto" width="95%" height="1px" orientation="horizontal" />
      <Box mx="auto" width="95%">
        {children}
      </Box>
    </Box>
  );
};
