/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type PaymentSource = 'APP' | 'CARD' | 'PLATFORM';

export const PaymentSource = {
  APP: 'APP' as PaymentSource,
  CARD: 'CARD' as PaymentSource,
  PLATFORM: 'PLATFORM' as PaymentSource,
};
