import { Box } from '@chakra-ui/react';
import { useOrganisationGet } from '@libs/api/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Loader, Wizard } from '@libs/ui/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentsForm } from './components/documents-form';
import { OnboardingCompany } from './components/onboarding-company';
import { OnboardingReview } from './components/onboarding-review';
import { OnboardingSuccess } from './components/onboarding-success';
import { OnboardingWelcome } from './components/onboarding-welcome';
import { ShareholderForm } from './components/shareholder-form';
import { AvailableSteps } from './onboarding-wizard.types';

type OnboardingWizardContentProps = {
  organisationId: string;
};

export const OnboardingWizardContent = ({
  organisationId,
}: OnboardingWizardContentProps) => {
  const [selectedShareholder, selectShareholder] = useState<string>(null);
  const { t } = useTranslation();
  const { data: organisation, isLoading } = useOrganisationGet(organisationId);
  // TODO fetch selected shareholder

  if (isLoading) {
    return (
      <Box py="10rem" maxW="69rem" position="relative">
        <Loader />
      </Box>
    );
  }

  return (
    <Box maxWidth="110rem" margin="0 auto">
      <Wizard.Step
        time={`15 ${t(i18nKeys.common.min)}`}
        label={t(i18nKeys.onboarding.wizard.start_onboarding)}
        hash={AvailableSteps.START_ONBOARDING}
      >
        <OnboardingWelcome />
      </Wizard.Step>

      <Wizard.Step
        time={`13 ${t(i18nKeys.common.min)}`}
        label={t(i18nKeys.onboarding.wizard.review_company)}
        hash={AvailableSteps.CONFIRM_ORGANISATION}
      >
        <OnboardingCompany
          organisationId={organisationId}
          onboardingStatus={organisation.onboarding_status}
        />
      </Wizard.Step>

      <Wizard.Step
        time={`10 ${t(i18nKeys.common.min)}`}
        label={t(i18nKeys.onboarding.wizard.review_beneficiary)}
        hash={AvailableSteps.CONFIRM_SHAREHOLDER}
      >
        <ShareholderForm
          organisation={organisation}
          selectShareholder={selectShareholder}
          shareholderId={selectedShareholder}
        />
      </Wizard.Step>
      <Wizard.Step
        time={`8 ${t(i18nKeys.common.min)}`}
        label={t(i18nKeys.onboarding.wizard.beneficiary_documents)}
        hash={AvailableSteps.DOCUMENT_SHAREHOLDER}
        canActivate={(goToStep) => {
          // TODO verify selected shareholder
          return selectedShareholder !== null ? true : goToStep(2);
        }}
      >
        <DocumentsForm
          shareholderId={selectedShareholder}
          organisation={organisation}
        />
      </Wizard.Step>
      <Wizard.Step
        time={`2 ${t(i18nKeys.common.min)}`}
        label={t(i18nKeys.onboarding.wizard.review_onboarding)}
        hash={AvailableSteps.REVIEW_SUBMIT}
      >
        <OnboardingReview organisation={organisation} />
      </Wizard.Step>
      <Wizard.Step isHidden hash={AvailableSteps.UPLOAD_EMPLOYEES}>
        <OnboardingSuccess organisationId={organisationId} />
      </Wizard.Step>
    </Box>
  );
};
