import {
  AlertDescription,
  AlertProps,
  CloseButton,
  Heading,
  Text,
  useToast as useToastChakra,
  UseToastOptions,
} from '@chakra-ui/react';
import React from 'react';
import { Alert } from '../alert';

export type ToastProps = {
  title: string;
  content?: string;
  status?: AlertProps['status'];
  options?: UseToastOptions;
};

export const useToast = (defaultOptions?: UseToastOptions) => {
  const toast = useToastChakra({
    status: 'success',
    duration: defaultOptions?.duration || 5000,
    position: 'bottom',
    ...defaultOptions,
  });

  return ({
    title,
    content,
    status = 'success',
    options: { isClosable, ...options } = {},
  }: ToastProps) =>
    toast({
      render: ({ onClose }) => (
        <Alert status={status}>
          <AlertDescription ml="1rem">
            {isClosable && (
              <CloseButton position="absolute" right="0" onClick={onClose} />
            )}
            <Heading data-cy="toast-heading" size="body2" mb="1rem">
              {title}
            </Heading>
            {content && <Text wordBreak="break-word">{content}</Text>}
          </AlertDescription>
        </Alert>
      ),
      status,
      ...options,
    });
};
