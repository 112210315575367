/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type SplitPaymentStatus =
  | 'PENDING'
  | 'DECLINED'
  | 'CANCELLED'
  | 'EXCEPTION'
  | 'ACCEPTED';

export const SplitPaymentStatus = {
  PENDING: 'PENDING' as SplitPaymentStatus,
  DECLINED: 'DECLINED' as SplitPaymentStatus,
  CANCELLED: 'CANCELLED' as SplitPaymentStatus,
  EXCEPTION: 'EXCEPTION' as SplitPaymentStatus,
  ACCEPTED: 'ACCEPTED' as SplitPaymentStatus,
};
