import { Box, Text } from '@chakra-ui/layout';
import {
  getMerchantCategoryRestrictionGroupsGetQueryKey,
  getMerchantIdRestrictionGroupsGetQueryKey,
  merchantCategoryRestrictionGroupsGet,
  merchantIdRestrictionGroupsGet,
} from '@libs/api/endpoints';
import { capitalize } from '@libs/core/utils';
import { Loader } from '@libs/ui/components';
import React from 'react';
import { useQuery } from 'react-query';
import { RestrictionGroupType } from '../../../restriction-group/restriction-group.type';

type CardRestrictionGroupNameProps = {
  type: RestrictionGroupType;
  id: string;
};

export const CardRestrictionGroupName = ({
  type,
  id,
}: CardRestrictionGroupNameProps) => {
  const params = {
    page_number: 1,
    page_size: 1000,
  };
  const queryKey =
    type === RestrictionGroupType.MCC
      ? getMerchantCategoryRestrictionGroupsGetQueryKey(params)
      : getMerchantIdRestrictionGroupsGetQueryKey(params);
  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      type === RestrictionGroupType.MCC
        ? merchantCategoryRestrictionGroupsGet(params)
        : merchantIdRestrictionGroupsGet(params),
    {
      keepPreviousData: true,
    },
  );

  if (isLoading) {
    return (
      <Box position="relative" h="2.5rem">
        <Loader />
      </Box>
    );
  }

  const restriction = data?.data.find((elem) => elem.id === id);
  if (!id || !restriction.name) {
    return null;
  }

  return <Text size="Body2">{capitalize(restriction.name)}</Text>;
};
