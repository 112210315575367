/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ValidationStatus =
  | 'APPROVED'
  | 'REFUSED'
  | 'NEED_MORE_INFO'
  | 'PENDING'
  | 'NO_APPROVAL_REQUIRED';

export const ValidationStatus = {
  APPROVED: 'APPROVED' as ValidationStatus,
  REFUSED: 'REFUSED' as ValidationStatus,
  NEED_MORE_INFO: 'NEED_MORE_INFO' as ValidationStatus,
  PENDING: 'PENDING' as ValidationStatus,
  NO_APPROVAL_REQUIRED: 'NO_APPROVAL_REQUIRED' as ValidationStatus,
};
