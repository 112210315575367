/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { BudgetType } from './budgetType';

export type ExpenseAllOfBudgetType = BudgetType;

export const ExpenseAllOfBudgetType = { ...BudgetType };
