import { Box } from '@chakra-ui/react';
import { useIcon } from '@libs/core/theme/utils';
import React, { ReactNode } from 'react';

type ProofValidIconProps = {
  icon: ReactNode;
};

export const ProofValidIcon = ({ icon }: ProofValidIconProps) => {
  const Check = useIcon('Check');
  return (
    <Box position="relative">
      <Box
        width="2rem"
        height="2rem"
        position="absolute"
        fill="ink.white"
        bgColor="status.positive.type"
        top="-.5rem"
        right="-1rem"
        borderRadius="full"
        padding=".5rem"
      >
        <Check />
      </Box>
      {icon}
    </Box>
  );
};
