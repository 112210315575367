import React from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { useProgramsGet } from '@libs/api-v2/endpoints';
import { Registration } from '@libs/api/models';
import { usePager } from '@libs/ui/components';
import { useTranslation } from 'react-i18next';
// import { BudgetCreateDrawer } from './budget-create-drawer';
import { ProgramTable } from './program-table';

type ProgramsTableProps = {
  employee: Registration;
};

export const EmployeeDetailsPrograms = ({ employee }: ProgramsTableProps) => {
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const params = {
    membership_id: employee.membership_id,
    page_number: pageNumber,
    page_size: pageSize,
  };
  const {
    isLoading,
    isFetching,
    refetch,
    data: Programs,
  } = useProgramsGet(params, { query: { keepPreviousData: true } });

  return (
    <Flex direction="column">
      <ProgramTable
        isLoading={isLoading}
        isFetching={isFetching}
        programs={Programs}
        refetch={refetch}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageSize={setPageSize}
        programsGetParams={params}
      />
    </Flex>
  );
};
