import * as React from 'react';

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 4.41422L4.75 15.4142V19.25H8.58579L19.5858 8.25001L15.75 4.41422ZM4.67705 15.4872L4.67678 15.4874L4.5 15.3107L4.67678 15.4874C4.67687 15.4874 4.67696 15.4873 4.67705 15.4872ZM14.5126 2.82323C14.8408 2.49504 15.2859 2.31067 15.75 2.31067C16.2141 2.31067 16.6592 2.49504 16.9874 2.82323L16.467 3.34366L16.9874 2.82323L21.1768 7.01257L20.4697 7.71968L21.1768 7.01257C21.505 7.34076 21.6893 7.78588 21.6893 8.25001C21.6893 8.71414 21.505 9.15926 21.1768 9.48745L9.70711 20.9571C9.51957 21.1447 9.26522 21.25 9 21.25H4.5C4.03587 21.25 3.59075 21.0656 3.26256 20.7374C2.93437 20.4093 2.75 19.9641 2.75 19.5V15.3107C2.75 15.0809 2.79527 14.8533 2.88321 14.641C2.97116 14.4287 3.10006 14.2357 3.26256 14.0732L14.5126 2.82323L15.2052 3.51591L14.5126 2.82323Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0429 5.29289C12.4334 4.90237 13.0666 4.90237 13.4571 5.29289L18.7071 10.5429C19.0976 10.9334 19.0976 11.5666 18.7071 11.9571C18.3166 12.3476 17.6834 12.3476 17.2929 11.9571L12.0429 6.70711C11.6524 6.31658 11.6524 5.68342 12.0429 5.29289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.09059 14.3406C3.48112 13.9501 4.11428 13.9501 4.50481 14.3406L9.41419 19.25H20.25C20.8023 19.25 21.25 19.6977 21.25 20.25C21.25 20.8023 20.8023 21.25 20.25 21.25H8.99998C8.73476 21.25 8.48041 21.1447 8.29287 20.9571L3.09059 15.7548C2.70007 15.3643 2.70007 14.7311 3.09059 14.3406Z"
      />
    </svg>
  );
}

export default SvgEdit;
