import { AnnualTurnOver } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import * as Yup from 'yup';
import { ShareholderDocumentTypeForm } from './onboarding-wizard.constants';

export const validationOrganisationConfirmSchema = Yup.object({
  termsAndConditions: Yup.boolean().equalTo(
    true,
    i18nKeys.common.errors.terms_and_condition_required,
  ),
  termsAndConditionsTreezor: Yup.boolean().equalTo(
    true,
    i18nKeys.common.errors.terms_and_condition_required,
  ),
});

export const validationOrganisationSchema = Yup.object({
  email: Yup.string().email().required(),
  phone_number: Yup.string().required().phoneNumber(),
  legal: Yup.object({
    sector: Yup.string().required(),
    number_of_employees_range: Yup.string().required(),
    net_income_range: Yup.string().required(),
    annual_turn_over: Yup.string().required(),
  }),
  bce: Yup.array().when('bce_url', {
    is: (url) => !url,
    then: Yup.array().required().min(1),
  }),
  constitution: Yup.array().when('constitution_url', {
    is: (url) => !url,
    then: Yup.array().required().min(1),
  }),
  activity_outside_eu: Yup.bool().when('legal.annual_turn_over', {
    is:
      AnnualTurnOver.less_three_k ||
      AnnualTurnOver.less_ten_k ||
      AnnualTurnOver.less_hundred_k ||
      AnnualTurnOver.over_hundred_k,
    then: Yup.bool().required(),
  }),
  economic_sanctions: Yup.bool().when('activity_outside_eu', {
    is: true,
    then: Yup.bool().required(),
  }),
  resident_countries_sanctions: Yup.bool().when('activity_outside_eu', {
    is: true,
    then: Yup.bool().required(),
  }),
  involved_sanctions: Yup.bool().when('activity_outside_eu', {
    is: true,
    then: Yup.bool().required(),
  }),
});

export const validationShareholderSchema = Yup.object({
  shareholderId: Yup.string().required(),
  email: Yup.string().email().required(),
  title: Yup.string().required(),
  last_name: Yup.string().required(),
  first_name: Yup.string().required(),
  phone_number: Yup.string().required().phoneNumber(),
  date_of_birth: Yup.string().required(),
  place_of_birth: Yup.string().required(),
  occupation: Yup.string().required(),
  nationality: Yup.string().required(),
  taxes: Yup.object().shape({
    tax_residence_id: Yup.string().required(),
  }),
  address: Yup.object().shape({
    street: Yup.string().required(),
    city: Yup.string().required(),
    postal_code: Yup.string().required(),
    country_code: Yup.string().required(),
  }),
});

export const validationShareholderDocumentsSchema = Yup.object().shape({
  documentType: Yup.string().required(),
  frontFile: Yup.array().when('documentType', {
    is: ShareholderDocumentTypeForm.IDENTITY,
    then: Yup.array().when('idFrontUrl', {
      is: (url) => !url,
      then: Yup.array().required().min(1),
    }),
  }),
  backFile: Yup.array().when('documentType', {
    is: ShareholderDocumentTypeForm.IDENTITY,
    then: Yup.array().when('idBackUrl', {
      is: (url) => !url,
      then: Yup.array().required().min(1),
    }),
  }),
  passportFile: Yup.array().when('documentType', {
    is: ShareholderDocumentTypeForm.PASSPORT,
    then: Yup.array().when('passportUrl', {
      is: (url) => !url,
      then: Yup.array().required().min(1),
    }),
  }),
});

export const validationShareholderDocumentsIdentity = Yup.object().shape({
  documentType: Yup.string().required(),
  frontFile: Yup.array().when('documentType', {
    is: ShareholderDocumentTypeForm.IDENTITY,
    then: Yup.array().when('idFrontUrl', {
      is: (url) => !url,
      then: Yup.array().required().min(1),
    }),
  }),
  backFile: Yup.array().when('documentType', {
    is: ShareholderDocumentTypeForm.IDENTITY,
    then: Yup.array().when('idBackUrl', {
      is: (url) => !url,
      then: Yup.array().required().min(1),
    }),
  }),
  passportFile: Yup.array().when('documentType', {
    is: ShareholderDocumentTypeForm.PASSPORT,
    then: Yup.array().when('passportUrl', {
      is: (url) => !url,
      then: Yup.array().required().min(1),
    }),
  }),
  imageClear: Yup.boolean().equalTo(
    true,
    i18nKeys.common.errors.field_required,
  ),
  imageColor: Yup.boolean().equalTo(
    true,
    i18nKeys.common.errors.field_required,
  ),
  imageCopy: Yup.boolean().equalTo(true, i18nKeys.common.errors.field_required),
  imagePdf: Yup.boolean().equalTo(true, i18nKeys.common.errors.field_required),
});

export const validationShareholderDocumentsAddress = Yup.object().shape({
  proofOfAddress: Yup.array().when('proofOfAddressUrl', {
    is: (url) => !url,
    then: Yup.array(),
  }),
  imageClear: Yup.boolean().equalTo(
    true,
    i18nKeys.common.errors.field_required,
  ),
  imageColor: Yup.boolean().equalTo(
    true,
    i18nKeys.common.errors.field_required,
  ),
  imageCopy: Yup.boolean().equalTo(true, i18nKeys.common.errors.field_required),
  imagePdf: Yup.boolean().equalTo(true, i18nKeys.common.errors.field_required),
});
