import { Box, Button, Flex, Text } from '@chakra-ui/react';
import {
  getShareholdersGetQueryKey,
  organisationUpdate,
  shareholderUpdate,
  useShareholderGet,
} from '@libs/api/endpoints';
import {
  DocumentType,
  OnboardingStatus,
  Organisation,
  Shareholder,
  ShareholdersGetResponse,
} from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import { ONBOARDING_EVENTS } from '@libs/dashboard-core/constants/analytics';
import {
  Assistant,
  FormSubmitData,
  handleFormSubmit,
  Loader,
  useWizard,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { findDocumentByType, mergeDocuments } from '../../../utils/documents';
import { ShareholderDocumentTypeForm } from '../onboarding-wizard.constants';
import { OnboardingDocumentFormData } from '../onboarding-wizard.types';
import {
  getShareholderDocumentsToUpload,
  uploadFile,
} from '../onboarding-wizard.utils';
import { validationShareholderDocumentsSchema } from '../onboarding-wizard.validations';
import { DocumentsModal } from './documents-modal';
import { ProofIdentity } from './documents/proof-identity';
import { OnboardingLayout } from './onboarding-layout';

type DocumentsFormProps = {
  shareholderId: string;
  organisation: Organisation;
};

export const DocumentsForm = ({
  shareholderId,
  organisation,
}: DocumentsFormProps) => {
  const { previousStep, nextStep } = useWizard();
  const { t } = useTranslation();
  const { data: shareholder, isLoading, queryKey } = useShareholderGet(
    shareholderId,
  );

  const queryClient = useQueryClient();
  const [
    selectedDocumentType,
    selectDocumentType,
  ] = useState<ShareholderDocumentTypeForm>();

  const { mutateAsync: submit } = useMutation(
    async ({ values }: FormSubmitData<OnboardingDocumentFormData>) => {
      const documents = getShareholderDocumentsToUpload(values);
      const uploadedDocuments = await Promise.all(documents.map(uploadFile));

      return shareholderUpdate(shareholder.id, {
        ...(shareholder as Shareholder),
        documents: mergeDocuments(
          uploadedDocuments,
          // only send documents from one identification document type (passport|identity)
          shareholder.documents?.filter(({ document_type }) =>
            values.documentType === ShareholderDocumentTypeForm.IDENTITY
              ? document_type !== DocumentType.PASSPORT
              : document_type !== DocumentType.ID_BACK &&
                document_type !== DocumentType.ID_FRONT,
          ),
        ),
      });
    },
    {
      onSuccess: (shareholder) => {
        queryClient.setQueryData(queryKey, shareholder);
        queryClient.setQueryData(
          getShareholdersGetQueryKey({ organisation_id: organisation.id }),
          (shareholders: ShareholdersGetResponse) => ({
            ...shareholders,
            data: [
              ...shareholders?.data.filter(({ id }) => shareholder.id !== id),
              shareholder,
            ],
          }),
        );
        organisationUpdate(shareholder.organisation_id, {
          // no need to pass all org fields only these ones are required
          use_mobility_budget: organisation.use_mobility_budget,
          use_payment_card: organisation.use_payment_card,
          is_freelancer: organisation.is_freelancer,
          onboarding_status: OnboardingStatus.UBO_DOC_UPLOADED,
          legal_name: organisation.legal_name,
        });

        const idDocuments = shareholder.documents?.reduce(
          (acc, { url, document_type }) =>
            (document_type === DocumentType.ID_BACK && { ...acc, back: url }) ||
            (document_type === DocumentType.ID_FRONT && {
              ...acc,
              front: url,
            }) ||
            acc,
          {},
        );
        window.analytics.track(ONBOARDING_EVENTS.UBO_ID_COMPLETED, {
          // UBO ID Document URL
          uboIdDocumentsUrl: idDocuments,
        });

        nextStep();
      },
    },
  );

  if (isLoading) {
    return (
      <OnboardingLayout
        title={t(i18nKeys.onboarding.documents.title)}
        introduction={t(i18nKeys.onboarding.documents.introduction)}
      >
        <Box py="10rem" maxW="69rem" position="relative">
          <Loader
            spinnerProps={{
              size: 'md',
            }}
            modRight
          >
            {t(i18nKeys.common.loading)}
          </Loader>
        </Box>
      </OnboardingLayout>
    );
  }

  const idBackDoc = findDocumentByType(
    shareholder?.documents,
    DocumentType.ID_BACK,
  );
  const idFrontDoc = findDocumentByType(
    shareholder?.documents,
    DocumentType.ID_FRONT,
  );
  const passportDoc = findDocumentByType(
    shareholder?.documents,
    DocumentType.PASSPORT,
  );

  const shareholderName = `${shareholder?.first_name} ${shareholder?.last_name}`;

  return (
    <>
      <OnboardingLayout
        title={t(i18nKeys.onboarding.documents.title)}
        introduction={t(i18nKeys.onboarding.documents.introduction)}
      >
        <Flex direction="column">
          <Formik<OnboardingDocumentFormData>
            validateOnMount={true}
            initialValues={{
              documentType: passportDoc
                ? ShareholderDocumentTypeForm.PASSPORT
                : ShareholderDocumentTypeForm.IDENTITY,
              backFile: [] as File[],
              frontFile: [] as File[],
              idBackUrl: idBackDoc?.url,
              idFrontUrl: idFrontDoc?.url,
              passportFile: [] as File[],
              passportUrl: passportDoc?.url,
            }}
            validate={validateSchema(validationShareholderDocumentsSchema)}
            onSubmit={handleFormSubmit(submit)}
          >
            {({ values, isSubmitting, setFieldValue, errors, isValid }) => (
              <Form noValidate id="documents-form">
                <ProofIdentity
                  errorMessage={
                    values.documentType === ShareholderDocumentTypeForm.IDENTITY
                      ? (errors.frontFile as string)
                      : (errors.passportFile as string)
                  }
                  setMainValues={setFieldValue}
                  mainValues={values}
                  shareholderName={shareholderName}
                />
                <Flex justify="space-between" p="4rem 0 8rem">
                  <Button onClick={previousStep} size="body1" variant="border">
                    {t(i18nKeys.common.previous)}
                  </Button>
                  <Button
                    form="documents-form"
                    type="submit"
                    variant="primary"
                    size="body1"
                    isLoading={isSubmitting}
                    disabled={!isValid}
                  >
                    {t(i18nKeys.common.finalize)}
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
        <Assistant>
          <Assistant.Card
            title={t(i18nKeys.onboarding.documents.assistant.title)}
          >
            <Text my={2}>
              {t(i18nKeys.onboarding.documents.assistant.content)}
            </Text>
          </Assistant.Card>
        </Assistant>
      </OnboardingLayout>

      <DocumentsModal
        isOpen={!!selectedDocumentType}
        onClose={() => selectDocumentType(null)}
        documentType={selectedDocumentType}
      />
    </>
  );
};
