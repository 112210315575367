import { Box, Button, Checkbox, Flex, Text, useTheme } from '@chakra-ui/react';
import { ShareholdersGetResponse, Title } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Card, SelectField } from '@libs/ui/components';
import { useIcon } from '@libs/core/theme/utils';
import { FormikState } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ShareholderTypeForm,
  SHAREHOLDER_DEFAULT,
  SHAREHOLDER_ID_NEW,
} from '../onboarding-wizard.constants';
import { OnboardingShareholderFormData } from '../onboarding-wizard.types';

type ShareholderSelectionProps = {
  resetForm: (
    nextState?: Partial<FormikState<OnboardingShareholderFormData>>,
  ) => void;
  shareholders: ShareholdersGetResponse;
  values: OnboardingShareholderFormData;
  previousStep: () => void;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ShareholderSelection = ({
  resetForm,
  shareholders,
  values,
  previousStep,
  setShowDetails,
}: ShareholderSelectionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const UserIcon = useIcon('User');
  const getShareholderData = (id: string) =>
    shareholders.data.find((shareholder) => shareholder.id === id);

  return (
    <>
      <Card data-cy="shareholder-selection" p="1rem 2.5rem">
        <Flex align="center">
          <Box
            mr="2rem"
            w="5rem"
            h="5rem"
            padding=".8rem"
            borderRadius="full"
            bgColor={theme.colors.main.secondary.lighter}
          >
            <UserIcon
              width="100%"
              height="100%"
              fill={theme.colors.main.secondary.normal}
            />
          </Box>
          <SelectField
            name="shareholderKnownId"
            label={t(i18nKeys.onboarding.review_beneficiary.select_beneficiary)}
            handleChange={(event) => {
              const shareholder = getShareholderData(event.target.value);
              resetForm({
                values: {
                  shareholderId: event.target.value,
                  ...(event.target.value !== SHAREHOLDER_ID_NEW
                    ? shareholder
                    : SHAREHOLDER_DEFAULT),
                  title: shareholder.title || Title.M,
                  dao: [],
                  daoUrl: '',
                  shareholderKnown: ShareholderTypeForm.KNOWN,
                  shareholderKnownId: event.target.value,
                },
              });
            }}
          >
            {shareholders.data.map((shareholder, index) => (
              <option value={shareholder.id} key={index}>
                {shareholder.first_name} {shareholder.last_name} -{' '}
                {shareholder.occupation}
              </option>
            ))}
          </SelectField>
        </Flex>
      </Card>

      <Card
        mt="2rem"
        cursor="pointer"
        h="9rem"
        onClick={() =>
          resetForm({
            values: {
              shareholderId: SHAREHOLDER_ID_NEW,
              ...SHAREHOLDER_DEFAULT,
              dao: [],
              daoUrl: '',
              title: Title.M,
              shareholderKnown:
                values.shareholderKnown === ShareholderTypeForm.UNKNOWN
                  ? ShareholderTypeForm.UNDEFINED
                  : ShareholderTypeForm.UNKNOWN,
              shareholderKnownId: '',
            },
          })
        }
      >
        <Flex h="100%" align="center" justify="space-between">
          <Text size="Body1" color="ink.dark" data-cy="no-shareholder-checkbox">
            {t(i18nKeys.onboarding.review_beneficiary.none)}
          </Text>
          <Checkbox
            pointerEvents="none"
            borderColor="ink.darker"
            isChecked={
              values.shareholderKnown === ShareholderTypeForm.UNKNOWN
                ? true
                : false
            }
          />
        </Flex>
      </Card>
      <Flex justify="space-between" p="4rem 0 8rem">
        <Button
          onClick={previousStep}
          size="body1"
          variant="border"
          data-cy="onboarding-button-previous"
        >
          {t(i18nKeys.common.previous)}
        </Button>
        <Button
          disabled={values.shareholderKnown === ShareholderTypeForm.UNDEFINED}
          variant="primary"
          size="body1"
          onClick={() => setShowDetails(true)}
          data-cy="shareholder-selection-next-button"
        >
          {t(i18nKeys.common.next)}
        </Button>
      </Flex>
    </>
  );
};
