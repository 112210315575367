import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import { Progress } from '@chakra-ui/progress';
import { Budget } from '@libs/api/models';
import { FORMAT_DATE } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Direction } from '@libs/core/types/direction';
import { Card, Currency, DescriptionData, Drawer } from '@libs/ui/components';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

type BudgetDetailsDrawerProps = {
  budget: Budget;
  isOpen: boolean;
  onClose: () => void;
};

export const BudgetDetailsDrawer = ({
  budget,
  isOpen,
  onClose,
}: BudgetDetailsDrawerProps) => {
  const { t } = useTranslation();

  const progressValue = (budget.remaining / budget.total) * 100 || 0;

  return (
    <Drawer
      title={t(i18nKeys.operator.employees.budgets.details.introduction)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.common.budgets)}
        >
          {budget.id}
        </DescriptionData>
        <Card bgColor="background.page" mt="3rem">
          <Flex justify="space-between">
            <Text>{t(i18nKeys.operator.employees.budgets.remaining)}</Text>
            <Text>{t(i18nKeys.operator.employees.budgets.total)}</Text>
          </Flex>
          <Flex justify="space-between" align="flex-end">
            <Box w="80%" pb=".5rem">
              <Currency
                size="Title3"
                textOptions={{
                  color:
                    progressValue > 0 ? 'status.positive.type' : 'ink.dark',
                }}
                isHeading
              >
                {budget.remaining}
              </Currency>
              <Progress
                colorScheme="status.positive"
                rounded="full"
                value={progressValue}
                size="lg"
              />
            </Box>
            <Currency size="Title3" isHeading>
              {budget.total}
            </Currency>
          </Flex>
          <Divider mt="2rem" />
          <Flex justify="space-between" mt="2rem">
            <Text color="ink.dark">
              {t(i18nKeys.operator.employees.budgets.initial_amount)}
            </Text>
            <Currency size="Body1Bold">{budget.initial_total}</Currency>
          </Flex>
          <Flex justify="space-between" mt="1rem">
            <Text color="ink.dark">
              {t(
                i18nKeys.operator.employees.budgets.carried_over_from_previous,
              )}
            </Text>
            <Currency size="Body1Bold">{budget.carry_over_amount}</Currency>
          </Flex>
        </Card>
        <Flex justify="space-between" mt="3rem">
          <Text color="ink.dark">
            {t(i18nKeys.operator.employees.budgets.type.title)}
          </Text>
          <Text size="Body1">
            {t(i18nKeys.operator.employees.budgets.type[budget.type])}
          </Text>
        </Flex>
        <Divider mt="2rem" />
        <Flex justify="space-between" mt="3rem">
          <Box>
            <Text color="ink.dark">{t(i18nKeys.common.start_at)}</Text>
            <Text>
              {budget.start_at && dayjs(budget.start_at).format(FORMAT_DATE)}
            </Text>
          </Box>
          <Box textAlign="right">
            <Text color="ink.dark" size="Body1">
              {t(i18nKeys.common.end_at)}
            </Text>
            <Text>
              {budget.end_at && dayjs(budget.end_at).format(FORMAT_DATE)}
            </Text>
          </Box>
        </Flex>
        <Divider mt="2rem" />
      </Drawer.Body>
    </Drawer>
  );
};
