/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type AddressType = 'OFFICIAL';

export const AddressType = {
  OFFICIAL: 'OFFICIAL' as AddressType,
};
