import { Box } from '@chakra-ui/layout';
import { CardTransaction } from '@libs/api/models';
import { FORMAT_DATE_WITH_HOURS } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Direction } from '@libs/core/types';
import { Currency, DescriptionData, Drawer } from '@libs/ui/components';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

type TransactionDetailsDrawer = {
  transaction: CardTransaction;
  isOpen: boolean;
  onClose: () => void;
};

export const TransactionDetailsDrawer = ({
  transaction,
  isOpen,
  onClose,
}: TransactionDetailsDrawer) => {
  const { t } = useTranslation();

  return (
    <Drawer
      title={t(i18nKeys.operator.transactions.details.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <Box my="2rem">
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.transaction)}
          >
            {transaction.transaction_reference}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.amount)}
            formatChildren={false}
          >
            <Currency alignItems="center">{transaction.amount}</Currency>
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.created_at)}
          >
            {transaction.created_at &&
              dayjs(transaction.created_at).format(FORMAT_DATE_WITH_HOURS)}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.merchant_name)}
          >
            {transaction.merchant_name}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.merchant_reference)}
          >
            {transaction.merchant_reference}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.merchant_code)}
          >
            {transaction.merchant_code}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.transactions.details.card_reference)}
          >
            {transaction.card_reference}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.status)}
          >
            {t(i18nKeys.operator.transactions.status[transaction.status])}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.transactions.details.authorization_note)}
          >
            {transaction.authorization_note}
          </DescriptionData>
        </Box>
      </Drawer.Body>
    </Drawer>
  );
};
