import { Flex, Text } from '@chakra-ui/layout';
import { useCardsGet } from '@libs/api-v2/endpoints';
import { CardStatus } from '@libs/api-v2/models';
import { Registration } from '@libs/api/models';
import { PAGE_SIZE } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { DrawerType } from '@libs/dashboard-core/pages/dashboard/dashboard.utils';
import { Currency, Pager, Table, Tag } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import { useIcon } from '@libs/core/theme/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RestrictionGroupType } from '../../../restriction-group/restriction-group.type';
import { CardStatusToTagStatus } from '../../employee.utils';
import { CardBlockDrawer } from './card-block-drawer';
import { CardEditDrawer } from './card-edit-drawer';
import { CardRestrictionGroupName } from './card-restriction-group-name';
import { ConvertPhysicalCardDrawer } from './convert-physical-card-drawer';

type CardTableProps = {
  employee: Registration;
};

export const CardTable = ({ employee }: CardTableProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const EditIcon = useIcon('Edit');
  const { t } = useTranslation();
  const CreditCardIcon = useIcon('CreditCard');
  const BlockIcon = useIcon('Blocked');
  const {
    activeDrawerIndex,
    drawerHandler,
    isOpen,
    onClose,
    selectedDrawerType,
  } = useDrawer();
  const { useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  const params = {
    membership_id: employee.membership_id,
    page_number: pageNumber,
    page_size: pageSize,
  };
  const { data, isLoading, isFetching, refetch } = useCardsGet(params, {
    query: { keepPreviousData: true },
  });

  useQueryParams({
    totalPages: data?.meta.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  const { data: cards, meta } = data || {};

  // sort cards by status
  // first in the list are unlocked
  const sortedCards = cards?.sort(({ card_status }) =>
    card_status === CardStatus.UNLOCK ? -1 : 1,
  );

  const headers: TableHeaders = [
    t(i18nKeys.operator.cards.card_reference),
    t(i18nKeys.operator.cards.monthly_limit),
    t(i18nKeys.operator.cards.is_physical),
    t(i18nKeys.operator.cards.mcc_restriction_group),
    t(i18nKeys.operator.cards.mid_restriction_group),
    t(i18nKeys.common.created_at),
    t(i18nKeys.common.updated_at),
    { name: 'card_status', title: t(i18nKeys.common.status) },
    '',
  ];

  const editDrawerHandler = (index) =>
    drawerHandler({
      index,
      drawerType: DrawerType.edit,
    });

  return (
    <>
      <Table headers={headers}>
        <Table.TBody
          colSpan={headers.length}
          isLoading={(isLoading || isFetching) && !cards}
          isEmpty={!isLoading && !isFetching && (!cards || cards?.length === 0)}
        >
          {sortedCards?.map((card, index) => {
            return (
              <Table.TR key={card.id} selectable index={card.id}>
                <Table.TD onClick={() => editDrawerHandler(index)}>
                  {card.card_reference}
                </Table.TD>
                <Table.TD onClick={() => editDrawerHandler(index)}>
                  <Currency size="Body2Bold">{card.monthly_limit}</Currency>
                </Table.TD>
                <Table.TD onClick={() => editDrawerHandler(index)}>
                  {card.is_physical
                    ? t(i18nKeys.common.yes)
                    : t(i18nKeys.common.no)}
                </Table.TD>
                <Table.TD onClick={() => editDrawerHandler(index)}>
                  <CardRestrictionGroupName
                    type={RestrictionGroupType.MCC}
                    id={card.mcc_restriction_group_id}
                  />
                </Table.TD>
                <Table.TD onClick={() => editDrawerHandler(index)}>
                  <CardRestrictionGroupName
                    type={RestrictionGroupType.MID}
                    id={card.mid_restriction_group_id}
                  />
                </Table.TD>
                <Table.Date onClick={() => editDrawerHandler(index)}>
                  {card.created_at}
                </Table.Date>
                <Table.Date onClick={() => editDrawerHandler(index)}>
                  {card.updated_at}
                </Table.Date>
                <Table.TD onClick={() => editDrawerHandler(index)}>
                  <Tag
                    hasIcon
                    tooltipLabel={
                      <Text size="Small">
                        {t(
                          i18nKeys.operator.cards.CardStatus[card.card_status],
                        )}
                      </Text>
                    }
                    status={CardStatusToTagStatus[card.card_status]}
                  />
                </Table.TD>
                <Table.Action
                  actions={[
                    {
                      icon: <EditIcon w="2rem" h="2rem" />,
                      onClick: () => editDrawerHandler(index),
                      buttonProps: {
                        disabled: card.card_status === CardStatus.LOST,
                      },
                    },
                    {
                      icon: <BlockIcon w="2rem" h="2rem" />,
                      onClick: () =>
                        drawerHandler({
                          index,
                          drawerType: DrawerType.block,
                        }),
                      buttonProps: {
                        disabled: card.card_status === CardStatus.LOST,
                      },
                    },
                    {
                      tooltipLabel: (
                        <Text size="small">
                          {card.is_physical
                            ? t(
                                i18nKeys.operator.cards.conversion
                                  .tooltip_is_converted,
                              )
                            : t(i18nKeys.operator.cards.conversion.title)}
                        </Text>
                      ),
                      icon: (
                        <Flex align="center" p="0" w="2rem" h="2rem">
                          <CreditCardIcon />
                        </Flex>
                      ),
                      onClick: () =>
                        drawerHandler({
                          index,
                          drawerType: DrawerType.conversion,
                        }),
                      buttonProps: {
                        disabled:
                          card.is_physical ||
                          card.card_status === CardStatus.LOST,
                      },
                    },
                  ]}
                />
              </Table.TR>
            );
          })}
        </Table.TBody>
      </Table>
      {isOpen &&
        cards?.length > 0 &&
        ((selectedDrawerType === DrawerType.block && (
          <CardBlockDrawer
            card={cards[activeDrawerIndex]}
            isOpen={isOpen}
            onClose={onClose}
            cardsGetQueryParams={params}
          />
        )) ||
          (selectedDrawerType === DrawerType.conversion && (
            <ConvertPhysicalCardDrawer
              card={cards[activeDrawerIndex]}
              isOpen={isOpen}
              onClose={onClose}
            />
          )) || (
            <CardEditDrawer
              card={cards[activeDrawerIndex]}
              isOpen={isOpen}
              onClose={onClose}
              cardsGetQueryParams={params}
            />
          ))}
      {cards && meta?.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={meta.total_items}
          page_index={meta.page_index}
          total_pages={meta.total_pages}
          page_size={pageSize}
          setPageSize={setPageSize}
          mt="2rem"
          isFetching={isFetching}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
};
