export const Accordion = {
  baseStyle: () => ({
    borderTopWidth: '0',
    container: {
      paddingTop: '3rem',
    },
    button: {},
    icon: {},
  }),
};
