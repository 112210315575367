export const Switch = {
  baseStyle: ({ theme }) => ({
    track: {
      border: `.2rem solid ${theme.colors.ink.light}`,
      bg: theme.colors.ink.white,
      _checked: {
        bg: theme.colors.main.secondary.normal,
      },
    },
    thumb: {
      boxShadow: `.1rem .2rem .3rem ${theme.colors.ink.light}`,
    },
  }),
  sizes: {
    md: {
      track: { w: '4.2rem', h: '2.5rem' },
      thumb: {
        w: '2.5rem',
        h: '2.5rem',
        _checked: {
          transform: 'translateX(1.7rem)',
        },
      },
    },
  },
};
