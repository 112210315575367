/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyLevel = 'SERVICE' | 'PROGRAM_GROUP' | 'PROGRAM';

export const PolicyLevel = {
  SERVICE: 'SERVICE' as PolicyLevel,
  PROGRAM_GROUP: 'PROGRAM_GROUP' as PolicyLevel,
  PROGRAM: 'PROGRAM' as PolicyLevel,
};
