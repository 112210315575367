export const getQueryParam = (name: string): string | undefined => {
  // clean the name
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  // extract the name from the window.location.search
  const results = regex.exec(window.location.search);
  // return the uri decoded result
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
