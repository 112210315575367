import {
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useShareholderGet } from '@libs/api/endpoints';
import { Organisation, Shareholder, Status } from '@libs/api/models';
import { FORMAT_DATE } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { capitalize } from '@libs/core/utils';
import { Card, Currency, DescriptionData, Loader } from '@libs/ui/components';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type OrganisationDetailsOverviewProps = {
  organisation: Organisation;
  shareholders: Shareholder[];
};

export const OrganisationDetailsOverview = ({
  organisation,
  shareholders,
}: OrganisationDetailsOverviewProps) => {
  const Info = useIcon('Info');
  const Treezor = useIcon('Treezor');
  const Email = useIcon('Mail');
  const User = useIcon('EmptyUserAccount');
  const Phone = useIcon('Phone');
  const Address = useIcon('Home');
  const Calendar = useIcon('Calendar');
  const CardWalletIcon = useIcon('CardWallet');
  const theme = useTheme();
  const { t } = useTranslation();
  const { t: tCountry } = useTranslation('countries');
  const [selectedShareholderId, selectShareholderId] = useState<string>();

  useEffect(() => {
    if (shareholders) {
      const shareholderCreated = shareholders.filter(
        (s: Shareholder) =>
          s.registration_status === (Status.CREATED || Status.CONFIRMED),
      );

      if (shareholderCreated.length) {
        selectShareholderId(shareholderCreated[0].id);
      }
    }
  }, [shareholders]);

  const { data: shareholder, isLoading } = useShareholderGet(
    selectedShareholderId,
  );

  if (isLoading) {
    return (
      <Box bgColor="white" borderRadius="1rem" mt="4rem">
        <Box p="2.5rem">
          <Heading size="Title3">
            {t(i18nKeys.operator.organisations.details.title)}
          </Heading>
        </Box>
        <Divider></Divider>
        <Box position="relative" p="4rem" h="8rem">
          <Loader />
        </Box>
      </Box>
    );
  }

  return (
    <Flex justify="space-between" flexDirection={{ base: 'column', lg: 'row' }}>
      <Card
        title={t(i18nKeys.operator.organisations.details.title)}
        mt="4rem"
        width="100%"
      >
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="4rem" p="2.5rem">
          <Box>
            <DescriptionData
              icon={<Treezor />}
              title={t(i18nKeys.common.bank_label)}
            >
              {organisation.bank_organisation_reference}
            </DescriptionData>
            <DescriptionData icon={<Treezor />} title={t(i18nKeys.common.iban)}>
              {organisation.wallet_iban}
            </DescriptionData>
            <DescriptionData icon={<Info />} title={t(i18nKeys.common.vat)}>
              {organisation.vat}
            </DescriptionData>
            <DescriptionData
              title={t(i18nKeys.common.wallet_amount)}
              icon={<CardWalletIcon />}
              formatChildren={false}
            >
              <Currency textOptions={{ color: 'ink.dark', size: 'Body2' }}>
                {organisation.wallet_amount}
              </Currency>
            </DescriptionData>
            <DescriptionData
              icon={<Calendar />}
              title={t(i18nKeys.common.legal_registration_date)}
            >
              {dayjs(organisation.legal.registration_date).format(FORMAT_DATE)}
            </DescriptionData>
          </Box>
          <Box>
            <DescriptionData
              icon={<Email />}
              title={t(i18nKeys.common.company_email)}
            >
              {organisation.email}
            </DescriptionData>
            <DescriptionData
              icon={<Phone />}
              title={t(i18nKeys.common.phone_number)}
            >
              {organisation.phone_number}
            </DescriptionData>
            <DescriptionData
              icon={<Address />}
              title={t(i18nKeys.common.address)}
            >
              {organisation.address.street}
              <br />
              {organisation.address.postal_code} {organisation.address.city}
              <br />
              {tCountry(organisation.address.country_code)}
            </DescriptionData>
            {organisation.entity_type ? (
              <DescriptionData
                icon={<Info />}
                title={t(i18nKeys.common.entity_type_label)}
              >
                {t(i18nKeys.common.entity_type[organisation.entity_type])}
              </DescriptionData>
            ) : null}
          </Box>
        </SimpleGrid>
      </Card>
      {shareholder && (
        <Card
          mt="4rem"
          ml={{ base: 0, lg: '2rem' }}
          maxWidth={{ base: '100%', lg: '35rem' }}
          width="100%"
        >
          <Box textAlign="center">
            <Box
              m="0 auto"
              borderRadius="50%"
              width="6rem"
              height="6rem"
              bgColor="main.secondary.lighter"
              fill={theme.colors.main.secondary.normal}
              p="1rem"
            >
              <User />
            </Box>
            <Heading size="Title2">
              {capitalize(shareholder.first_name)}{' '}
              {capitalize(shareholder.last_name)}
            </Heading>
            <Text>{shareholder.occupation}</Text>
            <Divider my="2.5rem"></Divider>
          </Box>
          <>
            <Box>
              <DescriptionData title={t(i18nKeys.common.shareholder_info)}>
                <Link href={`mailto:${shareholder.email}`}>
                  {shareholder.email}
                </Link>
                <br />
                {shareholder.phone_number}
              </DescriptionData>
            </Box>
            <Box>
              <DescriptionData title={t(i18nKeys.common.date_of_birth)}>
                {dayjs(shareholder.date_of_birth).format(FORMAT_DATE)}{' '}
                {shareholder.place_of_birth}
              </DescriptionData>
            </Box>
            <Box>
              <DescriptionData title={t(i18nKeys.common.address)}>
                {shareholder.address.street}, {shareholder.address.postal_code}{' '}
                {shareholder.address.city}
                <br />
                {tCountry(shareholder.address.country_code)}
              </DescriptionData>
            </Box>
          </>
        </Card>
      )}
    </Flex>
  );
};
