/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type CardTransactionAllOfStatus =
  | 'SETTLED'
  | 'ACCEPTED'
  | 'REFUSED'
  | 'REVERSED';

export const CardTransactionAllOfStatus = {
  SETTLED: 'SETTLED' as CardTransactionAllOfStatus,
  ACCEPTED: 'ACCEPTED' as CardTransactionAllOfStatus,
  REFUSED: 'REFUSED' as CardTransactionAllOfStatus,
  REVERSED: 'REVERSED' as CardTransactionAllOfStatus,
};
