/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type DocumentAllOfStatus =
  | 'PENDING'
  | 'REFUSED'
  | 'VALIDATED'
  | 'NOT_STARTED';

export const DocumentAllOfStatus = {
  PENDING: 'PENDING' as DocumentAllOfStatus,
  REFUSED: 'REFUSED' as DocumentAllOfStatus,
  VALIDATED: 'VALIDATED' as DocumentAllOfStatus,
  NOT_STARTED: 'NOT_STARTED' as DocumentAllOfStatus,
};
