import { Button } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils';
import {
  FormSubmitData,
  handleFormSubmit,
  InputField,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAuthState } from '../../context';
import { getPath, Routes } from '../../routes';
import { CreateUserForm } from './auth.types';
import { validationCreateUserSchema } from './auth.validations';
import { AuthLayout } from './components/auth-layout';

export const CreateUserPage = () => {
  const { createUser } = useAuthState();
  const { t } = useTranslation();
  const history = useHistory();

  const { mutateAsync: submit } = useMutation(
    ({ values }: FormSubmitData<CreateUserForm>) => {
      return createUser(values.email, values.password);
    },
    {
      onSuccess: (data) => {
        history.push(getPath(Routes.AUTH_LOGIN));
      },
    },
  );

  return (
    <AuthLayout
      title={t(i18nKeys.set_password.title)}
      introduction={t(i18nKeys.set_password.introduction)}
    >
      <Formik<CreateUserForm>
        initialValues={{ email: '', password: '', passwordConfirmed: '' }}
        validate={validateSchema(validationCreateUserSchema)}
        onSubmit={handleFormSubmit(submit)}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputField
              name="email"
              label={t(i18nKeys.common.email)}
              isRequired
            />
            <InputField
              name="password"
              label={t(i18nKeys.common.password)}
              isRequired
              isPassword
            />
            <InputField
              name="passwordConfirmed"
              label={t(i18nKeys.confirm_reset_password.confirm_password)}
              isRequired
              isPassword
            />
            <Button
              type="submit"
              variant="primary"
              size="body1"
              mt="4rem"
              w="100%"
              isLoading={isSubmitting}
            >
              {t(i18nKeys.set_password.confirm)}
            </Button>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};
