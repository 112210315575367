export type DrawerType =
  | 'edit'
  | 'search'
  | 'block'
  | 'create'
  | 'conversion'
  | 'view';
export const DrawerType = {
  edit: 'edit' as DrawerType,
  search: 'search' as DrawerType,
  block: 'block' as DrawerType,
  create: 'create' as DrawerType,
  conversion: 'conversion' as DrawerType,
  view: 'view' as DrawerType,
};
