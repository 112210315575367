/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type RegistrationAllOfRole = 'EMPLOYEE' | 'FLEET_MGR';

export const RegistrationAllOfRole = {
  EMPLOYEE: 'EMPLOYEE' as RegistrationAllOfRole,
  FLEET_MGR: 'FLEET_MGR' as RegistrationAllOfRole,
};
