import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import {
  getMerchantsGetQueryKey,
  merchantsGet,
  merchantsUpdate,
} from '@libs/api/endpoints';
import {
  useListProviders,
  useServicesGet,
  updateMerchant,
  getGetMerchantsQueryKey,
  getMerchants,
} from '@libs/api-v2/endpoints';
import {
  Merchant,
  UpdateMerchantRequest,
  GetMerchantsParams,
} from '@libs/api-v2/models';
import { ExpenseCategoryCode, MerchantsGetParams } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Direction } from '@libs/core/types';
import { validateSchema } from '@libs/core/utils/validation';
import {
  DescriptionData,
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  InputField,
  SelectField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { validationMerchantSchema } from '../merchant.validation';
import { capitalize } from '@libs/core/utils';

type MerchantsEditDrawerProps = {
  merchant: Merchant;
  isOpen: boolean;
  onClose: () => void;
  merchantsGetParams?: GetMerchantsParams;
};

export const MerchantsEditDrawer = ({
  merchant,
  isOpen,
  onClose,
  merchantsGetParams,
}: MerchantsEditDrawerProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const params = {
    page_size: 100,
  };
  const { data: providers } = useListProviders(params);
  const { data: services } = useServicesGet(params);

  const { mutateAsync: merchantUpdate, isLoading } = useMutation(
    async ({ values }: FormSubmitData<UpdateMerchantRequest>) => {
      return updateMerchant(merchant.merchant_id, values);
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: t(i18nKeys.operator.merchants.edit_merchant_success.title),
          content: t(i18nKeys.operator.merchants.edit_merchant_success.content),
        });
        const queryKey = getGetMerchantsQueryKey(merchantsGetParams);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey, () =>
          getMerchants(merchantsGetParams),
        );
      },
    },
  );

  const initialValues: UpdateMerchantRequest = {
    merchant_name: merchant.merchant_name,
    provider_id: merchant?.provider?.id,
    service_id: merchant?.service?.id,
  };
  function compare_name(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }
  return (
    <Drawer
      title={t(i18nKeys.operator.merchants.details.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Formik<UpdateMerchantRequest>
        onSubmit={handleFormSubmit(merchantUpdate)}
        initialValues={initialValues}
        validate={validateSchema(validationMerchantSchema)}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Drawer.Body>
              <DescriptionData
                direction={Direction.horizontal}
                title={t(i18nKeys.common.merchant_name)}
              >
                {merchant.merchant_name}
              </DescriptionData>
              <DescriptionData
                direction={Direction.horizontal}
                title={t(i18nKeys.common.merchant_reference)}
              >
                {merchant.merchant_id}
              </DescriptionData>
              <SelectField
                name="provider_id"
                label={t(i18nKeys.common.provider_name)}
              >
                {providers?.data.sort(compare_name).map((provider) => (
                  <option key={provider.id} value={provider.id}>
                    {capitalize(provider.name)}
                  </option>
                ))}
              </SelectField>
              <SelectField name="service_id" label={t(i18nKeys.common.service)}>
                {services?.data.map((service) => (
                  <option key={service.id} value={service.id}>
                    {capitalize(service.name_key)}
                  </option>
                ))}
              </SelectField>
            </Drawer.Body>
            <Drawer.Footer>
              <Flex w="100%" justify="space-between">
                <Button
                  size="body2"
                  w="auto"
                  variant="border"
                  onClick={onClose}
                >
                  {t(i18nKeys.common.cancel)}
                </Button>
                <Button
                  type="submit"
                  size="body2"
                  w="auto"
                  variant="primary"
                  disabled={!isValid || !dirty}
                  isLoading={isLoading}
                  loadingText={t(i18nKeys.common.submit)}
                >
                  {t(i18nKeys.common.submit)}
                </Button>
              </Flex>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
