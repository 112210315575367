import { Container } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type ContainerProps = {
  children: ReactNode;
};

export const ContentContainer = ({ children }: ContainerProps) => {
  return (
    <Container
      transition="ease-out width .4s"
      width={{ base: 'calc(100% - 6rem)', lg: 'calc(100% - 23rem)' }}
      p={{ base: '10rem 1rem 6rem', lg: '10rem 3rem 6rem' }}
      maxW="xl"
    >
      {children}
    </Container>
  );
};
