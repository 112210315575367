import { useBreakpointValue } from '@chakra-ui/media-query';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { IS_MOBILE_WEBVIEW } from '../constants';

export const breakpoints = createBreakpoints({
  sm: '420px',
  md: '770px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1600px',
});

export const useCurrentBreakpointValue = () => {
  const breakpoint = useBreakpointValue({
    // mobile
    base: 'base',
    // small tablet
    sm: 'sm',
    md: 'md',
    // large tablet
    lg: 'lg',
    // desktop
    xl: 'xl',
    '2xl': '2xl',
  });

  return {
    isMobile: breakpoint === 'base' || IS_MOBILE_WEBVIEW,
    isTablet: breakpoint === 'sm' || breakpoint === 'lg',
    isLargeTablet: breakpoint === 'lg',
    isSmallTablet: breakpoint === 'sm',
    isDesktop: breakpoint === 'xl' || breakpoint === '2xl',
    isSmallDesktop: breakpoint === 'xl',
    isLargeDesktop: breakpoint === '2xl',
  };
};
