import { Button } from '@chakra-ui/react';
import { useGetCurrentUser } from '@libs/api/endpoints';
import { Role } from '@libs/api/models';
import { STORAGE_KEYS } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Loader } from '@libs/ui/components';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '../pages/auth/components/auth-layout';
import { findFleetManagerInMemberships } from '../utils/membership';
import { useAuthState } from './auth.context';

type RoleContextState = {
  role: Role;
  setRole: React.Dispatch<React.SetStateAction<Role>>;
  availableRoles: Role[];
};

const RoleContext = createContext<RoleContextState>(null);

export function ProvideRole({ children }: { children: ReactNode }) {
  const { signout } = useAuthState();
  const { data: user } = useGetCurrentUser({
    query: {
      enabled: false,
    },
  });
  const [role, setRole] = useState<Role>();
  const [availableRoles, setAvailableRoles] = useState<Role[]>();
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (role) {
      localStorage.setItem(STORAGE_KEYS.SELECTED_ROLE, role);
    }
  }, [role]);

  useEffect(() => {
    if (!user.roles?.length) {
      signout();
      return;
    }

    const membership = findFleetManagerInMemberships(user);

    const currentRoles = membership
      ? user.roles
      : user.roles.filter((role) => role !== Role.USER);

    const currentRole =
      currentRoles.length === 1
        ? currentRoles[0]
        : localStorage.getItem(STORAGE_KEYS.SELECTED_ROLE);

    if (currentRoles.includes(currentRole as Role)) {
      setRole(currentRole as Role);
    }

    setAvailableRoles(currentRoles);
    setLoading(false);
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }

  if (!role && user.roles) {
    return (
      <AuthLayout
        title={t(i18nKeys.login.title)}
        introduction={t(i18nKeys.login.profile)}
      >
        {user.roles.map((role) => (
          <Button
            data-cy={role}
            mt="1rem"
            mr="1rem"
            variant="border"
            size="body2"
            key={role}
            onClick={() => setRole(role)}
          >
            {role}
          </Button>
        ))}
      </AuthLayout>
    );
  }

  return (
    <RoleContext.Provider value={{ role, setRole, availableRoles }}>
      {children}
    </RoleContext.Provider>
  );
}

export const useRoleState = () => {
  return useContext(RoleContext);
};
