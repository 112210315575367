/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseInterface = 'APP' | 'CARD' | 'MANUAL';

export const ExpenseInterface = {
  APP: 'APP' as ExpenseInterface,
  CARD: 'CARD' as ExpenseInterface,
  MANUAL: 'MANUAL' as ExpenseInterface,
};
