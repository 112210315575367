import React, { ReactNode } from 'react';
import {
  ModalFooterProps as ModalFooterBaseProps,
  ModalFooter as ChackraModalFooter,
} from '@chakra-ui/react';

type ModalFooterProps = ModalFooterBaseProps & {
  children: ReactNode;
};

export const ModalFooter = ({ children, ...rest }: ModalFooterProps) => (
  <ChackraModalFooter {...rest}>{children}</ChackraModalFooter>
);
