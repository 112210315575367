import { Button } from '@chakra-ui/button';
import { Box, SimpleGrid, Stack, Text } from '@chakra-ui/layout';
import { Radio } from '@chakra-ui/radio';
import { ResponsiveValue } from '@chakra-ui/styled-system';
import { useTheme } from '@chakra-ui/system';
import {
  getRegistrationGetQueryKey,
  useRegistrationUpdate,
} from '@libs/api/endpoints';
import {
  Gender,
  Registration,
  SocialSecretarySocialSecretaryEmployeeType,
  SocialSecretarySocialSecretaryName,
} from '@libs/api/models';
import { FORMAT_DATE, INPUT_TYPES } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import {
  Drawer,
  InputField,
  RadioGroupField,
  SelectField,
  useToast,
} from '@libs/ui/components';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { EmployeeFormInputsProps } from '../../employee.types';

type EmployeeDetailsProps = {
  employee: Registration;
  isOpen: boolean;
  onClose: () => void;
};

export const EmployeeDetailsDrawer = ({
  employee,
  isOpen,
  onClose,
}: EmployeeDetailsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: submit, isLoading } = useRegistrationUpdate({
    mutation: {
      onSuccess: () => {
        // update employee data
        const queryKey = getRegistrationGetQueryKey(employee.membership_id);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey);
        toast({
          title: t(
            i18nKeys.operator.employees.details.update_employee_success.title,
          ),
          content: t(
            i18nKeys.operator.employees.details.update_employee_success.content,
          ),
        });
      },
    },
  });

  const submitHandler = (values: Registration) => {
    submit({
      membershipid: employee.membership_id,
      data: { ...employee, ...values },
    });
  };

  const detailsInputs: {
    columns: ResponsiveValue<number>;
    inputs: EmployeeFormInputsProps[];
    title?: string;
  }[] = [
    {
      columns: { sm: 1, md: 2 },
      inputs: [
        {
          name: 'first_name',
          value: employee.first_name,
          label: t(i18nKeys.common.firstname),
        },
        { name: 'email', value: employee.email },
        {
          name: 'date_of_birth',
          value:
            employee.date_of_birth &&
            dayjs(employee.date_of_birth).format(FORMAT_DATE),
        },
        { name: 'nationality', value: employee.nationality },
        {
          name: 'last_name',
          value: employee.last_name,
          label: t(i18nKeys.common.lastname),
        },
        { name: 'phone_number', value: employee.phone_number },
        {
          name: 'country_of_birth',
          value: employee.country_of_birth,
          label: t(i18nKeys.common.country_of_birth),
        },
        { name: 'place_of_birth', value: employee.place_of_birth },
      ],
    },
    {
      columns: { sm: 1, md: 2 },
      title: t(i18nKeys.common.address),
      inputs: [
        {
          name: 'address.city',
          value: employee.address?.city,
          label: t(i18nKeys.common.city),
        },
        {
          name: 'address.postal_code',
          value: employee.address?.postal_code,
          label: t(i18nKeys.common.postal_code),
        },
        {
          name: 'address.country',
          value: employee.address?.country_code,
          label: t(i18nKeys.common.country_code),
        },
        {
          name: 'address.street',
          value: employee.address?.street,
          label: t(i18nKeys.common.street),
        },
      ],
    },
    {
      columns: { sm: 1, md: 2 },
      title: t(i18nKeys.operator.employees.details.work_related_title),
      inputs: [
        {
          name: 'start_on',
          value:
            employee.start_on && dayjs(employee.start_on).format(FORMAT_DATE),
          label: t(i18nKeys.common.start_at),
        },
      ],
    },
    {
      columns: { sm: 1, md: 2 },
      title: t(i18nKeys.operator.employees.details.social_secretary),
      inputs: [
        {
          name: 'social_secretary.social_secretary_name',
          type: 'select',
          options: Object.values(SocialSecretarySocialSecretaryName),
          value: employee.social_secretary?.social_secretary_name,
          isDisabled: false,
          label: t(i18nKeys.common.social_secretary_name),
        },
        {
          name: 'social_secretary.social_secretary_employee_reference',
          value: employee.social_secretary?.social_secretary_employee_reference,
          isDisabled: false,
          label: t(i18nKeys.common.social_secretary_employee_reference),
        },
        {
          name: 'social_secretary.social_secretary_employee_type',
          type: INPUT_TYPES.select,
          options: Object.values(SocialSecretarySocialSecretaryEmployeeType),
          optionsI18nKey: i18nKeys.common.SocialSecretaryEmployeeType,
          value: employee.social_secretary?.social_secretary_employee_type,
          isDisabled: false,
          label: t(i18nKeys.common.social_secretary_employee_type),
        },
      ],
    },
    {
      columns: { sm: 1, md: 2 },
      title: t(i18nKeys.operator.employees.details.other_title),
      inputs: [
        {
          name: 'internal_payroll_id',
          value: employee.internal_payroll_id,
          isDisabled: false,
        },
      ],
    },
  ];

  return (
    <Drawer
      title={t(i18nKeys.operator.employees.details.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body overflowX="hidden">
        <Text size="Body1" color="ink.dark" my="2rem">
          {t(i18nKeys.operator.employees.details.introduction)}
        </Text>

        <Formik<Registration>
          initialValues={{
            gender: employee.gender,
            first_name: employee.first_name,
            email: employee.email,
            date_of_birth: employee.date_of_birth,
            nationality: employee.nationality,
            last_name: employee.last_name,
            phone_number: employee.phone_number,
            country_of_birth: employee.country_of_birth,
            place_of_birth: employee.place_of_birth,
            address: {
              city: employee.address?.city,
              postal_code: employee.address?.postal_code,
              country_code: employee.address?.country_code,
              street: employee.address?.street,
            },
            start_on: employee.start_on,
            internal_payroll_id: employee.internal_payroll_id,
            social_secretary: {
              social_secretary_name:
                employee?.social_secretary?.social_secretary_name,
              social_secretary_employee_reference:
                employee.social_secretary?.social_secretary_employee_reference,
              social_secretary_employee_type:
                employee?.social_secretary?.social_secretary_employee_type,
            },
          }}
          onSubmit={submitHandler}
        >
          <Form>
            <Stack>
              <RadioGroupField ml="2rem" name="gender" isRequired>
                <Stack direction="row" spacing="1rem">
                  <Radio isDisabled value={Gender.Male}>
                    {t(i18nKeys.common.mr)}
                  </Radio>
                  <Radio isDisabled value={Gender.Female}>
                    {t(i18nKeys.common.mrs)}
                  </Radio>
                </Stack>
              </RadioGroupField>
              {detailsInputs.map(({ title, columns, inputs }, index) => (
                <Box key={title || index}>
                  <Text
                    fontSize="body2"
                    fontFamily={theme.fonts.large}
                    ml="2rem"
                    color="ink.darker"
                  >
                    {title}
                  </Text>
                  <SimpleGrid
                    columns={columns}
                    spacing="1rem"
                    p="0 2rem"
                    mb="2rem"
                  >
                    {inputs.map(
                      ({
                        name,
                        label,
                        isDisabled = true,
                        type,
                        options,
                        optionsI18nKey,
                      }: EmployeeFormInputsProps) =>
                        type === INPUT_TYPES.select ? (
                          <SelectField
                            key={name}
                            name={name}
                            isDisabled={isDisabled}
                            label={label || t(i18nKeys.common[name])}
                          >
                            {options.map((option) => (
                              <option key={option} value={option}>
                                {optionsI18nKey
                                  ? t(optionsI18nKey[option])
                                  : option}
                              </option>
                            ))}
                          </SelectField>
                        ) : (
                          <InputField
                            key={name}
                            name={name}
                            isDisabled={isDisabled}
                            label={label || t(i18nKeys.common[name])}
                          />
                        ),
                    )}
                  </SimpleGrid>
                </Box>
              ))}
              <Button
                type="submit"
                alignSelf="flex-end"
                variant="primary"
                size="body1"
                disabled={isLoading}
                isLoading={isLoading}
                loadingText={t(i18nKeys.common.submit)}
              >
                {t(i18nKeys.common.submit)}
              </Button>
            </Stack>
          </Form>
        </Formik>
      </Drawer.Body>
    </Drawer>
  );
};
