import { Box, BoxProps } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Loader } from '@libs/ui/components/loader';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../table';

export type TableBodyProps = BoxProps & {
  children: ReactNode;
  isEmpty?: boolean;
  isLoading?: boolean;
  colSpan?: number;
};

export const TableBody = ({
  children,
  isEmpty = false,
  isLoading = false,
  colSpan = null,
  ...rest
}: TableBodyProps) => {
  const { t } = useTranslation();

  return (
    <Box as="tbody" p={4} {...rest}>
      {(isLoading && (
        <Table.TR>
          <Table.TD colSpan={colSpan}>
            <Box h="8rem" position="relative">
              <Loader />
            </Box>
          </Table.TD>
        </Table.TR>
      )) ||
        (isEmpty && (
          <Table.TR>
            <Table.TD>{t(i18nKeys.common.empty_table)}</Table.TD>
          </Table.TR>
        )) ||
        children}
    </Box>
  );
};
