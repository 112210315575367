import * as React from 'react';

function SvgExternal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      overflow="visible"
      {...props}
    >
      <path d="M54.8,9.2l15.4,15.4L37.4,57.4l6.6,6.6l32.8-32.8l15.4,15.4V9.2H54.8z" />
      <path
        d="M82.9,84.1H17.4V18.6h32.8l-9.4-9.4H17.4c-5.2,0-9.4,4.2-9.4,9.4v65.5c0,5.2,4.2,9.4,9.4,9.4h65.5c5.2,0,9.4-4.2,9.4-9.4
	V60.7l-9.4-9.4V84.1z"
      />
    </svg>
  );
}

export default SvgExternal;
