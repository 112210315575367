import { Box, Button } from '@chakra-ui/react';
import { useGetCurrentUser } from '@libs/api/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { Loader, PageHeader } from '@libs/ui/components';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DashboardLayout } from '../components/dashboard-layout';

export const DashboardFleetManagerPage = () => {
  const { data: user, isLoading } = useGetCurrentUser();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Box py="10rem" maxW="69rem" position="relative">
        <Loader spinnerProps={{ size: 'md' }} modRight>
          {t(i18nKeys.common.loading)}
        </Loader>
      </Box>
    );
  }

  const title: ReactNode = (
    <>
      {t(i18nKeys.dashboard.hello)} {user.first_name}
      <br />
      {t(i18nKeys.dashboard.welcome)}
    </>
  );

  return (
    <DashboardLayout>
      <PageHeader
        size="Large"
        title={title}
        introduction={t(i18nKeys.dashboard.introduction)}
      />

      <Button
        as={Link}
        mt="2rem"
        variant="primary"
        size="body2"
        to={getPath(Routes.ONBOARDING)}
      >
        {t(i18nKeys.dashboard.go_onboarding)}
      </Button>
    </DashboardLayout>
  );
};
