import { Flex, Heading } from '@chakra-ui/layout';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../../components/dashboard-layout';
import { RestrictionGroupTable } from './components/restriction-group-table';
import { RestrictionGroupType } from './restriction-group.type';

export const RestrictionGroupPage = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <Flex display={{ base: 'block', lg: 'flex' }} justify="space-between">
        <Heading>{t(i18nKeys.operator.restriction_groups.mid_title)}</Heading>
      </Flex>
      <RestrictionGroupTable type={RestrictionGroupType.MID} />

      <Flex
        display={{ base: 'block', lg: 'flex' }}
        justify="space-between"
        mt="4rem"
      >
        <Heading>{t(i18nKeys.operator.restriction_groups.mcc_title)}</Heading>
      </Flex>
      <RestrictionGroupTable type={RestrictionGroupType.MCC} />
    </DashboardLayout>
  );
};
