/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ReasonRefusalType =
  | 'BCE_DOC_MISSING'
  | 'BCE_DOC_NOT_READABLE'
  | 'BCE_DOC_OUT_OF_DATE';

export const ReasonRefusalType = {
  BCE_DOC_MISSING: 'BCE_DOC_MISSING' as ReasonRefusalType,
  BCE_DOC_NOT_READABLE: 'BCE_DOC_NOT_READABLE' as ReasonRefusalType,
  BCE_DOC_OUT_OF_DATE: 'BCE_DOC_OUT_OF_DATE' as ReasonRefusalType,
};
