/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseSource = 'CLIENT' | 'OPERATOR' | 'BANK';

export const ExpenseSource = {
  CLIENT: 'CLIENT' as ExpenseSource,
  OPERATOR: 'OPERATOR' as ExpenseSource,
  BANK: 'BANK' as ExpenseSource,
};
