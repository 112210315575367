/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyActionActionRole = 'HR_MANAGER' | 'MANAGER';

export const PolicyActionActionRole = {
  HR_MANAGER: 'HR_MANAGER' as PolicyActionActionRole,
  MANAGER: 'MANAGER' as PolicyActionActionRole,
};
