/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type UseCard = 'VIRTUAL' | 'PHYSICAL' | 'NONE';

export const UseCard = {
  VIRTUAL: 'VIRTUAL' as UseCard,
  PHYSICAL: 'PHYSICAL' as UseCard,
  NONE: 'NONE' as UseCard,
};
