import { Text } from '@chakra-ui/layout';
import { FORMAT_DATE } from '@libs/core/constants';
import dayjs from 'dayjs';
import React from 'react';
import { Table } from '../table';

type TableDateProps = {
  children: string;
  format?: string;
  onClick?: () => void;
};

export const TableDate = ({ children, format, onClick }: TableDateProps) => {
  return (
    <Table.TD onClick={onClick}>
      <Text>{children && dayjs(children).format(format || FORMAT_DATE)}</Text>
    </Table.TD>
  );
};
