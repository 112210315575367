import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Radio,
  SimpleGrid,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { organisationUpdate, useOrganisationGet } from '@libs/api/endpoints';
import {
  AnnualTurnOver,
  DocumentType,
  NetIncomeRange,
  NumberOfEmployeeRange,
  OnboardingStatus,
} from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { validateSchema } from '@libs/core/utils';
import { FIREBASE_STORAGE_FILES } from '@libs/dashboard-core/constants';
import { ONBOARDING_EVENTS } from '@libs/dashboard-core/constants/analytics';
import {
  Assistant,
  Card,
  FormSubmitData,
  handleFormSubmit,
  InputField,
  Loader,
  RadioGroupField,
  SelectField,
  useWizard,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import mergeWith from 'lodash.mergewith';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { findDocumentByType, mergeDocuments } from '../../../utils/documents';
import {
  ANNUAL_TURN_OVER_ACCEPTED,
  CompanyDetailsSteps,
  UPLOAD_FILE_OPTIONS,
} from '../onboarding-wizard.constants';
import { OnboardingOrganisationFormData } from '../onboarding-wizard.types';
import {
  getOrganisationDocumentsToUpload,
  uploadFile,
} from '../onboarding-wizard.utils';
import { validationOrganisationSchema } from '../onboarding-wizard.validations';
import { OnboardingLayout } from './onboarding-layout';
import { UploadPreview } from './upload-preview';

type OrganisationProps = {
  organisationId: string;
  setCompanyDetailsStep: Dispatch<SetStateAction<CompanyDetailsSteps>>;
};

export const OrganisationForm = ({
  organisationId,
  setCompanyDetailsStep,
}: OrganisationProps) => {
  const { previousStep, nextStep } = useWizard();
  const queryClient = useQueryClient();
  const { data: organisation, isLoading, queryKey } = useOrganisationGet(
    organisationId,
  );
  const { t } = useTranslation();
  const External = useIcon('External');
  const theme = useTheme();

  const { mutateAsync: submit } = useMutation(
    async ({ values }: FormSubmitData<OnboardingOrganisationFormData>) => {
      const { bce, constitution, ...data } = values;
      const documents = getOrganisationDocumentsToUpload({ bce, constitution });
      const uploadedDocuments = await Promise.all(documents.map(uploadFile));

      return organisationUpdate(organisation.id, {
        ...data,
        documents: mergeDocuments(uploadedDocuments, data.documents),
        onboarding_status: OnboardingStatus.ORG_DATA_SENT,
      });
    },
    {
      onSuccess: (organisation) => {
        queryClient.setQueryData(queryKey, organisation);
        const constitutionDocUrl = organisation.documents?.find(
          (doc) => doc.document_type === DocumentType.ORGANISATION_CONSTITUTION,
        )?.url;
        const bceDocUrl = organisation.documents?.find(
          (doc) => doc.document_type === DocumentType.ORGANISATION_BCE,
        )?.url;

        window.analytics.track(ONBOARDING_EVENTS.ORG_DETAILS_COMPLETED);
        window.analytics.track(ONBOARDING_EVENTS.ORG_DOCUMENT_COMPLETED, {
          // Org Status Document URL
          orgConstitutionDoc: constitutionDocUrl,
          // Org Extract Document URL
          orgBceDoc: bceDocUrl,
        });
        nextStep();
      },
    },
  );

  if (isLoading) {
    return (
      <Box py="10rem" maxW="69rem" position="relative">
        <Loader spinnerProps={{ size: 'md' }} modRight>
          {t(i18nKeys.common.loading)}
        </Loader>
      </Box>
    );
  }

  const bceDoc = findDocumentByType(
    organisation?.documents,
    DocumentType.ORGANISATION_BCE,
  );
  const constitutionDoc = findDocumentByType(
    organisation?.documents,
    DocumentType.ORGANISATION_CONSTITUTION,
  );
  return (
    <OnboardingLayout
      title={t(i18nKeys.onboarding.review_company.title)}
      introduction={t(i18nKeys.onboarding.review_company.introduction)}
    >
      <Formik<OnboardingOrganisationFormData>
        initialValues={mergeWith(
          {
            email: '',
            phone_number: '',
            legal: {
              number_of_employees_range: '' as NumberOfEmployeeRange,
              net_income_range: '' as NetIncomeRange,
              annual_turn_over: '' as AnnualTurnOver,
            },
            bce: [] as File[],
            constitution: [] as File[],
            constitution_url: constitutionDoc?.url, // virtual field used only for design purposes
            bce_url: bceDoc?.url, // virtual field used only for design purposes
            activity_outside_eu: false,
          },
          organisation,
        )}
        validate={validateSchema(validationOrganisationSchema)}
        onSubmit={handleFormSubmit(submit)}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form noValidate>
            <Card>
              <SimpleGrid columns={[1, null, 2]} spacingX="2rem">
                <InputField
                  name="email"
                  label={t(i18nKeys.common.company_email)}
                  isRequired
                />
                <InputField
                  name="phone_number"
                  label={t(i18nKeys.common.phone_number)}
                  isRequired
                />
                <SelectField
                  name="legal.number_of_employees_range"
                  label={t(i18nKeys.common.number_of_employees)}
                  isRequired
                >
                  {Object.values(NumberOfEmployeeRange).map(
                    (legalNumberOfEmployeeRange) => (
                      <option
                        value={legalNumberOfEmployeeRange}
                        key={legalNumberOfEmployeeRange}
                      >
                        {t(
                          i18nKeys.onboarding.review_company.legal
                            .number_of_employee[legalNumberOfEmployeeRange],
                        )}
                      </option>
                    ),
                  )}
                </SelectField>
                <SelectField
                  name="legal.net_income_range"
                  label={t(i18nKeys.common.legal_net_income)}
                  isRequired
                >
                  {Object.values(NetIncomeRange).map((netIncomeRange) => (
                    <option value={netIncomeRange} key={netIncomeRange}>
                      {t(
                        i18nKeys.onboarding.review_company.legal.net_income[
                          netIncomeRange
                        ],
                      )}
                    </option>
                  ))}
                </SelectField>
                <SelectField
                  name="legal.annual_turn_over"
                  label={t(i18nKeys.common.legal_annual_turnover)}
                  isRequired
                  onChange={() => {
                    if (
                      !ANNUAL_TURN_OVER_ACCEPTED.includes(
                        values.legal.annual_turn_over,
                      )
                    ) {
                      setFieldValue('activity_outside_eu', false);
                      setFieldValue('economic_sanctions', false);
                      setFieldValue('resident_countries_sanctions', false);
                      setFieldValue('involved_sanctions', false);
                    }
                  }}
                >
                  {Object.values(AnnualTurnOver).map((annualTurnOver) => (
                    <option value={annualTurnOver} key={annualTurnOver}>
                      {t(
                        i18nKeys.onboarding.review_company.legal
                          .annual_turnover[annualTurnOver],
                      )}
                    </option>
                  ))}
                </SelectField>
              </SimpleGrid>
            </Card>
            {ANNUAL_TURN_OVER_ACCEPTED.includes(
              values.legal.annual_turn_over,
            ) ? (
              <Card mt="2rem">
                <RadioGroupField
                  label={t(i18nKeys.common.outside_eu)}
                  name="activity_outside_eu"
                  direction="column"
                  isBoolean
                >
                  <Stack direction="row" spacing="3rem" mt="-2rem">
                    <Radio
                      value="true"
                      data-cy="activity_outside_eu_radio_true"
                    >
                      {t(i18nKeys.common.yes)}
                    </Radio>
                    <Radio value="false">{t(i18nKeys.common.no)}</Radio>
                  </Stack>
                </RadioGroupField>
                <hr />
                {values.activity_outside_eu ? (
                  <>
                    <RadioGroupField
                      mt="2rem"
                      label={t(i18nKeys.common.economic_sanctions)}
                      name="economic_sanctions"
                      direction="column"
                      isBoolean
                    >
                      <Stack direction="row" spacing="3rem" mt="-1rem">
                        <Radio
                          value="true"
                          data-cy="economic_sanctions-radio-true"
                        >
                          {t(i18nKeys.common.yes)}
                        </Radio>
                        <Radio value="false">{t(i18nKeys.common.no)}</Radio>
                      </Stack>
                    </RadioGroupField>
                    <hr />
                    <RadioGroupField
                      mt="2rem"
                      label={t(i18nKeys.common.resident_countries_sanctions)}
                      name="resident_countries_sanctions"
                      direction="column"
                      isBoolean
                    >
                      <Stack direction="row" spacing="3rem" mt="-1rem">
                        <Radio
                          value="true"
                          data-cy="resident_countries_sanctions-radio-true"
                        >
                          {t(i18nKeys.common.yes)}
                        </Radio>
                        <Radio value="false">{t(i18nKeys.common.no)}</Radio>
                      </Stack>
                    </RadioGroupField>
                    <hr />
                    <RadioGroupField
                      mt="2rem"
                      label={t(i18nKeys.common.involved_sanctions)}
                      name="involved_sanctions"
                      direction="column"
                      isBoolean
                    >
                      <Stack direction="row" spacing="3rem" mt="-1rem">
                        <Radio
                          value="true"
                          data-cy="involved_sanctions-radio-true"
                        >
                          {t(i18nKeys.common.yes)}
                        </Radio>
                        <Radio value="false">{t(i18nKeys.common.no)}</Radio>
                      </Stack>
                    </RadioGroupField>
                    <hr />
                  </>
                ) : null}
              </Card>
            ) : null}
            <Card mt="2rem">
              <Flex>
                <Heading size="Headline">
                  {t(i18nKeys.common.constitution)}
                </Heading>
                <Text
                  ml=".2rem"
                  alignSelf="flex-end"
                  color="status.negative.type"
                >
                  *
                </Text>
              </Flex>
              <UploadPreview
                options={UPLOAD_FILE_OPTIONS}
                uploadName="constitution"
                previewTitle={t(i18nKeys.common.constitution)}
                uploadURL={values.constitution_url}
                previewURL={FIREBASE_STORAGE_FILES.images.CONSTITUTION}
              />

              <Flex>
                <Heading size="Headline" mt="6rem">
                  {t(i18nKeys.common.bce)}
                </Heading>
                <Text
                  ml=".2rem"
                  alignSelf="flex-end"
                  color="status.negative.type"
                >
                  *
                </Text>
              </Flex>
              {organisation.vat && (
                <Text>
                  <Link
                    href={`https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=${organisation.vat.slice(
                      2,
                    )}&actionLu=Recherche`}
                    target="_blank"
                    data-cy="bce-explanation-link"
                  >
                    {t(i18nKeys.onboarding.review_company.search_bce)}{' '}
                    <Box as="span" display="inline-block">
                      <External
                        fill={theme.colors.main.secondary.normal}
                        width="1.2rem"
                        height="1.2rem"
                      />
                    </Box>
                  </Link>
                </Text>
              )}
              <UploadPreview
                options={UPLOAD_FILE_OPTIONS}
                uploadName="bce"
                previewTitle={t(i18nKeys.common.bce)}
                uploadURL={values.bce_url}
                previewURL={FIREBASE_STORAGE_FILES.images.BCE}
              />
            </Card>

            <Flex justify="space-between" p="4rem 0 8rem">
              <Button
                onClick={() =>
                  setCompanyDetailsStep(CompanyDetailsSteps.CONFIRM)
                }
                size="body1"
                variant="border"
              >
                {t(i18nKeys.common.previous)}
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="body1"
                loadingText={t(i18nKeys.common.next)}
                isLoading={isSubmitting}
                data-cy="organisation-form-button-submit"
                disabled={
                  (values.constitution.length === 0 &&
                    !values.constitution_url) ||
                  (values.bce.length === 0 && !values.bce_url)
                }
              >
                {t(i18nKeys.common.next)}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
      <Assistant>
        <Assistant.Card
          title={t(i18nKeys.onboarding.review_company.assistant.title)}
        >
          <Heading size="Title3" mt=".5rem">
            {organisation.legal_name}
          </Heading>
          <Badge variant="info" bgColor="ink.light">
            {organisation.vat}
          </Badge>
          <Heading size="Body2" mt="1rem">
            {t(i18nKeys.common.address)}
          </Heading>
          <Text color="ink.dark">
            {organisation.address?.street || '-'}
            <br />
            {organisation.address?.postal_code || '-'}{' '}
            {organisation.address?.city}
            <br />
            {organisation.address?.country_code || '-'}
          </Text>
          <Heading size="Body2" mt="1rem">
            {t(i18nKeys.common.legal_registration_date)}
          </Heading>
          <Text color="ink.dark" pb={4}>
            {organisation.legal?.registration_date?.slice(0, 10) || '-'}
          </Text>
          <Text>{t(i18nKeys.onboarding.review_company.assistant.content)}</Text>
        </Assistant.Card>
      </Assistant>
    </OnboardingLayout>
  );
};
