/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type UserLanguage = 'fr' | 'nl' | 'en';

export const UserLanguage = {
  fr: 'fr' as UserLanguage,
  nl: 'nl' as UserLanguage,
  en: 'en' as UserLanguage,
};
