import { Box, Link, useTheme } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../context';

export const LogoutButton = () => {
  const { signout } = useAuthState();
  const theme = useTheme();
  const Logout = useIcon('Logout');
  const { t } = useTranslation();

  return (
    <Link as="button" onClick={signout}>
      <Box as="span" verticalAlign="middle" display="inline-block">
        <Logout fill={theme.colors.main.secondary.light} width="2rem" />
      </Box>
      {t(i18nKeys.header.logout)}
    </Link>
  );
};
