import React, { ReactNode } from 'react';
import { StyledTableCell } from '../table.style';

type TableCellProps = {
  children: ReactNode;
  colSpan?: number;
  onClick?: () => void;
};

export const TableCell = ({
  children,
  colSpan,
  onClick,
  ...rest
}: TableCellProps) => {
  return (
    <StyledTableCell onClick={onClick} colSpan={colSpan} {...rest}>
      {children}
    </StyledTableCell>
  );
};
