export const lineHeights = {
  Xlarge: '4.5rem',
  large: '4rem',
  title1: '3.2rem',
  title2: '3.2rem',
  title3: '2.5rem',
  headline: '2.2rem',
  subhead: '2rem',
  body1: '2.2rem',
  body2: '2rem',
  small: '1.8rem',
  xsmall: '1.6rem',
};
