import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { WizardProgressbarStep } from './wizard-progressbar-step';

type WizardProps = {
  children?: ReactNode;
};

export const WizardProgressbar = ({ children }: WizardProps) => {
  return (
    <Flex p="2rem 0" align="flex-start" w="100%">
      {children}
    </Flex>
  );
};

WizardProgressbar.Step = WizardProgressbarStep;
