import * as React from 'react';

function SvgFile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.8181 0C3.33591 0 2.87347 0.194505 2.53251 0.540726C2.19155 0.886947 2 1.35652 2 1.84615V22.1538C2 22.6435 2.19155 23.1131 2.53251 23.4593C2.87347 23.8055 3.33591 24 3.8181 24H20.1819C20.4207 24 20.6571 23.9522 20.8777 23.8595C21.0982 23.7667 21.2987 23.6307 21.4675 23.4593C21.6363 23.2878 21.7702 23.0843 21.8616 22.8603C21.953 22.6363 22 22.3963 22 22.1538V7.38461C22 7.33808 21.9965 7.29191 21.9898 7.24645C21.9965 7.20232 22 7.15713 22 7.11111C22 6.66432 21.6704 6.29451 21.2411 6.23165L15.7455 0.65121C15.6415 0.275693 15.2973 0 14.8888 0C14.8616 0 14.8346 0.00122368 14.808 0.00361905C14.7814 0.00121632 14.7546 0 14.7276 0H3.8181ZM14 1.84615H3.8181L3.8181 22.1538L20.1819 22.1538V8H14.8888C14.3979 8 14 7.60203 14 7.11111V1.84615ZM18.6606 6.22222L15.7776 3.29469V6.22222H18.6606Z"
      />
    </svg>
  );
}

export default SvgFile;
