import { PAGE_SIZE } from '@libs/core/constants';
import { useState } from 'react';

type usePagerProps = {
  pageNumber?: number;
  pageSize?: number;
};

export const usePager = (initialValues?: usePagerProps) => {
  const { pageNumber: initPageNumber = 1, pageSize: initPageSize = PAGE_SIZE } =
    initialValues || {};

  const [pageNumber, setPageNumber] = useState<number>(initPageNumber);
  const [pageSize, setPageSize] = useState<number>(initPageSize);

  return { pageNumber, setPageNumber, pageSize, setPageSize };
};
