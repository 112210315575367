export const FONT_SIZES = {
  X_LARGE: 'Xlarge',
  LARGE: 'Large',
  TITLE_1: 'Title1',
  TITLE_2: 'Title2',
  TITLE_3: 'Title3',
  HEADLINE: 'Headline',
  SUBHEAD: 'Subhead',
  BODY_1: 'Body1',
  BODY_2: 'Body2',
  SMALL: 'Small',
  X_SMALL: 'XSmall',
  BODY_1_BOLD: 'Body1Bold',
  BODY_2_BOLD: 'Body2Bold',
  SMALL_BOLD: 'SmallBold',
};
