import { Box, Divider, Heading, useBreakpointValue } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type NavHeaderProps = {
  isOpen?: boolean;
  title?: ReactNode;
  introduction?: ReactNode;
};

export const NavHeader = ({ isOpen, title, introduction }: NavHeaderProps) => {
  const breakpoint = useBreakpointValue({ base: 'base', lg: 'lg' });

  return (
    <Box display={isOpen || breakpoint === 'lg' ? 'block' : 'none'}>
      <Box p="0 3rem">
        <Heading size="Body2" color="nav.type.normal">
          {title}
        </Heading>
        {introduction}
      </Box>
      <Divider py="1rem" />
    </Box>
  );
};
