/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  TeamsGetResponse,
  TeamsListParams,
  TeamUpSertResponse,
  TeamPostRequest,
  TeamWithMeta,
  TeamGetParams,
  TeamPutRequest,
  TeamMembersGetResponse,
  TeamMembersListParams,
  Team,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const teamsList = <Data = unknown>(
  params?: TeamsListParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? TeamsGetResponse : Data>(
    { url: `/v2/teams`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getTeamsListQueryKey = (params?: TeamsListParams) => [
  `/v2/teams`,
  ...(params ? [params] : []),
];

export const useTeamsList = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: TeamsListParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof teamsList>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getTeamsListQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof teamsList>, Error>(
    queryKey,
    () => teamsList<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const teamsPost = <Data = unknown>(
  teamPostRequest: TeamPostRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? TeamUpSertResponse : Data>(
    { url: `/v2/teams`, method: 'post', data: teamPostRequest },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamsPost = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamsPost>,
    Error,
    { data: TeamPostRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamsPost>,
    Error,
    { data: TeamPostRequest }
  >((props) => {
    const { data } = props || {};

    return teamsPost<Data>(data, requestOptions);
  }, mutationOptions);
};
export const teamGet = <Data = unknown>(
  teamid: string,
  params?: TeamGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? TeamWithMeta : Data>(
    { url: `/v2/teams/${teamid}`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getTeamGetQueryKey = (teamid: string, params?: TeamGetParams) => [
  `/v2/teams/${teamid}`,
  ...(params ? [params] : []),
];

export const useTeamGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  teamid: string,
  params?: TeamGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof teamGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getTeamGetQueryKey(teamid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof teamGet>, Error>(
    queryKey,
    () => teamGet<Data>(teamid, params, requestOptions),
    { enabled: !!teamid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const teamPut = <Data = unknown>(
  teamid: string,
  teamPutRequest: TeamPutRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? TeamUpSertResponse : Data>(
    { url: `/v2/teams/${teamid}`, method: 'put', data: teamPutRequest },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamPut = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamPut>,
    Error,
    { teamid: string; data: TeamPutRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamPut>,
    Error,
    { teamid: string; data: TeamPutRequest }
  >((props) => {
    const { teamid, data } = props || {};

    return teamPut<Data>(teamid, data, requestOptions);
  }, mutationOptions);
};
export const teamDelete = <Data = unknown>(
  teamid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/teams/${teamid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamDelete>,
    Error,
    { teamid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamDelete>,
    Error,
    { teamid: string }
  >((props) => {
    const { teamid } = props || {};

    return teamDelete<Data>(teamid, requestOptions);
  }, mutationOptions);
};
export const teamMembersList = <Data = unknown>(
  teamid: string,
  params?: TeamMembersListParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? TeamMembersGetResponse : Data>(
    { url: `/v2/teams/${teamid}/members`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getTeamMembersListQueryKey = (
  teamid: string,
  params?: TeamMembersListParams,
) => [`/v2/teams/${teamid}/members`, ...(params ? [params] : [])];

export const useTeamMembersList = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  teamid: string,
  params?: TeamMembersListParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof teamMembersList>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getTeamMembersListQueryKey(teamid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof teamMembersList>, Error>(
    queryKey,
    () => teamMembersList<Data>(teamid, params, requestOptions),
    { enabled: !!teamid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const teamMemberPut = <Data = unknown>(
  teamid: string,
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    {
      url: `/v2/teams/${teamid}/members/${membershipid}`,
      method: 'put',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamMemberPut = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamMemberPut>,
    Error,
    { teamid: string; membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamMemberPut>,
    Error,
    { teamid: string; membershipid: string }
  >((props) => {
    const { teamid, membershipid } = props || {};

    return teamMemberPut<Data>(teamid, membershipid, requestOptions);
  }, mutationOptions);
};
export const teamMemberDelete = <Data = unknown>(
  teamid: string,
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/teams/${teamid}/members/${membershipid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamMemberDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamMemberDelete>,
    Error,
    { teamid: string; membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamMemberDelete>,
    Error,
    { teamid: string; membershipid: string }
  >((props) => {
    const { teamid, membershipid } = props || {};

    return teamMemberDelete<Data>(teamid, membershipid, requestOptions);
  }, mutationOptions);
};
export const teamManagerPut = <Data = unknown>(
  teamid: string,
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Team : Data>(
    {
      url: `/v2/teams/${teamid}/managers/${membershipid}`,
      method: 'put',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamManagerPut = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamManagerPut>,
    Error,
    { teamid: string; membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamManagerPut>,
    Error,
    { teamid: string; membershipid: string }
  >((props) => {
    const { teamid, membershipid } = props || {};

    return teamManagerPut<Data>(teamid, membershipid, requestOptions);
  }, mutationOptions);
};
export const teamManagerDelete = <Data = unknown>(
  teamid: string,
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/teams/${teamid}/managers/${membershipid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamManagerDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamManagerDelete>,
    Error,
    { teamid: string; membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamManagerDelete>,
    Error,
    { teamid: string; membershipid: string }
  >((props) => {
    const { teamid, membershipid } = props || {};

    return teamManagerDelete<Data>(teamid, membershipid, requestOptions);
  }, mutationOptions);
};
export const teamHrmanagerPut = <Data = unknown>(
  teamid: string,
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Team : Data>(
    {
      url: `/v2/teams/${teamid}/hr_managers/${membershipid}`,
      method: 'put',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamHrmanagerPut = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamHrmanagerPut>,
    Error,
    { teamid: string; membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamHrmanagerPut>,
    Error,
    { teamid: string; membershipid: string }
  >((props) => {
    const { teamid, membershipid } = props || {};

    return teamHrmanagerPut<Data>(teamid, membershipid, requestOptions);
  }, mutationOptions);
};
export const teamHrmanagerDelete = <Data = unknown>(
  teamid: string,
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    {
      url: `/v2/teams/${teamid}/hr_managers/${membershipid}`,
      method: 'delete',
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useTeamHrmanagerDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof teamHrmanagerDelete>,
    Error,
    { teamid: string; membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof teamHrmanagerDelete>,
    Error,
    { teamid: string; membershipid: string }
  >((props) => {
    const { teamid, membershipid } = props || {};

    return teamHrmanagerDelete<Data>(teamid, membershipid, requestOptions);
  }, mutationOptions);
};
