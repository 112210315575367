import { Image } from '@chakra-ui/react';
import React from 'react';

export type LogoProps = {
  src: string;
  alt: string;
};

export const Logo = ({ src, alt }: LogoProps) => {
  return (
    <h1 className="logo">
      <Image display="inline-block" alt={alt} src={src} />
    </h1>
  );
};
