import {
  Box,
  BoxProps,
  Center,
  Flex,
  Heading,
  Text,
  useTheme,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { Card } from './card';

export type CardInfoTileProps = Omit<BoxProps, 'title'> & {
  children?: ReactNode;
  title: ReactNode;
  introduction?: ReactNode;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  modRightIcon?: boolean;
  colorLight?: string;
  colorDark?: string;
  hover?: boolean;
  titleSize?: string;
};

export const CardInfoTile = ({
  children,
  title,
  introduction,
  icon,
  rightIcon,
  modRightIcon,
  colorLight = 'main.secondary.lighter',
  colorDark = 'main.secondary.normal',
  hover = true,
  titleSize = 'Body1',
  ...rest
}: CardInfoTileProps) => {
  const theme = useTheme();
  return (
    <Card role="group" cursor="pointer" {...rest}>
      <Flex>
        {icon && (
          <Center
            w={{ base: '6rem', xl: '9rem' }}
            minW={{ base: '6rem', xl: '9rem' }}
            h={{ base: '4rem', xl: '7rem' }}
            borderRadius={theme.borders.md}
            borderWidth=".2rem"
            borderColor={colorLight}
            mr={{ base: '1rem', xl: '2.5rem' }}
            transition="ease .4s all"
            bgColor={colorLight}
            _groupHover={hover ? { bgColor: colorDark } : null}
          >
            <Box
              w={{ base: '2.5rem', xl: '4.5rem' }}
              h={{ base: '2.5rem', xl: '4.5rem' }}
              fill={colorDark}
              _groupHover={hover ? { fill: 'ink.white' } : null}
            >
              {React.cloneElement(icon as React.ReactElement)}
            </Box>
          </Center>
        )}
        <Center w="100%">
          <Flex justify="space-between" align="center" w="100%">
            <Box>
              {title && (
                <Heading
                  size={titleSize}
                  transition="ease .4s color"
                  _groupHover={hover ? { color: colorDark } : null}
                >
                  {title}
                </Heading>
              )}
              {introduction && (
                <Text size="Body2" color="ink.dark">
                  {introduction}
                </Text>
              )}

              {children}
            </Box>
            {modRightIcon && (
              <Box
                padding=".5rem"
                w="3rem"
                h="3rem"
                borderRadius={theme.borders.md}
                borderColor={colorDark}
                borderWidth=".2rem"
                fill={colorDark}
                transition="ease .4s all"
                _groupHover={
                  hover
                    ? {
                        fill: 'ink.white',
                        bgColor: colorDark,
                      }
                    : null
                }
              >
                {React.cloneElement(rightIcon as React.ReactElement)}
              </Box>
            )}
          </Flex>
        </Center>
      </Flex>
    </Card>
  );
};
