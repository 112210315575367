/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type RegistrationAllOfStatus = 'CREATED' | 'VALIDATED' | 'CANCELED';

export const RegistrationAllOfStatus = {
  CREATED: 'CREATED' as RegistrationAllOfStatus,
  VALIDATED: 'VALIDATED' as RegistrationAllOfStatus,
  CANCELED: 'CANCELED' as RegistrationAllOfStatus,
};
