import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  useTheme,
} from '@chakra-ui/react';
import {
  organisationUpdate,
  useOrganisationInternalReview,
} from '@libs/api/endpoints';
import { OnboardingStatus, Organisation } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { ONBOARDING_EVENTS } from '@libs/dashboard-core/constants/analytics';
import { Assistant, Card, useWizard } from '@libs/ui/components';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingLayout } from './onboarding-layout';

type OrganisationConfirmFormProps = {
  organisation: Organisation;
};

export const OnboardingReview = ({
  organisation,
}: OrganisationConfirmFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const Check = useIcon('CheckRounded');
  const { previousStep, nextStep } = useWizard();

  const {
    mutateAsync: organisationInternalReview,
  } = useOrganisationInternalReview({
    mutation: {
      onSuccess: () => {
        window.analytics.track(ONBOARDING_EVENTS.ONBOARDING_COMPLETE);
        organisationUpdate(organisation.id, {
          // no need to pass all org fields only these ones are required
          use_mobility_budget: organisation.use_mobility_budget,
          use_payment_card: organisation.use_payment_card,
          is_freelancer: organisation.is_freelancer,
          onboarding_status: OnboardingStatus.INTERNAL_REVIEW,
          legal_name: organisation.legal_name,
        });
        nextStep();
      },
    },
  });

  return (
    <OnboardingLayout
      title={<>{t(i18nKeys.onboarding.wizard.review_onboarding)}</>}
      introduction={t(i18nKeys.onboarding.review_page.introduction)}
    >
      <Box>
        <Card>
          <Heading size="Body1">
            {t(i18nKeys.onboarding.review_page.document_list.title)}
          </Heading>
          <List spacing="2rem" mt="2.5rem">
            <ListItem>
              <ListIcon
                fill={theme.colors.status.positive.type}
                w="2rem"
                h="2rem"
                as={Check}
              />
              {t(i18nKeys.onboarding.welcome_page.document_list.item1)}
            </ListItem>
            <ListItem>
              <ListIcon
                fill={theme.colors.status.positive.type}
                w="2rem"
                h="2rem"
                as={Check}
              />
              {t(i18nKeys.onboarding.welcome_page.document_list.item2)}
            </ListItem>
            <ListItem>
              <ListIcon
                fill={theme.colors.status.positive.type}
                w="2rem"
                h="2rem"
                as={Check}
              />
              {t(i18nKeys.onboarding.welcome_page.document_list.item3)}
            </ListItem>
          </List>
        </Card>
        <Flex justify="space-between" p="4rem 0 8rem">
          <Button
            onClick={previousStep}
            size="body1"
            variant="border"
            data-cy="onboarding-button-previous"
          >
            {t(i18nKeys.common.previous)}
          </Button>
          <Button
            variant="primary"
            size="body1"
            data-cy="onboarding-review-next-button"
            onClick={() => {
              organisationInternalReview({
                organisationid: organisation.id,
              });
            }}
          >
            {t(i18nKeys.common.submit)}
          </Button>
        </Flex>
      </Box>
      <Assistant>
        <Assistant.Card
          title={t(i18nKeys.onboarding.review_page.assistant.title)}
        >
          <Text my={2}>
            {t(i18nKeys.onboarding.review_page.assistant.content)}
          </Text>
        </Assistant.Card>
      </Assistant>
    </OnboardingLayout>
  );
};
