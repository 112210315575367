import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  min-height: 12rem;
  border-width: 0.2rem;
  border-radius: 1.2rem;
  border-color: ${({ theme }) => theme.colors.ink.light};
  border-style: dashed;
  color: ${({ theme }) => theme.colors.ink.medium};
  outline: none;
`;
