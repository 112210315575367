export const Input = {
  baseStyle: ({ theme }) => ({
    field: {
      fontFamily: theme.fonts.body1,
      height: '5rem',
      border: 'none',
      borderRadius: '0',
      width: '100%',
      fontSize: theme.fontSizes.body1,
      lineHeight: theme.lineHeights.body1,
      color: theme.colors.ink.dark,
    },
    addon: {
      border: 'none',
      borderRadius: '0',
    },
  }),
  sizes: {
    md: ({ theme }) => ({
      field: {
        border: 'none',
        borderRadius: '0',
        fontSize: theme.fontSizes.body1,
        lineHeight: theme.lineHeights.body1,
      },
      addon: {
        border: 'none',
        borderRadius: '0',
      },
    }),
  },
};
