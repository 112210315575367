import { Box } from '@chakra-ui/react';
import { useWizard, WizardProgressbar } from '@libs/ui/components';
import React from 'react';

export const OrganisationOnboardingProgressBar = () => {
  const { steps, activeStepIndex, goToStep } = useWizard();
  return (
    <Box overflowX="auto" mb="3rem">
      <WizardProgressbar>
        {steps.map(({ id, label, isHidden }, index) => {
          if (isHidden) {
            return null;
          }
          return (
            <WizardProgressbar.Step
              key={id}
              modActive={activeStepIndex === index}
              modCompleted={activeStepIndex > index}
              onClick={() => {
                if (activeStepIndex < index) {
                  return;
                }
                goToStep(index);
              }}
            >
              {label}
            </WizardProgressbar.Step>
          );
        })}
      </WizardProgressbar>
    </Box>
  );
};
