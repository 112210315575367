import { Button } from '@chakra-ui/button';
import { Text } from '@chakra-ui/layout';
import {
  cardsGet,
  getCardsGetQueryKey,
  useCardsCreate,
} from '@libs/api/endpoints';
import { Registration } from '@libs/api/models';
import { PAGE_SIZE, QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer } from '@libs/ui/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { QueryStatusText } from '../../../components/query-status-text';

type CardCreateDrawerProps = {
  employee: Registration;
  isOpen: boolean;
  onClose: () => void;
};

export const CardCreateDrawer = ({
  employee,
  isOpen,
  onClose,
}: CardCreateDrawerProps) => {
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: createCard, status } = useCardsCreate({
    mutation: {
      onError: (error: any) => {
        setError(error);
      },
      onSuccess: () => {
        const params = {
          membership_id: employee.membership_id,
          page_number: 1,
          page_size: PAGE_SIZE,
        };
        const queryKey = getCardsGetQueryKey(params);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey, () => cardsGet(params));
      },
    },
  });

  const createHandler = () => {
    createCard({ data: { membership_id: employee.membership_id } });
  };

  const statusText = {
    [QueryStatus.loading]: t(i18nKeys.operator.cards.create.status.loading),
    [QueryStatus.error]: t(i18nKeys.operator.cards.create.status.error),
    [QueryStatus.success]: t(i18nKeys.operator.cards.create.status.success),
  };

  return (
    <Drawer
      title={t(i18nKeys.operator.cards.create.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <Text size="Body2" color="ink.dark" my="2rem">
          {t(i18nKeys.operator.cards.create.confirmation)}
        </Text>
        <Button
          alignSelf="flex-end"
          variant="primary"
          size="body1"
          mt="1rem"
          mb="0"
          onClick={createHandler}
          isLoading={status === QueryStatus.loading}
          loadingText={t(i18nKeys.operator.cards.add)}
        >
          {t(i18nKeys.operator.cards.add)}
        </Button>
        <QueryStatusText
          status={status}
          statusText={statusText}
          error={error}
        />
      </Drawer.Body>
    </Drawer>
  );
};
