import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

export const StyledTableCell = styled.td`
  color: ${({ theme }) => theme.colors.ink.black};
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 2rem;
  min-height: 8rem;
  border-bottom: 0.2rem solid ${({ theme }) => theme.colors.background.page};
  &:first-of-type {
    padding-left: 4rem;
  }
`;

export const StyledTableHeadCell = styled(StyledTableCell.withComponent('th'), {
  shouldForwardProp: isPropValid,
})`
  color: ${({ theme }) => theme.colors.ink.dark};
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-weight: bold;
`;
