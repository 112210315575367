import * as React from 'react';

function SvgIdentity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6979 10.5C12.6979 9.94772 13.127 9.5 13.6563 9.5H17.25C17.7793 9.5 18.2083 9.94772 18.2083 10.5C18.2083 11.0523 17.7793 11.5 17.25 11.5H13.6563C13.127 11.5 12.6979 11.0523 12.6979 10.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6979 13.5C12.6979 12.9477 13.127 12.5 13.6563 12.5H17.25C17.7793 12.5 18.2083 12.9477 18.2083 13.5C18.2083 14.0523 17.7793 14.5 17.25 14.5H13.6563C13.127 14.5 12.6979 14.0523 12.6979 13.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27462 10C7.61303 10 7.0767 10.5596 7.0767 11.25C7.0767 11.9404 7.61303 12.5 8.27462 12.5C8.93621 12.5 9.47253 11.9404 9.47253 11.25C9.47253 10.5596 8.93621 10 8.27462 10ZM5.16003 11.25C5.16003 9.45507 6.55448 8 8.27462 8C9.99475 8 11.3892 9.45507 11.3892 11.25C11.3892 13.0449 9.99475 14.5 8.27462 14.5C6.55448 14.5 5.16003 13.0449 5.16003 11.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27456 14.5C7.84954 14.5 7.43656 14.6474 7.10052 14.9189C6.76448 15.1905 6.52442 15.5708 6.41805 16.0002C6.2856 16.5349 5.76281 16.8564 5.25038 16.7181C4.73795 16.5799 4.42992 16.0344 4.56237 15.4997C4.77505 14.6411 5.25506 13.8806 5.92698 13.3376C6.5989 12.7947 7.42465 12.5 8.27449 12.5C9.12434 12.5 9.95011 12.7946 10.6221 13.3375C11.294 13.8804 11.7741 14.6409 11.9868 15.4994C12.1193 16.0341 11.8113 16.5797 11.2989 16.7179C10.7865 16.8562 10.2637 16.5348 10.1312 16.0001C10.0248 15.5707 9.78469 15.1904 9.44863 14.9188C9.11257 14.6473 8.69958 14.5 8.27456 14.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83334 5.5V18.5H19.1667V5.5H3.83334ZM1.91667 5.25C1.91667 4.2835 2.66753 3.5 3.59376 3.5H19.4063C20.3325 3.5 21.0833 4.2835 21.0833 5.25V18.75C21.0833 19.7165 20.3325 20.5 19.4063 20.5H3.59376C2.66753 20.5 1.91667 19.7165 1.91667 18.75V5.25Z"
      />
    </svg>
  );
}

export default SvgIdentity;
