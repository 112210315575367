import * as React from 'react';

function SvgOrganisation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4074 2.51098C2.72192 2.18279 3.14849 1.99841 3.59328 1.99841H12.2183C12.6631 1.99841 13.0896 2.18279 13.4042 2.51098C13.7187 2.83917 13.8954 3.28429 13.8954 3.74841V20.9984C13.8954 21.5507 13.4663 21.9984 12.937 21.9984C12.4078 21.9984 11.9787 21.5507 11.9787 20.9984V3.99841H3.83287V20.9984C3.83287 21.5507 3.4038 21.9984 2.87453 21.9984C2.34526 21.9984 1.9162 21.5507 1.9162 20.9984V3.74841C1.9162 3.28428 2.09289 2.83917 2.4074 2.51098Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9787 8.99841C11.9787 8.44613 12.4078 7.99841 12.937 7.99841H19.4058C19.8506 7.99841 20.2771 8.18279 20.5917 8.51098C20.9062 8.83917 21.0829 9.28429 21.0829 9.74841V20.9984C21.0829 21.5507 20.6538 21.9984 20.1245 21.9984C19.5953 21.9984 19.1662 21.5507 19.1662 20.9984V9.99841H12.937C12.4078 9.99841 11.9787 9.5507 11.9787 8.99841Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7912 6.74841C4.7912 6.19613 5.22026 5.74841 5.74953 5.74841H8.62453C9.15381 5.74841 9.58287 6.19613 9.58287 6.74841C9.58287 7.3007 9.15381 7.74841 8.62453 7.74841H5.74953C5.22026 7.74841 4.7912 7.3007 4.7912 6.74841Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.2287 14.2484C6.2287 13.6961 6.65776 13.2484 7.18703 13.2484H10.062C10.5913 13.2484 11.0204 13.6961 11.0204 14.2484C11.0204 14.8007 10.5913 15.2484 10.062 15.2484H7.18703C6.65776 15.2484 6.2287 14.8007 6.2287 14.2484Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7912 17.9984C4.7912 17.4461 5.22026 16.9984 5.74953 16.9984H8.62453C9.15381 16.9984 9.58287 17.4461 9.58287 17.9984C9.58287 18.5507 9.15381 18.9984 8.62453 18.9984H5.74953C5.22026 18.9984 4.7912 18.5507 4.7912 17.9984Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8537 16.4984C14.8537 15.9461 15.2828 15.4984 15.812 15.4984H17.2495C17.7788 15.4984 18.2079 15.9461 18.2079 16.4984C18.2079 17.0507 17.7788 17.4984 17.2495 17.4984H15.812C15.2828 17.4984 14.8537 17.0507 14.8537 16.4984Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8537 12.7484C14.8537 12.1961 15.2828 11.7484 15.812 11.7484H17.2495C17.7788 11.7484 18.2079 12.1961 18.2079 12.7484C18.2079 13.3007 17.7788 13.7484 17.2495 13.7484H15.812C15.2828 13.7484 14.8537 13.3007 14.8537 12.7484Z"
      />
    </svg>
  );
}

export default SvgOrganisation;
