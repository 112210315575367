import * as React from 'react';

function SvgEmptyuseraccount(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.5C10.4812 8.5 9.25 9.73122 9.25 11.25C9.25 12.7688 10.4812 14 12 14C13.5188 14 14.75 12.7688 14.75 11.25C14.75 9.73122 13.5188 8.5 12 8.5ZM7.25 11.25C7.25 8.62665 9.37665 6.5 12 6.5C14.6234 6.5 16.75 8.62665 16.75 11.25C16.75 13.8734 14.6234 16 12 16C9.37665 16 7.25 13.8734 7.25 11.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 16C10.9383 16 9.89742 16.2938 8.99244 16.8488C8.08747 17.4038 7.35373 18.1984 6.87244 19.1447C6.62206 19.6369 6.02003 19.833 5.52776 19.5827C5.03549 19.3323 4.8394 18.7302 5.08978 18.238C5.73839 16.9627 6.72723 15.8919 7.94683 15.1439C9.16644 14.3959 10.5692 14 11.9999 14C13.4306 14 14.8334 14.3959 16.053 15.1439C17.2726 15.8918 18.2615 16.9627 18.9101 18.2379C19.1605 18.7302 18.9644 19.3322 18.4721 19.5826C17.9799 19.833 17.3778 19.6369 17.1274 19.1446C16.6462 18.1984 15.9124 17.4038 15.0074 16.8488C14.1025 16.2938 13.0616 16 11.9999 16Z"
      />
    </svg>
  );
}

export default SvgEmptyuseraccount;
