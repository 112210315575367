export const Select = {
  baseStyle: ({ theme }) => ({
    field: {
      fontFamily: theme.fonts.body1,
      height: '5rem',
      border: 'none',
      borderRadius: '0',
      width: '100%',
      fontSize: theme.fontSizes.body1,
      lineHeight: theme.lineHeights.body1,
      color: theme.colors.ink.dark,
    },
    icon: {
      fontSize: '2.5rem',
      width: '2.5rem',
    },
  }),
  sizes: {
    md: ({ theme }) => ({
      fontSize: theme.fontSizes.body1,
      lineHeight: theme.lineHeights.body1,
      border: 'none',
      borderRadius: '0',
    }),
    sm: ({ theme }) => ({
      field: {
        fontSize: theme.fontSizes.body1,
        lineHeight: theme.lineHeights.body1,
        border: 'none',
        borderRadius: '0',
      },
      icon: {
        top: '60%',
        fontSize: '2rem',
        width: '2rem',
      },
    }),
  },
  defaultProps: {
    icon: {
      fontSize: '2.5rem',
      width: '2.5rem',
    },
  },
};
