import { ChakraTheme, ThemeComponents } from '@chakra-ui/react';
import { borders } from './borders';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { Accordion } from './components/accordion';
import { Alert } from './components/alert';
import { Badge } from './components/badge';
import { Breadcrumb } from './components/breadcrumb';
import { Button } from './components/buttons';
import { Checkbox } from './components/checkbox';
import { Drawer } from './components/drawer';
import { Heading } from './components/heading';
import { Input } from './components/input';
import { Link } from './components/link';
import { Modal } from './components/modal';
import { Popover } from './components/popover';
import { Progress } from './components/progress';
import { Radio } from './components/radio';
import { Select } from './components/select';
import { Spinner } from './components/spinner';
import { Switch } from './components/switch';
import { Tag } from './components/tag';
import { Text } from './components/text';
import { fontSizes } from './font-sizes';
import { fonts } from './fonts/global-fonts';
import { icons } from './icons';
import { images } from './images';
import { lineHeights } from './line-heights';
import { shadows } from './shadows';
import { sizes } from './sizes';

export * from './breakpoints';
export * from './fonts/global-fonts';
export * from './global-styles';

export const DefaultTheme: Partial<ChakraTheme> & {
  images: typeof images;
  icons: typeof icons;
} = {
  components: {
    Accordion,
    Badge,
    Button,
    Breadcrumb: Breadcrumb as ThemeComponents,
    Drawer,
    Heading,
    Checkbox,
    Input,
    Link,
    Modal,
    Popover,
    Radio: Radio as ThemeComponents,
    Progress: Progress as ThemeComponents,
    Select,
    Switch,
    Tag: Tag as ThemeComponents,
    Text,
    Alert: Alert as ThemeComponents,
    Spinner: Spinner as ThemeComponents,
  },
  images,
  borders,
  colors,
  icons,
  fonts,
  fontSizes,
  lineHeights,
  breakpoints,
  shadows,
  sizes,
};
