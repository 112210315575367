import { Box, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { FileIcon } from '../../file';
import { FileExtensionType } from '../thumbnail.constant';

type ThumbnailLinkProps = {
  title: string;
  fileURL: string;
};

export const ThumbnailLink = ({ title, fileURL }: ThumbnailLinkProps) => {
  return (
    <Link
      width="18rem"
      display="inline-block"
      textAlign="center"
      title={title}
      href={fileURL}
      isExternal
    >
      <Box width="12rem" margin="0 auto">
        <FileIcon type={FileExtensionType.PDF.toString().substr(-3)} />
      </Box>
      <Text size="Small">{title}</Text>
    </Link>
  );
};
