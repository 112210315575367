/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type BudgetType =
  | 'MAIN'
  | 'MOBILITY'
  | 'BONUS'
  | 'PRO'
  | 'UNCATEGORIZED'
  | 'KM_ALLOWANCE'
  | 'COMMUTE';

export const BudgetType = {
  MAIN: 'MAIN' as BudgetType,
  MOBILITY: 'MOBILITY' as BudgetType,
  BONUS: 'BONUS' as BudgetType,
  PRO: 'PRO' as BudgetType,
  UNCATEGORIZED: 'UNCATEGORIZED' as BudgetType,
  KM_ALLOWANCE: 'KM_ALLOWANCE' as BudgetType,
  COMMUTE: 'COMMUTE' as BudgetType,
};
