import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { FieldMetaProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputLabel } from '../form.style';
import { PasswordValidation } from './form-control-password-validation';

type FormControlProps = ChakraFormControlProps & {
  meta: FieldMetaProps<any>;
  isEmpty: boolean;
  errorLeftAlignement?: string;
  isPassword?: boolean;
  hasValidation?: boolean;
};

export const FormControl = (props: FormControlProps) => {
  const {
    children,
    isEmpty,
    meta: { error, touched },
    id,
    label,
    my = 4,
    errorLeftAlignement = '0',
    isPassword,
    hasValidation,
    ...rest
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const translateError = (error: string | string[]) => {
    // some fields return multiple errors (like password field)
    if (Array.isArray(error)) {
      return error.map((err) => t(err));
    }
    return t(error);
  };

  const errors = translateError(error);
  const hasPasswordValidation = isPassword && hasValidation;

  return (
    <ChakraFormControl
      id={id}
      isInvalid={hasPasswordValidation ? !!error : !!error && touched}
      my={my}
      {...rest}
    >
      <InputGroup>
        {children}
        {label && <InputLabel isEmpty={isEmpty}>{label}</InputLabel>}
      </InputGroup>
      {((error && !isPassword) || (error && isPassword && !hasValidation)) && (
        <FormErrorMessage ml={errorLeftAlignement} mt="0">
          <Text size="Small" color={theme.colors.status.negative.type}>
            {errors}
          </Text>
        </FormErrorMessage>
      )}

      <PasswordValidation
        active={hasPasswordValidation}
        isEmpty={isEmpty}
        errorLeftAlignement={errorLeftAlignement}
        error={error}
      />
    </ChakraFormControl>
  );
};
