import {
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  FormLabel,
  FormLabelProps,
  Input,
  Select,
} from '@chakra-ui/react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import React from 'react';

export const InputGroup = styled.div`
  position: relative;
  margin-top: 0;
  width: 100%;
`;

export const InputStyledField = styled<any>(Input)`
  color: ${({ theme }) => theme.colors.ink.black};
  height: 5rem;
  padding: 2rem 0 0.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  border: none;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.ink.medium};
  /* active state */
  &:focus ~ .bar:before,
  &:focus ~ .bar:after {
    width: 50%;
  }
  &:-internal-autofill-selected {
    background-color: transparent !important;
  }
  &:-webkit-autofill,
  &:focus:-webkit-autofill {
    box-shadow: 0 0 0 10rem ${({ theme }) => theme.colors.background.page} inset;
  }
  &:focus {
    outline: none;
  }
  &[aria-invalid='true'],
  &[data-invalid] {
    border-color: none;
    box-shadow: none;
  }

  &:-webkit-autofill {
    animation-name: inputHighlighter;
    animation-duration: 5000s;
    -webkit-box-shadow: 0 0 0 10rem
      ${({ theme }) => theme.colors.background.page} inset;
    box-shadow: 0 0 0 10rem ${({ theme }) => theme.colors.background.page} inset;
  }
`;

export const SelectStyledField = styled(
  InputStyledField.withComponent<any>(Select),
  {
    shouldForwardProp: isPropValid,
  },
)`
  padding: 2rem 2.5rem 0.6rem 0;
  height: 5rem;
`;

export const InputButton = styled((props: ButtonProps) => (
  <Button {...props} />
))`
  margin-top: 1.8rem;
  svg {
    width: 2rem;
    height: 2rem;
    color: ${({ theme }) => theme.colors.ink.dark};
  }
`;

export const SwitchFlex = styled((props: FlexProps) => <Flex {...props} />)`
  height: 5rem;
`;

export const InputSwitchLabel = styled(
  (props: FormLabelProps) => <FormLabel {...props} />,
  {
    shouldForwardProp: isPropValid,
  },
)`
  height: 5rem;
  line-height: 5rem;
  font-size: 1.7rem;
`;

export const InputLabel = styled(
  (props: FormLabelProps & { isEmpty?: boolean }) => <FormLabel {...props} />,
  {
    shouldForwardProp: isPropValid,
  },
)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  z-index: 5;
  margin: 0;
  position: absolute;
  font-size: 1.7rem;
  font-weight: normal;
  top: 50%;
  pointer-events: none;
  white-space: nowrap;
  transition: 0.2s ease all;
  color: ${({ theme }) => theme.colors.ink.medium};
  left: 0;
  transform-origin: left top;
  transform: translateY(${({ isEmpty }) => (isEmpty ? '-50%' : '-106%')})
    scale(${({ isEmpty }) => (isEmpty ? '1' : '0.75')});
  &[data-focus] {
    transform: translateY(-106%) scale(0.75);
  }
`;

export const InputHighLight = styled.span`
  position: absolute;
  height: 60%;
  width: 100%;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
`;

export const InputBar = styled.span`
  position: relative;
  display: block;
  width: 100%;
  &:before,
  &:after {
    content: '';
    height: 0.2rem;
    width: 0;
    bottom: 0.1rem;
    position: absolute;
    background: ${({ theme }) => theme.colors.main.secondary.lighter};
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    &:before {
      left: 50%;
    }
    &:after {
      right: 50%;
    }
  }
`;
