import * as React from 'react';

function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79289 8.29289C4.18342 7.90237 4.81658 7.90237 5.20711 8.29289L12 15.0858L18.7929 8.29289C19.1834 7.90237 19.8166 7.90237 20.2071 8.29289C20.5976 8.68342 20.5976 9.31658 20.2071 9.70711L12.7071 17.2071C12.3166 17.5976 11.6834 17.5976 11.2929 17.2071L3.79289 9.70711C3.40237 9.31658 3.40237 8.68342 3.79289 8.29289Z"
      />
    </svg>
  );
}

export default SvgArrowDown;
