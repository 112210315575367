/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  ShareholdersGetResponse,
  ShareholdersGetParams,
  Shareholder,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const shareholdersGet = <Data = unknown>(
  params?: ShareholdersGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ShareholdersGetResponse : Data>(
    { url: `/shareholders`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getShareholdersGetQueryKey = (params?: ShareholdersGetParams) => [
  `/shareholders`,
  ...(params ? [params] : []),
];

export const useShareholdersGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ShareholdersGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof shareholdersGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getShareholdersGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof shareholdersGet>, Error>(
    queryKey,
    () => shareholdersGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const shareholderCreate = <Data = unknown>(
  shareholder: Shareholder,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Shareholder : Data>(
    { url: `/shareholders`, method: 'post', data: shareholder },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useShareholderCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof shareholderCreate>,
    Error,
    { data: Shareholder },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof shareholderCreate>,
    Error,
    { data: Shareholder }
  >((props) => {
    const { data } = props || {};

    return shareholderCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const shareholderGet = <Data = unknown>(
  shareholderid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Shareholder : Data>(
    { url: `/shareholders/${shareholderid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getShareholderGetQueryKey = (shareholderid: string) => [
  `/shareholders/${shareholderid}`,
];

export const useShareholderGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  shareholderid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof shareholderGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getShareholderGetQueryKey(shareholderid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof shareholderGet>, Error>(
    queryKey,
    () => shareholderGet<Data>(shareholderid, requestOptions),
    { enabled: !!shareholderid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const shareholderUpdate = <Data = unknown>(
  shareholderid: string,
  shareholder: Shareholder,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Shareholder : Data>(
    { url: `/shareholders/${shareholderid}`, method: 'put', data: shareholder },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useShareholderUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof shareholderUpdate>,
    Error,
    { shareholderid: string; data: Shareholder },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof shareholderUpdate>,
    Error,
    { shareholderid: string; data: Shareholder }
  >((props) => {
    const { shareholderid, data } = props || {};

    return shareholderUpdate<Data>(shareholderid, data, requestOptions);
  }, mutationOptions);
};
export const shareholderDelete = <Data = unknown>(
  shareholderid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/shareholders/${shareholderid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useShareholderDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof shareholderDelete>,
    Error,
    { shareholderid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof shareholderDelete>,
    Error,
    { shareholderid: string }
  >((props) => {
    const { shareholderid } = props || {};

    return shareholderDelete<Data>(shareholderid, requestOptions);
  }, mutationOptions);
};
