import { Status } from '@libs/api/models';
import { TagStatus } from '@libs/ui/components';

export const StatusToTagStatus = {
  [Status.REFUSED]: TagStatus.NEGATIVE,
  [Status.CANCELED]: TagStatus.NEGATIVE,
  [Status.VALIDATED]: TagStatus.POSITIVE,
  [Status.CONFIRMED]: TagStatus.POSITIVE,
  [Status.CREATED]: TagStatus.CREATED,
  [Status.IN_REVIEW]: TagStatus.PENDING,
  [Status.SUGGESTED]: TagStatus.NONE,
  [Status.STARTED]: TagStatus.STARTED,
};
