import { Box, BoxProps } from '@chakra-ui/react';
import React, { createContext, ReactNode, useState } from 'react';

type TableRowProps = BoxProps & {
  children: ReactNode;
  selectable?: boolean;
  index?: string;
};

type TableRowContextState = {
  selectable?: boolean;
  over: string;
  setOver: React.Dispatch<React.SetStateAction<string>>;
};
export const TableRowContext = createContext<TableRowContextState>(null);

export const TableRow = ({
  children,
  selectable,
  index,
  ...rest
}: TableRowProps) => {
  const [over, setOver] = useState<string>(null);
  return selectable ? (
    <TableRowContext.Provider value={{ selectable, over, setOver }}>
      <Box
        as="tr"
        my={1}
        transition="ease .4s all"
        _hover={{ bgColor: 'background.table' }}
        cursor="pointer"
        onMouseEnter={() => setOver(index)}
        onMouseLeave={() => setOver(null)}
        {...rest}
      >
        {children}
      </Box>
    </TableRowContext.Provider>
  ) : (
    <Box as="tr" my={1} {...rest}>
      {children}
    </Box>
  );
};
