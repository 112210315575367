import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { useBudgetsGet } from '@libs/api/endpoints';
import { Registration } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { usePager } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetCreateDrawer } from './budget-create-drawer';
import { BudgetTable } from './budget-table';

type BudgetsTableProps = {
  employee: Registration;
};

export const EmployeeDetailsBudgets = ({ employee }: BudgetsTableProps) => {
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const params = {
    membership_id: employee.membership_id,
    page_number: pageNumber,
    page_size: pageSize,
    // TODO enable sort & filters when endpoints are updated
    // sort: currentSortQuery.length > 0 ? currentSortQuery.toString() : null,
    // is_carry_over_enabled:
    //   (filterQuery.length > 0 &&
    //     filterQuery.find('is_carry_over_enabled').split(':')[1]) ||
    //   null,
    // type:
    //   (filterQuery.length > 0 && filterQuery.find('type').split(':')[1]) ||
    //   null,
  };
  const {
    isLoading,
    isFetching,
    refetch,
    data: budgets,
  } = useBudgetsGet(params, { query: { keepPreviousData: true } });

  return (
    <Flex direction="column">
      <Button
        variant="primary"
        size="body1"
        alignSelf="flex-end"
        mt="1rem"
        onClick={onOpen}
      >
        {t(i18nKeys.operator.employees.budgets.add_budget)}
      </Button>
      <BudgetTable
        isLoading={isLoading}
        isFetching={isFetching}
        budgets={budgets}
        refetch={refetch}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageSize={setPageSize}
        budgetsGetParams={params}
        employee={employee}
      />
      {isOpen && (
        <BudgetCreateDrawer
          isOpen={isOpen}
          onClose={onClose}
          employee={employee}
          budgetsGetParams={params}
        />
      )}
    </Flex>
  );
};
