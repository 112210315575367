/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type KYCStatus =
  | 'NONE'
  | 'PENDING'
  | 'REFUSED'
  | 'VALIDATED'
  | 'CANCELED';

export const KYCStatus = {
  NONE: 'NONE' as KYCStatus,
  PENDING: 'PENDING' as KYCStatus,
  REFUSED: 'REFUSED' as KYCStatus,
  VALIDATED: 'VALIDATED' as KYCStatus,
  CANCELED: 'CANCELED' as KYCStatus,
};
