import { Text } from '@chakra-ui/layout';
import { MembershipSummariesGetResponse } from '@libs/api-v2/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { Currency, Table } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer } from '@libs/ui/components/table/table.utils';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult, RefetchOptions } from 'react-query';

type MembershipSummariesTableProps = {
  membershipSummaries: MembershipSummariesGetResponse;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<MembershipSummariesGetResponse, unknown>>;
  isLoading: boolean;
  isFetching: boolean;
};

export const MembershipSummariesTable = ({
  membershipSummaries,
  refetch,
  isLoading,
  isFetching,
}: MembershipSummariesTableProps) => {
  const { t } = useTranslation();
  const EyeIcon = useIcon('Eye');
  const EditIcon = useIcon('Edit');
  const AddIcon = useIcon('Add');
  const {
    drawerHandler,
    isOpen,
    onClose,
    activeDrawerIndex,
    selectedDrawerType,
  } = useDrawer();

  const headers: TableHeaders = [
    {
      name: 'title',
      title: t(i18nKeys.operator.employees.budgets.type.title),
    },
    {
      name: 'budget_limit',
      title: t(i18nKeys.operator.employees.budgets.total),
    },
    {
      name: 'used',
      title: t(i18nKeys.common.used),
    },
    {
      name: 'remaining',
      title: t(i18nKeys.operator.employees.budgets.remaining),
    },
    {
      name: 'start_date',
      title: t(i18nKeys.common.start_at),
    },
    {
      name: 'end_date',
      title: t(i18nKeys.common.end_at),
    },
    {
      name: 'is_unlimited',
      title: t(i18nKeys.operator.employees.budgets.is_unlimited.title),
      filter: true,
      filterValues: [true.toString(), false.toString()],
      filterValuesI18nKey:
        i18nKeys.operator.employees.budgets.is_carry_over_enabled,
    },
    {
      name: 'is_current',
      title: t(i18nKeys.operator.employees.budgets.is_current.title),
      filter: true,
      filterValues: [true.toString(), false.toString()],
      filterValuesI18nKey:
        i18nKeys.operator.employees.budgets.is_carry_over_enabled,
    },
    '',
  ];

  return (
    <Table headers={headers}>
      <Table.TBody
        isLoading={(isLoading || isFetching) && !membershipSummaries}
        colSpan={headers.length}
        isEmpty={
          !isLoading &&
          !isFetching &&
          (membershipSummaries?.length === 0 || !membershipSummaries)
        }
      >
        {membershipSummaries?.map(
          (
            {
              group_id,
              program_name,
              budget_limit,
              used,
              remaining,
              start_date,
              end_date,
              is_unlimited,
              is_current,
            },
            index,
          ) => {
            return (
              <Fragment key={`${group_id}`}>
                {' '}
                {/*FIXME: add from api membership summary id*/}
                <Table.TR
                  selectable
                  position="relative"
                  transform="scale(1,1)"
                  onClick={() => drawerHandler(index)}
                  index={group_id}
                >
                  <Table.TD>
                    <Text>{program_name}</Text>
                  </Table.TD>

                  <Table.TD>
                    <Currency
                      size="Body2Bold"
                      textOptions={{
                        color:
                          budget_limit > 0
                            ? 'status.positive.type'
                            : 'ink.dark',
                      }}
                    >
                      {budget_limit}
                    </Currency>
                  </Table.TD>

                  <Table.TD>
                    <Currency
                      size="Body2Bold"
                      textOptions={{
                        color: used > 0 ? 'status.positive.type' : 'ink.dark',
                      }}
                    >
                      {used}
                    </Currency>
                  </Table.TD>

                  <Table.TD>
                    <Currency
                      size="Body2Bold"
                      textOptions={{
                        color:
                          remaining > 0 ? 'status.positive.type' : 'ink.dark',
                      }}
                    >
                      {remaining}
                    </Currency>
                  </Table.TD>

                  <Table.Date>{start_date}</Table.Date>
                  <Table.Date>{end_date}</Table.Date>
                  <Table.TD>
                    {t(
                      is_unlimited
                        ? i18nKeys.common.true
                        : i18nKeys.common.false,
                    )}
                  </Table.TD>
                  <Table.TD>
                    {t(
                      is_current ? i18nKeys.common.true : i18nKeys.common.false,
                    )}
                  </Table.TD>
                </Table.TR>
              </Fragment>
            );
          },
        )}
      </Table.TBody>
    </Table>
  );
};
