import {
  Button,
  Center,
  Flex,
  Radio,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import {
  getOrganisationsGetQueryKey,
  organisationCreate,
  organisationsGet,
} from '@libs/api/endpoints';
import { EntityType, OrganisationCreateBody } from '@libs/api/models';
import { i18nCountryKeys, i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils';
import {
  DateField,
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  InputField,
  RadioGroupField,
  SelectField,
  SwitchField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { validationCreateOrganisationSchema } from '../organisation.validations';

type CreateOrganisationDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateOrganisationDrawer = ({
  isOpen,
  onClose,
}: CreateOrganisationDrawerProps) => {
  const { t } = useTranslation();
  return (
    <Drawer
      title={t(i18nKeys.operator.dashboard.add_organisation)}
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
    >
      <CreateOrganisationForm onClose={onClose} />
    </Drawer>
  );
};

type CreateOrganisationFormProps = {
  onClose: () => void;
};

const CreateOrganisationForm = ({ onClose }: CreateOrganisationFormProps) => {
  const { t } = useTranslation();
  const { t: tCountry } = useTranslation('countries');
  const theme = useTheme();
  const queryClient = useQueryClient();

  const toast = useToast();

  const { mutateAsync: submit, isLoading } = useMutation(
    async ({ values }: FormSubmitData<OrganisationCreateBody>) => {
      return organisationCreate(values);
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: t(i18nKeys.operator.dashboard.add_organisation_success.title),
          content: t(
            i18nKeys.operator.dashboard.add_organisation_success.content,
          ),
        });
        queryClient.fetchQuery(getOrganisationsGetQueryKey(), () =>
          organisationsGet(),
        );
      },
    },
  );

  return (
    <Formik<OrganisationCreateBody>
      initialValues={{
        vat_number: '',
        email: '',
        country_code: i18nCountryKeys.BELGIUM,
        is_freelancer: false,
        use_mobility_budget: false,
        use_payment_card: true,
        entity_type: EntityType.ACTIVE_NON_FINANCIAL_ENTITY_OTHER,
        force_creation: false,
        legal_name: '',
        legal_form: '',
        legal_sector: '',
        registration_date: '',
        address: {
          street: '',
          postal_code: '',
          city: '',
          country_code: i18nCountryKeys.BELGIUM,
        },
      }}
      validate={validateSchema(validationCreateOrganisationSchema)}
      onSubmit={handleFormSubmit(submit)}
    >
      {({ isSubmitting, values, isValid, dirty }) => (
        <>
          <Drawer.Body>
            <Text color={theme.colors.ink.dark} mt="2rem" size="body2">
              {t(i18nKeys.operator.dashboard.add_organisation_description)}
            </Text>
            <Form noValidate id="create-organisation-form">
              <InputField
                name="vat_number"
                label={t(i18nKeys.common.vat)}
                isRequired
              />
              <SelectField
                name="entity_type"
                label={t(i18nKeys.common.entity_type_label)}
                isRequired
              >
                {Object.values(EntityType).map((entityType) => (
                  <option value={entityType} key={entityType}>
                    {t(i18nKeys.common.entity_type[entityType])}
                  </option>
                ))}
              </SelectField>
              <InputField
                name="email"
                label={t(i18nKeys.common.email)}
                isRequired
              />
              <SelectField
                name="country_code"
                label={t(i18nKeys.common.country)}
                isRequired
              >
                {Object.values(i18nCountryKeys).map((code) => (
                  <option value={code} key={code}>
                    {tCountry(code)}
                  </option>
                ))}
              </SelectField>
              <SwitchField
                mt="2rem"
                name="use_mobility_budget"
                label={t(i18nKeys.organisation.use_mobility_budget)}
                isRequired
              />
              <SwitchField
                name="use_payment_card"
                label={t(i18nKeys.organisation.use_payment_card)}
                isRequired
              />
              <RadioGroupField
                label={t(i18nKeys.operator.organisations.force_creation)}
                name="force_creation"
                direction="column"
                isBoolean
              >
                <Stack direction="row" spacing="3rem" mt="-2rem">
                  <Radio value="true">{t(i18nKeys.common.yes)}</Radio>
                  <Radio value="false">{t(i18nKeys.common.no)}</Radio>
                </Stack>
              </RadioGroupField>
              {values.force_creation ? (
                <>
                  <InputField
                    name="legal_name"
                    label={t(i18nKeys.common.legal_name)}
                    isRequired
                  />
                  <InputField
                    name="legal_form"
                    label={t(i18nKeys.common.legal_form)}
                    isRequired
                  />
                  <InputField
                    name="legal_sector"
                    label={t(i18nKeys.common.legal_sector)}
                    isRequired
                  />
                  <DateField
                    name="registration_date"
                    label={t(i18nKeys.common.legal_registration_date)}
                    isRequired
                  />
                  <InputField
                    name="address.street"
                    label={t(i18nKeys.common.address)}
                    isRequired
                  />
                  <Stack direction="row">
                    <InputField
                      name="address.city"
                      label={t(i18nKeys.common.city)}
                      isRequired
                    />
                    <Center width="100px">
                      <InputField
                        name="address.postal_code"
                        label={t(i18nKeys.common.postal_code)}
                        isRequired
                      />
                    </Center>
                  </Stack>
                  <InputField
                    name="address.country_code"
                    label={t(i18nKeys.common.country_code)}
                    isRequired
                    display="none"
                  />
                </>
              ) : null}
            </Form>
          </Drawer.Body>
          <Drawer.Footer>
            <Flex justify="space-between" width="100%">
              <Button size="body2" variant="border" onClick={onClose}>
                {t(i18nKeys.common.cancel)}
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="body2"
                isLoading={isSubmitting}
                form="create-organisation-form"
                isDisabled={isLoading || !dirty || !isValid}
              >
                {t(i18nKeys.common.submit)}
              </Button>
            </Flex>
          </Drawer.Footer>
        </>
      )}
    </Formik>
  );
};
