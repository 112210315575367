import React from 'react';
import { Box, Flex, Image, useTheme, Heading } from '@chakra-ui/react';
import { Provider } from '@libs/api-v2/models/provider';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type IProviders = {
  provider_name: string;
  provider_url: string;
};
type ServiceProvidersProps = {
  providers: Provider[] | undefined;
};
export const ServiceProviders = ({ providers }: ServiceProvidersProps) => {
  // Attributes
  const { t } = useTranslation();
  const theme = useTheme();
  const listOfProviders = useMemo(() => {
    return providers?.map((provider) => (
      <Flex direction="row" alignItems="center" mb={4}>
        <Image
          w={'2.125rem'}
          h={'2.125rem'}
          borderRadius={1000}
          src={provider.icon_url}
        />
        <Heading ml={2} size="400">
          {provider.name}
        </Heading>
      </Flex>
    ));
  }, [providers]);

  return (
    <Box>
      <Heading size="600" mb={6} mt={6}>
        {t(i18nKeys.operator.services.providers_title)}
      </Heading>
      <Flex mt={8} direction="column">
        {listOfProviders}
      </Flex>
    </Box>
  );
};
