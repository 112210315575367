/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  OrganisationsGetResponse,
  OrganisationsGetParams,
  Organisation,
  OrganisationCreateBody,
  OrganisationUpdateBody,
  OrganisationRefusalReasonGetParams,
  OrganisationRefusalReasonRequestCreate,
  OrganisationsStatisticsGetResponse,
  OrganisationsStatisticsGetParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const organisationsGet = <Data = unknown>(
  params?: OrganisationsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? OrganisationsGetResponse : Data>(
    { url: `/organisations`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getOrganisationsGetQueryKey = (
  params?: OrganisationsGetParams,
) => [`/organisations`, ...(params ? [params] : [])];

export const useOrganisationsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: OrganisationsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof organisationsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getOrganisationsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof organisationsGet>, Error>(
    queryKey,
    () => organisationsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const organisationCreate = <Data = unknown>(
  organisationCreateBody: OrganisationCreateBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Organisation : Data>(
    { url: `/organisations`, method: 'post', data: organisationCreateBody },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useOrganisationCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof organisationCreate>,
    Error,
    { data: OrganisationCreateBody },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof organisationCreate>,
    Error,
    { data: OrganisationCreateBody }
  >((props) => {
    const { data } = props || {};

    return organisationCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const organisationGet = <Data = unknown>(
  organisationid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Organisation : Data>(
    { url: `/organisations/${organisationid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getOrganisationGetQueryKey = (organisationid: string) => [
  `/organisations/${organisationid}`,
];

export const useOrganisationGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  organisationid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof organisationGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getOrganisationGetQueryKey(organisationid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof organisationGet>, Error>(
    queryKey,
    () => organisationGet<Data>(organisationid, requestOptions),
    { enabled: !!organisationid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const organisationUpdate = <Data = unknown>(
  organisationid: string,
  organisationUpdateBody: OrganisationUpdateBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Organisation : Data>(
    {
      url: `/organisations/${organisationid}`,
      method: 'put',
      data: organisationUpdateBody,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useOrganisationUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof organisationUpdate>,
    Error,
    { organisationid: string; data: OrganisationUpdateBody },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof organisationUpdate>,
    Error,
    { organisationid: string; data: OrganisationUpdateBody }
  >((props) => {
    const { organisationid, data } = props || {};

    return organisationUpdate<Data>(organisationid, data, requestOptions);
  }, mutationOptions);
};
export const organisationDelete = <Data = unknown>(
  organisationid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/organisations/${organisationid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useOrganisationDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof organisationDelete>,
    Error,
    { organisationid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof organisationDelete>,
    Error,
    { organisationid: string }
  >((props) => {
    const { organisationid } = props || {};

    return organisationDelete<Data>(organisationid, requestOptions);
  }, mutationOptions);
};
export const organisationBankReview = <Data = unknown>(
  organisationid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Organisation : Data>(
    {
      url: `/organisations/${organisationid}/bank-review`,
      method: 'put',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useOrganisationBankReview = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof organisationBankReview>,
    Error,
    { organisationid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof organisationBankReview>,
    Error,
    { organisationid: string }
  >((props) => {
    const { organisationid } = props || {};

    return organisationBankReview<Data>(organisationid, requestOptions);
  }, mutationOptions);
};
export const organisationInternalReview = <Data = unknown>(
  organisationid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Organisation : Data>(
    {
      url: `/organisations/${organisationid}/internal-review`,
      method: 'put',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useOrganisationInternalReview = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof organisationInternalReview>,
    Error,
    { organisationid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof organisationInternalReview>,
    Error,
    { organisationid: string }
  >((props) => {
    const { organisationid } = props || {};

    return organisationInternalReview<Data>(organisationid, requestOptions);
  }, mutationOptions);
};
export const organisationRefusalReasonGet = <Data = unknown>(
  organisationid: string,
  params?: OrganisationRefusalReasonGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? OrganisationsGetResponse : Data>(
    {
      url: `/organisations/${organisationid}/refusal-reasons`,
      method: 'get',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getOrganisationRefusalReasonGetQueryKey = (
  organisationid: string,
  params?: OrganisationRefusalReasonGetParams,
) => [
  `/organisations/${organisationid}/refusal-reasons`,
  ...(params ? [params] : []),
];

export const useOrganisationRefusalReasonGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  organisationid: string,
  params?: OrganisationRefusalReasonGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof organisationRefusalReasonGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getOrganisationRefusalReasonGetQueryKey(
    organisationid,
    params,
  );
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof organisationRefusalReasonGet>,
    Error
  >(
    queryKey,
    () =>
      organisationRefusalReasonGet<Data>(
        organisationid,
        params,
        requestOptions,
      ),
    { enabled: !!organisationid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const organisationRefusalReasonPost = <Data = unknown>(
  organisationid: string,
  organisationRefusalReasonRequestCreate: OrganisationRefusalReasonRequestCreate,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    {
      url: `/organisations/${organisationid}/refusal-reasons`,
      method: 'post',
      data: organisationRefusalReasonRequestCreate,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useOrganisationRefusalReasonPost = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof organisationRefusalReasonPost>,
    Error,
    { organisationid: string; data: OrganisationRefusalReasonRequestCreate },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof organisationRefusalReasonPost>,
    Error,
    { organisationid: string; data: OrganisationRefusalReasonRequestCreate }
  >((props) => {
    const { organisationid, data } = props || {};

    return organisationRefusalReasonPost<Data>(
      organisationid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const organisationsStatisticsGet = <Data = unknown>(
  params?: OrganisationsStatisticsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? OrganisationsStatisticsGetResponse : Data
  >(
    { url: `/organisations/statistics`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getOrganisationsStatisticsGetQueryKey = (
  params?: OrganisationsStatisticsGetParams,
) => [`/organisations/statistics`, ...(params ? [params] : [])];

export const useOrganisationsStatisticsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: OrganisationsStatisticsGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof organisationsStatisticsGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getOrganisationsStatisticsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof organisationsStatisticsGet>,
    Error
  >(
    queryKey,
    () => organisationsStatisticsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
