/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type NumberOfEmployeeRange =
  | 'less_ten'
  | 'less_hundred'
  | 'less_two_hundred_fifty'
  | 'over_two_hundred_fifty';

export const NumberOfEmployeeRange = {
  less_ten: 'less_ten' as NumberOfEmployeeRange,
  less_hundred: 'less_hundred' as NumberOfEmployeeRange,
  less_two_hundred_fifty: 'less_two_hundred_fifty' as NumberOfEmployeeRange,
  over_two_hundred_fifty: 'over_two_hundred_fifty' as NumberOfEmployeeRange,
};
