import * as React from 'react';

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.875 4C7.07804 4 4 7.07804 4 10.875C4 14.672 7.07804 17.75 10.875 17.75C14.672 17.75 17.75 14.672 17.75 10.875C17.75 7.07804 14.672 4 10.875 4ZM2 10.875C2 5.97347 5.97347 2 10.875 2C15.7765 2 19.75 5.97347 19.75 10.875C19.75 15.7765 15.7765 19.75 10.875 19.75C5.97347 19.75 2 15.7765 2 10.875Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.736 15.7367C16.1265 15.3462 16.7597 15.3462 17.1502 15.7367L21.7065 20.293C22.0971 20.6836 22.0971 21.3167 21.7065 21.7073C21.316 22.0978 20.6828 22.0978 20.2923 21.7073L15.736 17.151C15.3455 16.7604 15.3455 16.1273 15.736 15.7367Z"
      />
    </svg>
  );
}

export default SvgSearch;
