export const Button = {
  baseStyle: ({ theme }) => ({
    fontFamily: theme.fonts.body1Bold,
    height: '5rem',
    borderRadius: theme.borders.xl,
    _hover: {
      _disabled: {
        bg: theme.colors.button.background.disabled,
        color: theme.colors.button.text.disabled,
      },
    },
  }),
  sizes: {
    small: ({ theme }) => ({
      fontSize: theme.fontSizes.small,
      padding: '.5rem',
      height: '3rem',
      width: '3rem',
      borderRadius: theme.borders.md,
    }),
    body2: ({ theme }) => ({
      height: '4.4rem',
      fontSize: theme.fontSizes.body2,
      padding: '2rem',
    }),
    body1: ({ theme }) => ({
      fontSize: theme.fontSizes.body1,
      padding: '2.2rem',
    }),
    icoLarge: ({ theme }) => ({
      height: '2rem',
    }),
  },
  variants: {
    secondary: ({ theme }) => ({
      bg: theme.colors.main.secondary.normal,
      color: theme.colors.button.text.normal,
    }),
    primary: ({ theme }) => ({
      bg: theme.colors.main.primary.normal,
      color: theme.colors.button.text.normal,
      _disabled: {
        opacity: 1,
        bg: theme.colors.button.background.disabled,
        color: theme.colors.button.text.disabled,
      },
    }),
    pagination: ({ theme }) => ({
      height: '3rem',
      bg: theme.colors.ink.white,
      color: theme.colors.ink.medium,
      fill: theme.colors.ink.medium,
      fontFamily: theme.fonts.body1,
      padding: '1.5rem',
      borderRadius: theme.borders.md,
      _hover: {
        bg: theme.colors.main.secondary.light,
        color: theme.colors.ink.white,
        fill: theme.colors.ink.white,
      },
      _active: {
        bg: theme.colors.main.secondary.light,
        color: theme.colors.ink.white,
      },
    }),
    disabled: ({ theme }) => ({
      bg: theme.colors.button.background.disabled,
      color: theme.colors.button.text.disabled,
    }),
    border: ({ theme }) => ({
      border: `solid ${theme.borders.xs}`,
      bg: 'transparent',
    }),
    transparant: {
      bg: 'transparent',
    },
    languageChoice: ({ theme }) => ({
      color: theme.colors.ink.medium,
      fontWeight: 400,
      bg: 'transparent',
      fontFamily: theme.fonts.body1,
      width: '4.2rem',
      border: `solid ${theme.borders.xs} ${theme.colors.main.secondary.lighter}`,
    }),
    wizard: ({ theme }) => ({
      bg: 'transparent',
      fontFamily: theme.fonts.body1,
      color: theme.colors.ink.black,
      fontWeight: theme.fontWeights.light,
    }),
  },
};
