export const Badge = {
  baseStyle: ({ theme }) => ({
    color: theme.colors.main.primary.normal,
  }),
  variants: {
    info: ({ theme }) => ({
      fontFamily: theme.fonts.body1,
      backgroundColor: theme.colors.status.info.normal,
      color: theme.colors.ink.white,
      borderRadius: theme.borders.md,
      padding: '.5rem 1rem',
      fontSize: theme.fontSizes.body2,
      fontWeight: theme.fontWeights.light,
      lineHeight: theme.lineHeights.body2,
    }),
    secondary: ({ theme }) => ({
      backgroundColor: theme.colors.main.secondary.normal,
      color: theme.colors.ink.white,
    }),
    cardTagPositive: ({ theme }) => ({
      fontFamily: theme.fonts.body1,
      backgroundColor: theme.colors.status.positive.light,
      color: theme.colors.status.positive.type,
      borderRadius: theme.borders.md,
      padding: '.5rem 1rem',
      fontSize: theme.fontSizes.body2,
      fontWeight: theme.fontWeights.light,
      lineHeight: theme.lineHeights.body2,
    }),
    cardTagNegative: ({ theme }) => ({
      fontFamily: theme.fonts.body1,
      backgroundColor: theme.colors.status.negative.light,
      color: theme.colors.status.negative.type,
      borderRadius: theme.borders.md,
      padding: '.5rem 1rem',
      fontSize: theme.fontSizes.body2,
      fontWeight: theme.fontWeights.light,
      lineHeight: theme.lineHeights.body2,
    }),
  },
};
