/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PaymentMethodStatus = 'CREATED' | 'VALIDATED';

export const PaymentMethodStatus = {
  CREATED: 'CREATED' as PaymentMethodStatus,
  VALIDATED: 'VALIDATED' as PaymentMethodStatus,
};
