/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, UseQueryOptions } from 'react-query';
import type {
  MembershipSummaryTopupListResponse,
  TopupsQueryParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const topupsQuery = <Data = unknown>(
  params?: TopupsQueryParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MembershipSummaryTopupListResponse : Data
  >(
    { url: `/v2/topups`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getTopupsQueryQueryKey = (params?: TopupsQueryParams) => [
  `/v2/topups`,
  ...(params ? [params] : []),
];

export const useTopupsQuery = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: TopupsQueryParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof topupsQuery>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getTopupsQueryQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof topupsQuery>, Error>(
    queryKey,
    () => topupsQuery<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
