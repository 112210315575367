/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type MetaStatus = 'succeeded' | 'failed';

export const MetaStatus = {
  succeeded: 'succeeded' as MetaStatus,
  failed: 'failed' as MetaStatus,
};
