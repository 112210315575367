import { Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useCurrentBreakpointValue } from '@libs/core/theme';
import { Currency, Pager, Table, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useExpenseList } from '@libs/api-v2/endpoints/expenses/expenses';
import { FORMAT_DATE_WITH_HOURS } from '@libs/core/constants';
import { ExpenseDetailsDrawer } from './expense-details-drawer';
import { ExpenseStatus } from '@libs/api-v2/models';
import { DrawerType } from '../../../dashboard.utils';
import { ExpenseUpdateDrawer } from './expense-update-drawer';

type ExpensesTableProps = {
  membershipId?: string;
};

export const ExpensesTable = ({ membershipId }: ExpensesTableProps) => {
  const Edit = useIcon('Edit');
  const Blocked = useIcon('Blocked');
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { isMobile, isTablet } = useCurrentBreakpointValue();
  const {
    searchQuery,
    setSearchQuery,
    useQueryParams,
    filterQuery,
    setFilterQuery,
    getFilterValue,
  } = useTable({
    setPageNumber,
    setPageSize,
  });

  const {
    drawerHandler,
    isOpen,
    onClose,
    activeDrawerIndex,
    selectedDrawerType,
  } = useDrawer();

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  const expensesGetParams = {
    // program_id?: string;
    membership_id: membershipId,
    page_number: pageNumber,
    page_size: pageSize,
    // date_from?: string;
    // range_to?: string;
    // only_teams?: boolean;
    review_status: getFilterValue('status') as ExpenseStatus,
    // refund_status?: ExpenseRefundStatus;
    // refund_issuer?: ExpenseRefundIssuer;
    search_employee_name: searchQuery,
    // expense_type?: ExpenseType;
  };

  const {
    // is false when fetching cached values
    isLoading,
    refetch,
    data: expenses,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useExpenseList(expensesGetParams, { query: { keepPreviousData: true } });

  useQueryParams({
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
    totalPages: expenses?.meta.total_pages,
  });

  const headers: TableHeaders = [
    t(i18nKeys.common.date),
    t(i18nKeys.common.title),
    '',
    '',
    t(i18nKeys.common.amount),
    {
      title: t(i18nKeys.common.status),
      name: 'status',
      filter: true,
      filterValues: Object.values(ExpenseStatus),
      filterValuesI18nKey: i18nKeys.operator.transactions.status,
    },
    t(i18nKeys.common.full_name),
    '',
  ];

  return (
    <>
      <Table
        onSubmit={submitHandler as any}
        initialValues={{ q: '' }}
        headers={headers}
        hasSearch
        searchPlaceholder={t(i18nKeys.common.table.employee.search_placeholder)}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        hasFilter
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
      >
        <Table.TBody
          isLoading={(isLoading || isFetching) && !expenses}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (expenses?.data.length === 0 || !expenses)
          }
        >
          {expenses?.data.map(
            (
              { id, expense_at, amount, review_status, meta, source },
              index,
            ) => (
              <Table.TR
                key={id}
                selectable
                index={id}
                position="relative"
                transform="scale(1,1)"
                onClick={() => drawerHandler(index)}
              >
                <Table.Date format={FORMAT_DATE_WITH_HOURS}>
                  {expense_at}
                </Table.Date>
                <Table.TD>
                  <Text>{meta.provider_name}</Text>
                </Table.TD>
                <Table.TD>
                  <Text>{meta.category_name_key}</Text>
                </Table.TD>
                <Table.TD>
                  <Text>{source}</Text>
                </Table.TD>
                <Table.TD>
                  <Currency size="Body2Bold">{amount?.total}</Currency>
                </Table.TD>
                <Table.TD>
                  <Text>{review_status}</Text>
                </Table.TD>
                <Table.TD>
                  <Text>
                    {meta.user_first_name} {meta.user_last_name}
                  </Text>
                </Table.TD>
                <Table.Action
                  actions={[
                    {
                      icon: <Edit w="2rem" h="2rem" />,
                      onClick: () =>
                        drawerHandler({
                          index: index,
                          drawerType: DrawerType.view,
                        }),
                    },
                    {
                      icon: <Blocked w="2rem" h="2rem" />,
                      onClick: () =>
                        drawerHandler({
                          index: index,
                          drawerType: DrawerType.block,
                        }),
                    },
                  ]}
                />
              </Table.TR>
            ),
          )}
        </Table.TBody>
      </Table>
      {(isOpen && selectedDrawerType === DrawerType.view && (
        <ExpenseDetailsDrawer
          isOpen={isOpen}
          onClose={onClose}
          expense={expenses.data[activeDrawerIndex]}
        />
      )) ||
        (isOpen && selectedDrawerType === DrawerType.block && (
          <ExpenseUpdateDrawer
            isOpen={isOpen}
            onClose={onClose}
            expense={expenses.data[activeDrawerIndex]}
            expensesGetParams={expensesGetParams}
          />
        ))}
      {expenses && expenses?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={expenses.meta.total_items}
          page_index={expenses.meta.page_index}
          total_pages={expenses.meta.total_pages}
          mt="2rem"
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          isFetching={isFetching}
        />
      )}
    </>
  );
};
