import { Heading, ListItem, Text } from '@chakra-ui/react';
import React from 'react';
import { OnboardingProgressBarItemWrapper } from './onboarding-progress-bar-item-wrapper';

type OnboardingProgressBarItemProps = {
  isOpen: boolean;
  modActive: boolean;
  modComplete: boolean;
  modLast: boolean;
  children: React.ReactNode;
};

export const OnboardingProgressBarItem = ({
  isOpen,
  modActive,
  modComplete,
  modLast,
  children,
}: OnboardingProgressBarItemProps) => {
  return (
    <ListItem>
      {modActive && (
        <OnboardingProgressBarItemWrapper
          modActive={modActive}
          vLine={!modLast}
          isOpen={isOpen}
        >
          <Heading color="nav.type.normal" size="Body2">
            {children}
          </Heading>
        </OnboardingProgressBarItemWrapper>
      )}
      {!modComplete && !modActive && (
        <OnboardingProgressBarItemWrapper
          borderColor="nav.type.normal"
          vLine={!modLast}
          isOpen={isOpen}
        >
          <Text color="nav.type.normal" size="Body2">
            {children}
          </Text>
        </OnboardingProgressBarItemWrapper>
      )}
      {modComplete && (
        <OnboardingProgressBarItemWrapper
          checked
          vLine={!modLast}
          bgColor="nav.type.normal"
          borderColor="nav.type.normal"
          isOpen={isOpen}
        >
          <Text
            textDecoration="line-through"
            color="nav.type.normal"
            size="Body2"
          >
            {children}
          </Text>
        </OnboardingProgressBarItemWrapper>
      )}
    </ListItem>
  );
};
