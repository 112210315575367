import { CommonTheme } from './types';

/*
  Example:
  ${media.up('md')} {
    font-size: 100px;
  }
*/

type Orientation = 'landscape' | 'portrait';

const withOrientation = (media: string, orientation?: Orientation) => {
  if (orientation) {
    return `${media} and (orientation: ${orientation})`;
  }

  return media;
};

export const media = {
  up(name: keyof CommonTheme['breakpoints'], orientation?: Orientation) {
    return ({ theme }) =>
      withOrientation(
        ` @media (min-width: ${theme.breakpoints[name]})`,
        orientation,
      );
  },
  down(name: keyof CommonTheme['breakpoints'], orientation?: Orientation) {
    return ({ theme }) =>
      withOrientation(
        `@media (max-width: ${theme.breakpoints[name]})`,
        orientation,
      );
  },
  between(
    min: keyof CommonTheme['breakpoints'],
    max: keyof CommonTheme['breakpoints'],
    orientation?: Orientation,
  ) {
    return ({ theme }) =>
      withOrientation(
        `@media (min-width: ${theme.breakpoints[min]}) and (max-width: ${theme.breakpoints[max]})`,
        orientation,
      );
  },
};
