export const DatePicker = `
.flatpickr-calendar {
    font-family: ${({ theme }) => theme.fonts.body1};
}
.flatpickr-calendar.open {
    z-index: 2147483100;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
    font-family: ${({ theme }) => theme.fonts.body1Bold};
}
.flatpickr-current-month .numInputWrapper {
    font-family: ${({ theme }) => theme.fonts.body1Bold};
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: ${({ theme }) => theme.colors.main.primary.normal};
    -webkit-box-shadow: none;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.ink.white};
    border-color: ${({ theme }) => theme.colors.main.primary.normal};
}`;
