/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  Card,
  CardsGetResponse,
  CardsGetParams,
  CardCreateRequest,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const cardsUpdate = <Data = unknown>(
  cardid: string,
  card: Card,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Card : Data>(
    { url: `/cards/${cardid}`, method: 'put', data: card },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useCardsUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof cardsUpdate>,
    Error,
    { cardid: string; data: Card },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof cardsUpdate>,
    Error,
    { cardid: string; data: Card }
  >((props) => {
    const { cardid, data } = props || {};

    return cardsUpdate<Data>(cardid, data, requestOptions);
  }, mutationOptions);
};
export const cardsGet = <Data = unknown>(
  params?: CardsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? CardsGetResponse : Data>(
    { url: `/cards`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getCardsGetQueryKey = (params?: CardsGetParams) => [
  `/cards`,
  ...(params ? [params] : []),
];

export const useCardsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: CardsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof cardsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getCardsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof cardsGet>, Error>(
    queryKey,
    () => cardsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const cardsCreate = <Data = unknown>(
  cardCreateRequest: CardCreateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Card : Data>(
    { url: `/cards`, method: 'post', data: cardCreateRequest },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useCardsCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof cardsCreate>,
    Error,
    { data: CardCreateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof cardsCreate>,
    Error,
    { data: CardCreateRequest }
  >((props) => {
    const { data } = props || {};

    return cardsCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
