import { useGetCurrentUser } from '@libs/api/endpoints';
import { LoggedUser, Membership, MembershipRole } from '@libs/api/models';

export const useFleetManagerMembership = () => {
  const { data: user } = useGetCurrentUser({
    query: {
      enabled: false,
    },
  });

  return findFleetManagerInMemberships(user);
};

export function findFleetManagerInMemberships(user: LoggedUser): Membership {
  return (user.memberships || []).find(
    (membership) => membership.role === MembershipRole.FLEET_MGR,
  );
}
