/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ReportAggregation =
  | 'ALL'
  | 'PROGRAM'
  | 'PROGRAM_GROUP'
  | 'SERVICE'
  | 'CATEGORY'
  | 'PROVIDER'
  | 'EMPLOYEE'
  | 'GROUP';

export const ReportAggregation = {
  ALL: 'ALL' as ReportAggregation,
  PROGRAM: 'PROGRAM' as ReportAggregation,
  PROGRAM_GROUP: 'PROGRAM_GROUP' as ReportAggregation,
  SERVICE: 'SERVICE' as ReportAggregation,
  CATEGORY: 'CATEGORY' as ReportAggregation,
  PROVIDER: 'PROVIDER' as ReportAggregation,
  EMPLOYEE: 'EMPLOYEE' as ReportAggregation,
  GROUP: 'GROUP' as ReportAggregation,
};
