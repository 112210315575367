/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ReportTimeScale = 'YEAR' | 'MONTH' | 'WEEK' | 'DAY';

export const ReportTimeScale = {
  YEAR: 'YEAR' as ReportTimeScale,
  MONTH: 'MONTH' as ReportTimeScale,
  WEEK: 'WEEK' as ReportTimeScale,
  DAY: 'DAY' as ReportTimeScale,
};
