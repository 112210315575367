/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type KmAllowanceType = 'LEGAL' | 'CUSTOM';

export const KmAllowanceType = {
  LEGAL: 'LEGAL' as KmAllowanceType,
  CUSTOM: 'CUSTOM' as KmAllowanceType,
};
