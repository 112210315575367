import * as React from 'react';

function CsvStoreFront(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25016 11.1006C3.8945 11.1006 4.41683 11.6229 4.41683 12.2673V19.4581H19.5835V12.2674C19.5835 11.6231 20.1058 11.1008 20.7502 11.1008C21.3945 11.1008 21.9168 11.6231 21.9168 12.2674V19.7498C21.9168 20.2913 21.7017 20.8106 21.3188 21.1935C20.936 21.5764 20.4166 21.7915 19.8752 21.7915H4.12516C3.58368 21.7915 3.06438 21.5764 2.68149 21.1935C2.2986 20.8106 2.0835 20.2913 2.0835 19.7498V12.2673C2.0835 11.6229 2.60583 11.1006 3.25016 11.1006Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13019 2.54134L3.04686 6.33301H20.9535L19.8702 2.54134H4.13019ZM2.6807 0.619713C3.03491 0.352534 3.46651 0.208008 3.91019 0.208008H20.0902C20.5338 0.208008 20.9654 0.352534 21.3196 0.619713C21.6739 0.886894 21.9314 1.26218 22.0533 1.68879L23.6219 7.17917C23.7225 7.53117 23.652 7.90997 23.4316 8.20223C23.2111 8.4945 22.8663 8.66634 22.5002 8.66634H1.50017C1.13408 8.66634 0.789223 8.4945 0.568766 8.20224C0.348309 7.90997 0.277822 7.53117 0.378395 7.17917L1.94708 1.68879C2.06896 1.26218 2.3265 0.886892 2.6807 0.619713Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50016 6.33301C2.14449 6.33301 2.66683 6.85534 2.66683 7.49967V9.24967C2.66683 9.86851 2.91266 10.462 3.35025 10.8996C3.78783 11.3372 4.38132 11.583 5.00016 11.583C5.619 11.583 6.21249 11.3372 6.65008 10.8996C7.08766 10.462 7.3335 9.86851 7.3335 9.24967V7.49967C7.3335 6.85534 7.85583 6.33301 8.50016 6.33301C9.14449 6.33301 9.66683 6.85534 9.66683 7.49967V9.24967C9.66683 10.4874 9.17516 11.6743 8.29999 12.5495C7.42482 13.4247 6.23784 13.9163 5.00016 13.9163C3.76248 13.9163 2.5755 13.4247 1.70033 12.5495C0.825161 11.6743 0.333496 10.4874 0.333496 9.24967V7.49967C0.333496 6.85534 0.85583 6.33301 1.50016 6.33301Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50016 6.33301C9.1445 6.33301 9.66683 6.85534 9.66683 7.49967V9.24967C9.66683 9.86851 9.91266 10.462 10.3502 10.8996C10.7878 11.3372 11.3813 11.583 12.0002 11.583C12.619 11.583 13.2125 11.3372 13.6501 10.8996C14.0877 10.462 14.3335 9.86851 14.3335 9.24967V7.49967C14.3335 6.85534 14.8558 6.33301 15.5002 6.33301C16.1445 6.33301 16.6668 6.85534 16.6668 7.49967V9.24967C16.6668 10.4874 16.1752 11.6743 15.3 12.5495C14.4248 13.4247 13.2378 13.9163 12.0002 13.9163C10.7625 13.9163 9.5755 13.4247 8.70033 12.5495C7.82516 11.6743 7.3335 10.4874 7.3335 9.24967V7.49967C7.3335 6.85534 7.85583 6.33301 8.50016 6.33301Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5002 6.33301C16.1445 6.33301 16.6668 6.85534 16.6668 7.49967V9.24967C16.6668 9.86851 16.9127 10.462 17.3502 10.8996C17.7878 11.3372 18.3813 11.583 19.0002 11.583C19.619 11.583 20.2125 11.3372 20.6501 10.8996C21.0877 10.462 21.3335 9.86851 21.3335 9.24967V7.49967C21.3335 6.85534 21.8558 6.33301 22.5002 6.33301C23.1445 6.33301 23.6668 6.85534 23.6668 7.49967V9.24967C23.6668 10.4874 23.1752 11.6743 22.3 12.5495C21.4248 13.4247 20.2378 13.9163 19.0002 13.9163C17.7625 13.9163 16.5755 13.4247 15.7003 12.5495C14.8252 11.6743 14.3335 10.4874 14.3335 9.24967V7.49967C14.3335 6.85534 14.8558 6.33301 15.5002 6.33301Z"
      />
    </svg>
  );
}

export default CsvStoreFront;
