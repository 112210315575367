/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type NetIncomeRange =
  | 'less_five'
  | 'less_ten'
  | 'less_fifty'
  | 'less_hundred_fifty'
  | 'less_five_hundred_fifty'
  | 'over_five_hundred_fifty';

export const NetIncomeRange = {
  less_five: 'less_five' as NetIncomeRange,
  less_ten: 'less_ten' as NetIncomeRange,
  less_fifty: 'less_fifty' as NetIncomeRange,
  less_hundred_fifty: 'less_hundred_fifty' as NetIncomeRange,
  less_five_hundred_fifty: 'less_five_hundred_fifty' as NetIncomeRange,
  over_five_hundred_fifty: 'over_five_hundred_fifty' as NetIncomeRange,
};
