import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useOrganisationGet } from '@libs/api/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Loader } from '@libs/ui/components';
import { Link } from '@libs/ui/components/link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OnboardingWizard } from '../../../../../components/onboarding-wizard/onboarding-wizard';
import { getPath, Routes } from '../../../../../routes';
import { DashboardLayout } from '../../../components/dashboard-layout';
import { OrganisationOnboardingProgressBar } from './organisation-onboarding-progress-bar';

export const OrganisationOnboardingPage = () => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const { data: organisation, isLoading } = useOrganisationGet(organisationId);
  const { t } = useTranslation();
  if (!organisation || isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <Breadcrumb mb="2rem">
        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD)}
          >
            {t(i18nKeys.common.home)}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD_ORGANISATIONS)}
          >
            {t(i18nKeys.operator.organisations.title)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD_ORGANISATIONS_DETAILS, {
              params: {
                organisationId: organisation.id,
              },
            })}
          >
            {organisation.legal_name}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink variant="breadcrumb" as={Link} to="#">
            {t(i18nKeys.common.onboarding)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <OnboardingWizard
        isEmbedded={true}
        organisationId={organisationId}
        onboardingStatus={organisation.onboarding_status}
        organisationStatus={organisation.status}
      >
        <OrganisationOnboardingProgressBar />
      </OnboardingWizard>
    </DashboardLayout>
  );
};
