export type FileExtensionType =
  | 'application/pdf'
  | 'application/xls'
  | 'image/jpeg'
  | 'image/png'
  | 'text/csv';

export const FileExtensionType = {
  PDF: 'application/pdf' as FileExtensionType,
  JPG: 'image/jpeg' as FileExtensionType,
  PNG: 'image/png' as FileExtensionType,
  CSV: 'text/csv' as FileExtensionType,
  XLS: 'application/xls' as FileExtensionType,
};

export const FileExtensionTypeToExtension = {
  [FileExtensionType.PDF]: 'pdf',
  [FileExtensionType.PNG]: 'png',
  [FileExtensionType.JPG]: 'jpeg',
  [FileExtensionType.CSV]: 'csv',
  [FileExtensionType.XLS]: 'xls',
};
