/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PaymentMethodParam = 'APP' | 'CARD' | 'REFUND';

export const PaymentMethodParam = {
  APP: 'APP' as PaymentMethodParam,
  CARD: 'CARD' as PaymentMethodParam,
  REFUND: 'REFUND' as PaymentMethodParam,
};
