import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { checkVAT, countries } from 'jsvat';
import { parsePhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';

Yup.setLocale({
  mixed: {
    required: i18nKeys.common.errors.field_required,
  },
  string: {
    email: i18nKeys.common.errors.email_invalid,
  },
  array: {
    min: i18nKeys.common.errors.field_required,
  },
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'password', function () {
  return this.min(8, i18nKeys.common.errors.password.password_too_short)
    .matches(
      RegExp('(.*[A-Z].*)'),
      i18nKeys.common.errors.password.password_capital_required,
    )
    .matches(
      RegExp('(.*\\d.*)'),
      i18nKeys.common.errors.password.password_number_required,
    )
    .matches(
      RegExp('.*[!@#$?%^=_&*].*'),
      i18nKeys.common.errors.password.password_special_character_required,
    );
});

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'vatNumber',
  function (error?: string) {
    return this.test(
      'vatNumberValidation',
      () => error || i18nKeys.common.errors.vat_invalid,
      (value) => !value || checkVAT(value, countries).isValid,
    );
  },
);

Yup.addMethod(
  Yup.mixed,
  'equalToField',
  function (field: string, error: string) {
    return (this as MixedSchema).oneOf([Yup.ref(field), null], error);
  },
);

Yup.addMethod(Yup.mixed, 'equalTo', function <T>(values: T, error?: string) {
  error = error ?? i18nKeys.common.errors.field_not_equal_to + values;
  return (this as MixedSchema<T>).oneOf([values], error);
});

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'phoneNumber',
  function (error?: string) {
    return this.test(
      'phoneNumberValidation',
      () => error || i18nKeys.common.errors.phone_number_invalid,
      (value) => {
        try {
          return !value || parsePhoneNumber(value).isValid();
        } catch (e) {
          return false;
        }
      },
    );
  },
);
