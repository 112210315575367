import * as React from 'react';

function SvgArrowSortDesc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.25C11.4477 21.25 11 20.8023 11 20.25L11 3.75C11 3.19772 11.4477 2.75 12 2.75C12.5523 2.75 13 3.19772 13 3.75L13 20.25C13 20.8023 12.5523 21.25 12 21.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 20.9571C12.3166 21.3476 11.6834 21.3476 11.2929 20.9571L4.54289 14.2071C4.15237 13.8166 4.15237 13.1834 4.54289 12.7929C4.93342 12.4024 5.56658 12.4024 5.95711 12.7929L12 18.8358L18.0429 12.7929C18.4334 12.4024 19.0666 12.4024 19.4571 12.7929C19.8476 13.1834 19.8476 13.8166 19.4571 14.2071L12.7071 20.9571Z"
      />
    </svg>
  );
}

export default SvgArrowSortDesc;
