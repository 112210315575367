import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type TableHeadProps = BoxProps & {
  children: ReactNode;
};

export const TableHead = ({ children, ...rest }: TableHeadProps) => {
  return (
    <Box as="thead" p={4} textAlign="left" {...rest}>
      {children}
    </Box>
  );
};
