/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type RegistrationStatus = 'CREATED' | 'VALIDATED' | 'CANCELED';

export const RegistrationStatus = {
  CREATED: 'CREATED' as RegistrationStatus,
  VALIDATED: 'VALIDATED' as RegistrationStatus,
  CANCELED: 'CANCELED' as RegistrationStatus,
};
