/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type Operation = 'expense_approved' | 'expense_create';

export const Operation = {
  expense_approved: 'expense_approved' as Operation,
  expense_create: 'expense_create' as Operation,
};
