import { Collapse } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 1.1rem solid transparent;
  border-right: 1.1rem solid transparent;
  border-bottom: 1.1rem solid ${({ theme }) => theme.colors.ink.white};
  margin: 0 auto;
  @media (max-width: 770px) {
    display: none;
  }
`;

export const AssistantCollapse = styled(Collapse)`
  margin: 1rem 0 0 0 !important;
`;
