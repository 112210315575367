import { Box, Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Nav } from '@libs/dashboard-core/components/nav';
import { Tag, TagStatus, useWizard } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingProgressBar } from './onboarding-progress-bar';

export const OnboardingWizardNav = () => {
  const { t } = useTranslation();
  const { steps, activeStepIndex } = useWizard();
  const isLastStep = activeStepIndex === steps.length - 1;
  return (
    <Nav
      title={
        isLastStep
          ? t(i18nKeys.onboarding.wizard.nav.success_title)
          : t(i18nKeys.onboarding.wizard.nav.title)
      }
      introduction={
        isLastStep ? (
          <Box mt=".5rem">
            <Tag status={TagStatus.PENDING}>
              <Text size="Small" color="nav.type.lighter">
                {t(i18nKeys.common.TagStatus.PENDING)}
              </Text>
            </Tag>
            <Text size="Small" mt="1rem" color="nav.type.normal">
              {t(i18nKeys.onboarding.wizard.nav.success_introduction)}
            </Text>
          </Box>
        ) : (
          <Text size="Small" color="nav.type.lighter">
            {t(i18nKeys.onboarding.wizard.nav.introduction)}
          </Text>
        )
      }
    >
      <OnboardingProgressBar steps={steps} activeStepIndex={activeStepIndex} />
    </Nav>
  );
};
