import {
  Organisation,
  OrganisationDocument,
  Shareholder,
} from '@libs/api/models';
import { DOCUMENT_EXPIRE_DAYS_CHANGE } from '@libs/dashboard-core/constants/expire-date';
import dayjs from 'dayjs';

export const getDaysToDocExpired = (
  data: Organisation | Shareholder,
  document: OrganisationDocument,
) => {
  const isOrganisation = (data as Organisation)?.vat;

  return !isOrganisation ||
    dayjs(document.created_at).diff(
      dayjs(DOCUMENT_EXPIRE_DAYS_CHANGE),
      'day',
    ) <= 0
    ? 15
    : 30;
};
