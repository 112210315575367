/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseType = 'EXPENSE' | 'REFUND';

export const ExpenseType = {
  EXPENSE: 'EXPENSE' as ExpenseType,
  REFUND: 'REFUND' as ExpenseType,
};
