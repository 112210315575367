import * as React from 'react';

function SvgXls(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.6618 13.4446C15.5607 13.2742 15.4207 13.1399 15.2419 13.0416C15.0631 12.9434 14.7872 12.8483 14.4139 12.7564C14.0407 12.6644 13.8058 12.5761 13.709 12.4913C13.6329 12.4246 13.5949 12.3443 13.5949 12.2506C13.5949 12.1478 13.6355 12.0658 13.7168 12.0045C13.8429 11.909 14.0175 11.8612 14.2403 11.8612C14.4562 11.8612 14.6183 11.9059 14.7262 11.995C14.8342 12.0842 14.9046 12.2308 14.9374 12.4345L15.7045 12.3993C15.6925 12.0352 15.566 11.744 15.3249 11.5259C15.0838 11.3077 14.7249 11.1987 14.2481 11.1987C13.9561 11.1987 13.7069 11.2446 13.5004 11.3366C13.294 11.4286 13.1358 11.5624 13.0261 11.7381C12.9164 11.9139 12.8615 12.1028 12.8615 12.3048C12.8615 12.6184 12.9782 12.8843 13.2115 13.1026C13.3773 13.2575 13.6658 13.3883 14.0771 13.4946C14.3967 13.5776 14.6015 13.6353 14.6912 13.6677C14.8226 13.7164 14.9146 13.7736 14.9672 13.8394C15.0199 13.9053 15.0463 13.985 15.0463 14.0788C15.0463 14.2248 14.9836 14.3524 14.8584 14.4614C14.7331 14.5704 14.547 14.625 14.2999 14.625C14.0667 14.625 13.8814 14.5637 13.744 14.4411C13.6067 14.3185 13.5156 14.1265 13.4706 13.8651L12.7242 13.9408C12.7743 14.3843 12.9281 14.7219 13.1855 14.9536C13.443 15.1852 13.8118 15.301 14.2922 15.301C14.6221 15.301 14.8978 15.2528 15.119 15.1563C15.3401 15.06 15.511 14.9125 15.632 14.7142C15.753 14.5159 15.8135 14.3032 15.8135 14.076C15.8134 13.8254 15.7629 13.6149 15.6618 13.4446ZM10.4642 14.5628V11.2987H9.69702V15.2308H12.3717V14.5628H10.4642ZM7.98397 13.1917L9.16581 11.2663H8.2769L7.53052 12.54L6.76851 11.2663H5.87187L7.04838 13.1619L5.75 15.2308H6.67L7.51494 13.8596L8.35718 15.2308H9.28239L7.98397 13.1917Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.65902 0C3.19692 0 2.75375 0.194505 2.42699 0.540726C2.10024 0.886947 1.91667 1.35652 1.91667 1.84615V22.1538C1.91667 22.6435 2.10024 23.1131 2.42699 23.4593C2.75375 23.8055 3.19692 24 3.65902 24H19.341C19.5698 24 19.7964 23.9522 20.0078 23.8595C20.2192 23.7667 20.4112 23.6307 20.573 23.4593C20.7348 23.2878 20.8632 23.0843 20.9507 22.8603C21.0383 22.6363 21.0833 22.3963 21.0833 22.1538V7.38461C21.0833 7.33808 21.08 7.2919 21.0735 7.24644C21.08 7.20231 21.0833 7.15713 21.0833 7.11111C21.0833 6.66432 20.7675 6.29451 20.3561 6.23165L15.0894 0.651208C14.9898 0.275692 14.66 0 14.2684 0C14.2423 0 14.2165 0.00122366 14.191 0.00361898C14.1655 0.0012163 14.1398 0 14.114 0H3.65902ZM13.4167 1.84615H3.65902L3.65902 22.1538L19.341 22.1538V8H14.2684C13.798 8 13.4167 7.60203 13.4167 7.11111V1.84615ZM17.8831 6.22222L15.1202 3.29469V6.22222H17.8831Z"
      />
    </svg>
  );
}

export default SvgXls;
