/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ActivationStatus = 'ACTIVE' | 'INACTIVE';

export const ActivationStatus = {
  ACTIVE: 'ACTIVE' as ActivationStatus,
  INACTIVE: 'INACTIVE' as ActivationStatus,
};
