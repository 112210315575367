import * as React from 'react';

function SvgUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.30224 10.6316C6.71829 10.4824 6.10928 10.4591 5.51564 10.5632C4.92199 10.6673 4.35728 10.8966 3.859 11.2356C3.36071 11.5747 2.94025 12.0159 2.62551 12.5299C2.31077 13.0439 2.10896 13.619 2.03347 14.217C1.95798 14.8149 2.01054 15.4221 2.18766 15.9982C2.36477 16.5743 2.6624 17.1061 3.06078 17.5584C3.45916 18.0107 3.94918 18.3731 4.49832 18.6215C5.04746 18.8699 5.64316 18.9987 6.24587 18.9993H8.4949C9.04718 18.9993 9.4949 19.447 9.4949 19.9993C9.4949 20.5515 9.04718 20.9993 8.4949 20.9993H6.2449C5.35855 20.9984 4.48156 20.809 3.674 20.4437C2.86645 20.0784 2.14582 19.5455 1.55997 18.8804C0.974117 18.2153 0.536431 17.4331 0.275963 16.5859C0.0154954 15.7387 -0.0617966 14.8458 0.049218 13.9665C0.160232 13.0871 0.457014 12.2414 0.919864 11.4855C1.38271 10.7296 2.00105 10.0808 2.73382 9.58216C3.46659 9.08352 4.29704 8.74643 5.17005 8.59328C6.04306 8.44013 6.93866 8.47441 7.79741 8.69385C8.3325 8.83059 8.65543 9.37522 8.51869 9.91031C8.38195 10.4454 7.83733 10.7683 7.30224 10.6316Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5505 6.33361C15.5735 6.0079 14.533 5.91903 13.5149 6.0743C12.4968 6.22957 11.5301 6.62455 10.6946 7.2267C9.85908 7.82884 9.17858 8.6209 8.70921 9.53762C8.23983 10.4543 7.995 11.4695 7.9949 12.4994C7.99485 13.0516 7.54709 13.4993 6.99481 13.4993C6.44252 13.4992 5.99485 13.0515 5.9949 12.4992C5.99504 11.1524 6.31519 9.8249 6.92899 8.62611C7.54279 7.42733 8.43267 6.39155 9.52529 5.60414C10.6179 4.81673 11.882 4.30021 13.2134 4.09716C14.5448 3.89411 15.9054 4.01034 17.183 4.43626C18.4607 4.86218 19.6189 5.58561 20.5621 6.54693C21.5053 7.50825 22.2066 8.67995 22.6082 9.96547C23.0098 11.251 23.1002 12.6135 22.8719 13.9408C22.6436 15.2681 22.1032 16.5222 21.2952 17.5997C20.9638 18.0415 20.337 18.1311 19.8952 17.7997C19.4533 17.4684 19.3638 16.8416 19.6951 16.3997C20.313 15.5758 20.7263 14.6168 20.9008 13.6018C21.0754 12.5868 21.0063 11.5449 20.6992 10.5618C20.3921 9.57878 19.8558 8.68278 19.1345 7.94765C18.4132 7.21252 17.5276 6.65931 16.5505 6.33361Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7449 11.4992C14.0101 11.4992 14.2645 11.6046 14.452 11.7921L17.634 14.9742C18.0245 15.3647 18.0245 15.9979 17.634 16.3884C17.2435 16.7789 16.6103 16.7789 16.2198 16.3884L13.7449 13.9135L11.2701 16.3884C10.8795 16.7789 10.2464 16.7789 9.85585 16.3884C9.46532 15.9979 9.46531 15.3647 9.85583 14.9742L13.0378 11.7921C13.2253 11.6046 13.4797 11.4992 13.7449 11.4992Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7449 11.4993C14.2972 11.4993 14.7449 11.947 14.7449 12.4993V19.9993C14.7449 20.5516 14.2972 20.9993 13.7449 20.9993C13.1926 20.9993 12.7449 20.5516 12.7449 19.9993V12.4993C12.7449 11.947 13.1926 11.4993 13.7449 11.4993Z"
      />
    </svg>
  );
}

export default SvgUpload;
