import { List, ListItem, useTheme } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorText } from './error-text';

const PasswordValidationList = ({
  item,
  isValid = false,
  errorLeftAlignement = '0',
}: {
  item: string;
  isValid?: boolean;
  errorLeftAlignement?: string;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const Check = useIcon('Check');
  const Close = useIcon('Close');

  return (
    <ListItem
      key={item}
      ml={errorLeftAlignement}
      mt="0"
      color={
        isValid
          ? theme.colors.status.positive.type
          : theme.colors.status.negative.type
      }
    >
      <ErrorText
        isValid={isValid}
        icon={isValid ? <Check /> : <Close />}
        error={t(item)}
      />
    </ListItem>
  );
};

type PasswordValidation = {
  active: boolean;
  isEmpty: boolean;
  errorLeftAlignement?: string;
  error: string[] | string;
};

export const PasswordValidation = ({
  active,
  isEmpty,
  errorLeftAlignement,
  error,
}: PasswordValidation) => {
  const {
    passwords_mismatch,
    ...passwordErrors
  } = i18nKeys.common.errors.password;
  return active ? (
    <List data-cy="form-control-password-validation-list">
      {Object.values(passwordErrors).map((item) => {
        if (error?.includes(item) || isEmpty) {
          return (
            <PasswordValidationList
              key={item}
              item={item}
              errorLeftAlignement={errorLeftAlignement}
            />
          );
        }

        return (
          <PasswordValidationList
            key={item}
            item={item}
            isValid
            errorLeftAlignement={errorLeftAlignement}
          />
        );
      })}
    </List>
  ) : null;
};
