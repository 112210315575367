import React, { ReactNode } from 'react';
import {
  DrawerBody as ChakraDrawerBody,
  ModalBodyProps,
} from '@chakra-ui/react';

type DrawerBodyProps = ModalBodyProps & {
  children: ReactNode;
};

export const DrawerBody = ({ children, ...rest }: DrawerBodyProps) => (
  <ChakraDrawerBody {...rest}>{children}</ChakraDrawerBody>
);
