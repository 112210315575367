import * as React from 'react';

function SvgPdf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.80711 11.7138C8.65388 11.5146 8.46366 11.3852 8.2365 11.3257C8.08866 11.286 7.77136 11.2662 7.28456 11.2662H6V15.2308H6.80048V13.7353H7.32244C7.68483 13.7353 7.96158 13.7163 8.15269 13.6785C8.29331 13.6478 8.43169 13.5852 8.56781 13.4905C8.70394 13.3958 8.81616 13.2656 8.90452 13.0997C8.99288 12.9339 9.03703 12.7292 9.03703 12.4859C9.03699 12.1703 8.96034 11.913 8.80711 11.7138ZM8.12156 12.805C8.06119 12.8933 7.9778 12.9582 7.87139 12.9997C7.76498 13.0412 7.55409 13.0619 7.23858 13.0619H6.80048V11.9369H7.1872C7.47567 11.9369 7.66767 11.9459 7.7632 11.964C7.893 11.9874 8.0003 12.046 8.085 12.1397C8.16975 12.2335 8.21213 12.3525 8.21213 12.4967C8.21217 12.6139 8.18194 12.7166 8.12156 12.805V12.805ZM12.8852 12.3493C12.7987 12.096 12.6725 11.8819 12.5066 11.707C12.3408 11.5321 12.1415 11.4105 11.909 11.3419C11.7359 11.2914 11.4844 11.2662 11.1545 11.2662H9.69141V15.2308H11.1977C11.4934 15.2308 11.7296 15.2028 11.9063 15.1469C12.1425 15.0712 12.33 14.9657 12.4688 14.8305C12.6526 14.652 12.7942 14.4186 12.8933 14.1301C12.9745 13.8939 13.015 13.6126 13.015 13.2863C13.015 12.915 12.9718 12.6026 12.8852 12.3493ZM12.1064 13.9773C12.0523 14.1549 11.9824 14.2825 11.8968 14.36C11.8111 14.4375 11.7034 14.4925 11.5736 14.5249C11.4745 14.5502 11.3131 14.5628 11.0895 14.5628H10.4919V11.9369H10.8516C11.1779 11.9369 11.397 11.9495 11.5087 11.9748C11.6583 12.0072 11.7818 12.0694 11.8792 12.1614C11.9766 12.2534 12.0523 12.3813 12.1064 12.5454C12.1605 12.7095 12.1875 12.9447 12.1875 13.2512C12.1875 13.5577 12.1605 13.7997 12.1064 13.9773ZM16.4171 11.9369V11.2662H13.6992V15.2308H14.4997V13.546H16.1548V12.8753H14.4997V11.9369H16.4171Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.8181 0C3.33591 0 2.87347 0.194505 2.53251 0.540726C2.19155 0.886947 2 1.35652 2 1.84615V22.1538C2 22.6435 2.19155 23.1131 2.53251 23.4593C2.87347 23.8055 3.33591 24 3.8181 24H20.1819C20.4207 24 20.6571 23.9522 20.8777 23.8595C21.0982 23.7667 21.2987 23.6307 21.4675 23.4593C21.6363 23.2878 21.7702 23.0843 21.8616 22.8603C21.953 22.6363 22 22.3963 22 22.1538V7.38461C22 7.33808 21.9965 7.29191 21.9898 7.24645C21.9965 7.20232 22 7.15713 22 7.11111C22 6.66432 21.6704 6.29451 21.2411 6.23165L15.7455 0.65121C15.6415 0.275693 15.2973 0 14.8888 0C14.8616 0 14.8346 0.00122368 14.808 0.00361905C14.7814 0.00121632 14.7546 0 14.7276 0H3.8181ZM14 1.84615H3.8181L3.8181 22.1538L20.1819 22.1538V8H14.8888C14.3979 8 14 7.60203 14 7.11111V1.84615ZM18.6606 6.22222L15.7776 3.29469V6.22222H18.6606Z"
      />
    </svg>
  );
}

export default SvgPdf;
