/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type OperatingSystem = 'Android' | 'iOS';

export const OperatingSystem = {
  Android: 'Android' as OperatingSystem,
  iOS: 'iOS' as OperatingSystem,
};
