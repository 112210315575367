import {
  Divider,
  Heading,
  Modal as ChackraModal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps as ModalBaseProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { ModalBody } from './components/modal-body';
import { ModalFooter } from './components/modal-footer';

export type ModalProps = ModalBaseProps & {
  title?: string | ReactNode;
  children: ReactNode;
  showCloseButton?: boolean;
};

export const Modal = ({
  children,
  variant,
  size = 'xl',
  colorScheme,
  title,
  showCloseButton = true,
  ...rest
}: ModalProps) => {
  return (
    <ChackraModal
      variant={variant}
      size={size}
      colorScheme={colorScheme}
      isCentered
      {...rest}
    >
      <ModalOverlay />
      <ModalContent data-cy="modal-content">
        {title && (
          <ModalHeader data-cy="modal-header">
            <Heading p={3} variant="Title1">
              {title}
            </Heading>
            <Divider
              mx="auto"
              width="95%"
              height=".2rem"
              orientation="horizontal"
            />
          </ModalHeader>
        )}
        {showCloseButton && <ModalCloseButton data-cy="modal-close-button" />}
        {children}
      </ModalContent>
    </ChackraModal>
  );
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
