import { InputRightElement } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { FC, useState } from 'react';
import { useIcon } from '@libs/core/theme/utils/useIcon';
import { FormControl } from '../components/form-control';
import {
  InputBar,
  InputButton,
  InputHighLight,
  InputStyledField,
} from '../form.style';
import { BaseProps } from '../form.types';

export type InputFieldProps = BaseProps & {
  isPassword?: boolean;
  type?: string;
  hasValidation?: boolean;
};

export const InputField: FC<InputFieldProps> = (props: InputFieldProps) => {
  const { name, type = 'text', isPassword, ...formControlProps } = props;
  const Eye = useIcon('Eye');
  const EyeOff = useIcon('EyeOff');
  const [field, meta] = useField(name);
  const [show, setShow] = useState<boolean>(false);

  return (
    <FormControl
      id={name}
      meta={meta}
      isEmpty={!field.value}
      isPassword={isPassword}
      {...formControlProps}
    >
      <InputStyledField
        {...field}
        value={field.value != null ? field.value : ''}
        type={!show && isPassword ? 'password' : type}
      />
      {isPassword && (
        <InputRightElement
          children={
            <InputButton
              variant="transparant"
              size="lg"
              onClick={() => setShow((show) => !show)}
            >
              {show ? <EyeOff /> : <Eye />}
            </InputButton>
          }
        />
      )}
      <InputHighLight />
      <InputBar />
    </FormControl>
  );
};
