import * as React from 'react';

function SvgQueue(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4V7L11.9976 10.7482L17 6.96695V4H7ZM5.51256 2.51256C5.84075 2.18438 6.28587 2 6.75 2H17.25C17.7141 2 18.1592 2.18437 18.4874 2.51256C18.8156 2.84075 19 3.28587 19 3.75V7.09136C19 7.36193 18.9373 7.62881 18.8167 7.87104C18.6962 8.11327 18.5211 8.32424 18.3053 8.4874L12.603 12.7977C12.2473 13.0666 11.7567 13.0675 11.4 12.8L5.7 8.525C5.48265 8.36199 5.30625 8.15062 5.18475 7.90762C5.06325 7.66463 5 7.39668 5 7.125V3.75C5 3.28587 5.18438 2.84075 5.51256 2.51256Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4 11.2C11.7567 10.9325 12.2473 10.9334 12.603 11.2023L18.3053 15.5126C18.5211 15.6758 18.6962 15.8867 18.8167 16.129C18.9373 16.3712 19 16.6381 19 16.9086V20.25C19 20.7141 18.8156 21.1592 18.4874 21.4874C18.1592 21.8156 17.7141 22 17.25 22H6.75C6.28587 22 5.84075 21.8156 5.51256 21.4874C5.18437 21.1592 5 20.7141 5 20.25V16.875C5 16.6033 5.06325 16.3354 5.18475 16.0924C5.30625 15.8494 5.48266 15.638 5.7 15.475L11.4 11.2ZM7 17V20H17V17.033L11.9976 13.2518L7 17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.99957C5 5.44729 5.44772 4.99957 6 4.99957H18C18.5523 4.99957 19 5.44729 19 5.99957C19 6.55186 18.5523 6.99957 18 6.99957H6C5.44772 6.99957 5 6.55186 5 5.99957Z"
      />
    </svg>
  );
}

export default SvgQueue;
