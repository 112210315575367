import * as React from 'react';

function SvgPending(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.5C12.5523 6.5 13 6.94772 13 7.5V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V7.5C11 6.94772 11.4477 6.5 12 6.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.134 11.5C11.4101 11.0217 12.0217 10.8578 12.5 11.134L16.3971 13.384C16.8754 13.6601 17.0393 14.2717 16.7631 14.75C16.487 15.2283 15.8754 15.3922 15.3971 15.116L11.5 12.866C11.0217 12.5899 10.8578 11.9783 11.134 11.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0156 4.59838C21.5679 4.59838 22.0156 5.0461 22.0156 5.59838V9.34838C22.0156 9.90067 21.5679 10.3484 21.0156 10.3484H17.2656C16.7133 10.3484 16.2656 9.90067 16.2656 9.34838C16.2656 8.7961 16.7133 8.34838 17.2656 8.34838H20.0156V5.59838C20.0156 5.0461 20.4633 4.59838 21.0156 4.59838Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4144 4.88931C12.008 4.60956 10.5503 4.75314 9.22554 5.30187C7.90078 5.85061 6.76849 6.77986 5.97185 7.97212C5.1752 9.16437 4.75 10.5661 4.75 12C4.75 13.4339 5.17521 14.8356 5.97185 16.0279C6.76849 17.2201 7.90078 18.1494 9.22554 18.6981C10.5503 19.2469 12.008 19.3904 13.4144 19.1107C14.8208 18.831 16.1126 18.1405 17.1265 17.1265C17.517 16.736 18.1502 16.736 18.5407 17.1265C18.9313 17.517 18.9313 18.1502 18.5407 18.5407C17.2471 19.8344 15.5989 20.7154 13.8046 21.0723C12.0103 21.4292 10.1504 21.246 8.46018 20.5459C6.76996 19.8458 5.32531 18.6602 4.30891 17.139C3.2925 15.6179 2.75 13.8295 2.75 12C2.75 10.1705 3.2925 8.38213 4.30891 6.86098C5.32531 5.33982 6.76996 4.15423 8.46018 3.45411C10.1504 2.754 12.0103 2.57082 13.8046 2.92774C15.5989 3.28465 17.2471 4.16563 18.5407 5.45926L17.8979 6.10209L18.5407 5.45926L21.7227 8.64124C22.1132 9.03177 22.1132 9.66493 21.7227 10.0555C21.3322 10.446 20.699 10.446 20.3085 10.0555L17.1265 6.87348C16.1126 5.85954 14.8208 5.16905 13.4144 4.88931Z"
      />
    </svg>
  );
}

export default SvgPending;
