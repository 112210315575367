/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type BudgetDistribution = 'MONTHLY' | 'FULL';

export const BudgetDistribution = {
  MONTHLY: 'MONTHLY' as BudgetDistribution,
  FULL: 'FULL' as BudgetDistribution,
};
