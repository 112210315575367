import { Organisation, Shareholder } from '@libs/api/models';
import {
  ShareholderDocumentTypeForm,
  ShareholderTypeForm,
} from './onboarding-wizard.constants';

export type OnboardingShareholderFormData = Shareholder & {
  shareholderId: string;
  dao: File[];
  daoUrl: string;
  shareholderKnown: ShareholderTypeForm;
  shareholderKnownId: string;
};

export type OnboardingDocumentQualityFormData = {
  imageClear: boolean;
  imageColor: boolean;
  imageCopy: boolean;
  imagePdf: boolean;
};

type OnboardingDocumentIdentityData = {
  documentType: ShareholderDocumentTypeForm;
  frontFile: File[];
  backFile: File[];
  idBackUrl: string;
  idFrontUrl: string;
  passportUrl: string;
  passportFile: File[];
};

export type OnboardingDocumentIdentityFormData = OnboardingDocumentQualityFormData &
  OnboardingDocumentIdentityData;

export type OnboardingDocumentFormData = OnboardingDocumentIdentityData;

export type OnboardingOrganisationFormData = Organisation & {
  bce: File[];
  constitution: File[];
  constitution_url: string;
  bce_url: string;
};

export type OnboardingOrganisationConfirmFormData = Organisation & {
  termsAndConditions: boolean;
  termsAndConditionsTreezor: boolean;
};

export type AvailableSteps =
  | 'START_ONBOARDING'
  | 'CONFIRM_ORGANISATION'
  | 'REVIEW_ORGANISATION'
  | 'CONFIRM_SHAREHOLDER'
  | 'DOCUMENT_SHAREHOLDER'
  | 'REVIEW_SUBMIT'
  | 'UPLOAD_EMPLOYEES';

export const AvailableSteps = {
  START_ONBOARDING: 'START_ONBOARDING' as AvailableSteps,
  CONFIRM_ORGANISATION: 'CONFIRM_ORGANISATION' as AvailableSteps,
  REVIEW_ORGANISATION: 'REVIEW_ORGANISATION' as AvailableSteps,
  CONFIRM_SHAREHOLDER: 'CONFIRM_SHAREHOLDER' as AvailableSteps,
  DOCUMENT_SHAREHOLDER: 'DOCUMENT_SHAREHOLDER' as AvailableSteps,
  REVIEW_SUBMIT: 'REVIEW_SUBMIT' as AvailableSteps,
  UPLOAD_EMPLOYEES: 'UPLOAD_EMPLOYEES' as AvailableSteps,
};
