/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  PaymentMethodGetResponse,
  ListPaymentMethodParams,
  CreatePaymentMethodResponse,
  CreatePaymentMethodRequest,
  PaymentMethod,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const listPaymentMethod = <Data = unknown>(
  params?: ListPaymentMethodParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? PaymentMethodGetResponse : Data>(
    { url: `/v2/payment_methods`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getListPaymentMethodQueryKey = (
  params?: ListPaymentMethodParams,
) => [`/v2/payment_methods`, ...(params ? [params] : [])];

export const useListPaymentMethod = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ListPaymentMethodParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof listPaymentMethod>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getListPaymentMethodQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof listPaymentMethod>, Error>(
    queryKey,
    () => listPaymentMethod<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const createPaymentMethod = <Data = unknown>(
  createPaymentMethodRequest: CreatePaymentMethodRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? CreatePaymentMethodResponse : Data
  >(
    {
      url: `/v2/payment_methods`,
      method: 'post',
      data: createPaymentMethodRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useCreatePaymentMethod = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createPaymentMethod>,
    Error,
    { data: CreatePaymentMethodRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof createPaymentMethod>,
    Error,
    { data: CreatePaymentMethodRequest }
  >((props) => {
    const { data } = props || {};

    return createPaymentMethod<Data>(data, requestOptions);
  }, mutationOptions);
};
export const deletePaymentMethod = <Data = unknown>(
  paymentmethodid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/payment_methods/${paymentmethodid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useDeletePaymentMethod = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deletePaymentMethod>,
    Error,
    { paymentmethodid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof deletePaymentMethod>,
    Error,
    { paymentmethodid: string }
  >((props) => {
    const { paymentmethodid } = props || {};

    return deletePaymentMethod<Data>(paymentmethodid, requestOptions);
  }, mutationOptions);
};
export const validatePaymentMethod = <Data = unknown>(
  paymentmethodid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? PaymentMethod : Data>(
    {
      url: `/v2/payment_methods/${paymentmethodid}/validate`,
      method: 'post',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useValidatePaymentMethod = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof validatePaymentMethod>,
    Error,
    { paymentmethodid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof validatePaymentMethod>,
    Error,
    { paymentmethodid: string }
  >((props) => {
    const { paymentmethodid } = props || {};

    return validatePaymentMethod<Data>(paymentmethodid, requestOptions);
  }, mutationOptions);
};
