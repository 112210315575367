import { Flex } from '@chakra-ui/layout';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { PageHeader } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../../components/dashboard-layout';
import { TransactionTable } from './component/transaction-table';

export const TransactionPage = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <Flex display={{ base: 'block', lg: 'flex' }} justify="space-between">
        <PageHeader size="Large" title={t(i18nKeys.common.transactions)} />
      </Flex>
      <TransactionTable />
    </DashboardLayout>
  );
};
