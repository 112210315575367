import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { media } from '@libs/ui/theme';
import React from 'react';

type WizardBulletProps = {
  modCompleted: boolean;
  modActive: boolean;
  modSuccess: boolean;
};

export const WizardConnector = styled(
  (props: BoxProps & WizardBulletProps) => <Box {...props} />,
  {
    shouldForwardProp: isPropValid,
  },
)`
  top: 2rem;
  left: calc(-50% + 3rem);
  right: calc(50% + 3rem);
  position: absolute;
  flex: 1 1 auto;
  border-top-style: solid;
  border-top-width: 0.2rem;
  width: calc(100% - 6rem);
  border-color: ${({ theme, modActive }) =>
    modActive && theme.colors.main.primary.normal};
  border-color: ${({ theme, modCompleted }) =>
    modCompleted && theme.colors.main.primary.normal};
  border-color: ${({ theme, modSuccess }) =>
    modSuccess && theme.colors.status.positive.type};
`;

export const WizardBullet = styled.span<WizardBulletProps>`
  border: 0.3rem solid
    ${({ theme, modCompleted, modActive, modSuccess }) => {
      if (modSuccess) {
        return theme.colors.status.positive.type;
      }
      if (!modActive && !modCompleted) {
        return theme.colors.ink.light;
      }

      return theme.colors.main.primary.normal;
    }};
  ${media.up('md')} {
    border: 1rem solid
      ${({ theme, modCompleted, modActive, modSuccess }) => {
        if (modSuccess) {
          return theme.colors.status.positive.type;
        }
        if (!modActive && !modCompleted) {
          return theme.colors.ink.light;
        }

        return theme.colors.main.primary.normal;
      }};
  }

  width: 2.5rem;
  height: 2.5rem;

  ${media.up('md')} {
    width: 4rem;
    height: 4rem;
  }

  background-color: ${({ theme, modCompleted, modSuccess }) => {
    if (modSuccess) {
      return theme.colors.status.positive.type;
    }
    if (modCompleted) {
      return theme.colors.main.primary.normal;
    }
    return 'transparant';
  }};
  color: ${({ theme }) => theme.colors.ink.white};
  border-radius: 50%;
  margin-bottom: 1rem;
  flex-shrink: 0;
  display: flex;
  padding-right: 0;
`;

export const WizardLabel = styled(
  (props: FlexProps & WizardBulletProps) => <Flex {...props} />,
  {
    shouldForwardProp: isPropValid,
  },
)`
  width: 90%;
  min-width: 12rem;
  font-size: 1.2rem;
  ${media.up('sm')} {
    font-size: 1.5rem;
  }

  white-space: normal;
  color: ${({ theme, modCompleted, modActive, modSuccess }) => {
    if (modSuccess) {
      return theme.colors.status.positive.type;
    }
    if (!modActive && !modCompleted) {
      return theme.colors.ink.light;
    }
    return theme.colors.ink.black;
  }};
`;

export const WizardButton = styled((props: ButtonProps) => (
  <Button {...props} />
))`
  &:focus {
    box-shadow: none;
  }
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 auto;
`;

export const WizardBox = styled(Box)`
  flex: 1;
  position: relative;
  &:first-of-type {
    ${WizardConnector} {
      display: none;
    }
  }
`;
