import { Document, DocumentType } from '@libs/api/models';

export const mergeDocuments = (
  newDocuments: Document[],
  oldDocuments: Document[] = [],
) => {
  return [
    ...newDocuments,
    ...oldDocuments.filter(
      (document) =>
        !newDocuments.some((d) => d.document_type === document.document_type),
    ),
  ];
};

export const findDocumentByType = (
  documents: Document[],
  type: DocumentType,
) => {
  return documents?.find((doc) => doc.document_type === type);
};
