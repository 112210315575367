export const Tag = {
  baseStyle: ({ theme }) => ({
    container: {
      fontWeight: theme.fontWeights.normal,
      fontSize: theme.fontSizes.small,
    },
    label: {
      lineHeight: theme.lineHeights.small,
      fontSize: theme.fontSizes.small,
    },
  }),
  sizes: {
    md: {
      container: ({ theme }) => ({
        fontSize: theme.fontSizes.small,
        minH: '1.8rem',
        px: 0,
      }),
    },
  },
  variants: {
    status: ({ theme }) => ({
      container: {
        backgroundColor: 'transparant',
        color: theme.colors.ink.medium,
      },
    }),
    positive: ({ theme }) => ({
      container: {
        backgroundColor: 'status.positive.light',
        color: 'status.positive.type',
        border: `solid ${theme.borders.xs}`,
        borderColor: 'status.positive.type',
        borderRadius: '1.2rem',
        px: '.75rem',
        py: '.5rem',
      },
    }),
    negative: ({ theme }) => ({
      container: {
        backgroundColor: 'status.negative.light',
        color: 'status.negative.type',
        border: `solid ${theme.borders.xs}`,
        borderColor: 'status.negative.type',
        borderRadius: '1.2rem',
        px: '.75rem',
        py: '.5rem',
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'status',
  },
};
