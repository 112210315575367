import jwt_decode from 'jwt-decode';

export type DecodeTokenResponse<T = unknown> = {
  token: T;
  error: Error;
};

export const decodeJWT = <T = unknown>(
  token: string,
): DecodeTokenResponse<T> => {
  if (token === '') {
    return { token: undefined, error: Error('missing_token') };
  }
  try {
    const decodedToken = jwt_decode<T>(token);
    return { token: decodedToken, error: undefined };
  } catch (error) {
    return { token: undefined, error: Error('invalid_token') };
  }
};
