import { Box, Text } from '@chakra-ui/layout';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Loader } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type QueryStatusTextProps = {
  statusText: { [x: string]: string };
  status: QueryStatus;
  error: any;
  isDisabled?: boolean;
};

export const QueryStatusText = ({
  statusText,
  status,
  error,
  isDisabled,
}: QueryStatusTextProps) => {
  const { t } = useTranslation();

  if (isDisabled) {
    return null;
  }

  return (
    <Box>
      {status === QueryStatus.loading && statusText[QueryStatus.loading] && (
        <Box height="5rem" position="relative">
          <Loader modRight ml="0">
            <Text ml="4rem" size="Body2">
              {statusText[status]}
            </Text>
          </Loader>
        </Box>
      )}
      {status === QueryStatus.idle && statusText[QueryStatus.idle] && (
        <Text size="Body2" color="ink.dark" mt="2rem">
          {statusText[status]}
        </Text>
      )}
      {status === QueryStatus.success && statusText[QueryStatus.success] && (
        <Text size="Body2" color="status.positive.type" mt="2rem">
          {statusText[status]}
        </Text>
      )}

      {status === QueryStatus.error && statusText[QueryStatus.error] && (
        <Text size="Body2" color="status.negative.type" mt="2rem">
          {statusText[status]} -{' '}
          {error?.translation?.key
            ? t(i18nKeys.http_errors[error.translation.key])
            : error}
        </Text>
      )}
    </Box>
  );
};
