import { Alert, AlertProps } from '@chakra-ui/react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import React from 'react';

export const AlertBox = styled((props: AlertProps) => <Alert {...props} />, {
  shouldForwardProp: isPropValid,
})`
  background-color: ${({ theme }) => theme.colors.ink.white};
  border-left: 0.5rem solid
    ${({ status }) => {
      switch (status) {
        case 'error':
          return ({ theme }) => theme.colors.status.negative.type;
        case 'success':
          return ({ theme }) => theme.colors.status.positive.type;
        case 'warning':
          return ({ theme }) => theme.colors.status.pending.type;
        case 'info':
          return ({ theme }) => theme.colors.status.info.type;
        default:
          return ({ theme }) => theme.colors.status.info.type;
      }
    }};
  border-radius: 0.6rem;
`;
