export type AvailableLanguage = 'fr' | 'en' | 'nl';

export const AvailableLanguage = {
  FR: 'fr' as AvailableLanguage,
  EN: 'en' as AvailableLanguage,
  NL: 'nl' as AvailableLanguage,
};

export type I18nConfig = {
  languages: AvailableLanguage[];
  defaultLanguage: AvailableLanguage;
  fallbackLanguage: AvailableLanguage;
};
