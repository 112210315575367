/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type WalletStatus = 'EMPTY' | 'LOW' | 'FILLED';

export const WalletStatus = {
  EMPTY: 'EMPTY' as WalletStatus,
  LOW: 'LOW' as WalletStatus,
  FILLED: 'FILLED' as WalletStatus,
};
