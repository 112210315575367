/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, UseQueryOptions } from 'react-query';
import type {
  ReportGraphDataGetResponse,
  ReportGetReportCo2GraphDataParams,
  ReportSummaryDataGetResponse,
  ReportGetReportCo2SummaryDataParams,
  ReportExpensesSummaryDataGetResponse,
  ReportGetReportExpensesSummaryDataParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const reportGetReportCo2GraphData = <Data = unknown>(
  reporttype: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? ReportGraphDataGetResponse : Data
  >(
    { url: `/v2/reports/${reporttype}/graphs`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getReportGetReportCo2GraphDataQueryKey = (
  reporttype: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
) => [`/v2/reports/${reporttype}/graphs`, ...(params ? [params] : [])];

export const useReportGetReportCo2GraphData = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  reporttype: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof reportGetReportCo2GraphData>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getReportGetReportCo2GraphDataQueryKey(reporttype, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof reportGetReportCo2GraphData>,
    Error
  >(
    queryKey,
    () => reportGetReportCo2GraphData<Data>(reporttype, params, requestOptions),
    { enabled: !!reporttype, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const reportGetReportCo2SummaryData = <Data = unknown>(
  params?: ReportGetReportCo2SummaryDataParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? ReportSummaryDataGetResponse : Data
  >(
    { url: `/v2/reports/co2/summaries`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getReportGetReportCo2SummaryDataQueryKey = (
  params?: ReportGetReportCo2SummaryDataParams,
) => [`/v2/reports/co2/summaries`, ...(params ? [params] : [])];

export const useReportGetReportCo2SummaryData = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ReportGetReportCo2SummaryDataParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof reportGetReportCo2SummaryData>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getReportGetReportCo2SummaryDataQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof reportGetReportCo2SummaryData>,
    Error
  >(
    queryKey,
    () => reportGetReportCo2SummaryData<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const reportGetReportExpensesSummaryData = <Data = unknown>(
  params?: ReportGetReportExpensesSummaryDataParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? ReportExpensesSummaryDataGetResponse : Data
  >(
    { url: `/v2/reports/expenses/summaries`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getReportGetReportExpensesSummaryDataQueryKey = (
  params?: ReportGetReportExpensesSummaryDataParams,
) => [`/v2/reports/expenses/summaries`, ...(params ? [params] : [])];

export const useReportGetReportExpensesSummaryData = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ReportGetReportExpensesSummaryDataParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof reportGetReportExpensesSummaryData>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getReportGetReportExpensesSummaryDataQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof reportGetReportExpensesSummaryData>,
    Error
  >(
    queryKey,
    () => reportGetReportExpensesSummaryData<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
