/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyConditionStatement = 'BETWEEN';

export const PolicyConditionStatement = {
  BETWEEN: 'BETWEEN' as PolicyConditionStatement,
};
