/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PaymentMethodType = 'CREDIT_CARD';

export const PaymentMethodType = {
  CREDIT_CARD: 'CREDIT_CARD' as PaymentMethodType,
};
