/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  MerchantsGetResponse,
  MerchantsGetParams,
  Merchant,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const merchantsGet = <Data = unknown>(
  params?: MerchantsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? MerchantsGetResponse : Data>(
    { url: `/merchants`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMerchantsGetQueryKey = (params?: MerchantsGetParams) => [
  `/merchants`,
  ...(params ? [params] : []),
];

export const useMerchantsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: MerchantsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof merchantsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMerchantsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof merchantsGet>, Error>(
    queryKey,
    () => merchantsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const merchantsUpdate = <Data = unknown>(
  merchantid: string,
  merchant: Merchant,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Merchant : Data>(
    { url: `/merchants/${merchantid}`, method: 'put', data: merchant },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMerchantsUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof merchantsUpdate>,
    Error,
    { merchantid: string; data: Merchant },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof merchantsUpdate>,
    Error,
    { merchantid: string; data: Merchant }
  >((props) => {
    const { merchantid, data } = props || {};

    return merchantsUpdate<Data>(merchantid, data, requestOptions);
  }, mutationOptions);
};
