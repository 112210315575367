import { Box, Text } from '@chakra-ui/react';
import React from 'react';

type ReleaseVersionProps = {
  isOpen?: boolean;
  color?: string | { base: string; md: string };
  size?: string;
};

export const ReleaseVersion = ({
  isOpen,
  color,
  size = 'Small',
}: ReleaseVersionProps) => {
  return (
    <Box
      display={{ base: isOpen ? 'block' : 'none', lg: 'block' }}
      position="absolute"
      bottom="0"
      text-align="center"
      p="2rem 1rem 0"
      left="50%"
      transform="translateX(-50%)"
      width="80%"
    >
      <Text
        color={color ? color : 'nav.type.normal'}
        opacity=".6"
        size={size}
        textAlign="center"
        fontWeight="bold"
      >
        {process.env.NX_RELEASE_VERSION}
      </Text>
    </Box>
  );
};
