/* eslint-disable react-hooks/rules-of-hooks */
import { useExpensesGet } from '@libs/api/endpoints';
import { useExpenseList } from '@libs/api-v2/endpoints';
import { ExpensesGetResponse } from '@libs/api-v2/models';
import {
  Registration,
  ExpensesGetResponse as ExpensesGetResponseV1,
} from '@libs/api/models';
import { usePager } from '@libs/ui/components';
import { useTable } from '@libs/ui/components/table/table.utils';
import React, { useEffect } from 'react';
import { ExpensesTableOldGen } from './expenses-table-oldgen';
import { ExpensesTable } from '../../../expense/components/expenses-table';

type EmployeeDetailsExpensesProps = {
  employee: Registration;
  isOldGen?: boolean;
};

interface ExpensesListResponse {
  data: ExpensesGetResponseV1 | ExpensesGetResponse;
  isLoading: boolean;
  isFetching: boolean;
  refetch;
}
export const EmployeeDetailsExpenses = ({
  employee,
  isOldGen = false,
}: EmployeeDetailsExpensesProps) => {
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();

  const params = {
    membership_id: employee.membership_id,
    page_number: pageNumber,
    page_size: pageSize,
  };
  const {
    data: expenses,
    isLoading,
    isFetching,
    refetch,
  }: ExpensesListResponse = !isOldGen
    ? useExpenseList(params, { query: { keepPreviousData: true } })
    : useExpensesGet(params, { query: { keepPreviousData: true } });

  const { useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  useQueryParams({
    totalPages: expenses?.meta.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  useEffect(() => {
    refetch();
  }, [refetch, pageSize]);

  return isOldGen ? (
    <ExpensesTableOldGen
      expenses={expenses}
      isLoading={isLoading}
      isFetching={isFetching}
      setPageNumber={setPageNumber}
      setPageSize={setPageSize}
      pageSize={pageSize}
      pageNumber={pageNumber}
    />
  ) : (
    <ExpensesTable membershipId={employee?.membership_id} />
  );
};
