import { Box, Text, useTheme } from '@chakra-ui/react';
import React from 'react';

export const ErrorText = ({
  error,
  isValid = false,
  icon,
}: {
  error: string;
  isValid?: boolean;
  icon: any;
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        display="inline-block"
        w="1.2rem"
        h="1.2rem"
        mr=".5rem"
        top=".2rem"
        pos="relative"
        fill={
          isValid
            ? theme.colors.status.positive.type
            : theme.colors.status.negative.type
        }
        data-cy={
          isValid
            ? 'form-control-password-validation-success'
            : 'form-control-password-validation-error'
        }
      >
        {icon}
      </Box>
      <Text
        textDecoration={isValid ? 'line-through' : undefined}
        display="inline-block"
        size="Small"
        color={
          isValid
            ? theme.colors.status.positive.type
            : theme.colors.status.negative.type
        }
      >
        {error}
      </Text>
    </>
  );
};
