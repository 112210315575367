/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type MembershipRole = 'EMPLOYEE' | 'OBSERVER' | 'REVIEWER' | 'ADMIN';

export const MembershipRole = {
  EMPLOYEE: 'EMPLOYEE' as MembershipRole,
  OBSERVER: 'OBSERVER' as MembershipRole,
  REVIEWER: 'REVIEWER' as MembershipRole,
  ADMIN: 'ADMIN' as MembershipRole,
};
