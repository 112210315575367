import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { PageHeader } from '@libs/ui/components';
import { Link } from '@libs/ui/components/link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../../components/dashboard-layout';
import { ServicesTable } from './components/services-table';
// import { CreateServiceDrawer } from './components/service-create-drawer';

export const ServicePage = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <Breadcrumb mb="2rem">
        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD)}
          >
            {t(i18nKeys.common.home)}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink variant="breadcrumb" as={Link} to="#">
            {t(i18nKeys.operator.services.title)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex display={{ base: 'block', lg: 'flex' }} justify="space-between">
        <PageHeader
          size="Large"
          title={t(i18nKeys.operator.services.title)}
          introduction={t(i18nKeys.operator.services.introduction)}
        />
        {/* <Button
          mt={{ base: '2rem', lg: '0' }}
          type="button"
          variant="primary"
          size="body1"
          onClick={() => onOpen()}
        >
          {t(i18nKeys.operator.services.add)}
        </Button> */}
      </Flex>
      <ServicesTable />
      {/* <CreateServiceDrawer isOpen={isOpen} onClose={onClose} /> */}
    </DashboardLayout>
  );
};
