/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  PoliciesListResponse,
  ListProgramExpensePoliciesParams,
  Policy,
  UpsertPolicyRequest,
  CheckPolicyResponse,
  CheckPolicyRequest,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const listProgramExpensePolicies = <Data = unknown>(
  params?: ListProgramExpensePoliciesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? PoliciesListResponse : Data>(
    { url: `/v2/policies`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getListProgramExpensePoliciesQueryKey = (
  params?: ListProgramExpensePoliciesParams,
) => [`/v2/policies`, ...(params ? [params] : [])];

export const useListProgramExpensePolicies = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ListProgramExpensePoliciesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof listProgramExpensePolicies>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getListProgramExpensePoliciesQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof listProgramExpensePolicies>,
    Error
  >(
    queryKey,
    () => listProgramExpensePolicies<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const createProgramPolicy = <Data = unknown>(
  upsertPolicyRequest: UpsertPolicyRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Policy : Data>(
    { url: `/v2/policies`, method: 'post', data: upsertPolicyRequest },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useCreateProgramPolicy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createProgramPolicy>,
    Error,
    { data: UpsertPolicyRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof createProgramPolicy>,
    Error,
    { data: UpsertPolicyRequest }
  >((props) => {
    const { data } = props || {};

    return createProgramPolicy<Data>(data, requestOptions);
  }, mutationOptions);
};
export const checkPolicy = <Data = unknown>(
  checkPolicyRequest: CheckPolicyRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? CheckPolicyResponse : Data>(
    {
      url: `/v2/policies/check_policy`,
      method: 'post',
      data: checkPolicyRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useCheckPolicy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof checkPolicy>,
    Error,
    { data: CheckPolicyRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof checkPolicy>,
    Error,
    { data: CheckPolicyRequest }
  >((props) => {
    const { data } = props || {};

    return checkPolicy<Data>(data, requestOptions);
  }, mutationOptions);
};
export const getProgramExpensePolicy = <Data = unknown>(
  policyid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Policy : Data>(
    { url: `/v2/policies/${policyid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGetProgramExpensePolicyQueryKey = (policyid: string) => [
  `/v2/policies/${policyid}`,
];

export const useGetProgramExpensePolicy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  policyid: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getProgramExpensePolicy>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGetProgramExpensePolicyQueryKey(policyid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof getProgramExpensePolicy>,
    Error
  >(queryKey, () => getProgramExpensePolicy<Data>(policyid, requestOptions), {
    enabled: !!policyid,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const putProgramExpensePolicy = <Data = unknown>(
  policyid: string,
  upsertPolicyRequest: UpsertPolicyRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Policy : Data>(
    {
      url: `/v2/policies/${policyid}`,
      method: 'put',
      data: upsertPolicyRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const usePutProgramExpensePolicy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof putProgramExpensePolicy>,
    Error,
    { policyid: string; data: UpsertPolicyRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof putProgramExpensePolicy>,
    Error,
    { policyid: string; data: UpsertPolicyRequest }
  >((props) => {
    const { policyid, data } = props || {};

    return putProgramExpensePolicy<Data>(policyid, data, requestOptions);
  }, mutationOptions);
};
export const deleteProgramExpensePolicy = <Data = unknown>(
  policyid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/policies/${policyid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useDeleteProgramExpensePolicy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteProgramExpensePolicy>,
    Error,
    { policyid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof deleteProgramExpensePolicy>,
    Error,
    { policyid: string }
  >((props) => {
    const { policyid } = props || {};

    return deleteProgramExpensePolicy<Data>(policyid, requestOptions);
  }, mutationOptions);
};
