import {
  Button,
  Center,
  Flex,
  Heading,
  Radio,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Title } from '@libs/api/models';
import { LEGAL_BIRTHDAY } from '@libs/core/constants';
import { i18nCountryKeys } from '@libs/core/i18n';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { FIREBASE_STORAGE_FILES } from '@libs/dashboard-core/constants';
import { initYearMaxDate } from '@libs/dashboard-core/utils/date';
import {
  Card,
  DateField,
  InputField,
  RadioGroupField,
  SelectField,
} from '@libs/ui/components';
import { FormikState } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ShareholderTypeForm,
  SHAREHOLDER_DEFAULT,
  SHAREHOLDER_ID_NEW,
  UPLOAD_FILE_OPTIONS,
} from '../onboarding-wizard.constants';
import { OnboardingShareholderFormData } from '../onboarding-wizard.types';
import { UploadPreview } from './upload-preview';

type ShareholderDetailsProps = {
  resetForm: (
    nextState?: Partial<FormikState<OnboardingShareholderFormData>>,
  ) => void;
  isSubmitting: boolean;
  isValid: boolean;
  values: OnboardingShareholderFormData;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ShareholderDetails = ({
  values,
  setShowDetails,
  resetForm,
  isSubmitting,
  isValid,
}: ShareholderDetailsProps) => {
  const { t } = useTranslation();
  const { t: tCountry } = useTranslation('countries');

  return (
    <>
      <Card>
        <RadioGroupField
          mt="2rem"
          label={t(i18nKeys.common.gender)}
          name="title"
          isRequired
        >
          <Stack direction="row" spacing="3rem">
            <Radio value={Title.M}>{t(i18nKeys.common.mr)}</Radio>
            <Radio value={Title.MME}>{t(i18nKeys.common.mrs)}</Radio>
          </Stack>
        </RadioGroupField>
        <SimpleGrid columns={[1, null, 2]} spacingX={10} mb="2rem">
          <InputField
            name="email"
            label={t(i18nKeys.common.email)}
            isRequired
          />
          <InputField
            name="phone_number"
            label={t(i18nKeys.common.phone_number)}
            isRequired
          />
          <InputField
            name="first_name"
            label={t(i18nKeys.common.firstname)}
            isRequired
          />
          <InputField
            name="last_name"
            label={t(i18nKeys.common.lastname)}
            isRequired
          />
          <SelectField
            name="nationality"
            label={t(i18nKeys.common.nationality)}
            isRequired
          >
            {Object.values(i18nCountryKeys).map((code) => (
              <option value={code} key={code}>
                {tCountry(code)}
              </option>
            ))}
          </SelectField>
          <InputField
            name="taxes.tax_residence_id"
            label={t(i18nKeys.common.national_registry_number)}
            isRequired
          />
          <InputField
            name="address.street"
            label={t(i18nKeys.common.address)}
            isRequired
          />
          <Stack direction="row">
            <InputField
              name="address.city"
              label={t(i18nKeys.common.city)}
              isRequired
            />
            <Center width="100px">
              <InputField
                name="address.postal_code"
                label={t(i18nKeys.common.postal_code)}
                isRequired
              />
            </Center>
          </Stack>
          <SelectField
            name="address.country_code"
            label={t(i18nKeys.common.country_code)}
            isRequired
          >
            {Object.values(i18nCountryKeys).map((code) => (
              <option value={code} key={code}>
                {tCountry(code)}
              </option>
            ))}
          </SelectField>
          <DateField
            maxDate={initYearMaxDate(LEGAL_BIRTHDAY)}
            name="date_of_birth"
            label={t(i18nKeys.common.date_of_birth)}
            hasSimpleDate
            isRequired
          />
          <InputField
            name="place_of_birth"
            label={t(i18nKeys.common.place_of_birth)}
            isRequired
          />
          <InputField
            name="occupation"
            label={t(i18nKeys.common.occupation)}
            isRequired
          />
        </SimpleGrid>
      </Card>

      {values.shareholderId === SHAREHOLDER_ID_NEW && (
        <Card mt="2rem">
          <Heading size="Headline">
            {t(i18nKeys.onboarding.review_beneficiary.document.title)}
          </Heading>
          <Text>
            {t(i18nKeys.onboarding.review_beneficiary.document.decription)}
          </Text>
          <UploadPreview
            options={UPLOAD_FILE_OPTIONS}
            uploadName="dao"
            previewTitle={t(
              i18nKeys.onboarding.review_beneficiary.document.title,
            )}
            uploadURL={values.daoUrl}
            previewURL={FIREBASE_STORAGE_FILES.images.DOA}
          />
        </Card>
      )}

      <Flex justify="space-between" p="4rem 0 8rem">
        <Button
          onClick={() => {
            setShowDetails(false);
            resetForm({
              values: {
                shareholderId: SHAREHOLDER_ID_NEW,
                ...SHAREHOLDER_DEFAULT,
                dao: [],
                daoUrl: '',
                title: Title.M,
                shareholderKnown: ShareholderTypeForm.UNDEFINED,
                shareholderKnownId: '',
              },
            });
          }}
          data-cy="shareholder-details-button-previous"
          size="body1"
          variant="border"
        >
          {t(i18nKeys.common.previous)}
        </Button>

        <Button
          type="submit"
          variant="primary"
          size="body1"
          isLoading={isSubmitting}
          loadingText={t(i18nKeys.common.next)}
          data-cy="shareholder-details-button-submit"
          disabled={!isValid}
        >
          {t(i18nKeys.common.next)}
        </Button>
      </Flex>
    </>
  );
};
