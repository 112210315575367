/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type SocialSecretarySocialSecretaryEmployeeType = 'WORKER' | 'CLERK';

export const SocialSecretarySocialSecretaryEmployeeType = {
  WORKER: 'WORKER' as SocialSecretarySocialSecretaryEmployeeType,
  CLERK: 'CLERK' as SocialSecretarySocialSecretaryEmployeeType,
};
