import { Center, Image } from '@chakra-ui/react';
import { Loader, Modal } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderDocumentTypeForm } from '../onboarding-wizard.constants';
import { useDocumentExample } from '../onboarding-wizard.utils';

type DocumentsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  documentType: ShareholderDocumentTypeForm;
};

export const DocumentsModal = ({
  isOpen,
  onClose,
  documentType,
}: DocumentsModalProps) => {
  const { t } = useTranslation();
  const [title, image] = useDocumentExample(documentType);
  return (
    <Modal title={t(title)} isOpen={isOpen} onClose={onClose}>
      <Modal.Body>
        <Center>
          <Image
            alt={t(title)}
            src={image}
            w="300px"
            h="auto"
            fallback={<Loader />}
          />
        </Center>
      </Modal.Body>
    </Modal>
  );
};
