/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type SubscriptionStatus =
  | 'NOT_STARTED'
  | 'VALIDATED'
  | 'PENDING'
  | 'REFUSED';

export const SubscriptionStatus = {
  NOT_STARTED: 'NOT_STARTED' as SubscriptionStatus,
  VALIDATED: 'VALIDATED' as SubscriptionStatus,
  PENDING: 'PENDING' as SubscriptionStatus,
  REFUSED: 'REFUSED' as SubscriptionStatus,
};
