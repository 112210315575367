import * as React from 'react';

function SvgInfo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 11.25C10.25 10.6977 10.6977 10.25 11.25 10.25H12C12.5523 10.25 13 10.6977 13 11.25V15.5315C13.4313 15.6425 13.75 16.034 13.75 16.5C13.75 17.0523 13.3023 17.5 12.75 17.5H12C11.4477 17.5 11 17.0523 11 16.5V12.2185C10.5687 12.1075 10.25 11.716 10.25 11.25Z"
      />
      <path d="M12 9C12.6213 9 13.125 8.49632 13.125 7.875C13.125 7.25368 12.6213 6.75 12 6.75C11.3787 6.75 10.875 7.25368 10.875 7.875C10.875 8.49632 11.3787 9 12 9Z" />
    </svg>
  );
}

export default SvgInfo;
