export type CurrencySymbol = '€';
export const CurrencySymbol = { euro: '€' as CurrencySymbol };

export type CurrencyFormat = 'euro' | 'eurocent';
export const CurrencyFormat = {
  euro: 'euro' as CurrencyFormat,
  eurocent: 'eurocent' as CurrencyFormat,
};

type formatCurrencyProps = {
  format?: CurrencyFormat;
  number: number;
};
export const formatCurrency = ({
  format = CurrencyFormat.euro,
  number,
}: formatCurrencyProps) => {
  const formattedValue =
    format === CurrencyFormat.euro
      ? Number(number / 100).toFixed(2)
      : (parseFloat(number.toFixed(2)) * 100).toFixed(0);

  return formattedValue;
};
