import { Role } from '@libs/api/models';
import React, { PropsWithChildren } from 'react';
import { useRoleState } from '../context';

type RoleRouteProps = PropsWithChildren<{
  role: Role;
}>;

export const RoleRoute = ({ role, children }: RoleRouteProps) => {
  const { role: actualRole } = useRoleState();

  if (role !== actualRole) {
    return null;
  }
  return children as React.ReactElement;
};
