import React from 'react';
import { Text } from '@chakra-ui/layout';
import { NotionTextArrayItem } from '@libs/api-v2/models/notionTextArrayItem';

type ServiceTextSpanProps = {
  text: NotionTextArrayItem;
  index: number;
};
export const ServiceTextSpan = ({ text, index }: ServiceTextSpanProps) => {
  // Attributes
  //   const theme = useTheme();
  const hasUrl = !!text?.text?.link?.url;

  if (hasUrl) {
    return (
      <Text
        as={'a'}
        target="_blank"
        variant={text?.annotations?.bold ? 'bold' : 'normal'}
        size="200"
        ml={index !== 0 ? 1 : 0}
        style={{ cursor: 'pointer' }}
        // color={theme.colors.main.primary.t500}
      >
        {text?.plain_text}
      </Text>
    );
  }

  return (
    <Text
      as={'span'}
      variant={text?.annotations?.bold ? 'bold' : 'normal'}
      size="200"
      ml={index !== 0 ? 1 : 0}
      //   color={theme.colors.main.neutral.t700}
    >
      {text?.plain_text}
    </Text>
  );
};
