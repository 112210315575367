/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type Title = 'M' | 'MME';

export const Title = {
  M: 'M' as Title,
  MME: 'MME' as Title,
};
