/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, UseQueryOptions } from 'react-query';
import type {
  CardTransactionsGetResponse,
  CardTransactionsGetParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const cardTransactionsGet = <Data = unknown>(
  params?: CardTransactionsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? CardTransactionsGetResponse : Data
  >(
    { url: `/cardTransactions`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getCardTransactionsGetQueryKey = (
  params?: CardTransactionsGetParams,
) => [`/cardTransactions`, ...(params ? [params] : [])];

export const useCardTransactionsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: CardTransactionsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof cardTransactionsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getCardTransactionsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof cardTransactionsGet>, Error>(
    queryKey,
    () => cardTransactionsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
