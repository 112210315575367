/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  ExpensesGetResponse,
  ExpenseListParams,
  ExpenseWithMeta,
  Expense,
  Policy,
  ExpenseValidationRequest,
  ExpenseRefundUpdateRequest,
  ExpenseExportResponse,
  ExpenseExportRequest,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const expenseList = <Data = unknown>(
  params?: ExpenseListParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpensesGetResponse : Data>(
    { url: `/v2/expenses`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getExpenseListQueryKey = (params?: ExpenseListParams) => [
  `/v2/expenses`,
  ...(params ? [params] : []),
];

export const useExpenseList = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ExpenseListParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof expenseList>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getExpenseListQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof expenseList>, Error>(
    queryKey,
    () => expenseList<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const expensesCreate = <Data = unknown>(
  expense: Expense,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpenseWithMeta : Data>(
    { url: `/v2/expenses`, method: 'post', data: expense },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useExpensesCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof expensesCreate>,
    Error,
    { data: Expense },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof expensesCreate>,
    Error,
    { data: Expense }
  >((props) => {
    const { data } = props || {};

    return expensesCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const expenseGet = <Data = unknown>(
  expenseid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpenseWithMeta : Data>(
    { url: `/v2/expenses/${expenseid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getExpenseGetQueryKey = (expenseid: string) => [
  `/v2/expenses/${expenseid}`,
];

export const useExpenseGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  expenseid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof expenseGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getExpenseGetQueryKey(expenseid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof expenseGet>, Error>(
    queryKey,
    () => expenseGet<Data>(expenseid, requestOptions),
    { enabled: !!expenseid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const expensesUpdate = <Data = unknown>(
  expenseid: string,
  expense: Expense,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpenseWithMeta : Data>(
    { url: `/v2/expenses/${expenseid}`, method: 'put', data: expense },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useExpensesUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof expensesUpdate>,
    Error,
    { expenseid: string; data: Expense },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof expensesUpdate>,
    Error,
    { expenseid: string; data: Expense }
  >((props) => {
    const { expenseid, data } = props || {};

    return expensesUpdate<Data>(expenseid, data, requestOptions);
  }, mutationOptions);
};
export const expenseGetPolicy = <Data = unknown>(
  expenseid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Policy | unknown : Data>(
    { url: `/v2/expenses/${expenseid}/applied_policy`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getExpenseGetPolicyQueryKey = (expenseid: string) => [
  `/v2/expenses/${expenseid}/applied_policy`,
];

export const useExpenseGetPolicy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  expenseid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof expenseGetPolicy>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getExpenseGetPolicyQueryKey(expenseid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof expenseGetPolicy>, Error>(
    queryKey,
    () => expenseGetPolicy<Data>(expenseid, requestOptions),
    { enabled: !!expenseid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const expensesValidationUpdate = <Data = unknown>(
  expenseid: string,
  expenseValidationRequest: ExpenseValidationRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpenseWithMeta : Data>(
    {
      url: `/v2/expenses/${expenseid}/review`,
      method: 'post',
      data: expenseValidationRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useExpensesValidationUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof expensesValidationUpdate>,
    Error,
    { expenseid: string; data: ExpenseValidationRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof expensesValidationUpdate>,
    Error,
    { expenseid: string; data: ExpenseValidationRequest }
  >((props) => {
    const { expenseid, data } = props || {};

    return expensesValidationUpdate<Data>(expenseid, data, requestOptions);
  }, mutationOptions);
};
export const expensesRefundUpdate = <Data = unknown>(
  expenseid: string,
  expenseRefundUpdateRequest: ExpenseRefundUpdateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpenseWithMeta : Data>(
    {
      url: `/v2/expenses/${expenseid}/refund_status`,
      method: 'put',
      data: expenseRefundUpdateRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useExpensesRefundUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof expensesRefundUpdate>,
    Error,
    { expenseid: string; data: ExpenseRefundUpdateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof expensesRefundUpdate>,
    Error,
    { expenseid: string; data: ExpenseRefundUpdateRequest }
  >((props) => {
    const { expenseid, data } = props || {};

    return expensesRefundUpdate<Data>(expenseid, data, requestOptions);
  }, mutationOptions);
};
export const expensesSubmit = <Data = unknown>(
  expenseid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpenseWithMeta : Data>(
    {
      url: `/v2/expenses/${expenseid}/submit`,
      method: 'post',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useExpensesSubmit = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof expensesSubmit>,
    Error,
    { expenseid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof expensesSubmit>,
    Error,
    { expenseid: string }
  >((props) => {
    const { expenseid } = props || {};

    return expensesSubmit<Data>(expenseid, requestOptions);
  }, mutationOptions);
};
export const expensesExport = <Data = unknown>(
  expenseExportRequest: ExpenseExportRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpenseExportResponse : Data>(
    { url: `/v2/expenses/export`, method: 'post', data: expenseExportRequest },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useExpensesExport = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof expensesExport>,
    Error,
    { data: ExpenseExportRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof expensesExport>,
    Error,
    { data: ExpenseExportRequest }
  >((props) => {
    const { data } = props || {};

    return expensesExport<Data>(data, requestOptions);
  }, mutationOptions);
};
