import { Text } from '@chakra-ui/layout';
import {
  getMerchantCategoryRestrictionGroupsGetQueryKey,
  getMerchantIdRestrictionGroupsGetQueryKey,
  merchantCategoryRestrictionGroupsGet,
  merchantIdRestrictionGroupsGet,
} from '@libs/api/endpoints';
import { RestrictionGroupStatus } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Pager, Table, Tag, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer } from '@libs/ui/components/table/table.utils';
import { useIcon } from '@libs/core/theme/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RestrictionGroupType } from '../restriction-group.type';
import { RestrictionGroupStatusToTagStatus } from '../restriction-group.utils';
import { RestrictionGroupEditDrawer } from './restriction-group-edit-drawer';
import { RestrictionGroupSearchDrawer } from './restriction-group-search-drawer';

type RestrictionGroupProps = {
  type: RestrictionGroupType;
};

export const RestrictionGroupTable = ({ type }: RestrictionGroupProps) => {
  const [isSearch, setSearch] = useState<boolean>(false);
  const SearchIcon = useIcon('Search');
  const Edit = useIcon('Edit');
  const { t } = useTranslation();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { drawerHandler, isOpen, onClose, activeDrawerIndex } = useDrawer();

  const params = {
    page_number: pageNumber,
    page_size: pageSize,
  };
  const queryKey =
    type === RestrictionGroupType.MCC
      ? getMerchantCategoryRestrictionGroupsGetQueryKey(params)
      : getMerchantIdRestrictionGroupsGetQueryKey(params);
  const { data, isLoading, isFetching } = useQuery(
    queryKey,
    () =>
      type === RestrictionGroupType.MCC
        ? merchantCategoryRestrictionGroupsGet(params)
        : merchantIdRestrictionGroupsGet(params),
    {
      keepPreviousData: true,
    },
  );

  const headers: TableHeaders = [
    t(i18nKeys.common.full_name),
    t(i18nKeys.common.updated_at),
    t(i18nKeys.common.created_at),
    t(i18nKeys.common.status),
    '',
  ];

  const onDrawerClose = () => {
    setSearch(false);
    onClose();
  };

  return (
    <>
      <Table headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !data}
          colSpan={headers.length}
          isEmpty={
            !isLoading && !isFetching && (data?.data.length === 0 || !data)
          }
        >
          {data?.data.map(
            ({ id, name, created_at, updated_at, status }, index) => (
              <Table.TR key={id} index={id} selectable>
                <Table.TD onClick={() => drawerHandler({ index })}>
                  <Text size="Body2">{capitalize(name)}</Text>
                  <Text size="Small" color="ink.dark">
                    {id}
                  </Text>
                </Table.TD>
                <Table.Date onClick={() => drawerHandler({ index })}>
                  {updated_at}
                </Table.Date>
                <Table.Date onClick={() => drawerHandler({ index })}>
                  {created_at}
                </Table.Date>
                <Table.TD onClick={() => drawerHandler({ index })}>
                  <Tag
                    status={RestrictionGroupStatusToTagStatus[status]}
                    hasIcon
                    tooltipLabel={t(i18nKeys.common.TagStatus[status])}
                  />
                </Table.TD>
                <Table.Action
                  p="0"
                  actions={[
                    {
                      buttonProps: {
                        disabled: status === RestrictionGroupStatus.CANCELED,
                      },
                      onClick: () =>
                        drawerHandler({
                          index,
                        }),
                      icon: <Edit width="2rem" height="2rem" />,
                    },
                    {
                      onClick: () =>
                        drawerHandler({
                          index,
                          beforeOpen: () => setSearch(true),
                        }),
                      icon: <SearchIcon width="2rem" height="2rem" />,
                    },
                  ]}
                />
              </Table.TR>
            ),
          )}
        </Table.TBody>
      </Table>
      {isOpen &&
        (isSearch ? (
          <RestrictionGroupSearchDrawer
            type={type}
            restrictionGroup={data.data[activeDrawerIndex]}
            isOpen={isOpen}
            RestrictionGroupQueryKey={queryKey}
            onClose={onDrawerClose}
          />
        ) : (
          <RestrictionGroupEditDrawer
            type={type}
            restrictionGroup={data.data[activeDrawerIndex]}
            isOpen={isOpen}
            onClose={onDrawerClose}
            restrictionGroupGetParams={params}
          />
        ))}
      {data && data.meta?.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={data.meta.total_items}
          page_index={data.meta.page_index}
          total_pages={data.meta.total_pages}
          page_size={pageSize}
          setPageSize={setPageSize}
          mt="2rem"
          isFetching={isFetching}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
};
