import * as React from 'react';

function SvgArrowSortAsc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.42715 14.4825C7.41812 14.4269 7.41395 14.3706 7.41468 14.3142L7.41468 4.71968L7.31738 4.92889C7.22059 5.13339 7.08886 5.31943 6.92815 5.47868L4.2376 8.16922C3.89933 8.52357 3.35517 8.58311 2.94828 8.31032C2.51468 7.99282 2.42053 7.38395 2.73803 6.95033C2.7637 6.9153 2.79167 6.882 2.82178 6.85071L7.68714 1.98534C8.06695 1.60511 8.68305 1.60478 9.06328 1.98458C9.06353 1.98483 9.0638 1.9851 9.06404 1.98534L13.9294 6.85071C14.3089 7.23124 14.308 7.84735 13.9275 8.22685C13.8975 8.25671 13.8657 8.28459 13.8321 8.31032C13.4252 8.58311 12.881 8.52357 12.5428 8.16922L9.84737 5.48354C9.70445 5.34077 9.58461 5.17663 9.49219 4.99701L9.36083 4.70508L9.36083 14.2607C9.37929 14.7578 9.03205 15.1939 8.54345 15.2873C8.01297 15.3733 7.51321 15.013 7.42715 14.4825Z" />
    </svg>
  );
}

export default SvgArrowSortAsc;
