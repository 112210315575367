/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type OnboardingStatus =
  | 'STARTED'
  | 'ORG_DATA_SENT'
  | 'UBO_SELECTED'
  | 'UBO_DOC_UPLOADED'
  | 'BANK_REVIEW'
  | 'INTERNAL_REVIEW'
  | 'ONBOARDING_FINISHED';

export const OnboardingStatus = {
  STARTED: 'STARTED' as OnboardingStatus,
  ORG_DATA_SENT: 'ORG_DATA_SENT' as OnboardingStatus,
  UBO_SELECTED: 'UBO_SELECTED' as OnboardingStatus,
  UBO_DOC_UPLOADED: 'UBO_DOC_UPLOADED' as OnboardingStatus,
  BANK_REVIEW: 'BANK_REVIEW' as OnboardingStatus,
  INTERNAL_REVIEW: 'INTERNAL_REVIEW' as OnboardingStatus,
  ONBOARDING_FINISHED: 'ONBOARDING_FINISHED' as OnboardingStatus,
};
