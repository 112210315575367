import { Container, Flex } from '@chakra-ui/react';
import { ContentContainer } from '@libs/dashboard-core/components/container';
import { Nav } from '@libs/dashboard-core/components/nav';
import React from 'react';
import { Header } from '../../../components/header';
import { SidebarMenu } from './sidebar-menu';

export const DashboardLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Flex>
        <Nav type="regular">
          <SidebarMenu />
        </Nav>
        <ContentContainer>
          <Container maxW="xl">{children}</Container>
        </ContentContainer>
      </Flex>
    </>
  );
};
