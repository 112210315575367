import * as React from 'react';

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 4.75V19.25H19.25V4.75H4.75ZM2.75 4.5C2.75 3.5335 3.5335 2.75 4.5 2.75H19.5C20.4665 2.75 21.25 3.5335 21.25 4.5V19.5C21.25 20.4665 20.4665 21.25 19.5 21.25H4.5C3.5335 21.25 2.75 20.4665 2.75 19.5V4.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 1.25C17.0523 1.25 17.5 1.69772 17.5 2.25V5.25C17.5 5.80228 17.0523 6.25 16.5 6.25C15.9477 6.25 15.5 5.80228 15.5 5.25V2.25C15.5 1.69772 15.9477 1.25 16.5 1.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1.25C8.05228 1.25 8.5 1.69772 8.5 2.25V5.25C8.5 5.80228 8.05228 6.25 7.5 6.25C6.94772 6.25 6.5 5.80228 6.5 5.25V2.25C6.5 1.69772 6.94772 1.25 7.5 1.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 8.25C2.75 7.69772 3.19772 7.25 3.75 7.25H20.25C20.8023 7.25 21.25 7.69772 21.25 8.25C21.25 8.80228 20.8023 9.25 20.25 9.25H3.75C3.19772 9.25 2.75 8.80228 2.75 8.25Z"
      />
    </svg>
  );
}

export default SvgCalendar;
