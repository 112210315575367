import * as React from 'react';

function SvgUserGroup(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9063 6.25C5.85537 6.25 4.19276 7.9849 4.19276 10.125C4.19276 12.2651 5.85537 14 7.9063 14C9.95723 14 11.6198 12.2651 11.6198 10.125C11.6198 7.9849 9.95723 6.25 7.9063 6.25ZM2.27609 10.125C2.27609 6.88033 4.79682 4.25 7.9063 4.25C11.0158 4.25 13.5365 6.88033 13.5365 10.125C13.5365 13.3697 11.0158 16 7.9063 16C4.79682 16 2.27609 13.3697 2.27609 10.125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7937 6.29379C15.2692 6.20973 14.7335 6.24394 14.2227 6.39411C13.7133 6.54389 13.1839 6.23437 13.0404 5.70278C12.8969 5.17119 13.1935 4.61884 13.7029 4.46906C14.4773 4.2414 15.2894 4.18953 16.0846 4.31696C16.8798 4.4444 17.6396 4.74816 18.3129 5.20781C18.9861 5.66745 19.5571 6.2723 19.9874 6.98161C20.4178 7.69093 20.6974 8.48823 20.8076 9.31983C20.9178 10.1514 20.8559 10.998 20.6261 11.8025C20.3963 12.6071 20.004 13.3509 19.4755 13.9839C18.9471 14.6169 18.2948 15.1244 17.5625 15.4721C16.8303 15.8199 16.0351 15.9999 15.2306 16C14.7013 16.0001 14.2722 15.5524 14.2721 15.0001C14.2721 14.4478 14.7011 14.0001 15.2304 14C15.761 13.9999 16.2855 13.8812 16.7685 13.6518C17.2515 13.4224 17.6818 13.0877 18.0304 12.6702C18.3789 12.2526 18.6377 11.762 18.7893 11.2313C18.9409 10.7006 18.9817 10.1422 18.909 9.59371C18.8363 9.04518 18.6519 8.51928 18.368 8.05141C18.0842 7.58354 17.7075 7.18458 17.2635 6.8814C16.8194 6.57821 16.3182 6.37784 15.7937 6.29379Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90602 16C6.79108 16 5.69252 16.28 4.70307 16.8163C3.71363 17.3525 2.86237 18.1293 2.22114 19.0811C1.91674 19.5329 1.31898 19.6416 0.886 19.324C0.453019 19.0064 0.34878 18.3826 0.653177 17.9308C1.47125 16.7166 2.5573 15.7255 3.81963 15.0414C5.08196 14.3572 6.4835 14.0001 7.90595 14C9.32839 13.9999 10.73 14.357 11.9923 15.0411C13.2547 15.7251 14.3408 16.7161 15.159 17.9302C15.4634 18.382 15.3592 19.0058 14.9263 19.3235C14.4933 19.6411 13.8956 19.5324 13.5911 19.0806C12.9498 18.1289 12.0985 17.3522 11.109 16.816C10.1195 16.2798 9.02096 16 7.90602 16Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.434 16.8148C17.4446 16.2786 16.346 15.9992 15.2312 16C14.7019 16.0004 14.2725 15.553 14.2721 15.0007C14.2717 14.4484 14.7005 14.0004 15.2298 14C16.6526 13.9989 18.0546 14.3556 19.3173 15.0398C20.58 15.7241 21.6661 16.7157 22.4836 17.9308C22.7877 18.3828 22.6831 19.0065 22.2499 19.3239C21.8167 19.6412 21.219 19.532 20.9149 19.08C20.2743 18.1279 19.4233 17.3509 18.434 16.8148Z"
      />
    </svg>
  );
}

export default SvgUserGroup;
