import { css, Global } from '@emotion/react';
import React from 'react';
import { DatePicker } from './components/datepicker';
import { fonts } from './fonts/global-fonts';
export const GlobalStyle = () => (
  <Global
    styles={({ colors }) => css`
      html {
        height: 100%;
        font-size: 62.5%;
      }
      body {
        margin: 0;
        height: 100%;
        font-size: 1.5rem;
        line-height: 2rem;
        font-family: ${fonts.body1};
        background-color: ${colors.background.page};
        color: ${colors.ink.black};
      }

      /* TODO remove this... never put style like this in global styles  */
      div[name='pager'] .chakra-select__icon-wrapper {
        width: 2.5rem !important;
        top: 65% !important;
      }

      ${DatePicker}
    `}
  />
);
