import { Button, Flex, Link, Text } from '@chakra-ui/react';
import {
  getRegistrationsGetQueryKey,
  registrationImportCsv,
  registrationsGet,
} from '@libs/api/endpoints';
import { TEN_MB } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import { FIREBASE_STORAGE_FILES } from '@libs/dashboard-core/constants';
import { toBase64 } from '@libs/dashboard-core/utils/converter';
import {
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  UploadField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { validationUploadEmployeesSchema } from '../../organisation.validations';

type EmployeesUploadDrawerProps = {
  isOpen: boolean;
  onClose(): void;
  organisationId: string;
};

export const EmployeesUploadDrawer = ({
  isOpen,
  onClose,
  organisationId,
}: EmployeesUploadDrawerProps) => {
  const { t } = useTranslation();

  return (
    <Drawer
      title={t(i18nKeys.employees_form.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <EmployeesUploadForm organisationId={organisationId} onClose={onClose} />
    </Drawer>
  );
};

type EmployeesUploadFormProps = {
  onClose(): void;
  organisationId: string;
};

const EmployeesUploadForm = ({
  onClose,
  organisationId,
}: EmployeesUploadFormProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const toast = useToast();

  const { mutateAsync: submit } = useMutation(
    async ({ values }: FormSubmitData<{ employees: File[] }>) => {
      const { employees } = values;
      const fileBase64 = await toBase64(employees[0]);
      return registrationImportCsv({
        file: fileBase64 as string,
        organisation_id: organisationId,
      });
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: t(i18nKeys.employees_form.success.title),
        });
        queryClient.fetchQuery(
          getRegistrationsGetQueryKey({ organisation_id: organisationId }),
          () => registrationsGet({ organisation_id: organisationId }),
        );
      },
    },
  );

  return (
    <Formik<{ employees: File[] }>
      initialValues={{
        employees: [],
      }}
      validate={validateSchema(validationUploadEmployeesSchema)}
      onSubmit={handleFormSubmit(submit)}
    >
      {({ isSubmitting }) => (
        <>
          <Drawer.Body>
            <Form noValidate id="upload-employees-form">
              <Text color="ink.dark" size="Body2">
                {t(i18nKeys.employees_form.introduction)}
              </Text>
              <UploadField
                name="employees"
                explanations={t(i18nKeys.common.upload_explaination)}
                options={{
                  multiple: false,
                  maxFiles: 1,
                  accept: 'text/csv',
                  maxSize: TEN_MB,
                }}
              />
            </Form>
            <Link
              href={FIREBASE_STORAGE_FILES.templates.UPLOAD_EMPLOYEES}
              download
            >
              {t(i18nKeys.employees_form.template_link)}
            </Link>
          </Drawer.Body>
          <Drawer.Footer>
            <Flex justify="space-between" width="100%">
              <Button size="body1" variant="border" onClick={onClose}>
                {t(i18nKeys.common.cancel)}
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="body1"
                isLoading={isSubmitting}
                form="upload-employees-form"
              >
                {t(i18nKeys.common.submit)}
              </Button>
            </Flex>
          </Drawer.Footer>
        </>
      )}
    </Formik>
  );
};
