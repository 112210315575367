/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseRefundIssuer =
  | 'NONE'
  | 'ORGANISATION'
  | 'EMPLOYEE'
  | 'RESELLER'
  | 'PROVIDER';

export const ExpenseRefundIssuer = {
  NONE: 'NONE' as ExpenseRefundIssuer,
  ORGANISATION: 'ORGANISATION' as ExpenseRefundIssuer,
  EMPLOYEE: 'EMPLOYEE' as ExpenseRefundIssuer,
  RESELLER: 'RESELLER' as ExpenseRefundIssuer,
  PROVIDER: 'PROVIDER' as ExpenseRefundIssuer,
};
