/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseStatus =
  | 'DRAFT'
  | 'PENDING'
  | 'NEED_MORE_INFO'
  | 'REFUSED'
  | 'APPROVED'
  | 'CANCELED'
  | 'MISSING_PROVIDER';

export const ExpenseStatus = {
  DRAFT: 'DRAFT' as ExpenseStatus,
  PENDING: 'PENDING' as ExpenseStatus,
  NEED_MORE_INFO: 'NEED_MORE_INFO' as ExpenseStatus,
  REFUSED: 'REFUSED' as ExpenseStatus,
  APPROVED: 'APPROVED' as ExpenseStatus,
  CANCELED: 'CANCELED' as ExpenseStatus,
  MISSING_PROVIDER: 'MISSING_PROVIDER' as ExpenseStatus,
};
