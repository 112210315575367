/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useMutation, UseMutationOptions } from 'react-query';
import type { ResetPasswordBody, ForgotPasswordBody } from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const resetPassword = <Data = unknown>(
  resetPasswordBody: ResetPasswordBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/auth/reset-password`, method: 'post', data: resetPasswordBody },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useResetPassword = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof resetPassword>,
    Error,
    { data: ResetPasswordBody },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof resetPassword>,
    Error,
    { data: ResetPasswordBody }
  >((props) => {
    const { data } = props || {};

    return resetPassword<Data>(data, requestOptions);
  }, mutationOptions);
};
export const forgotPassword = <Data = unknown>(
  forgotPasswordBody: ForgotPasswordBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/auth/forgot-password`, method: 'post', data: forgotPasswordBody },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useForgotPassword = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof forgotPassword>,
    Error,
    { data: ForgotPasswordBody },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof forgotPassword>,
    Error,
    { data: ForgotPasswordBody }
  >((props) => {
    const { data } = props || {};

    return forgotPassword<Data>(data, requestOptions);
  }, mutationOptions);
};
