import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Organisation, Shareholder } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { diffOfDaysFromToday } from '@libs/dashboard-core/utils/date';
import { Table, Thumbnail } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDaysToDocExpired } from '../../organisation.utils';

type KycRowProps = {
  data: Organisation | Shareholder;
  title: string;
};

export const KycRow = ({ data, title }: KycRowProps) => {
  const { t } = useTranslation();

  return (
    <Table.TD>
      <Heading size="Body2">{title}</Heading>

      {data.documents?.length ? (
        <>
          <Text size="Small">
            {t(i18nKeys.operator.organisations.details.kyc.uploaded_files, {
              count: data.documents?.length,
            })}
          </Text>
          <Flex maxW="56rem" width="100%" flexWrap="wrap">
            {data.documents?.map((document) => {
              const daysToDocExpired = getDaysToDocExpired(data, document);

              const daysSinceExpired = diffOfDaysFromToday(document.created_at);
              const docIsExpired = daysSinceExpired > daysToDocExpired;
              return (
                <Box key={document.id} p="1rem">
                  <Thumbnail
                    m="0"
                    w="15rem"
                    h="10rem"
                    fileURL={document.url}
                    title={t(i18nKeys.common.doctype[document.document_type])}
                    disabled={docIsExpired}
                  >
                    {t(i18nKeys.common.doctype[document.document_type])}
                  </Thumbnail>
                  {docIsExpired && (
                    <Text
                      size="Small"
                      color="status.negative.type"
                      textAlign="center"
                    >
                      {t(i18nKeys.common.document.expired_since, {
                        days: daysSinceExpired,
                      })}
                    </Text>
                  )}
                </Box>
              );
            })}
          </Flex>
        </>
      ) : (
        <Text size="Small">
          {t(i18nKeys.operator.organisations.details.no_documents)}
        </Text>
      )}
    </Table.TD>
  );
};
