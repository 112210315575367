import { Container } from '@chakra-ui/react';
import { Wizard } from '@libs/ui/components';
import React from 'react';
import { ContentContainer } from '../container';
import { OnboardingWizardContent } from './onboarding-wizard-content';
import { OnboardingStatus, Status } from '@libs/api/models';
import { getWizardStep } from './onboarding-wizard.utils';

type OnboardingWizardProps = {
  organisationId: string;
  onboardingStatus: OnboardingStatus;
  organisationStatus: Status;
  children?: React.ReactNode;
  isEmbedded?: boolean;
};

export const OnboardingWizard = ({
  organisationId,
  isEmbedded,
  onboardingStatus,
  organisationStatus,
  children,
}: OnboardingWizardProps) => {
  const isInReview = organisationStatus === Status.IN_REVIEW;

  return (
    <Wizard
      // Users with organisation in review can go back to any step
      initialHash={isInReview ? undefined : getWizardStep(onboardingStatus)}
    >
      {children}
      {isEmbedded ? (
        <OnboardingWizardContent organisationId={organisationId} />
      ) : (
        <ContentContainer>
          <Container py="6rem" maxW="xl" px="2rem">
            <OnboardingWizardContent organisationId={organisationId} />
          </Container>
        </ContentContainer>
      )}
    </Wizard>
  );
};
