import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useGetCurrentUser } from '@libs/api/endpoints';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { Assistant, Card, Loader, useWizard } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingLayout } from './onboarding-layout';

export const OnboardingWelcome = () => {
  const { t } = useTranslation();
  const { data: user, isLoading } = useGetCurrentUser();
  const theme = useTheme();
  const Check = useIcon('CheckRounded');
  const Queue = useIcon('Queue');
  const { nextStep } = useWizard();

  if (isLoading) {
    return (
      <Box py="10rem" maxW="69rem" position="relative">
        <Loader spinnerProps={{ size: 'md' }} modRight>
          {t(i18nKeys.common.loading)}
        </Loader>
      </Box>
    );
  }

  return (
    <OnboardingLayout
      title={
        <>
          {t(i18nKeys.common.welcome)} {user.first_name}
        </>
      }
      introduction={t(i18nKeys.onboarding.welcome_page.introduction)}
    >
      <Box>
        <Card>
          <Heading size="Body1">
            {t(i18nKeys.onboarding.welcome_page.document_list.title)}
          </Heading>
          <List spacing="2rem" mt="2.5rem">
            <ListItem>
              <ListIcon w="2rem" h="2rem" as={Check} />
              {t(i18nKeys.onboarding.welcome_page.document_list.item1)}
            </ListItem>
            <ListItem>
              <ListIcon w="2rem" h="2rem" as={Check} />
              {t(i18nKeys.onboarding.welcome_page.document_list.item2)}
            </ListItem>
            <ListItem>
              <ListIcon w="2rem" h="2rem" as={Check} />
              {t(i18nKeys.onboarding.welcome_page.document_list.item3)}
            </ListItem>
          </List>
        </Card>
        <Card mt="2rem">
          <Flex>
            <Box>
              <Heading size="Body1">
                {t(i18nKeys.onboarding.welcome_page.app_submitted.title)}
              </Heading>
              <Text>
                {t(i18nKeys.onboarding.welcome_page.app_submitted.introduction)}
              </Text>
            </Box>
            <Box ml="2rem" width="7rem" height="7rem">
              <Queue
                width="100%"
                height="100%"
                fill={theme.colors.main.secondary.normal}
              />
            </Box>
          </Flex>
        </Card>
        <Button
          my="4rem"
          variant="primary"
          size="body1"
          data-cy="onboarding-welcome-button-next"
          onClick={() => nextStep()}
        >
          {t(i18nKeys.onboarding.wizard.start_onboarding)}
        </Button>
      </Box>
      <Assistant>
        <Assistant.Card
          title={t(i18nKeys.onboarding.welcome_page.assistant.title)}
        >
          <Text my={2}>
            {t(i18nKeys.onboarding.welcome_page.assistant.content)}
          </Text>
        </Assistant.Card>
      </Assistant>
    </OnboardingLayout>
  );
};
