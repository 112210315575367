import { OnboardingStatus } from '@libs/api/models';
import React, { useState } from 'react';
import { CompanyDetailsSteps } from '../onboarding-wizard.constants';
import { OrganisationConfirmForm } from './organisation-confirm-form';
import { OrganisationForm } from './organisation-form';

type OnboardingCompanyProps = {
  organisationId: string;
  onboardingStatus: OnboardingStatus;
};

export const OnboardingCompany = ({
  organisationId,
  onboardingStatus,
}: OnboardingCompanyProps) => {
  const [
    companyDetailsStep,
    setCompanyDetailsStep,
  ] = useState<CompanyDetailsSteps>(
    onboardingStatus && onboardingStatus !== OnboardingStatus.STARTED
      ? CompanyDetailsSteps.REVIEW
      : CompanyDetailsSteps.CONFIRM,
  );

  if (companyDetailsStep === CompanyDetailsSteps.CONFIRM) {
    return (
      <OrganisationConfirmForm
        setCompanyDetailsStep={setCompanyDetailsStep}
        organisationId={organisationId}
      />
    );
  }
  if (companyDetailsStep === CompanyDetailsSteps.REVIEW) {
    return (
      <OrganisationForm
        setCompanyDetailsStep={setCompanyDetailsStep}
        organisationId={organisationId}
      />
    );
  }
};
