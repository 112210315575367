import { Flex } from '@chakra-ui/react';
import { useGetCurrentUser, useOrganisationGet } from '@libs/api/endpoints';
import React, { useEffect } from 'react';
import { Header } from '../../components/header';
import { OnboardingWizard } from '../../components/onboarding-wizard/onboarding-wizard';
import { useFleetManagerMembership } from '../../utils/membership';
import { OnboardingWizardNav } from './components/onboarding-wizard-nav';

export const OnboardingPage = () => {
  const { data: user, isLoading } = useGetCurrentUser();
  const membership = useFleetManagerMembership();
  const {
    data: organisation,
    isLoading: isOrganisationLoading,
  } = useOrganisationGet(membership?.organisation_id);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.identify(user.id);
    }
  }, [user]);

  return (
    <>
      <Header />
      {!isLoading && !isOrganisationLoading && (
        <Flex>
          <OnboardingWizard
            organisationId={membership.organisation_id}
            onboardingStatus={organisation.onboarding_status}
            organisationStatus={organisation.status}
          >
            <OnboardingWizardNav />
          </OnboardingWizard>
        </Flex>
      )}
    </>
  );
};
