import { default as Add } from './add';
import { default as Address } from './address';
import { default as Alert } from './alert';
import { default as ArrowDown } from './arrow-down';
import { default as ArrowLeft } from './arrow-left';
import { default as ArrowNoSort } from './arrow-no-sort';
import { default as ArrowRight } from './arrow-right';
import { default as ArrowSortAsc } from './arrow-sort-asc';
import { default as ArrowSortDesc } from './arrow-sort-desc';
import { default as ArrowUp } from './arrow-up';
import { default as Attachment } from './attachment';
import { default as Blocked } from './blocked';
import { default as Calendar } from './calendar';
import { default as CardWallet } from './card-wallet';
import { default as Chat } from './chat';
import { default as Check } from './check';
import { default as CheckRounded } from './check-rounded';
import { default as Clock } from './clock';
import { default as Close } from './close';
import { default as CreditCard } from './credit-card';
import { default as Csv } from './csv';
import { default as Edit } from './edit';
import { default as EmptyUserAccount } from './empty-user-account';
import { default as External } from './external';
import { default as Eye } from './eye';
import { default as EyeOff } from './eye-off';
import { default as Fail } from './fail';
import { default as File } from './file';
import { default as Filter } from './filter';
import { default as Home } from './home';
import { default as Identity } from './identity';
import { default as Info } from './info';
import { default as Logout } from './logout';
import { default as Mail } from './mail';
import { default as Organisation } from './organisation';
import { default as Pdf } from './pdf';
import { default as Pending } from './pending';
import { default as Phone } from './phone';
import { default as ProofAuthority } from './proof-authority';
import { default as Queue } from './queue';
import { RestrictionGroup } from './restriction-group';
import { default as Search } from './search';
import { default as StoreFront } from './store-front';
import { default as Talk } from './talk';
import { default as Treezor } from './treezor';
import { default as Upload } from './upload';
import { default as User } from './user';
import { default as UserGroup } from './user-group';
import { default as Xls } from './xls';

export const icons = {
  Add,
  Address,
  Alert,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Attachment,
  Calendar,
  CardWallet,
  Check,
  CheckRounded,
  Close,
  Clock,
  Csv,
  Edit,
  EmptyUserAccount,
  External,
  Eye,
  EyeOff,
  File,
  Home,
  Identity,
  Info,
  Logout,
  Mail,
  Organisation,
  Pdf,
  Pending,
  Phone,
  Queue,
  Upload,
  User,
  UserGroup,
  Xls,
  Search,
  Treezor,
  Filter,
  ArrowNoSort,
  ArrowSortAsc,
  ArrowSortDesc,
  Chat,
  Fail,
  ProofAuthority,
  Talk,
  Blocked,
  CreditCard,
  StoreFront,
  RestrictionGroup,
};
