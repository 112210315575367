import { BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { ThumbnailLink } from './components/thumbnail-link';
import { ThumbnailPreview } from './components/thumbnail-preview';
import { FileExtensionType } from './thumbnail.constant';
import { isPdfFileFromURL } from './thumbnail.utils';

export type ThumbnailProps = BoxProps & {
  children?: ReactNode;
  title?: string;
  fileURL: string;
  type?: FileExtensionType;
  disabled?: boolean;
};

export const Thumbnail = ({
  children,
  fileURL,
  title,
  type,
  ...rest
}: ThumbnailProps) => {
  return type === FileExtensionType.PDF ||
    type === FileExtensionType.XLS ||
    isPdfFileFromURL(fileURL) ? (
    <ThumbnailLink fileURL={fileURL} title={title} />
  ) : (
    <ThumbnailPreview fileURL={fileURL} title={title} {...rest}>
      {children}
    </ThumbnailPreview>
  );
};
