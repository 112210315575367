/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { ActivationStatus } from './activationStatus';

export type ProgramAllOfStatus = ActivationStatus;

export const ProgramAllOfStatus = { ...ActivationStatus };
