import * as React from 'react';

function SvgCreditCard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 26 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79134 3.29134V16.708H23.208V3.29134H2.79134ZM0.458008 2.99967C0.458008 1.87209 1.37209 0.958008 2.49967 0.958008H23.4997C24.6273 0.958008 25.5413 1.87209 25.5413 2.99967V16.9997C25.5413 18.1273 24.6273 19.0413 23.4997 19.0413H2.49967C1.37209 19.0413 0.458008 18.1273 0.458008 16.9997V2.99967Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2075 14.3747C16.2075 13.7303 16.7299 13.208 17.3742 13.208H20.8742C21.5185 13.208 22.0409 13.7303 22.0409 14.3747C22.0409 15.019 21.5185 15.5413 20.8742 15.5413H17.3742C16.7299 15.5413 16.2075 15.019 16.2075 14.3747Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9575 14.3747C10.9575 13.7303 11.4799 13.208 12.1242 13.208H13.8742C14.5185 13.208 15.0409 13.7303 15.0409 14.3747C15.0409 15.019 14.5185 15.5413 13.8742 15.5413H12.1242C11.4799 15.5413 10.9575 15.019 10.9575 14.3747Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.45752 6.59245C0.45752 5.94812 0.979854 5.42578 1.62419 5.42578H24.3742C25.0185 5.42578 25.5408 5.94812 25.5408 6.59245C25.5408 7.23678 25.0185 7.75912 24.3742 7.75912H1.62419C0.979854 7.75912 0.45752 7.23678 0.45752 6.59245Z"
      />
    </svg>
  );
}

export default SvgCreditCard;
