import { Alert, Box, Button, Link as ChakraLink, Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { AuthError, AuthErrorCode } from '@libs/core/types';
import { validateSchema } from '@libs/core/utils';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import {
  FormSubmitData,
  handleFormSubmit,
  InputField,
  Link,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAuthState } from '../../context';
import { LoginForm } from './auth.types';
import { validationLoginSchema } from './auth.validations';
import { AuthLayout } from './components/auth-layout';

export const LoginPage = () => {
  const { signin } = useAuthState();
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState<AuthError>(null);

  const { mutateAsync: submit } = useMutation(
    ({ values }: FormSubmitData<LoginForm>) => {
      return signin(values.email, values.password);
    },
    {
      onSuccess: () => {
        history.push(getPath(Routes.DASHBOARD));
      },
      onError: (error: AuthError) => {
        if (error) {
          setError(error);
        }
      },
    },
  );

  return (
    <AuthLayout title={t(i18nKeys.login.title)} introduction="">
      <Formik<LoginForm>
        initialValues={{ email: '', password: '' }}
        validate={validateSchema(validationLoginSchema)}
        onSubmit={handleFormSubmit(submit)}
      >
        {({ isSubmitting }) => (
          <Form noValidate autoComplete="off">
            {error?.message && (
              <Alert variant="errorStyle">
                {(error.code === AuthErrorCode.user_not_found && (
                  <Text color="status.negative.type" data-cy="error-text">
                    {t(i18nKeys.http_errors.authentication_user_not_found)}
                  </Text>
                )) ||
                  (error.code === AuthErrorCode.wrong_password && (
                    <Text color="status.negative.type" data-cy="error-text">
                      {t(i18nKeys.http_errors.authentication_wrong_password)}{' '}
                      <ChakraLink
                        textDecoration="underline"
                        cursor="pointer"
                        color="status.negative.type"
                        _hover={{ color: 'status.negative.type' }}
                        href={getPath(Routes.AUTH_RESET_PASSWORD)}
                        as="a"
                      >
                        {t(i18nKeys.common.errors.auth.reset_password)}
                      </ChakraLink>
                    </Text>
                  )) || (
                    <Text color="status.negative.type" data-cy="error-text">
                      {t(i18nKeys.common.errors.authentication.title)}
                    </Text>
                  )}
              </Alert>
            )}
            <InputField
              type="email"
              name="email"
              label={t(i18nKeys.common.email)}
              isRequired
            />
            {error?.code === AuthErrorCode.user_not_found && (
              <Text
                data-cy="email-invalid-error-text"
                size="XSmall"
                color="status.negative.type"
              >
                {t(i18nKeys.common.errors.auth.email_invalid)}
              </Text>
            )}
            <InputField
              name="password"
              label={t(i18nKeys.common.password)}
              isRequired
              isPassword
            />
            {error?.code === AuthErrorCode.wrong_password && (
              <Text
                data-cy="password-invalid-error-text"
                size="XSmall"
                color="status.negative.type"
              >
                {t(i18nKeys.common.errors.auth.password_invalid)}
              </Text>
            )}
            <Box>
              <Button
                type="submit"
                variant="primary"
                size="body1"
                w="100%"
                mt="4rem"
                isLoading={isSubmitting}
              >
                {t(i18nKeys.login.signin)}
              </Button>
              <Box pt="1rem" textAlign="center">
                <Link to={getPath(Routes.AUTH_RESET_PASSWORD)}>
                  {t(i18nKeys.login.forgot_password)}
                </Link>
                <Text>
                  {t(i18nKeys.login.no_account)}{' '}
                  <ChakraLink
                    data-cy="contact-us-link"
                    href={`mailto:${process.env.NX_EMAIL_SUPPORT}`}
                  >
                    {t(i18nKeys.login.ask_account)}
                  </ChakraLink>
                </Text>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};
