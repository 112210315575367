import * as React from 'react';

function SvgEyeOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66785 3.01018C4.05948 2.63867 4.66558 2.66879 5.02161 3.07745L19.3966 19.5774C19.7526 19.9861 19.7238 20.6186 19.3321 20.9901C18.9405 21.3616 18.3344 21.3315 17.9784 20.9228L3.60339 4.42279C3.24736 4.01414 3.27622 3.38169 3.66785 3.01018Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79164 8.5526C10.1477 8.96123 10.1189 9.59368 9.7273 9.96522C9.21018 10.4559 8.90101 11.1407 8.8678 11.8692C8.83459 12.5977 9.08005 13.3101 9.5502 13.8498C10.0203 14.3894 10.6767 14.7121 11.3748 14.7468C12.0729 14.7815 12.7557 14.5255 13.2729 14.0349C13.6645 13.6635 14.2706 13.6936 14.6266 14.1023C14.9826 14.511 14.9537 15.1435 14.562 15.5149C13.6687 16.3622 12.4894 16.8045 11.2835 16.7445C10.0777 16.6846 8.94404 16.1272 8.13198 15.1951C7.31991 14.263 6.89593 13.0325 6.9533 11.7742C7.01067 10.5159 7.54468 9.33292 8.43788 8.48546C8.82948 8.11392 9.43558 8.14398 9.79164 8.5526Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5029 5.97901C7.74202 6.47172 7.55308 7.07341 7.0809 7.32292C5.41471 8.20336 4.21644 9.47831 3.42985 10.5495C3.03815 11.0829 2.75386 11.5586 2.56942 11.8968C2.54974 11.9328 2.53122 11.9673 2.51385 12.0001C2.59022 12.1441 2.68866 12.3208 2.80998 12.5219C3.16286 13.1066 3.70382 13.888 4.45105 14.6676C5.93998 16.2211 8.22494 17.7494 11.5 17.7494H11.5076L11.5076 17.7494C13.0395 17.7622 14.5522 17.394 15.9205 16.6754C16.3933 16.4271 16.9695 16.6258 17.2074 17.1191C17.4453 17.6124 17.2549 18.2136 16.7822 18.4619C15.143 19.3227 13.3311 19.764 11.4962 19.7494C7.58577 19.7482 4.84071 17.9025 3.09584 16.0819C2.22588 15.1742 1.59887 14.2683 1.18808 13.5876C0.982217 13.2464 0.82929 12.9596 0.726176 12.7542C0.674584 12.6514 0.635354 12.5688 0.608105 12.5098C0.594478 12.4803 0.583836 12.4566 0.576135 12.4392L0.566777 12.4179L0.563737 12.4109L0.562625 12.4083L0.562171 12.4072C0.561972 12.4068 0.561785 12.4063 1.43751 12.0001C0.561753 11.594 0.561889 11.5937 0.56203 11.5934L0.562344 11.5927L0.56308 11.5909L0.56499 11.5865L0.570572 11.5737L0.58883 11.5327C0.604173 11.4987 0.625901 11.4516 0.654163 11.3927C0.710664 11.2749 0.793428 11.1096 0.903671 10.9075C1.12387 10.5038 1.4554 9.95004 1.90845 9.33307C2.81137 8.10347 4.21831 6.59374 6.21499 5.53866C6.68718 5.28915 7.26379 5.4863 7.5029 5.97901ZM1.43751 12.0001L0.561753 11.594C0.451634 11.8526 0.451645 12.1478 0.561785 12.4063L1.43751 12.0001ZM2.31302 12.4068L2.3125 12.408L2.31281 12.4073L2.31302 12.4068ZM2.31246 11.5922C2.31224 11.5917 2.3122 11.5916 2.31233 11.5919L2.31246 11.5922Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48524 4.42073C10.1514 4.3056 10.8256 4.2483 11.5008 4.24941C11.5011 4.24941 11.5013 4.24941 11.5016 4.24941L11.5 5.24941V4.24941C11.5003 4.24941 11.5005 4.24941 11.5008 4.24941C15.4129 4.24966 18.1589 6.09632 19.9042 7.9177C20.7742 8.82557 21.4012 9.73171 21.812 10.4126C22.0178 10.7538 22.1708 11.0407 22.2739 11.2461C22.3255 11.3489 22.3647 11.4315 22.3919 11.4905C22.4056 11.5201 22.4162 11.5438 22.4239 11.5611L22.4333 11.5824L22.4363 11.5895L22.4374 11.5921L22.4379 11.5931C22.4381 11.5936 22.4382 11.594 21.5625 12.0001C20.6868 11.594 20.6869 11.5937 20.687 11.5935L20.6871 11.5931L20.6831 11.6023C20.6786 11.6125 20.6707 11.6299 20.6595 11.654C20.637 11.7023 20.6012 11.7772 20.5514 11.8747C20.5318 11.9131 20.5101 11.955 20.4862 12.0001C20.4098 11.856 20.3113 11.6792 20.19 11.4781C19.8371 10.8932 19.2961 10.1117 18.5489 9.33184C17.0599 7.77799 14.775 6.24941 11.5 6.24941H11.4984V6.2494C10.9289 6.24843 10.3603 6.29675 9.79842 6.39385C9.27626 6.4841 8.78286 6.11556 8.69638 5.57069C8.60989 5.02583 8.96308 4.51098 9.48524 4.42073ZM21.5625 12.0001L22.4382 12.4063C22.5484 12.1478 22.5484 11.8526 22.4382 11.594L21.5625 12.0001ZM20.4862 12.0001C20.3887 12.1838 20.2555 12.4205 20.0848 12.6929C19.6583 13.3736 19.0055 14.2647 18.1029 15.1083C17.7086 15.4768 17.6753 16.109 18.0284 16.5204C18.3815 16.9318 18.9874 16.9666 19.3816 16.5981C20.4378 15.611 21.1951 14.5756 21.689 13.7874C21.9366 13.3923 22.12 13.0562 22.2431 12.8148C22.3048 12.694 22.3515 12.5967 22.3838 12.5272C22.4 12.4925 22.4126 12.4647 22.4216 12.4443L22.4325 12.4195L22.436 12.4114L22.4373 12.4085L22.4378 12.4073L22.438 12.4068C22.4381 12.4065 22.4382 12.4063 21.5625 12.0001C20.6867 12.4062 20.6869 12.4066 20.6871 12.407L20.6842 12.4004C20.6805 12.3922 20.6742 12.378 20.665 12.3581C20.6466 12.3183 20.617 12.2558 20.576 12.174C20.5505 12.1232 20.5205 12.065 20.4862 12.0001Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2353 8.12789C11.3351 7.58552 11.8373 7.23026 12.3571 7.33441C13.3245 7.52825 14.2056 8.04416 14.8682 8.80475C15.5308 9.56534 15.9393 10.5296 16.0322 11.5529C16.0822 12.1027 15.6955 12.5907 15.1686 12.6428C14.6417 12.6949 14.1741 12.2915 14.1241 11.7417C14.0703 11.149 13.8337 10.5906 13.45 10.1501C13.0662 9.70957 12.5559 9.41079 11.9957 9.29853C11.4759 9.19438 11.1355 8.67027 11.2353 8.12789Z"
      />
    </svg>
  );
}

export default SvgEyeOff;
