/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExportFormat = 'CSV' | 'EXCEL';

export const ExportFormat = {
  CSV: 'CSV' as ExportFormat,
  EXCEL: 'EXCEL' as ExportFormat,
};
