/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type BankServiceRegistrationStatus =
  | 'SUGGESTED'
  | 'CREATED'
  | 'CONFIRMED'
  | 'VALIDATED'
  | 'REFUSED';

export const BankServiceRegistrationStatus = {
  SUGGESTED: 'SUGGESTED' as BankServiceRegistrationStatus,
  CREATED: 'CREATED' as BankServiceRegistrationStatus,
  CONFIRMED: 'CONFIRMED' as BankServiceRegistrationStatus,
  VALIDATED: 'VALIDATED' as BankServiceRegistrationStatus,
  REFUSED: 'REFUSED' as BankServiceRegistrationStatus,
};
