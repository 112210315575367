import * as Yup from 'yup';

export const validationCreateOrganisationSchema = Yup.object({
  vat_number: Yup.string().required().vatNumber(),
  email: Yup.string().email().required(),
  country_code: Yup.string().required(),
  legal_name: Yup.string().when('force_creation', {
    is: true,
    then: Yup.string().required(),
  }),
  legal_form: Yup.string().when('force_creation', {
    is: true,
    then: Yup.string().required(),
  }),
  legal_sector: Yup.string().when('force_creation', {
    is: true,
    then: Yup.string().required(),
  }),
  registration_date: Yup.string().when('force_creation', {
    is: true,
    then: Yup.string().required(),
  }),
  address: Yup.object().when('force_creation', {
    is: true,
    then: Yup.object().shape({
      street: Yup.string().required(),
      city: Yup.string().required(),
      postal_code: Yup.string().required(),
    }),
  }),
});

export const validationUploadEmployeesSchema = Yup.object({
  employees: Yup.array().required().min(1),
});

export const validationUpdateOrganisationSettingsSchema = Yup.object({
  social_secretary_reference: Yup.string(),
  configuration: Yup.object().shape({
    automatic_expense_approval_limits: Yup.object().shape({
      card_pro_expense: Yup.number(),
      app_pro_expense: Yup.number(),
    }),
    km_allowance: Yup.object().shape({
      enabled: Yup.boolean(),
      bike_allowance_type: Yup.string().when(
        'configuration.km_allowance.enabled',
        {
          is: true,
          then: Yup.string().required(),
        },
      ),
      bike_fee_per_km: Yup.number(),
      car_allowance_type: Yup.string().when(
        'configuration.km_allowance.enabled',
        {
          is: true,
          then: Yup.string().required(),
        },
      ),
      car_fee_per_km: Yup.number(),
    }),
  }),
});
