import { Text } from '@chakra-ui/layout';
import { ServicesGetParams, ServicesGetResponse } from '@libs/api-v2/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { Pager, Table, Tag } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult, RefetchOptions } from 'react-query';

type ServiceTableProps = {
  services: ServicesGetResponse;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<ServicesGetResponse, unknown>>;
  isLoading: boolean;
  isFetching: boolean;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  pageSize?: number;
  servicesGetParams: ServicesGetParams;
};

export const ServiceTable = ({
  services,
  refetch,
  isLoading,
  isFetching,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
}: ServiceTableProps) => {
  const { t } = useTranslation();

  const { useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });

  useQueryParams({
    totalPages: services?.meta.total_pages,
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
  });

  useEffect(() => {
    refetch();
  }, [refetch, pageSize]);

  const headers: TableHeaders = [
    {
      name: 'type',
      title: t(i18nKeys.common.service),
    },
  ];

  return (
    <Table headers={headers}>
      <Table.TBody
        isLoading={(isLoading || isFetching) && !services}
        colSpan={headers.length}
        isEmpty={
          !isLoading &&
          !isFetching &&
          (services?.data.length === 0 || !services)
        }
      >
        {services?.data.map(({ id, name_key }, index) => {
          return (
            <Fragment key={`${id}`}>
              <Table.TR
                selectable
                position="relative"
                transform="scale(1,1)"
                index={id}
              >
                <Table.TD>
                  <Text>{name_key}</Text>
                </Table.TD>
              </Table.TR>
            </Fragment>
          );
        })}
      </Table.TBody>
    </Table>
  );
};
