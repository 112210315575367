/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type ExpenseAllOfType = 'PRO' | 'PRIVATE' | 'MIXED' | 'UNCATEGORIZED';

export const ExpenseAllOfType = {
  PRO: 'PRO' as ExpenseAllOfType,
  PRIVATE: 'PRIVATE' as ExpenseAllOfType,
  MIXED: 'MIXED' as ExpenseAllOfType,
  UNCATEGORIZED: 'UNCATEGORIZED' as ExpenseAllOfType,
};
