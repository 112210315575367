import { Flex, Text } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';
import React, { ReactNode } from 'react';

export type AvatarProps = {
  firstname?: string;
  lastname?: string;
  icon?: ReactNode;
  size?: string;
};

export const Avatar = ({
  firstname = '',
  lastname = '',
  icon,
  size,
}: AvatarProps) => {
  const theme = useTheme();
  const letters = `${firstname[0]}${lastname[0]}`;

  return (
    <Flex mr="1rem" align="center" cursor="pointer">
      <Text
        size={size}
        textTransform="uppercase"
        color="main.primary.t500"
        bgColor="main.primary.t200"
        borderRadius={`${theme.borders.pill}`}
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        boxSize={8}
      >
        {letters}
      </Text>
      {icon}
    </Flex>
  );
};
