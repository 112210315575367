import { Box, Button, List, ListItem, useTheme } from '@chakra-ui/react';
import { AvailableLanguage, useLanguage } from '@libs/core/i18n';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';

export const LanguageSelector = () => {
  const theme = useTheme();
  const { language, changeLanguage, availableLanguages } = useLanguage();
  const LanguageSelect = useIcon('Chat');

  if (availableLanguages.length <= 1) {
    return null;
  }

  return (
    <List d="flex" alignItems="center">
      <Box w="2rem" h="2rem">
        <LanguageSelect />
      </Box>
      {availableLanguages.map((value: AvailableLanguage) => {
        return (
          <ListItem key={value}>
            <Button
              h="3.2rem"
              variant="languageChoice"
              size="body2"
              border="none"
              onClick={() => changeLanguage(value)}
              isActive={value === language}
              _hover={{ color: theme.colors.main.secondary.light }}
              _active={{ color: theme.colors.main.secondary.light }}
            >
              {value.toUpperCase()}
            </Button>
          </ListItem>
        );
      })}
    </List>
  );
};
