/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type IncomeRange =
  | 'less_twenty_five'
  | 'less_fifty_five'
  | 'over_fifty_five';

export const IncomeRange = {
  less_twenty_five: 'less_twenty_five' as IncomeRange,
  less_fifty_five: 'less_fifty_five' as IncomeRange,
  over_fifty_five: 'over_fifty_five' as IncomeRange,
};
