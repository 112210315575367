import * as React from 'react';

function SvgMail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.25C2 4.69772 2.44772 4.25 3 4.25H21C21.5523 4.25 22 4.69772 22 5.25V18C22 18.4641 21.8156 18.9092 21.4874 19.2374C21.1592 19.5656 20.7141 19.75 20.25 19.75H3.75C3.28587 19.75 2.84075 19.5656 2.51256 19.2374C2.18437 18.9092 2 18.4641 2 18V5.25ZM4 6.25V17.75H20V6.25H4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26285 4.57427C2.63604 4.16715 3.26861 4.13965 3.67573 4.51284L12 12.1434L20.3243 4.51284C20.7314 4.13965 21.364 4.16715 21.7372 4.57427C22.1104 4.98139 22.0828 5.61396 21.6757 5.98715L12.6757 14.2372C12.2934 14.5876 11.7066 14.5876 11.3243 14.2372L2.32428 5.98715C1.91716 5.61396 1.88966 4.98139 2.26285 4.57427Z"
      />
    </svg>
  );
}

export default SvgMail;
