export const Checkbox = {
  baseStyle: ({ theme }) => ({
    control: {
      borderRadius: theme.borders.sm,
      bg: theme.colors.ink.white,
      _checked: {
        bg: theme.colors.ink.white,
        borderColor: theme.colors.ink.black,
        color: theme.colors.ink.black,
        _hover: {
          bg: theme.colors.ink.white,
          borderColor: theme.colors.ink.dark,
          color: theme.colors.ink.black,
        },
      },
    },
    label: { fontFamily: theme.fonts.body1, marginLeft: '1rem' },
  }),
  variants: {
    sorting: ({ theme }) => ({
      label: {
        _checked: {
          color: theme.colors.main.secondary.normal,
        },
      },
      control: {
        _checked: {
          borderColor: theme.colors.main.secondary.normal,
          color: theme.colors.main.secondary.normal,
          _hover: {
            borderColor: theme.colors.main.secondary.normal,
            color: theme.colors.main.secondary.normal,
          },
        },
      },
      container: {
        color: theme.colors.ink.darker,
        _hover: {
          borderColor: theme.colors.main.secondary.normal,
          color: theme.colors.main.secondary.normal,
        },
        _checked: {
          color: theme.colors.main.secondary.normal,
        },
      },
    }),
  },
  sizes: {
    md: ({ theme }) => ({
      control: { w: '2.4rem', h: '2.4rem' },
      label: { fontSize: theme.fontSizes.body1 },
      icon: { fontSize: theme.fontSizes.body1 },
    }),
    sm: ({ theme }) => ({
      control: { w: '2.4rem', h: '2.4rem' },
      label: { fontSize: theme.fontSizes.body2 },
      icon: {
        fontSize: theme.fontSizes.body2,
        lineHeight: theme.lineHeights.body2,
      },
    }),
    Body2: ({ theme }) => ({
      control: { w: '2.4rem', h: '2.4rem' },
      label: {
        fontSize: theme.fontSizes.body2,
        lineHeight: theme.lineHeights.body2,
      },
    }),
  },
};
