import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import {
  getOrganisationGetQueryKey,
  useOrganisationBankReview,
  useOrganisationGet,
  useShareholdersGet,
} from '@libs/api/endpoints';
import { KYCStatus, Shareholder, Status } from '@libs/api/models';
import { FORMAT_DATE_WITH_HOURS, QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { diffOfDaysFromToday } from '@libs/dashboard-core/utils/date';
import { StatusToTagStatus } from '@libs/dashboard-core/utils/status';
import { Loader, PageHeader, Tag, Tooltip } from '@libs/ui/components';
import { Link } from '@libs/ui/components/link';
import { useDrawer } from '@libs/ui/components/table/table.utils';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryStatus as QueryStatusType, useQueryClient } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { DashboardLayout } from '../../../components/dashboard-layout';
import { DrawerType } from '../../../dashboard.utils';
import { EmployeesTable } from '../../employee/components/employees-table';
import { GroupsTable } from '../../program/components/groups-table';
import { ProgramsTable } from '../../program/components/programs-table';
import { BankReviewDrawer } from './components/bank-review-drawer';
import { EmployeesUploadDrawer } from './components/employees-upload-drawer';
import { KycTable } from './components/kyc-table';
import { OrganisationDetailsOverview } from './components/organisation-details-overview';
import { SettingsDrawer } from './components/settings-drawer';

export const OrganisationDetailsPage = () => {
  const [shareholder, setShareholder] = useState<Shareholder>(null);
  const [bankReviewStatus, setBankReviewStatus] = useState<QueryStatusType>(
    QueryStatus.idle,
  );
  const [bankReviewError, setBankReviewError] = useState(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { organisationId } = useParams<{ organisationId: string }>();
  const { isOpen, onClose, drawerHandler, selectedDrawerType } = useDrawer();

  const { data: organisation, isLoading } = useOrganisationGet(organisationId);
  const {
    data: shareholders,
    isLoading: isLoadingShareholders,
  } = useShareholdersGet({
    organisation_id: organisationId,
  });
  const { mutateAsync: mutateBankReview } = useOrganisationBankReview({
    mutation: {
      onSuccess: (data) => {
        setBankReviewStatus(QueryStatus.success);
        drawerHandler();

        // Update organisation data in cache with the response from bank-review
        // This will change the organisation status in the detail page without needing a page refresh
        const queryKey = getOrganisationGetQueryKey(organisationId);
        queryClient.setQueryData(queryKey, data);
      },
      onError: (error: any) => {
        setBankReviewStatus(QueryStatus.error);
        setBankReviewError(error.translation?.key || error.detail);
        drawerHandler();
      },
    },
  });

  const startBankReview = () => {
    setBankReviewStatus(QueryStatus.loading);
    drawerHandler();
    mutateBankReview({ organisationid: organisationId });
  };

  useEffect(() => {
    if (shareholders) {
      const shareholder = shareholders.data.find(
        (shareholder) => shareholder.registration_status !== Status.SUGGESTED,
      );
      setShareholder(shareholder);
    }
  }, [shareholders]);

  if (!organisation || isLoading || isLoadingShareholders) {
    return <Loader />;
  }

  const isKycDisabled =
    organisation.kyc_status === KYCStatus.PENDING ||
    organisation.kyc_status === KYCStatus.VALIDATED;

  return (
    <>
      <DashboardLayout>
        <Breadcrumb mb="2rem">
          <BreadcrumbItem>
            <BreadcrumbLink
              variant="breadcrumb"
              as={Link}
              to={getPath(Routes.DASHBOARD)}
            >
              {t(i18nKeys.common.home)}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              variant="breadcrumb"
              as={Link}
              to={getPath(Routes.DASHBOARD_ORGANISATIONS)}
            >
              {t(i18nKeys.operator.organisations.title)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink variant="breadcrumb" as={Link} to="#">
              {capitalize(organisation.legal_name)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box>
          <PageHeader
            size="Large"
            title={capitalize(organisation.legal_name)}
          />
          <Tag status={StatusToTagStatus[organisation.status]} hasIcon>
            <strong>{t(i18nKeys.common.TagStatus[organisation.status])}</strong>
            {organisation.kyc_started_on
              ? ` ${t(i18nKeys.common.from)} ${dayjs(
                  organisation.kyc_started_on,
                ).format(FORMAT_DATE_WITH_HOURS)}
                  ${
                    diffOfDaysFromToday(organisation.kyc_started_on) > 0
                      ? `, ${t(i18nKeys.common.there_is)} ${t(
                          i18nKeys.common.days,
                          {
                            count: diffOfDaysFromToday(
                              organisation.kyc_started_on,
                            ),
                          },
                        )}`
                      : ''
                  }`
              : null}
          </Tag>
        </Box>
        {organisation.status !== Status.VALIDATED && (
          <>
            <Button
              variant="primary"
              size="body2"
              type="button"
              onClick={() => drawerHandler()}
              width={{ sm: 'auto', base: '100%' }}
              mx="auto"
              mt="1rem"
              mr={{ sm: '1rem', base: '0' }}
              isDisabled={isKycDisabled}
            >
              {t(i18nKeys.onboarding.start_kyc)}
            </Button>
            <Tooltip
              size="lg"
              label={
                <Text size="Small">
                  {t(i18nKeys.dashboard.go_onboarding_intro)}
                </Text>
              }
              aria-label={t(i18nKeys.dashboard.go_onboarding_intro)}
            >
              <Button
                as={RouterLink}
                variant="border"
                size="body2"
                mt="1rem"
                width={{ sm: 'auto', base: '100%' }}
                to={getPath(Routes.DASHBOARD_ORGANISATIONS_DETAILS_ONBOARDING, {
                  params: { organisationId },
                })}
                mr={{ sm: '1rem', base: '0' }}
              >
                {t(i18nKeys.dashboard.go_onboarding)}
              </Button>
            </Tooltip>
          </>
        )}

        <Button
          variant="border"
          size="body2"
          mt="1rem"
          width={{ sm: 'auto', base: '100%' }}
          onClick={() => drawerHandler({ drawerType: DrawerType.edit })}
        >
          {t(i18nKeys.common.settings)}
        </Button>
        <OrganisationDetailsOverview
          organisation={organisation}
          shareholders={shareholders.data}
        />
        {organisation?.status !== Status.VALIDATED ||
        (shareholder !== null &&
          shareholder?.registration_status !== Status.VALIDATED) ? (
          <>
            <Heading size="Title2" mt="6rem">
              {t(i18nKeys.operator.organisations.details.kyc.title)}
            </Heading>
            <Text>
              {t(i18nKeys.operator.organisations.details.kyc.introduction)}
            </Text>
            <KycTable organisation={organisation} shareholder={shareholder} />
          </>
        ) : null}
        <Flex
          display={{ base: 'block', sm: 'flex' }}
          justify="space-between"
          mt="6rem"
        >
          <Heading size="Title2">{t(i18nKeys.common.programs)}</Heading>
        </Flex>
        <ProgramsTable organisation={organisation} />
        <Flex
          display={{ base: 'block', sm: 'flex' }}
          justify="space-between"
          mt="6rem"
        >
          <Heading size="Title2">{t(i18nKeys.common.groups.title)}</Heading>
        </Flex>
        <GroupsTable organisation={organisation} />
        <Flex
          display={{ base: 'block', sm: 'flex' }}
          justify="space-between"
          mt="6rem"
        >
          <Heading size="Title2">{t(i18nKeys.common.employees)}</Heading>
          <Button
            mt={{ base: '2rem', md: '0' }}
            variant="primary"
            size="body1"
            onClick={() => drawerHandler({ drawerType: DrawerType.create })}
          >
            {t(i18nKeys.common.add_employees)}
          </Button>
        </Flex>
        <EmployeesTable organisation={organisation} />
      </DashboardLayout>
      {(selectedDrawerType === DrawerType.create && (
        <EmployeesUploadDrawer
          organisationId={organisationId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )) ||
        (selectedDrawerType === DrawerType.edit && (
          <SettingsDrawer
            organisation={organisation}
            isOpen={isOpen}
            onClose={onClose}
          />
        )) || (
          <BankReviewDrawer
            isDisabled={isKycDisabled}
            status={bankReviewStatus}
            startBankReview={startBankReview}
            error={bankReviewError}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
    </>
  );
};
