import { Button, ButtonProps } from '@chakra-ui/button';
import { Box, BoxProps, Flex } from '@chakra-ui/layout';
import { Tooltip } from '@libs/ui/components';
import React, { ReactNode, useContext } from 'react';
import { Table } from '../table';
import { TableRowContext } from './table-row';

type ActionProps = BoxProps & {
  buttonProps?: ButtonProps;
  onClick?: () => void;
  icon?: ReactNode;
  tooltipLabel?: ReactNode;
};

type TableActionCellProps = ActionProps & {
  actions?: {
    onClick: () => void;
    icon: ReactNode;
    tooltipLabel?: ReactNode;
    buttonProps?: ButtonProps;
  }[];
};

type ActionButtonProps = ActionProps & {
  over?: string;
};

export const ActionButton = ({
  over = '',
  onClick,
  icon,
  buttonProps,
  tooltipLabel,
  ...rest
}: ActionButtonProps) => {
  return (
    <Tooltip label={tooltipLabel}>
      <Box minH="3rem" w="3rem" {...rest}>
        <Button
          display={over ? 'inline-flex' : 'none'}
          variant="border"
          size="small"
          p="0"
          fill="main.secondary.normal"
          color="main.secondary.normal"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          {...buttonProps}
        >
          {icon}
        </Button>
      </Box>
    </Tooltip>
  );
};

export const TableActionCell = ({
  onClick,
  icon,
  actions,
  ...rest
}: TableActionCellProps) => {
  const { over } = useContext(TableRowContext);

  return (
    <Table.TD>
      {actions ? (
        <Flex>
          {actions.map(
            ({ icon, onClick, tooltipLabel, buttonProps }, index, arr) => (
              <ActionButton
                {...rest}
                key={`TableActionCell-actions-${index}`}
                onClick={onClick}
                over={over}
                icon={icon}
                mr={index < arr.length ? '1rem' : ''}
                buttonProps={buttonProps}
                tooltipLabel={tooltipLabel}
              />
            ),
          )}
        </Flex>
      ) : (
        <ActionButton {...rest} onClick={onClick} over={over} icon={icon} />
      )}
    </Table.TD>
  );
};
