import { SORT_VALUES } from '@libs/core/constants';
import { useIcon } from '@libs/core/theme/utils';
import React from 'react';

export const SortIcon = ({ value }: { value: string }) => {
  const ArrowSortDesc = useIcon('ArrowSortDesc');
  const ArrowSortAsc = useIcon('ArrowSortAsc');
  const ArrowNoSort = useIcon('ArrowNoSort');

  switch (value) {
    case SORT_VALUES.ASC:
      return <ArrowSortAsc width="100%" />;

    case SORT_VALUES.DESC:
      return <ArrowSortDesc width="100%" />;

    case SORT_VALUES.DEFAULT:
    default:
      return <ArrowNoSort width="100%" />;
  }
};
