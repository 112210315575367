import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import {
  cardsGet,
  getCardsGetQueryKey,
  useCardsUpdate,
} from '@libs/api/endpoints';
import { CardsGetParams, CardStatus } from '@libs/api/models';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer } from '@libs/ui/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { QueryStatusText } from '../../../components/query-status-text';
import { CardDeactivation, CardDrawerProps } from '../../employee.types';

type CardBlockDrawerProps = CardDrawerProps & {
  cardsGetQueryParams: CardsGetParams;
};

export const CardBlockDrawer = ({
  card,
  isOpen,
  onClose,
  cardsGetQueryParams,
}: CardBlockDrawerProps) => {
  const [error, setError] = useState(null);
  const [selectedAction, setSelectedAction] = useState<CardDeactivation>(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState<boolean>(
    card.card_status === CardStatus.LOST,
  );

  useEffect(() => {
    setDisabled(card.card_status === CardStatus.LOST);
  }, [card.card_status]);

  // update the card status in the employee overview
  const successHandler = () => {
    const queryKey = getCardsGetQueryKey(cardsGetQueryParams);
    queryClient.invalidateQueries(queryKey);
    queryClient.fetchQuery(queryKey, () => cardsGet(cardsGetQueryParams));
    setSelectedAction(null);
    setError(null);
  };

  const { mutateAsync: cardUpdate, status } = useCardsUpdate({
    mutation: {
      onError: (error: any) => {
        setError(error.translation?.key || error.detail);
        setSelectedAction(null);
      },
      onSuccess: successHandler,
    },
  });

  const handleDeactivation = (type: CardDeactivation) => {
    setSelectedAction(type);
    const cardStatus =
      type === CardDeactivation.PERMANENT
        ? CardStatus.LOST
        : (card.card_status === CardStatus.UNLOCK && CardStatus.LOCK) ||
          CardStatus.UNLOCK;

    cardUpdate({
      cardid: card.id,
      data: { ...card, card_status: cardStatus },
    });
  };

  const cardI18nKeyBase =
    i18nKeys.operator.cards[
      card.card_status === CardStatus.UNLOCK ? 'lock' : 'unlock'
    ];
  const statusText = {
    [CardDeactivation.PERMANENT]: {
      [QueryStatus.loading]: t(i18nKeys.operator.cards.block.status.loading),
      [QueryStatus.error]: t(i18nKeys.operator.cards.block.status.error),
      [QueryStatus.success]: t(i18nKeys.operator.cards.block.status.success),
    },
    [CardDeactivation.TEMPORARY]: {
      [QueryStatus.loading]: t(cardI18nKeyBase.status.loading),
      [QueryStatus.error]: t(cardI18nKeyBase.status.error),
      [QueryStatus.success]: t(cardI18nKeyBase.status.success),
    },
  };

  const getSubmitButtonText = (deactivationType: CardDeactivation) =>
    deactivationType === CardDeactivation.PERMANENT
      ? t(i18nKeys.operator.cards.block.status.idle)
      : t(cardI18nKeyBase.status.idle);

  return (
    <Drawer
      title={t(i18nKeys.operator.cards.block.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        {Object.values(CardDeactivation).map((deactivationType) => {
          const isPermanent = deactivationType === CardDeactivation.PERMANENT;
          const translationKey = isPermanent ? 'block' : 'lock';
          const isSelectedAction = selectedAction === deactivationType;

          return (
            <Box mb="4rem" key={deactivationType}>
              <Text size="Body2" color="ink.dark" mt="2rem" mb="4rem">
                {t(i18nKeys.operator.cards[translationKey].explanation)}
              </Text>
              <Button
                variant="primary"
                size="body1"
                onClick={() => handleDeactivation(deactivationType)}
                disabled={isDisabled}
                isLoading={status === QueryStatus.loading}
                loadingText={getSubmitButtonText(deactivationType)}
              >
                {getSubmitButtonText(deactivationType)}
              </Button>
              <QueryStatusText
                error={error}
                status={status}
                statusText={statusText[deactivationType]}
                isDisabled={!isSelectedAction}
              />
            </Box>
          );
        })}
      </Drawer.Body>
    </Drawer>
  );
};
