/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyActionAction = 'ASSIGN_REVIEWER' | 'BLOCK' | 'NOTIFY';

export const PolicyActionAction = {
  ASSIGN_REVIEWER: 'ASSIGN_REVIEWER' as PolicyActionAction,
  BLOCK: 'BLOCK' as PolicyActionAction,
  NOTIFY: 'NOTIFY' as PolicyActionAction,
};
