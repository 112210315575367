import { ShareholderDocumentTypeForm } from '../../onboarding-wizard.constants';

export const hasAlreadyCompletedForm = ({
  documentType,
  document,
  secondaryDocument,
}: {
  documentType: ShareholderDocumentTypeForm;
  document: string;
  secondaryDocument?: string;
}): boolean => {
  switch (documentType) {
    case ShareholderDocumentTypeForm.IDENTITY:
      return !!(document && secondaryDocument);

    case ShareholderDocumentTypeForm.PASSPORT:
      return !!document;

    default:
      return false;
  }
};
