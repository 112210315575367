export const fontSizes = {
  Xlarge: '4.5rem',
  large: '3.5rem',
  title1: '3.6rem',
  title2: '2.4rem',
  title3: '2rem',
  headline: '1.7rem',
  subhead: '1.5rem',
  body1: '1.7rem',
  body2: '1.5rem',
  small: '1.3rem',
  xsmall: '1.2rem',
  sm: '1.3rem',
  md: '1.7rem',
  lg: '3.5rem',
  xl: '4.5rem',
};
