import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import {
  cardsGet,
  getCardsGetQueryKey,
  getRegistrationsGetQueryKey,
  useCardsUpdate,
  registrationsGet,
} from '@libs/api/endpoints';
import { useRegistrationDelete } from '@libs/api-v2/endpoints';
import { Card, Registration } from '@libs/api/models';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer } from '@libs/ui/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { QueryStatusText } from '../../../components/query-status-text';

type ArchiveEmployeeDrawerProps = {
  employee: Registration;
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
};

export const ArchiveEmployeeDrawer = ({
  employee,
  isOpen,
  onClose,
  refetch,
}: ArchiveEmployeeDrawerProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const { mutateAsync: archiveEmployee, status } = useRegistrationDelete({
    mutation: {
      onError: (error: any) => {
        setError(error.translation?.key || error.detail);
      },
      onSuccess: () => {
        refetch();
        setError(null);
      },
    },
  });

  const handleArchive = () => {
    archiveEmployee({ membershipid: employee.membership_id });
  };

  const statusText = {
    [QueryStatus.loading]: t(i18nKeys.common.loading),
    [QueryStatus.error]: t(i18nKeys.common.errors.http_error),
    [QueryStatus.success]: t(i18nKeys.operator.cards.conversion.status.success),
  };

  return (
    <Drawer
      title={t(i18nKeys.operator.employees.archive.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <Box>
          <Text size="Body2" color="ink.dark" mt="2rem" mb="4rem">
            {t(i18nKeys.operator.employees.archive.explanation)}
          </Text>
          <Button
            variant="primary"
            size="body1"
            onClick={handleArchive}
            isLoading={status === QueryStatus.loading}
            loadingText={t(i18nKeys.operator.employees.archive.status.idle)}
          >
            {t(i18nKeys.operator.employees.archive.status.idle)}
          </Button>

          <QueryStatusText
            error={error}
            status={status}
            statusText={statusText}
          />
        </Box>
      </Drawer.Body>
    </Drawer>
  );
};
