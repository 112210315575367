import {
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
} from '@chakra-ui/tooltip';
import React from 'react';

type TooltipProps = ChakraTooltipProps;

export const Tooltip = ({ children, ...rest }: TooltipProps) => {
  return (
    <ChakraTooltip
      hasArrow
      bg="ink.white"
      placement="top"
      color="ink.black"
      {...rest}
    >
      {children}
    </ChakraTooltip>
  );
};
