import React from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { useMembershipSummariesGet } from '@libs/api-v2/endpoints';
import { Registration } from '@libs/api/models';
import { useTranslation } from 'react-i18next';
import { MembershipSummariesTable } from './membership-summaries-table';

type MembershipSummariesTableProps = {
  employee: Registration;
};

export const EmployeeDetailsMembershipSummaries = ({
  employee,
}: MembershipSummariesTableProps) => {
  const {
    isLoading,
    isFetching,
    refetch,
    data: MembershipSummaries,
  } = useMembershipSummariesGet(employee.membership_id, null, {
    query: { keepPreviousData: true },
  });

  return (
    <Flex direction="column">
      <MembershipSummariesTable
        isLoading={isLoading}
        isFetching={isFetching}
        membershipSummaries={MembershipSummaries}
        refetch={refetch}
      />
    </Flex>
  );
};
