/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  GroupsGetResponse,
  GroupsGetParams,
  Group,
  GroupCreateRequest,
  GroupUpdateRequest,
  RegistrationsWithoutMetaGetResponse,
  ProgramsGetResponse,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const groupsGet = <Data = unknown>(
  params?: GroupsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? GroupsGetResponse : Data>(
    { url: `/v2/groups`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGroupsGetQueryKey = (params?: GroupsGetParams) => [
  `/v2/groups`,
  ...(params ? [params] : []),
];

export const useGroupsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: GroupsGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof groupsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGroupsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof groupsGet>, Error>(
    queryKey,
    () => groupsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const groupsCreate = <Data = unknown>(
  groupCreateRequest: GroupCreateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Group : Data>(
    { url: `/v2/groups`, method: 'post', data: groupCreateRequest },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useGroupsCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof groupsCreate>,
    Error,
    { data: GroupCreateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof groupsCreate>,
    Error,
    { data: GroupCreateRequest }
  >((props) => {
    const { data } = props || {};

    return groupsCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const groupPut = <Data = unknown>(
  groupid: string,
  groupUpdateRequest: GroupUpdateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Group : Data>(
    { url: `/v2/groups/${groupid}`, method: 'put', data: groupUpdateRequest },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useGroupPut = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof groupPut>,
    Error,
    { groupid: string; data: GroupUpdateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof groupPut>,
    Error,
    { groupid: string; data: GroupUpdateRequest }
  >((props) => {
    const { groupid, data } = props || {};

    return groupPut<Data>(groupid, data, requestOptions);
  }, mutationOptions);
};
export const groupDelete = <Data = unknown>(
  groupid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/groups/${groupid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useGroupDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof groupDelete>,
    Error,
    { groupid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof groupDelete>,
    Error,
    { groupid: string }
  >((props) => {
    const { groupid } = props || {};

    return groupDelete<Data>(groupid, requestOptions);
  }, mutationOptions);
};
export const groupMembersGet = <Data = unknown>(
  groupid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? RegistrationsWithoutMetaGetResponse : Data
  >(
    { url: `/v2/groups/${groupid}/members`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGroupMembersGetQueryKey = (groupid: string) => [
  `/v2/groups/${groupid}/members`,
];

export const useGroupMembersGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  groupid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof groupMembersGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGroupMembersGetQueryKey(groupid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof groupMembersGet>, Error>(
    queryKey,
    () => groupMembersGet<Data>(groupid, requestOptions),
    { enabled: !!groupid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const groupMemberPost = <Data = unknown>(
  groupid: string,
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    {
      url: `/v2/groups/${groupid}/members/${membershipid}`,
      method: 'put',
      data: undefined,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useGroupMemberPost = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof groupMemberPost>,
    Error,
    { groupid: string; membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof groupMemberPost>,
    Error,
    { groupid: string; membershipid: string }
  >((props) => {
    const { groupid, membershipid } = props || {};

    return groupMemberPost<Data>(groupid, membershipid, requestOptions);
  }, mutationOptions);
};
export const groupMemberDelete = <Data = unknown>(
  groupid: string,
  membershipid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? unknown : Data>(
    { url: `/v2/groups/${groupid}/members/${membershipid}`, method: 'delete' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useGroupMemberDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof groupMemberDelete>,
    Error,
    { groupid: string; membershipid: string },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof groupMemberDelete>,
    Error,
    { groupid: string; membershipid: string }
  >((props) => {
    const { groupid, membershipid } = props || {};

    return groupMemberDelete<Data>(groupid, membershipid, requestOptions);
  }, mutationOptions);
};
export const groupProgramsGet = <Data = unknown>(
  groupid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ProgramsGetResponse : Data>(
    { url: `/v2/groups/${groupid}/programs`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGroupProgramsGetQueryKey = (groupid: string) => [
  `/v2/groups/${groupid}/programs`,
];

export const useGroupProgramsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  groupid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof groupProgramsGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGroupProgramsGetQueryKey(groupid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof groupProgramsGet>, Error>(
    queryKey,
    () => groupProgramsGet<Data>(groupid, requestOptions),
    { enabled: !!groupid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
