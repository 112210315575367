/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type Platform = 'MOBILE_APP_MOBILITY' | 'MOBILE_APP_MAAS';

export const Platform = {
  MOBILE_APP_MOBILITY: 'MOBILE_APP_MOBILITY' as Platform,
  MOBILE_APP_MAAS: 'MOBILE_APP_MAAS' as Platform,
};
