/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type CardStatus = 'UNLOCK' | 'LOCK' | 'LOST' | 'STOLEN';

export const CardStatus = {
  UNLOCK: 'UNLOCK' as CardStatus,
  LOCK: 'LOCK' as CardStatus,
  LOST: 'LOST' as CardStatus,
  STOLEN: 'STOLEN' as CardStatus,
};
