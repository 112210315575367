/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, UseQueryOptions } from 'react-query';
import type {
  ServicesGetResponse,
  ServicesGetParams,
  Service,
  ServiceDetails,
  ServiceDetailsParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const servicesGet = <Data = unknown>(
  params?: ServicesGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ServicesGetResponse : Data>(
    { url: `/v2/services`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getServicesGetQueryKey = (params?: ServicesGetParams) => [
  `/v2/services`,
  ...(params ? [params] : []),
];

export const useServicesGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: ServicesGetParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof servicesGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getServicesGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof servicesGet>, Error>(
    queryKey,
    () => servicesGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const serviceGet = <Data = unknown>(
  serviceid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Service : Data>(
    { url: `/v2/services/${serviceid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getServiceGetQueryKey = (serviceid: string) => [
  `/v2/services/${serviceid}`,
];

export const useServiceGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  serviceid: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof serviceGet>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getServiceGetQueryKey(serviceid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof serviceGet>, Error>(
    queryKey,
    () => serviceGet<Data>(serviceid, requestOptions),
    { enabled: !!serviceid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const serviceDetails = <Data = unknown>(
  serviceid: string,
  params?: ServiceDetailsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ServiceDetails : Data>(
    { url: `/v2/services/${serviceid}/details`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getServiceDetailsQueryKey = (
  serviceid: string,
  params?: ServiceDetailsParams,
) => [`/v2/services/${serviceid}/details`, ...(params ? [params] : [])];

export const useServiceDetails = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  serviceid: string,
  params?: ServiceDetailsParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof serviceDetails>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getServiceDetailsQueryKey(serviceid, params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof serviceDetails>, Error>(
    queryKey,
    () => serviceDetails<Data>(serviceid, params, requestOptions),
    { enabled: !!serviceid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
