import { Box } from '@chakra-ui/react';
import { Organisation, Shareholder } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Table } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KycRow } from './kyc-row';
import { KycRowComment } from './kyc-row-comment';

type KycTableProps = {
  organisation: Organisation;
  shareholder: Shareholder;
};

export const KycTable = ({ organisation, shareholder }: KycTableProps) => {
  const { t } = useTranslation();
  return (
    <Table>
      <Table.THead>
        <Table.TR>
          <Table.TH>{t(i18nKeys.common.documents)}</Table.TH>
          <Table.TH>{t(i18nKeys.common.status)}</Table.TH>
          <Table.TH>{t(i18nKeys.common.comments)}</Table.TH>
        </Table.TR>
      </Table.THead>
      <Table.TBody>
        {!organisation.documents && !shareholder?.documents ? (
          <Table.TR>
            <Table.TD colSpan={3}>
              {t(i18nKeys.operator.organisations.details.no_documents)}
            </Table.TD>
          </Table.TR>
        ) : organisation.documents ? (
          <Table.TR key={organisation.id} verticalAlign="top">
            <KycRow
              data={organisation}
              title={t(
                i18nKeys.operator.organisations.details.kyc
                  .organisation_documents,
              )}
            />
            <KycRowComment
              status={organisation.status}
              comment={organisation.kyc_comment}
            />
          </Table.TR>
        ) : null}

        {shareholder ? (
          <Table.TR key={shareholder.id} verticalAlign="top">
            <KycRow
              data={shareholder}
              title={t(
                i18nKeys.operator.organisations.details.kyc
                  .shareholder_documents,
              )}
            />
            <KycRowComment
              status={shareholder.registration_status}
              comment={shareholder.kyc_comment}
            />
          </Table.TR>
        ) : null}
      </Table.TBody>
    </Table>
  );
};
