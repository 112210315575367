/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { PaymentSource } from './paymentSource';

export type ExpenseAllOfPaymentSource = PaymentSource;

export const ExpenseAllOfPaymentSource = { ...PaymentSource };
