import * as React from 'react';

function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C9.23858 4 7 6.23858 7 9C7 11.7614 9.23858 14 12 14C14.7614 14 17 11.7614 17 9C17 6.23858 14.7614 4 12 4ZM5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9C19 12.866 15.866 16 12 16C8.13401 16 5 12.866 5 9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 16C10.3324 16 8.69395 16.4389 7.2495 17.2725C5.80505 18.1062 4.60542 19.3053 3.77112 20.7494C3.49484 21.2276 2.8832 21.3913 2.40499 21.115C1.92678 20.8387 1.76308 20.2271 2.03936 19.7489C3.04924 18.0009 4.50133 16.5494 6.24976 15.5403C7.9982 14.5312 9.9814 14 12.0001 14C14.0189 14 16.0021 14.5313 17.7505 15.5404C19.4989 16.5496 20.951 18.0011 21.9608 19.7491C22.2371 20.2273 22.0734 20.8389 21.5951 21.1152C21.1169 21.3915 20.5053 21.2278 20.229 20.7495C19.3947 19.3054 18.1951 18.1063 16.7507 17.2726C15.3063 16.4389 13.6679 16 12.0001 16Z"
      />
    </svg>
  );
}

export default SvgUser;
