import { useCallback, useState } from 'react';

export const useDebounce = (
  callback: (args?: unknown) => void,
  options?: {
    timeout?: number;
  },
) => {
  const { timeout = 750 } = options || {};
  const [timer, setTimer] = useState(null);

  const onChange = useCallback(() => {
    if (timer) clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        callback();
      }, timeout),
    );
  }, [callback, timeout, timer]);

  return { onChange, timer };
};
