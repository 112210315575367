import { useServicesGet } from '@libs/api-v2/endpoints/services/services';
import { Program } from '@libs/api-v2/models';
import { Budget } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Card, Drawer } from '@libs/ui/components';
import { usePager } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceTable } from './services-table';

type ProgramServicesDrawerProps = {
  program: Program;
  isOpen: boolean;
  onClose: () => void;
};

export const ProgramServicesDrawer = ({
  program,
  isOpen,
  onClose,
}: ProgramServicesDrawerProps) => {
  const { t } = useTranslation();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const params = {
    program_id: program.id,
    page_number: pageNumber,
    page_size: pageSize,
  };
  const {
    isLoading,
    isFetching,
    refetch,
    data: Services,
  } = useServicesGet(params, { query: { keepPreviousData: true } });

  return (
    <Drawer
      title={t(i18nKeys.operator.employees.programs.services.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <Card bgColor="background.page" mt="3rem">
          <ServiceTable
            isLoading={isLoading}
            isFetching={isFetching}
            services={Services}
            refetch={refetch}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            servicesGetParams={params}
          />
        </Card>
      </Drawer.Body>
    </Drawer>
  );
};
