/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type { Document, DocumentCreateBody, DocumentType } from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const documentCreate = <Data = unknown>(
  documentCreateBody: DocumentCreateBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Document : Data>(
    { url: `/documents`, method: 'post', data: documentCreateBody },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useDocumentCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof documentCreate>,
    Error,
    { data: DocumentCreateBody },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof documentCreate>,
    Error,
    { data: DocumentCreateBody }
  >((props) => {
    const { data } = props || {};

    return documentCreate<Data>(data, requestOptions);
  }, mutationOptions);
};
export const documentsTypesGet = <Data = unknown>(
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? DocumentType[] : Data>(
    { url: `/documents/types`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getDocumentsTypesGetQueryKey = () => [`/documents/types`];

export const useDocumentsTypesGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof documentsTypesGet>, Error>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const queryKey = getDocumentsTypesGetQueryKey();
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof documentsTypesGet>, Error>(
    queryKey,
    () => documentsTypesGet<Data>(requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
