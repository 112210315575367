/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type Status =
  | 'SUGGESTED'
  | 'CREATED'
  | 'CONFIRMED'
  | 'VALIDATED'
  | 'REFUSED'
  | 'CANCELED'
  | 'STARTED'
  | 'IN_REVIEW';

export const Status = {
  SUGGESTED: 'SUGGESTED' as Status,
  CREATED: 'CREATED' as Status,
  CONFIRMED: 'CONFIRMED' as Status,
  VALIDATED: 'VALIDATED' as Status,
  REFUSED: 'REFUSED' as Status,
  CANCELED: 'CANCELED' as Status,
  STARTED: 'STARTED' as Status,
  IN_REVIEW: 'IN_REVIEW' as Status,
};
