/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */

export type BudgetStatus = 'VALIDATED' | 'BLOCKED';

export const BudgetStatus = {
  VALIDATED: 'VALIDATED' as BudgetStatus,
  BLOCKED: 'BLOCKED' as BudgetStatus,
};
