import * as React from 'react';

function SvgCheckRounded(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1463 8.30953C16.5118 8.70903 16.4977 9.34202 16.1148 9.72336L10.844 14.9734C10.4736 15.3422 9.89088 15.3422 9.52056 14.9734L6.88517 12.3484C6.50232 11.967 6.48822 11.334 6.85367 10.9345C7.21912 10.535 7.82574 10.5203 8.20859 10.9016L10.1823 12.8676L14.7914 8.27664C15.1743 7.89531 15.7809 7.91003 16.1463 8.30953Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 4C7.26582 4 3.83334 7.58172 3.83334 12C3.83334 16.4183 7.26582 20 11.5 20C15.7342 20 19.1667 16.4183 19.1667 12C19.1667 7.58172 15.7342 4 11.5 4ZM1.91667 12C1.91667 6.47715 6.20728 2 11.5 2C16.7927 2 21.0833 6.47715 21.0833 12C21.0833 17.5228 16.7927 22 11.5 22C6.20728 22 1.91667 17.5228 1.91667 12Z"
      />
    </svg>
  );
}

export default SvgCheckRounded;
