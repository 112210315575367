import * as React from 'react';

function SvgHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8222 2.71802C11.1443 2.42513 11.5641 2.26283 11.9994 2.26282C12.4348 2.2628 12.8545 2.42508 13.1767 2.71795L20.6772 9.53686C20.8576 9.70088 21.0018 9.90079 21.1004 10.1238C21.199 10.3468 21.25 10.5879 21.25 10.8317V19.4999C21.25 19.7297 21.2047 19.9573 21.1168 20.1696C21.0288 20.382 20.8999 20.5749 20.7374 20.7374C20.5748 20.8999 20.3819 21.0288 20.1695 21.1167C19.9572 21.2047 19.7296 21.2499 19.4998 21.2499L14.9992 21.2493C14.5351 21.2492 14.0901 21.0648 13.7619 20.7366C13.4338 20.4084 13.2494 19.9634 13.2495 19.4993V15.2492H10.7495V19.4993C10.7495 19.9634 10.5651 20.4085 10.237 20.7366C9.90882 21.0648 9.46376 21.2492 8.99968 21.2493L4.50023 21.2499C4.27039 21.2499 4.0428 21.2047 3.83046 21.1167C3.61811 21.0288 3.42517 20.8999 3.26264 20.7374C3.10012 20.5749 2.97119 20.3819 2.88323 20.1696C2.79527 19.9573 2.75 19.7297 2.75 19.4999V10.8317C2.75 10.5879 2.80095 10.3468 2.89957 10.1238C2.99819 9.90083 3.14232 9.70093 3.32271 9.53691L10.8222 2.71802ZM11.9995 4.3507L4.75 10.9423V19.2498L8.74945 19.2493V14.9992C8.74945 14.535 8.93383 14.0899 9.26201 13.7617C9.5902 13.4336 10.0353 13.2492 10.4995 13.2492H13.4995C13.9636 13.2492 14.4087 13.4336 14.7369 13.7617C15.0651 14.0899 15.2495 14.5351 15.2495 14.9992V19.2493L19.25 19.2498V10.9423L11.9995 4.3507ZM12.1674 4.19806C12.1675 4.19797 12.1676 4.19788 12.1677 4.19779L11.9995 4.01282L12.1677 4.19779L12.1674 4.19806Z"
      />
    </svg>
  );
}

export default SvgHome;
