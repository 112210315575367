import { Heading, Text, AspectRatio, Flex, Box, Image } from '@chakra-ui/react';
import { NotionBlock, NotionTextArray } from '@libs/api-v2/models';
import { Fragment, ReactNode } from 'react';
import { ServiceHeadingSpan } from './heading-span';
import { ServiceTextSpan } from './text-span';
import React from 'react';

type ServiceContentProps = {
  contents: NotionBlock[] | undefined;
};

export const ServiceContent = ({ contents }: ServiceContentProps) => {
  // Attributes
  // const theme = useTheme();

  // Handlers
  function renderInlineHeading(text: NotionTextArray) {
    return text.map((text, index) => (
      <ServiceHeadingSpan text={text} index={index} />
    ));
  }

  function renderInlineText(text: NotionTextArray) {
    return text
      .filter((text) => text.plain_text)
      .map((text, index) => <ServiceTextSpan text={text} index={index} />);
  }

  function mapContent(content) {
    if (!content) {
      return <Fragment />;
    }
    switch (content?.type) {
      case 'video':
        return (
          <AspectRatio maxW="327px" ratio={1}>
            <iframe src={content.video?.external?.url} allowFullScreen />
          </AspectRatio>
        );
      case 'image':
        return <Image src={content?.image?.file?.url} />;
      case 'heading_2':
        return (
          <Heading size="600" mb={6} mt={6}>
            {renderInlineHeading(content?.heading_2?.text ?? [])}
          </Heading>
        );
      case 'heading_3':
        return (
          <Heading size="500" mb={6} mt={6}>
            {renderInlineHeading(content?.heading_3?.text ?? [])}
          </Heading>
        );
      case 'paragraph':
        return content?.paragraph?.text &&
          content?.paragraph?.text.length > 0 ? (
          <Text mb={6}>{renderInlineText(content.paragraph?.text)}</Text>
        ) : undefined;
      case 'bulleted_list_item':
        return (
          <Flex direction="row" /* alignItems="center" */>
            <Box
              borderRadius={1000}
              maxH={'0.313rem'}
              minW={'0.313rem'}
              width={'0.313rem'}
              height={'0.313rem'}
              ml={'0.600rem'}
              mr={'0.600rem'}
              mt={'.4375rem'}
            />
            <Text>{renderInlineText(content?.bulleted_list_item?.text)}</Text>
          </Flex>
        );
      default:
        return <Text mb={6}>{}</Text>;
    }
  }

  function renderResults(results: NotionBlock[] | undefined) {
    return results?.map((content) => renderContent(content));
  }

  function renderContent(content: NotionBlock) {
    let parent: ReactNode;
    let children: ReactNode;
    if (content.has_children) {
      parent = mapContent(content);
      children = renderResults(content?.children_blocks);
    } else {
      parent = mapContent(content);
    }

    return (
      <Box>
        {parent}
        {children}
      </Box>
    );
  }

  return <>{renderResults(contents ?? [])}</>;
};
