export const Heading = {
  baseStyle: ({ theme }) => ({
    fontFamily: theme.fonts.title1,
    color: theme.colors.title.color1,
  }),
  sizes: {
    XLarge: ({ theme }) => ({
      fontFamily: theme.fonts.Xlarge,
      fontSize: theme.fontSizes.Xlarge,
      lineHeight: theme.lineHeights.Xlarge,
    }),
    Large: ({ theme }) => ({
      fontFamily: theme.fonts.large,
      fontSize: theme.fontSizes.large,
      lineHeight: theme.lineHeights.large,
    }),
    Title1: ({ theme }) => ({
      fontFamily: theme.fonts.title1,
      fontSize: theme.fontSizes.title1,
      lineHeight: theme.lineHeights.title1,
    }),
    Title2: ({ theme }) => ({
      fontFamily: theme.fonts.title2,
      fontSize: theme.fontSizes.title2,
      lineHeight: theme.lineHeights.title2,
    }),
    Title3: ({ theme }) => ({
      fontFamily: theme.fonts.title3,
      fontSize: theme.fontSizes.title3,
      lineHeight: theme.lineHeights.title3,
    }),
    Headline: ({ theme }) => ({
      fontFamily: theme.fonts.headline,
      fontSize: theme.fontSizes.headline,
      lineHeight: theme.lineHeights.headline,
    }),
    Subhead: ({ theme }) => ({
      fontFamily: theme.fonts.subhead,
      fontSize: theme.fontSizes.subhead,
      lineHeight: theme.lineHeights.subhead,
    }),
    Body1: ({ theme }) => ({
      fontFamily: theme.fonts.body1Bold,
      fontSize: theme.fontSizes.body1,
      lineHeight: theme.lineHeights.body1,
    }),
    Body2: ({ theme }) => ({
      fontFamily: theme.fonts.body2Bold,
      fontSize: theme.fontSizes.body2,
      lineHeight: theme.lineHeights.body2,
    }),
    Small: ({ theme }) => ({
      fontFamily: theme.fonts.SmallBold,
      fontSize: theme.fontSizes.small,
      lineHeight: theme.lineHeights.small,
    }),
  },
};
