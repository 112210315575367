import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const HeaderStyled = styled(Box)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.ink.white};
  position: fixed;
  left: 0;
  right: '0';
  z-index: 500;
  backdrop-filter: blur(1rem);
  background-color: rgba(255, 255, 255, 0.5);
`;
