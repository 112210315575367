/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type InvitationStatus = 'SCHEDULED' | 'INVITED';

export const InvitationStatus = {
  SCHEDULED: 'SCHEDULED' as InvitationStatus,
  INVITED: 'INVITED' as InvitationStatus,
};
