/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  MerchantRestrictionGroupsGetResponse,
  MerchantIdRestrictionGroupsGetParams,
  MerchantIdRestrictionGroupGet200,
  MerchantRestrictionGroup,
  MerchantRestrictionGroupsRequest,
  MerchantIdRestrictionGroupMerchantsListUpdateParams,
  MerchantIdRestrictionGroupMerchantsListDeleteParams,
  MerchantRestrictionGroupsGetPresenceResponse,
  MerchantIdRestrictionGroupsGetPresenceParams,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const merchantIdRestrictionGroupsGet = <Data = unknown>(
  params?: MerchantIdRestrictionGroupsGetParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MerchantRestrictionGroupsGetResponse : Data
  >(
    { url: `/merchantIdRestrictionGroups`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMerchantIdRestrictionGroupsGetQueryKey = (
  params?: MerchantIdRestrictionGroupsGetParams,
) => [`/merchantIdRestrictionGroups`, ...(params ? [params] : [])];

export const useMerchantIdRestrictionGroupsGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: MerchantIdRestrictionGroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof merchantIdRestrictionGroupsGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMerchantIdRestrictionGroupsGetQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof merchantIdRestrictionGroupsGet>,
    Error
  >(
    queryKey,
    () => merchantIdRestrictionGroupsGet<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const merchantIdRestrictionGroupGet = <Data = unknown>(
  midrestrictionid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MerchantIdRestrictionGroupGet200 : Data
  >(
    { url: `/merchantIdRestrictionGroups/${midrestrictionid}`, method: 'get' },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMerchantIdRestrictionGroupGetQueryKey = (
  midrestrictionid: string,
) => [`/merchantIdRestrictionGroups/${midrestrictionid}`];

export const useMerchantIdRestrictionGroupGet = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  midrestrictionid: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof merchantIdRestrictionGroupGet>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMerchantIdRestrictionGroupGetQueryKey(midrestrictionid);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof merchantIdRestrictionGroupGet>,
    Error
  >(
    queryKey,
    () => merchantIdRestrictionGroupGet<Data>(midrestrictionid, requestOptions),
    { enabled: !!midrestrictionid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const merchantIdRestrictionGroupUpdate = <Data = unknown>(
  midrestrictionid: string,
  merchantRestrictionGroupsRequest: MerchantRestrictionGroupsRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? MerchantRestrictionGroup : Data>(
    {
      url: `/merchantIdRestrictionGroups/${midrestrictionid}`,
      method: 'put',
      data: merchantRestrictionGroupsRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMerchantIdRestrictionGroupUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof merchantIdRestrictionGroupUpdate>,
    Error,
    { midrestrictionid: string; data: MerchantRestrictionGroupsRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof merchantIdRestrictionGroupUpdate>,
    Error,
    { midrestrictionid: string; data: MerchantRestrictionGroupsRequest }
  >((props) => {
    const { midrestrictionid, data } = props || {};

    return merchantIdRestrictionGroupUpdate<Data>(
      midrestrictionid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const merchantIdRestrictionGroupMerchantsListUpdate = <Data = unknown>(
  midrestrictionid: string,
  params?: MerchantIdRestrictionGroupMerchantsListUpdateParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? MerchantRestrictionGroup : Data>(
    {
      url: `/merchantIdRestrictionGroups/${midrestrictionid}/merchants`,
      method: 'put',
      data: undefined,
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMerchantIdRestrictionGroupMerchantsListUpdate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof merchantIdRestrictionGroupMerchantsListUpdate>,
    Error,
    {
      midrestrictionid: string;
      params?: MerchantIdRestrictionGroupMerchantsListUpdateParams;
    },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof merchantIdRestrictionGroupMerchantsListUpdate>,
    Error,
    {
      midrestrictionid: string;
      params?: MerchantIdRestrictionGroupMerchantsListUpdateParams;
    }
  >((props) => {
    const { midrestrictionid, params } = props || {};

    return merchantIdRestrictionGroupMerchantsListUpdate<Data>(
      midrestrictionid,
      params,
      requestOptions,
    );
  }, mutationOptions);
};
export const merchantIdRestrictionGroupMerchantsListDelete = <Data = unknown>(
  midrestrictionid: string,
  params?: MerchantIdRestrictionGroupMerchantsListDeleteParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? MerchantRestrictionGroup : Data>(
    {
      url: `/merchantIdRestrictionGroups/${midrestrictionid}/merchants`,
      method: 'delete',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useMerchantIdRestrictionGroupMerchantsListDelete = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof merchantIdRestrictionGroupMerchantsListDelete>,
    Error,
    {
      midrestrictionid: string;
      params?: MerchantIdRestrictionGroupMerchantsListDeleteParams;
    },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof merchantIdRestrictionGroupMerchantsListDelete>,
    Error,
    {
      midrestrictionid: string;
      params?: MerchantIdRestrictionGroupMerchantsListDeleteParams;
    }
  >((props) => {
    const { midrestrictionid, params } = props || {};

    return merchantIdRestrictionGroupMerchantsListDelete<Data>(
      midrestrictionid,
      params,
      requestOptions,
    );
  }, mutationOptions);
};
export const merchantIdRestrictionGroupsGetPresence = <Data = unknown>(
  midrestrictionid: string,
  params?: MerchantIdRestrictionGroupsGetPresenceParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<
    Data extends unknown ? MerchantRestrictionGroupsGetPresenceResponse : Data
  >(
    {
      url: `/merchantIdRestrictionGroups/${midrestrictionid}/presence`,
      method: 'get',
      params,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getMerchantIdRestrictionGroupsGetPresenceQueryKey = (
  midrestrictionid: string,
  params?: MerchantIdRestrictionGroupsGetPresenceParams,
) => [
  `/merchantIdRestrictionGroups/${midrestrictionid}/presence`,
  ...(params ? [params] : []),
];

export const useMerchantIdRestrictionGroupsGetPresence = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  midrestrictionid: string,
  params?: MerchantIdRestrictionGroupsGetPresenceParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof merchantIdRestrictionGroupsGetPresence>,
      Error
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getMerchantIdRestrictionGroupsGetPresenceQueryKey(
    midrestrictionid,
    params,
  );
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<
    AsyncReturnType<typeof merchantIdRestrictionGroupsGetPresence>,
    Error
  >(
    queryKey,
    () =>
      merchantIdRestrictionGroupsGetPresence<Data>(
        midrestrictionid,
        params,
        requestOptions,
      ),
    { enabled: !!midrestrictionid, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
