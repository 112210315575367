export const Text = {
  baseStyle: ({ theme }) => ({
    fontFamily: theme.fonts.body1,
    fontSize: theme.fontSizes.body1,
    lineHeight: theme.lineHeights.body1,
    color: theme.colors.ink.black,
  }),
  sizes: {
    Body1: ({ theme }) => ({
      fontSize: theme.fontSizes.body1,
      lineHeight: theme.lineHeights.body1,
    }),
    Body1Bold: ({ theme }) => ({
      fontFamily: theme.fonts.body1Bold,
      fontSize: theme.fontSizes.body1Bold,
      lineHeight: theme.lineHeights.body1,
    }),
    Body2: ({ theme }) => ({
      fontSize: theme.fontSizes.body2,
      lineHeight: theme.lineHeights.body2,
    }),
    Body2Bold: ({ theme }) => ({
      fontFamily: theme.fonts.body2Bold,
      fontSize: theme.fontSizes.body2Bold,
      lineHeight: theme.lineHeights.body2,
    }),
    Small: ({ theme }) => ({
      fontSize: theme.fontSizes.small,
      lineHeight: theme.lineHeights.small,
    }),
    SmallBold: ({ theme }) => ({
      fontFamily: theme.fonts.smallBold,
      fontSize: theme.fontSizes.small,
      lineHeight: theme.lineHeights.small,
    }),
    XSmall: ({ theme }) => ({
      fontSize: theme.fontSizes.xsmall,
      lineHeight: theme.lineHeights.xsmall,
    }),
  },
};
