/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import type {
  MerchantsGetResponse,
  GetMerchantsParams,
  Merchant,
  UpdateMerchantRequest,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getMerchants = <Data = unknown>(
  params?: GetMerchantsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? MerchantsGetResponse : Data>(
    { url: `/v2/merchants`, method: 'get', params },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const getGetMerchantsQueryKey = (params?: GetMerchantsParams) => [
  `/v2/merchants`,
  ...(params ? [params] : []),
];

export const useGetMerchants = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
  params?: GetMerchantsParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getMerchants>, Error>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const queryKey = getGetMerchantsQueryKey(params);
  const { query: queryOptions, request: requestOptions } = options || {};

  const query = useQuery<AsyncReturnType<typeof getMerchants>, Error>(
    queryKey,
    () => getMerchants<Data>(params, requestOptions),
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateMerchant = <Data = unknown>(
  merchantid: string,
  updateMerchantRequest: UpdateMerchantRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? Merchant : Data>(
    {
      url: `/v2/merchants/${merchantid}`,
      method: 'put',
      data: updateMerchantRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useUpdateMerchant = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateMerchant>,
    Error,
    { merchantid: string; data: UpdateMerchantRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof updateMerchant>,
    Error,
    { merchantid: string; data: UpdateMerchantRequest }
  >((props) => {
    const { merchantid, data } = props || {};

    return updateMerchant<Data>(merchantid, data, requestOptions);
  }, mutationOptions);
};
