import * as React from 'react';

function SvgLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6082 5.35539C15.9987 4.96487 16.6319 4.96487 17.0224 5.35539L20.9599 9.29289C21.3504 9.68342 21.3504 10.3166 20.9599 10.7071L17.0224 14.6446C16.6319 15.0351 15.9987 15.0351 15.6082 14.6446C15.2176 14.2541 15.2176 13.6209 15.6082 13.2304L18.8386 10L15.6082 6.76961C15.2176 6.37908 15.2176 5.74592 15.6082 5.35539Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 10C8.75 9.44772 9.19772 9 9.75 9H20.25C20.8023 9 21.25 9.44772 21.25 10C21.25 10.5523 20.8023 11 20.25 11H9.75C9.19772 11 8.75 10.5523 8.75 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 2.75H11.25C11.8023 2.75 12.25 2.30228 12.25 1.75C12.25 1.19772 11.8023 0.75 11.25 0.75H4.5C4.03587 0.75 3.59075 0.934375 3.26256 1.26256C2.93438 1.59075 2.75 2.03587 2.75 2.5V17.5C2.75 17.9641 2.93437 18.4092 3.26256 18.7374C3.59075 19.0656 4.03587 19.25 4.5 19.25H11.25C11.8023 19.25 12.25 18.8023 12.25 18.25C12.25 17.6977 11.8023 17.25 11.25 17.25H4.75V2.75Z"
      />
    </svg>
  );
}

export default SvgLogout;
