import { Box } from '@chakra-ui/layout';
import { Expense } from '@libs/api/models';
import { FORMAT_DATE } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Direction } from '@libs/core/types';
import { Currency, DescriptionData, Drawer } from '@libs/ui/components';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ExpenseDetailsDrawerProps = {
  expense: Expense;
  isOpen: boolean;
  onClose: () => void;
};

export const ExpenseDetailsDrawer = ({
  expense,
  isOpen,
  onClose,
}: ExpenseDetailsDrawerProps) => {
  const { t } = useTranslation();

  return (
    <Drawer
      title={t(i18nKeys.operator.employees.expenses.details.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <Box m="2rem">
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.expenses)}
          >
            {expense.id}
          </DescriptionData>

          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.employees.budgets.type.title)}
          >
            {t(i18nKeys.operator.employees.budgets.type[expense.budget_type])}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.employees.expenses.is_home_work)}
          >
            {expense.is_home_work
              ? t(i18nKeys.common.yes)
              : t(i18nKeys.common.no)}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.employees.expenses.title)}
          >
            {expense.title}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.employees.expenses.expense_date)}
          >
            {expense.created_at &&
              dayjs(expense.created_at).format(FORMAT_DATE)}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.amount)}
            formatChildren={false}
          >
            <Currency alignItems="center">{expense.amount}</Currency>
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.employees.expenses.expense_code)}
          >
            {expense.expense_code}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.operator.employees.expenses.category_code)}
          >
            {t(i18nKeys.common.ExpenseCategoryCode[expense.category_code])}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.source)}
          >
            {expense.payment_source}
          </DescriptionData>
          <DescriptionData
            direction={Direction.horizontal}
            title={t(i18nKeys.common.comments)}
          >
            {expense.validation_comment}
          </DescriptionData>
        </Box>
      </Drawer.Body>
    </Drawer>
  );
};
