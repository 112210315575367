import React, { ReactNode } from 'react';
import { StyledTableHeadCell } from '../table.style';

type TableHeadCellProps = {
  children: ReactNode;
  colSpan?: number;
};

export const TableHeadCell = ({
  children,
  colSpan,
  ...rest
}: TableHeadCellProps) => {
  return (
    <StyledTableHeadCell colSpan={colSpan} {...rest}>
      {children}
    </StyledTableHeadCell>
  );
};
