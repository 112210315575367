/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useMutation, UseMutationOptions } from 'react-query';
import type {
  CostEstimationResponse,
  CostEstimationRequest,
  TripCreateRequest,
  ExpenseWithMeta,
} from '../../models';
import { customInstance } from '../../config';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const kmAllowanceCostEstimationCalculate = <Data = unknown>(
  productid: string,
  costEstimationRequest: CostEstimationRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? CostEstimationResponse : Data>(
    {
      url: `/v2/products/${productid}/km_allowance/cost_estimations`,
      method: 'post',
      data: costEstimationRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useKmAllowanceCostEstimationCalculate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof kmAllowanceCostEstimationCalculate>,
    Error,
    { productid: string; data: CostEstimationRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof kmAllowanceCostEstimationCalculate>,
    Error,
    { productid: string; data: CostEstimationRequest }
  >((props) => {
    const { productid, data } = props || {};

    return kmAllowanceCostEstimationCalculate<Data>(
      productid,
      data,
      requestOptions,
    );
  }, mutationOptions);
};
export const kmAllowanceCalculateTrip = <Data = unknown>(
  productid: string,
  tripCreateRequest: TripCreateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? CostEstimationResponse : Data>(
    {
      url: `/v2/products/${productid}/km_allowance/calculate_trip_cost`,
      method: 'post',
      data: tripCreateRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useKmAllowanceCalculateTrip = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof kmAllowanceCalculateTrip>,
    Error,
    { productid: string; data: TripCreateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof kmAllowanceCalculateTrip>,
    Error,
    { productid: string; data: TripCreateRequest }
  >((props) => {
    const { productid, data } = props || {};

    return kmAllowanceCalculateTrip<Data>(productid, data, requestOptions);
  }, mutationOptions);
};
export const kmAllowanceCreate = <Data = unknown>(
  productid: string,
  tripCreateRequest: TripCreateRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Data extends unknown ? ExpenseWithMeta : Data>(
    {
      url: `/v2/products/${productid}/km_allowance/trip`,
      method: 'post',
      data: tripCreateRequest,
    },
    // eslint-disable-next-line
    // @ts-ignore
    options,
  );
};

export const useKmAllowanceCreate = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof kmAllowanceCreate>,
    Error,
    { productid: string; data: TripCreateRequest },
    unknown
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  return useMutation<
    AsyncReturnType<typeof kmAllowanceCreate>,
    Error,
    { productid: string; data: TripCreateRequest }
  >((props) => {
    const { productid, data } = props || {};

    return kmAllowanceCreate<Data>(productid, data, requestOptions);
  }, mutationOptions);
};
