import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { FC, ReactNode } from 'react';
import { FormControl } from '../components/form-control';
import { BaseProps } from '../form.types';

export type CheckboxFieldProps = BaseProps & {
  checkboxProps?: CheckboxProps;
  children?: ReactNode;
};

export const CheckboxField: FC<CheckboxFieldProps> = (
  props: CheckboxFieldProps,
) => {
  const { name, label, checkboxProps, children, ...formControlProps } = props;
  const [field, meta] = useField(name);
  return (
    <FormControl
      id={name}
      meta={meta}
      isEmpty={!field.value}
      label={label}
      errorLeftAlignement="3rem"
      {...formControlProps}
    >
      <Checkbox
        variant="sorting"
        isChecked={field.value}
        {...field}
        {...checkboxProps}
        alignItems="flex-start"
      >
        {children}
      </Checkbox>
    </FormControl>
  );
};
