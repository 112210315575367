import { SelectProps } from '@chakra-ui/react';
import { useIcon } from '@libs/core/theme/utils';
import { useField } from 'formik';
import React, { FC, ReactNode } from 'react';
import { FormControl } from '../components/form-control';
import {
  InputBar,
  InputGroup,
  InputHighLight,
  SelectStyledField,
} from '../form.style';
import { BaseProps } from '../form.types';

export type SelectFieldProps = BaseProps & {
  children: ReactNode;
  icon?: ReactNode;
  handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectInputField = ({
  value,
  onChange,
  children,
  icon,
  ...field
}: SelectFieldProps & SelectProps) => {
  const IconSelect = useIcon('ArrowDown');
  return (
    <InputGroup>
      <SelectStyledField
        {...field}
        value={value}
        onChange={onChange}
        isEmpty={!value}
        icon={icon || <IconSelect width="2.5rem" />}
      >
        {!value && <option></option>}
        {children}
      </SelectStyledField>
      <InputHighLight />
      <InputBar />
    </InputGroup>
  );
};

export const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const { name, children, handleChange, ...formControlProps } = props;

  const [field, meta, helpers] = useField(name);
  return (
    <FormControl
      id={name}
      meta={meta}
      isEmpty={!field.value}
      {...formControlProps}
    >
      <SelectInputField
        {...field}
        value={field.value || ''}
        children={children}
        onChange={(event) => {
          helpers.setValue(event.target.value);
          if (handleChange) {
            handleChange(event);
          }
        }}
      />
    </FormControl>
  );
};
