/*
 * Generated by orval v5.1.2 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type SplittableTransactionStatus =
  | 'UNKNOWN'
  | 'AWAITING_PRODUCT_ASSOCIATION'
  | 'AWAITING_SPLIT_PAYMENT'
  | 'RECONCILED';

export const SplittableTransactionStatus = {
  UNKNOWN: 'UNKNOWN' as SplittableTransactionStatus,
  AWAITING_PRODUCT_ASSOCIATION: 'AWAITING_PRODUCT_ASSOCIATION' as SplittableTransactionStatus,
  AWAITING_SPLIT_PAYMENT: 'AWAITING_SPLIT_PAYMENT' as SplittableTransactionStatus,
  RECONCILED: 'RECONCILED' as SplittableTransactionStatus,
};
