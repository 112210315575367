import { BudgetStatus, CardStatus, ValidationStatus } from '@libs/api/models';
import { TagStatus } from '@libs/ui/components';
import { ActivationStatus, ExpenseReviewStatus } from '@libs/api-v2/models';

export const ValidationStatusToTagStatus = {
  [ValidationStatus.REFUSED]: TagStatus.NEGATIVE,
  [ValidationStatus.APPROVED]: TagStatus.POSITIVE,
  [ValidationStatus.NEED_MORE_INFO]: TagStatus.INFO,
  [ValidationStatus.PENDING]: TagStatus.PENDING,
  [ValidationStatus.NO_APPROVAL_REQUIRED]: TagStatus.POSITIVE,
};

export const CardStatusToTagStatus = {
  [CardStatus.STOLEN]: TagStatus.NEGATIVE,
  [CardStatus.LOST]: TagStatus.NEGATIVE,
  [CardStatus.LOCK]: TagStatus.NEGATIVE,
  [CardStatus.UNLOCK]: TagStatus.POSITIVE,
};

export const BudgetStatusToTagStatus = {
  [BudgetStatus.VALIDATED]: TagStatus.POSITIVE,
  [BudgetStatus.BLOCKED]: TagStatus.NEGATIVE,
};

export const ProgramStatusToTagStatus = {
  [ActivationStatus.ACTIVE]: TagStatus.POSITIVE,
  [ActivationStatus.INACTIVE]: TagStatus.NEGATIVE,
};

export const ExpenseReviewStatusToTagStatus = {
  [ExpenseReviewStatus.REFUSED]: TagStatus.NEGATIVE,
  [ExpenseReviewStatus.APPROVED]: TagStatus.POSITIVE,
  [ExpenseReviewStatus.NEED_MORE_INFO]: TagStatus.INFO,
  [ExpenseReviewStatus.PENDING]: TagStatus.PENDING,
  [ExpenseReviewStatus.MISSING_PROVIDER]: TagStatus.NEGATIVE,
};
