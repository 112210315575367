export const Drawer = {
  baseStyle: ({ theme }) => ({
    header: {
      fontFamily: theme.fonts.title1,
      fontSize: theme.fontSizes.title2,
      py: '3rem',
    },
    overlay: {
      zIndex: 2147483005,
    },
    footer: { borderTopWidth: '.1rem', py: '3rem' },
  }),
  sizes: {
    md: {},
  },
};
