export const Popover = {
  variants: {
    responsive: {
      popper: {
        maxWidth: 'unset',
        width: 'unset',
      },
    },
  },
};
